import React, { Fragment, useEffect, useState, useContext } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import InviteTableToolbar from "./InviteTableToolbar";
import InviteTableHead from "./InviteTableHead";
import { InviteTableStyles } from "./InviteTableStyles";
import { getComparator, stableSort } from "../../TableHelpers";
import Row from "./Row";
import { GlobalContext } from "../../../contexts/GlobalContext";

const InviteTable = ({
  validParticipants,
  setValidParticipants,
  setDeleteFlag,
}) => {
  const classes = InviteTableStyles();
  const [rows, setRows] = useState([]);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("email");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [appData] = useContext(GlobalContext);

  const createData = (name, email, share, phone, id, status, emailStatus) => {
    if ([1, 2, 6, 7].includes(appData.user_role_id)) {
      return { name, email, share, phone, id, status, emailStatus };
    } else {
      return { name, email, phone, id, status, emailStatus };
    }
  };
  useEffect(() => {
    const tempRows = [];
    validParticipants.forEach((item) => {
      tempRows.push(
        createData(
          item.name,
          item.email,
          item.share ? Number(item.share) : null,
          item.phone ? Number(item.phone) : null,
          item.id,
          item.status,
          item.emailStatus
        )
      );
    });
    setRows(tempRows);
  }, [validParticipants]);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      setSelected(newSelecteds);

      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <>
      <InviteTableToolbar
        validParticipants={validParticipants}
        numSelected={selected.length}
        selected={selected}
        setDeleteFlag={setDeleteFlag}
      />
      <TableContainer component={Paper}>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size={dense ? "small" : "medium"}
          aria-label="enhanced table"
        >
          <InviteTableHead
            classes={classes}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            rowCount={rows.length}
            setOrder={setOrder}
            setOrderBy={setOrderBy}
          />
          <TableBody>
            {stableSort(rows, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <Row
                    key={index}
                    index={index}
                    isItemSelected={isItemSelected}
                    labelId={labelId}
                    row={row}
                    selected={selected}
                    setSelected={setSelected}
                    setValidParticipants={setValidParticipants}
                    validParticipants={validParticipants}
                  />
                );
              })}

            {emptyRows > 0 && (
              <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />

      <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
    </>
  );
};

export default InviteTable;
