import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { Box, Chip, Collapse, MuiThemeProvider } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { CreatorTableStyles } from './CreatorTableStyles';
import PollSummary from '../PollSummary';
import { getComparator, stableSort } from '../../TableHelpers';
import CreatorTableHead from './CreatorTableHead';
import CreatorTableToolbar from './CreatorTableToolbar';
import { axiosDelete, TextField, makeStyles } from '../../../collections/Imports';
import useAPIError from '../../../hooks/useAPIError';
import { useRowStyles } from './useRowStyles';
import { colorPicker } from './Constants';
import { chipTheme } from './useRowStyles';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import Tooltip from '@material-ui/core/Tooltip';

import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';

const useStyles = makeStyles({
  search: {
    border: '1px solid #d4d4d4',
    borderRadius: '5px',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '1rem',
    padding: '5px',
    width: '90%',
    '&:hover': {
      border: '2px solid midnightblue',
    },
  },
});

const createData = (
  _id,
  poll_id,
  poll_title,
  status,
  created_by_id,
  id,
  start_date,
  start_time,
  end_date,
  end_time,
  election_type,
  blockchain_security,
  rta_id,
  scrutinizer_id,
  issuer_id
) => {
  return {
    _id,
    poll_id,
    poll_title,
    status,
    created_by_id,
    id,
    start_date,
    start_time,
    end_date,
    end_time,
    election_type,
    blockchain_security,
    rta_id,
    scrutinizer_id,
    issuer_id
  };
};

CreatorTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  // numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  // onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

CreatorTableToolbar.propTypes = {
  // numSelected: PropTypes.number.isRequired,
};

const Row = props => {
  const {
    polls,
    row,
    index,
    fetchPolls,
    setAppData,
    appData,
    setCurrentIndex,
  } = props;

  const [rowOpen, setRowOpen] = useState(false);
  const classes = useRowStyles();
  // console.log(row);
  const { addError } = useAPIError();
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
  });

  const deletePollById = () => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    axiosDelete('api/polls/id/' + row._id)
      .then(res => {
        if (res.message === 'Success') {
          setRowOpen(false);
          fetchPolls();
        }
      })
      .catch(err => {
        addError(`${err}`);
      });
  };

  const handleClick = (id, index) => {
    setRowOpen(!rowOpen);
    // console.log('index', index, 'selected_poll_id', id);
    localStorage.setItem('selected_poll_id', id);
    localStorage.setItem('selected_poll', JSON.stringify(row));

    setCurrentIndex(index);
    setAppData({
      ...appData,
      selected_poll_id: id,
      selected_poll: polls ? polls[index] : null,
      selected_poll_index: index,
    });
  };

  return (
    <Fragment>
      <TableRow key={row._id} onClick={() => handleClick(row._id, index)} style={{ cursor: 'pointer' }}>
        <TableCell
          padding="checkbox"
          style={{
            borderBottom: 'none',
          }}
        >
          {row.blockchain_security
            ? <Tooltip title="Blockchain Enabled">
              <VerifiedUserIcon />
            </Tooltip>
            : null}
        </TableCell>

        <TableCell
          component="th"
          scope="row"
          padding="none"
          style={{
            borderBottom: 'none',
          }}
        >
          <Typography className={classes.polltitle} noWrap>
            {row.poll_id}
          </Typography>
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          padding="none"
          style={{
            borderBottom: 'none',
          }}
        >
          <Typography className={classes.polltitle} noWrap>
            {row.poll_title}
          </Typography>
        </TableCell>

        <TableCell
          component="th"
          scope="row"
          padding="none"
          style={{
            borderBottom: 'none',
          }}
        >
          <Typography className={classes.polltitle} noWrap>
            {row.election_type.election_type}
          </Typography>
        </TableCell>

        <MuiThemeProvider theme={chipTheme}>
          <TableCell
            align="left"
            style={{
              borderBottom: 'none',
            }}
          >
            {row.status === 'scheduled'
              ? <Chip
                label={row.status}
                style={{ backgroundColor: '#60A045', color: 'white' }}
                className={classes.chip}
              />
              : <Chip
                color={colorPicker(row.status)}
                label={row.status}
                className={classes.chip}
              />}
          </TableCell>
        </MuiThemeProvider>

        <TableCell
          align="left"
          style={{
            borderBottom: 'none',
          }}
        >
          {row.created_by_id ? row.created_by_id.name : null}
        </TableCell>

        <TableCell
          style={{
            borderBottom: rowOpen ? `none` : `  `,
          }}
        >
          {rowOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={rowOpen} timeout="auto" unmountOnExit>
            <PollSummary
              electionType={row.election_type.election_type}
              fetchPolls={fetchPolls}
              poll={row}
              deletePollById={deletePollById}
            />
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  );
};

const CreatorTable = props => {
  const classes1 = useStyles();
  const { polls, ...restProps } = props;
  const [rows, setRows] = useState([]);
  const classes = CreatorTableStyles();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('status');
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pollSearch, setPollSearch] = useState(null);

  useEffect(
    () => {
      if (pollSearch && pollSearch.length > 0) {
        let searchRows = polls.filter(item =>
          JSON.stringify(item)
            .toLowerCase()
            .includes(pollSearch.toLowerCase())
        );
        setRows(searchRows);
      } else setRows(polls);
    },
    [pollSearch, polls]
  );

  useEffect(
    () => {
      const tempRows = [];
      polls.forEach(item => {
        tempRows.push(
          createData(
            item._id,
            item.poll_id,
            item.poll_title,
            item.status,
            item.created_by_id,
            item._id,
            item.start_date,
            item.start_time,
            item.end_date,
            item.end_time,
            item.election_type,
            item.blockchain_security,
            item.rta_id,
            item.scrutinizer_id,
            item.issuer_id
          )
        );
      });
      setRows(tempRows);
    },
    [polls]
  );

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = event => {
    setDense(event.target.checked);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <Box className={classes.root}>
      <Paper className={classes.paper}>
        <Box>
          <Box style={{ display: 'flex', justifyContent: 'center' }}>
            <TextField
              className={classes1.search}
              placeholder="Search Your Poll"
              autoFocus={true}
              onChange={e => {
                setPollSearch(e.target.value);
              }}
              InputProps={{
                disableUnderline: true,
                endAdornment: (
                  <InputAdornment>
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Box>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            aria-label="enhanced table"
          >
            <CreatorTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <Row
                      key={row._id}
                      row={row}
                      index={index}
                      polls={polls}
                      {...restProps}
                    />
                  );
                })}

              {emptyRows > 0 &&
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
      <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
    </Box>
  );
};

export default CreatorTable;
