import React from 'react';
import { fb, ln, tw } from '../pages/Home_Page/HomeImages';
import { footerStyles } from './FooterStyles';
import { Box, Grid, Typography, NavLink, Logo } from '../collections/Imports';

const Footer = () => {
  const classes = footerStyles();
  return (
    <Box className={classes.footer}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={7}>
          <img className={classes.footerLogo} src={Logo} alt="logo" />
          <Typography style={{ fontWeight: '500' }}>A Product of Votechain Private Limited</Typography>
          <br />
          <Typography
            className={classes.footerHeading}
            variant="h5"
            component="h5"
          >
            About EazyVC
          </Typography>
          <br />

          <Typography
            style={{ fontSize: '16px', textAlign: 'justify', fontFamily: 'inherit' }}
            variant="body2"
            component="p"
          >
            EazyVC, a Product of Votechain Private Limited, is determined to build tech backed secured voting platform
            that can bring revolutionary changes in how elections happen.
          </Typography>
          <Typography
            style={{ fontSize: '16px', marginTop: '3px', textAlign: 'justify', fontFamily: 'inherit' }}
            variant="body2"
            component="p"
          >
            We enable organizations and establishments to experience a seamless
            election process that is accurate and trustworthy.
          </Typography>
          <Typography
            style={{ fontSize: '16px', marginTop: '3px', textAlign: 'justify' }}
            variant="body2"
            component="p"
          >
            We take the load off your shoulders by creating a tailored voting
            platform in the digital environment where people can access their
            right to vote safely with ease while we ensure flawless nitty-gritty
            of your elections, their tabulation and statistics with guaranteed
            privacy and protection.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={2}>
          <Box className={classes.quicklinks} >
            <Typography style={{ fontWeight: '500' }}>
              <NavLink to="/aboutus">About</NavLink>
            </Typography>

            <Typography style={{ fontWeight: '500' }}>
              <NavLink to="/contactus">Contact</NavLink>
            </Typography>

            <Typography style={{ fontWeight: '500' }}>
              <NavLink to="/privacypolicy">Privacy Policy</NavLink>
            </Typography>
            <Typography style={{ fontWeight: '500' }}>
              <NavLink to="/termsandconditions">Terms and Conditions</NavLink>
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={6} sm={1}>
          <Box>
            <Typography style={{ fontWeight: '500', padding: "5px", width: '100px' }}>Follow Us</Typography>
            <span
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <a href="https://facebook.com">
                <img
                  style={{ paddingLeft: '3px' }}
                  width="38px"
                  className="fb"
                  src={fb}
                  alt="fb"
                />
              </a>
              <span>
                <a href="https://www.linkedin.com/company/eazyvc/">
                  <img
                    style={{ paddingLeft: '3px' }}
                    width="38px"
                    className="ln"
                    src={ln}
                    alt="ln"
                  />
                </a>
              </span>
              <a href="https://twitter.com/">
                <img
                  style={{ paddingLeft: '3px' }}
                  width="38px"
                  className="ln"
                  src={tw}
                  alt="tw"
                />
              </a>
            </span>
          </Box>
        </Grid>
      </Grid>
      <Grid container sm={12} style={{ marginTop: '2rem', fontWeight: '600' }}>
        <Grid item xs={12} md={4}>
          <Typography style={{ fontWeight: '500' }}>Copyright © 2021 Votechain Private Limited</Typography>
        </Grid>
      </Grid>
    </Box>
  );
};
export default Footer;
