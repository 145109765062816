import { React, Box } from '../../collections/Imports';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
const ControlPanel = (props) => {
  const { pageNumber, numPages, setPageNumber, scale, setScale } = props;
  const isFirstPage = pageNumber === 1;
  const isLastPage = pageNumber === numPages;

  const goToFirstPage = () => {
    if (!isFirstPage) setPageNumber(1);
  };
  const goToPreviousPage = () => {
    if (!isFirstPage) setPageNumber(pageNumber - 1);
  };
  const goToNextPage = () => {
    if (!isLastPage) setPageNumber(pageNumber + 1);
  };
  const goToLastPage = () => {
    if (!isLastPage) setPageNumber(numPages);
  };

  const onPageChange = (e) => {
    const { value } = e.target;
    setPageNumber(Number(value));
  };

  const isMinZoom = scale < 0.6;
  const isMaxZoom = scale >= 2.0;

  const zoomOut = () => {
    if (!isMinZoom) setScale(scale - 0.1);
  };
  const zoomIn = () => {
    if (!isMaxZoom) setScale(scale + 0.1);
  };

  return (
    <div>
      <Box
        style={{
          backgroundColor: 'midnightblue',
          color: '#fff',
          cursor: 'pointer',
          padding: '5px',
        }}
      >
        <FirstPageIcon onClick={goToFirstPage} />
        <ArrowLeftIcon onClick={goToPreviousPage} />
        <span>
          {' '}
          Page{' '}
          <input
            name="pageNumber"
            type="number"
            value={pageNumber}
            min={1}
            max={numPages || 1}
            onChange={onPageChange}
          />{' '}
          of {numPages}
        </span>
        <ArrowRightIcon onClick={goToNextPage} />
        <LastPageIcon onClick={goToLastPage} />
        <Box>
          <ZoomOutIcon onClick={zoomOut} />
          <span>{(scale * 100).toFixed()}%</span>
          <ZoomInIcon onClick={zoomIn} />
        </Box>
      </Box>
    </div>
  );
};

export default ControlPanel;
