import {makeStyles} from '@material-ui/core';

const VotingPageStyles = makeStyles (theme => ({
  root: {
    flexGrow: 1,
    fontSize: '18px',
    paddingTop: '20px',
    marginTop: '80px',
  },

  card: {
    color: '#121212',
    background: '#FFFFFF',
    boxShadow: '0px 0px 20px rgba(57, 57, 57, 0.15)',
    borderRadius: '10px',
    marginLeft: '20px',
    marginRight: '20px',
  },
  cardContent: {
    [theme.breakpoints.down ('sm')]: {
      textAlign: 'center',
    },
  },

  innerCard: {
    padding: '30px',
    marginBottom: '20px',
    fontSize: '24px',
    background: '#FFFFFF',
    boxSizing: 'border-box',
    boxShadow: '0px 2px 5px 1px rgba(0, 0, 0, 0.1)',
    borderRadius: '10px',
    '&:hover': {
      border: '1px solid #1171FF',
    },
  },

  modal: {
    // padding: '22px',
    display: 'block',
    height: '95%',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'scroll',
    marginTop: '20px',
    overflowY: `scroll`,
  },
  paper: {
    background: '#FFFFFF',
    boxShadow: '0px 0px 20px rgba(57, 57, 57, 0.15)',
    borderRadius: '10px',
    border: '3px solid #000',
    padding: '8px',
    overflowY: `scroll`,
    height: '100%',
  },

  heading2: {
    fontSize: '26px',
    lineHeight: '29px',
    color: '#1171FF',
  },

  optionCard: {
    marginBottom: '10px',
    border: '2px solid #DEDEDE',
    boxSizing: 'border-box',
    borderRadius: '10px',
    '&:hover': {
      border: '2px solid #1171FF',
    },
  },
  checked:{
  color:'rgb(178,34,34)!important',
},
}));

export default VotingPageStyles;
