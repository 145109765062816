import { makeStyles } from '@material-ui/core/styles';

export const AddQuestionStyles = makeStyles((theme) => ({
  addquestionbtn: {
    display: 'block',
  },
  paperbg: {
    background: '#FFFFFF',
    border: '1px dashed #0B2F64',
    boxSizing: 'border-box',
    borderRadius: '10px',
    padding: '30px',
  },
  fileInput: {
    padding: '1rem',
    margin: '3px',
  },

  ListQuestion: {
    background: '#FFFFFF',
    border: '1px solid #E5E5E5',
    boxSizing: 'border-box',
    boxShadow: '0px 2px 5px 1px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
    margin: '10px',
    marginBottom: '5px',
    '&:hover':{
      border: '1px solid #1171FF',
      color: '#1171FF'
    },
  },
  srno: {
    width: '10%',
    fontSize: '18px',
    lineHeight: '21px',
    color: '#414448',
  },
  questionOption: {
    width: '60%',
    fontSize: '18px',
    lineHeight: '21px',
    color: '#414448',
  },
  questionName: {
    width: '50%',
    fontSize: '16px',
    lineHeight: '19px',
    color: '#414448',
  },
  publishbtn: {
    display: 'flex',
    justifyContent: 'center',
    width: '30%',
    margin: '0 auto',
    padding: '10px',
    marginTop: '50px',
    marginBottom: '20px',
    background: '#1171FF',
    boxShadow: '0px 4px 20px rgba(11, 47, 100, 0.4)',
    borderRadius: '15px',
  },
  Addbtn: {
    width:'70%',
    display: 'flex',
    justifyContent: 'center',
    margin: '0 auto',
    padding: '10px',
    paddingLeft: '1rem',
    paddingRight: '1rem',
    marginTop: '50px',
    marginBottom: '20px',
    background: '#0B2F64',
    color: '#fff',

  },
  addQuestionfromPaper: {
    background: '#FFFFFF',
    border: '1px solid #0B2F64',
    boxSizing: 'border-box',
    boxShadow: '0px 0px 8px 4px rgba(11, 47, 100, 0.2)',
    borderRadius: '8px',
    width: '100%',
    margin: '0px 10px 10px 0',
  },
  multiline: {
    background: '#FFFFFF',
    border: '1px solid #E5E5E5',
    boxSizing: 'border-box',
    boxShadow: 'inset 0px 0px 5px 1px rgba(0, 0, 0, 0.15)',
    borderRadius: '10px',
    paddingRight: '215px',
    width: '90%',
  },
  formControl: {
    margin: theme.spacing(1),
    Width: '100%',
    '& .MuiFormControl-root': {
      width: '100%',
    },
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  questionlabel: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '15px',
    lineHeight: '28px',
    color: '#414448',
  },
  questionlabelbold: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '18px',
    lineHeight: '23px',
    color: '#000000',
  },
  switchsection: {
    padding: '30px',
  },
  tablecellno: {
    width: '20%',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '18px',
    lineHeight: '21px',
    color: '#0B2F64',
  },
  tablecelltext: {
    width: '73%',
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '21px',
    color: '#0B2F64',
  },
  tabledeleteicon: {
    width: '1%',
  },
  tablepublishicon: {
    width: '1%',
    cursor: 'pointer',
  },
  tablepublishiconLabel: {
    padding: '5px',
    borderRadius: '5px',
    '&:hover': {
      cursur: 'pointer',
      background: '#f3f3f3',
    },
  },
  questionPapercell: {
    border: '1px solid #2c81ff',
    background: '#FFFFFF',
    boxShadow: '0px 2px 5px 1px rgb(0 0 0 / 10%)',
    boxSizing: 'border-box',
    borderRadius: '10px',
    marginBottom: '8px',
  },
  inputcell: {
    background: '#FFFFFF',
    border: '1px solid #E5E5E5',
    boxSizing: 'border-box',
    // boxShadow: "inset 0px 0px 5px 1px rgb(0 0 0 / 15%)",
    borderRadius: '8px',
    padding: '10px',
    margin: '10px',
  },
  questionSplit:{
    display: 'inline-block',
    width: '300px',
    whiteSpace: 'nowrap',
    overflow: 'hidden !important',
    textOverflow: 'ellipsis',
  },
}));
