import {
  Snackbar,
  useState,
  Typography,
  Button,
  FormControl,
  Card,
  Grid,
  LoginStyles,
  axiosPost,
  React,
  Box,
  CircularProgress,
  NavLink,
  InputLabel,
} from "../../collections/Imports";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import download_app from "../../../img/download_app.svg";
import useAPIError from "./../../hooks/useAPIError";
import { Helmet } from "react-helmet";

const VerifyEmail = (props) => {
  const [loading, setLoading] = useState(false);
  const { addError } = useAPIError();
  const classes = LoginStyles();
  const initialState = { email: "", phone: "", password: "" };
  const [open, setopen] = useState(false);
  const [message, setmessage] = useState("");
  const [values] = useState({ initialState });

  const [state, setState] = useState({
    formValues: {
      email: "",
      phone: "",
    },
    errorData: {},
    errorMessage: "",
  });

  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();

    if (validateUserInfo()) {
      axiosPost(
        "api/auth/reset/password/otp",
        {
          email: state.formValues.email,
        },
        { headers: { "Content-Type": "application/json" } }
      )
        .then((res) => {
          if (res.code === "otp_sent") {
            localStorage.setItem("isAuthenticated", true);
            localStorage.setItem("email", state.formValues.email);
            setopen(true);
            setmessage("Redirecting to otp page");
            setLoading(false);
            props.history.push("/verify_forget_password");
          } else if (res.code === "email_not_found") {
            setopen(true);
            setmessage("Email not found");
            state.errorData.email = ["* Email id doesn't exists"];
          } else {
            setopen(true);
            setmessage("failed to do Login");
          }
        })
        .catch((err) => {
          addError(`${err}`);
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };

  const validateUserInfo = () => {
    let isFormValid = true;
    let formValues = state.formValues;
    state.errorData = {
      email: [],
    };

    if (formValues.email.trim().length === 0) {
      isFormValid = false;
      state.errorData.email = ["* Email field cannot be empty."];
    }
    if (formValues.email.trim().length !== 0) {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (re.test(formValues.email) === true) {
        state.errorData.email = [];
      } else {
        state.errorData.email = ["* invalid email"];
      }
    }

    let tempState = state;

    setState({
      ...state,
      ...tempState,
    });

    return isFormValid;
  };

  const handleChange = (event, field) => {
    if (
      state.errorData &&
      state.errorData[field] &&
      state.errorData[field][0]
    ) {
      state.errorData[field][0] = null;
    }
    const test = state;
    if (field === "rememberme") {
      test.formValues[field] = event.target.checked;
    } else {
      test.formValues[field] = event.target.value;
    }
    setState({ ...state, ...test });
  };

  return (
    <Box className={classes.bg}>
       <Helmet>
        <meta charSet="utf-8" />
        {/* <title>EazyVC Voting Platform - Contact US</title> */}
        <meta
          name="title"
          content="The easy way to Vote Online on EazyVC’s Blockchain-secured Voting Platform."
        />
        <meta
          name="description"
          content="EazyVC is a Blockchain-based eVoting platform for Corporates, Government Bodies, Education Institutions, Associations & Clubs, Housing Societies, NGO, Trusts and more."
        />
      </Helmet>
      <Grid container spacing={2} className={classes.gridContainer}>
        <Grid className={classes.imgnText} item xs={12} sm={6}>
          <img
            src={download_app}
            style={{ width: "80%" }}
            alt="EazyVC Mobile Optimized Online Voting Software "
          />
          <Typography className={classes.loginText}> Welcome to the</Typography>
          <Typography className={classes.loginText}>
            <span style={{ color: "#fff" }}>Most Secure </span> E-Voting
            Platform
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box className={classes.main}>
            <Card className={classes.card}>
              <Typography component="h1" variant="h5">
                Forgot Password?
              </Typography>
              <Typography
                style={{
                  fontSize: "15px",
                  marginTop: "8px",
                  marginBottom: "8px",
                  color: "#777",
                }}
              >
                Please enter your email address to recieve a otp to reset your
                password
              </Typography>
              <form className={classes.form} onSubmit={handleSubmit}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel htmlFor="component-outlined">
                    Email Address
                  </InputLabel>
                  <OutlinedInput
                    // id="component-outlined"
                    placeholder="Enter Email Address"
                    variant="outlined"
                    id="email"
                    name="email"
                    value={values.email}
                    onChange={(event) => handleChange(event, "email")}
                    label="Email Address"
                  />
                </FormControl>
                {/* <FormControl margin="normal" required fullWidth>
                  <Typography>Email Address</Typography>
                  <TextField
                    className={classes.inputfield}
                    placeholder="Enter Email Address"
                    variant="outlined"
                    id="email"
                    name="email"
                    value={values.email}
                    onChange={(event) => handleChange(event, 'email')}
                  />
                </FormControl> */}
                <span style={{ color: "red" }}>
                  {state.errorData?.email && state.errorData?.email[0]}
                </span>

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={loading}
                  className={classes.submit}
                >
                  {" "}
                  {loading && (
                    <CircularProgress
                      size={20}
                      style={{ color: "#000", marginRight: "5px" }}
                    />
                  )}
                  {loading && (
                    <Box component="span" style={{ color: "#000" ,fontWeight:'600'}}>
                      {" "}
                      please wait
                    </Box>
                  )}
                  {!loading && <Box>SEND OTP</Box>}
                </Button>
                <NavLink
                  to="login"
                  style={{
                    margin: "auto",
                    fontWeight: "600",
                    textAlign: "center",
                    justifyContent: "center",
                    display: "flex",
                  }}
                >
                  BACK
                </NavLink>

                {/* <Button
                  type="submit"
                  fullWidth
                  color="secondary"
                  className={classes.submitText}
                >
                  Don't have an account?
                  <NavLink to="/signup" className={classes.submitText2}>
                    SignUp right now!
                  </NavLink>
                </Button> */}
              </form>
            </Card>
          </Box>
        </Grid>
      </Grid>
      <Snackbar open={open} message={message} />
    </Box>
  );
};

export default VerifyEmail;
