import {
  useState,
  Typography,
  TextField,
  Grid,
  CreatePollstyle,
  axiosPost,
  Button2,
  Snackbar,
  Alert,
  Box,
} from '../../collections/Imports';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import useAPIError from '../../hooks/useAPIError';

const validationSchema = Yup.object ().shape ({
  name: Yup.string ()
    .min (2, 'Too Short!')
    .max (50, 'Too Long!')
    .required ('Required'),
  email: Yup.string ().email ('Invalid email').required ('Required'),
});

const ScrutinizerInvite = props => {
  const {addError} = useAPIError ();
  const classes = CreatePollstyle ();
  const [open, setopen] = useState (false);
  const [message, setmessage] = useState ('');

  const initialValues = {
    name: '',
    email: '',
  };

  const onSubmit = async values => {
    let poll_id = localStorage.getItem ('selected_poll_id');
    await axiosPost ('api/scrutinizers/add', {poll_id, ...values})
      .then (res => {
        if (res.code === 'add_scrutinizer_success') {
          setopen (true);
          props.setScrutMailSent (true);
          setmessage ('Scrutinizer added Successfully');
          setopen (false);
          // console.log('scrutinizer added');
        } else if (res.code === 'server_error') {
          addError ('Scrutinizer add failed. Please try again');
          // console.log('scrutinizer added');
        }
      })
      .catch (err => {
        addError (`${err}`);
      });
  };

  const formik = useFormik ({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return (
    <Box>
      <form>
        <Grid>
          <Typography className={classes.formlabeText}>
            Add Scrutnizer
          </Typography>
        </Grid>
        <Grid container spacing={2}>
          <Grid item sm={6} xs={12} style={{marginTop: '1rem'}}>
            <Typography className={classes.formlabeText}>
              Name<span style={{color: 'red'}}>*</span>
            </Typography>
            <form variant="outlined" className={classes.formlabeText}>
              <TextField
                placeholder="Enter Scrutinizer Name"
                className={classes.textfieldcontrol}
                id="name"
                variant="outlined"
                name="name"
                style={{minWidth: '90%'}}
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean (formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
              {formik.errors.name ? <Box>{formik.errors.name}</Box> : null}
            </form>
          </Grid>
          <Grid item sm={6} xs={12} style={{marginTop: '1rem'}}>
            <Typography className={classes.formlabeText}>
              Email / Phone Number <span style={{color: 'red'}}>*</span>
            </Typography>
            <form className={classes.formlabeText} variant="outlined">
              <TextField
                placeholder="Enter Scrutinizer Email"
                className={classes.textfieldcontrol}
                id="email"
                variant="outlined"
                name="email"
                style={{minWidth: '90%'}}
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean (formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
              {formik.errors.email ? <Box>{formik.errors.email}</Box> : null}
            </form>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <Box style={{marginRight: '2.5rem'}}>
              <Button2
                style={{float: 'right'}}
                onClick={() => props.handleScrutinizerCancel ()}
                backgroundColor="#1171FF"
                width="20%"
              >
                Cancel
              </Button2>

              <Button2
                style={{float: 'right', marginRight: '10px'}}
                backgroundColor="#1171FF"
                width="20%"
                disable={formik.isSubmitting}
                type="submit"
                onClick={() => formik.handleSubmit}
              >
                {formik.isSubmitting ? 'please wait..' : 'Invite'}
              </Button2>
            </Box>
            <Snackbar
              anchorOrigin={{vertical: 'top', horizontal: 'center'}}
              open={open}
              autoHideDuration={1000}
            >
              <Alert severity="success">{message}</Alert>
            </Snackbar>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default ScrutinizerInvite;
