import ReactToPrint from 'react-to-print';
import {
  React,
  Box,
  Typography,
  makeStyles,
  Button2,
  Grid,
  useRef,
  Avatar,
} from '../../collections/Imports';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';

const PreviewModal = props => {
  const classes = useStyles();
  const { questions, PreviewModalClose } = props;
  const componentRef = useRef();

  const getOccurrence = (array, value) => {
    var count = 0;
    array.forEach(v => v === value && count++);
    return count;
  };
  const quesArray = questions.map(item => {
    return item.options.map(option => {
      return option.status;
    });
  });
  const voteArray = quesArray.map(item => {
    return item.includes('voted');
  });

  return (
    <Box className={classes.paper} ref={componentRef}>
      <Grid
        container
        justify="center"
        alignItem="center"
        style={{ paddingBottom: '10px' }}
      >
        <Grid item sm={4} xs={12}>
          <Box
            className={classes.questionBox}
            style={{
              color: 'black',
              fontWeight: '600', display: 'flex',
              alignItems: 'center', justifyContent: 'flex-start'
            }}
          >
            <Typography variant="h5" style={{ width: '70%' }}>Total Number of Questions</Typography>
            <Avatar alt="Remy Sharp" src="/broken-image.jpg" style={{ transform: 'scale(1.2)', background: 'darkblue', color: 'white', margin: 'auto' }}>
              <Typography variant="h5">{questions.length}</Typography>
            </Avatar>
          </Box>
        </Grid>
        <Grid item sm={4} xs={12}>
          <Box
            className={classes.questionBox}
            style={{
              color: 'black',
              fontWeight: '600', display: 'flex',
              alignItems: 'center', justifyContent: 'flex-start'
            }}
          >
            <Typography variant="h5" style={{ width: '70%' }}>Number of Questions Voted</Typography>
            <Avatar alt="Remy Sharp" src="/broken-image.jpg"
              style={{ transform: 'scale(1.2)', background: 'green', color: 'white', margin: 'auto' }}>
              <Typography variant="h5">
                {getOccurrence(voteArray, true)}
              </Typography>
            </Avatar>
          </Box>
        </Grid>
        <Grid item sm={4} xs={12}>
          <Box
            className={classes.questionBox}
            style={{
              color: 'black',
              fontWeight: '600', display: 'flex',
              alignItems: 'center', justifyContent: 'flex-start'
            }}
          >
            <Typography variant="h5" style={{ width: '70%' }}>Number of Questions Skipped</Typography>

            <Avatar alt="Remy Sharp" src="/broken-image.jpg"
              style={{ transform: 'scale(1.2)', background: 'gray', color: 'white', margin: 'auto' }}>
              <Typography variant="h5">
                {getOccurrence(voteArray, false)}
              </Typography>
            </Avatar>
          </Box>
        </Grid>
      </Grid>

      {questions?.map((question, index) => {
        return (
          <Grid container style={{ padding: "13px" }} justify="center" alignItems="center" spacing={2}>
            <Grid
              item
              sm={12}
              xs={12}
              style={{ color: 'white', backgroundColor: '#007bff', borderRadius: '5px' }}
            >
              <Typography variant="h6">{`Question ${index + 1})`}</Typography>
              <Typography
                variant="h6"
                dangerouslySetInnerHTML={{
                  __html: question.question,
                }}
              />
            </Grid>
            <Grid
              item
              sm={12}
              xs={12}
              style={{ color: 'black', backgroundColor: 'aliceblue' }}
            >
              {question.options.map((option, index) => {
                return (
                <box>  
                  <Typography
                    variant="h6"
                    style={{
                      color: option.status === 'voted' ? 'green' : 'red',
                      display:'flex',
                      alignItems:'center',
                  
                    }}
                  >
                    <Grid className={classes.previewCircle}>
                      {String.fromCharCode(65 + index)}
                    </Grid>
                          &nbsp; &nbsp;
                     <Grid style={{padding:'1rem'}}>  {option.status === 'voted' ?  <CheckCircleIcon/> :  <CancelIcon/>}</Grid>
                     
                      {
                    // question.questionType.question_type_id === 3 && index === 2
                    //   ? null
                    //   : 
                      option.shares
                          ? `${question.questionType.question_type_id === 5 ? `Shares` : option.value} : ${option.shares}`
                          : option.value}
                          
                  </Typography>
                  </box>
                );
              })}
            </Grid>
          </Grid>
        );
      })}
      <Box
        displayPrint="none"
        className={classes.previewbtn}
      >
        <Grid
          spacing={2}
          container
          alignItems="center"
          justify="center"
          style={{ paddingTop: '10px', marginBottom: '10px' }}
        >
          <Grid item>
            <Button2 width="150px" onClick={() => PreviewModalClose(false)}>
              Close
            </Button2>
          </Grid>
          <Grid item>
            <ReactToPrint
              trigger={() => <Button2 width="150px">Print Preview!</Button2>}
              content={() => componentRef.current}
            />
          </Grid>
        </Grid>
        {/* <Grid item>
          <ReactToPrint
            trigger={() => <Button2 width="150px">Print Preview!</Button2>}
            content={() => componentRef.current}
          />
        </Grid> */}
      </Box>
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: '100%',
    borderRadius: '10px',
    overflowY: `scroll`,
    height: '100%',
  },

  questionBox: {
    border: '1px solid grey',
    padding: '10px',
    margin: '5px',
    minHeight: '8rem',
    textAlign: 'center',
    borderRadius: '12px',
  },
  previewbtn: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      paddingTop: '20px',
    }
  },
  previewCircle:{
    color:'#fff',
    background:'gray',
    fontWeight:'500',
    borderRadius:'50%',
    textAlign:'center',
    border:'2px solid gray',  
    fontSize:'1rem',
    width:'25px',
    display:'flex',
    alignItems:'center',
    height:'25px',
    justifyContent:'center',
    marginTop:'5px',
    [theme.breakpoints.down('sm')]: {
      width: '1em',
    height: '1em',
    borderRadius: '50%',
    padding: '9px',
    fontSize: '1rem'
    }
  }
}));
export default PreviewModal;
