import React from 'react';

import { Grid, Typography } from '../../collections/Imports';

const dashTitle = 'Add Question';

const Title = () => {
  return (
    <Grid>
      <Typography
        gutterBottom
        variant="h6"
        component="h6"
        style={{
          paddingTop: '30px',
          paddingLeft: '1rem',
          paddingBottom: '20px',
        }}
      >
        {dashTitle}
      </Typography>
    </Grid>
  );
};

export default Title;
