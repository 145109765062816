import { useCallback } from 'react';
import {
  React,
  useEffect,
  useState,
  MyTableCell,
  TableRow,
  axiosGet,
  useContext,
  GlobalContext,
  Table,
  TableHead,
  Typography,
  TableContainer,
  TableBody,
  moment,
  Grid,
} from '../../collections/Imports';
import useAPIError from '../../hooks/useAPIError';

const PollDetails = () => {
  const { addError } = useAPIError();
  const [poll, setPollData] = useState({});
  const [appData, setAppData] = useContext(GlobalContext);
  const [loading, setLoading] = useState(false);

  const getPollDetails = useCallback(() => {
    setLoading(true);
    axiosGet(
      'api/polls/id/' + appData.selected_poll_id ||
      localStorage.getItem('selected_poll_id')
    )
      .then((res) => {
        setPollData(res.poll);
        setAppData({
          ...appData,
          poll,
        });
        setLoading(false);
      })
      .catch((err) => {
        addError(`Unable to fetch poll detials: ${err}`);
      });
  }, [appData.selected_poll_id]);

  useEffect(() => {
    const { poll } = appData
    if (poll) {
      setPollData(poll);
    } else {
      getPollDetails();
    }
  }, [addError]);

  return !loading ? (
    <>
      <Typography varient="body1" noWrap>
        <Table>
          <TableHead>
            <TableRow>
              <MyTableCell
                fontSize="22px"
                paddingBottom="15px"
                style={{
                  textTransform: 'capitalize',fontWeight:'500'
                }}
              >
                Poll Title: {poll && poll.poll_title}
              </MyTableCell>
            </TableRow>
          </TableHead>
        </Table>
      </Typography>

      <Typography varient="body1" noWrap>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <Grid container>
                  <Grid item xs={6} sm={3}>
                    {<MyTableCell color="blue" style={{fontWeight:"500"}}>Starting Date:</MyTableCell>}
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <MyTableCell align="right">
                      {poll && moment(poll.start_date).format('D MMM YYYY')}
                    </MyTableCell>
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <MyTableCell color="blue" style={{fontWeight:"500"}}>Starting Time:</MyTableCell>
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <MyTableCell>
                      {poll &&
                        moment(poll.start_time, 'hh:mm:ss').format('hh:mm a')}
                    </MyTableCell>
                  </Grid>
                </Grid>
              </TableRow>

              <TableRow>
                <Grid container>
                  <Grid item xs={6} sm={3}>
                    <MyTableCell color="red" style={{fontWeight:"500"}}>Ending Date:</MyTableCell>
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <MyTableCell align="right">
                      {poll && moment(poll.end_date).format('D MMM YYYY')}
                    </MyTableCell>
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <MyTableCell color="red" style={{fontWeight:"500"}}>Ending Time:</MyTableCell>
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <MyTableCell>
                      {poll &&
                        moment(poll.end_time, 'hh:mm:ss').format('hh:mm a')}
                    </MyTableCell>
                  </Grid>
                </Grid>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Typography>
    </>
  ) : (
    <Typography variant="h6">Fetching Details...</Typography>
  );
};

export default PollDetails;
