import {
  React,
  MyTableCell,
  TableRow,
  TableContainer,
  moment,
  Table,
  TableBody,
  Typography,
} from '../../collections/Imports';

const PollSummaryTable = (props) => {
  const { poll } = props;

  return (
    <Typography varient="body1" noWrap>
      <TableContainer>
        <Table>
          <TableBody>
            <TableRow>
              {<MyTableCell color="blue">Starting Date:</MyTableCell>}
              <MyTableCell>
                {poll && moment(poll.start_date).format('D MMM YYYY')}
              </MyTableCell>
              <MyTableCell color="red">Ending Date:</MyTableCell>
              <MyTableCell>
                {poll && moment(poll.end_date).format('D MMM YYYY')}
              </MyTableCell>
            </TableRow>

            <TableRow>
              <MyTableCell color="blue">Starting Time:</MyTableCell>
              <MyTableCell>
                {poll && moment(poll.start_time, 'hh:mm:ss').format('hh:mm a')}
              </MyTableCell>
              <MyTableCell color="red">Ending Time:</MyTableCell>
              <MyTableCell>
                {poll && moment(poll.end_time, 'hh:mm:ss').format('hh:mm a')}
              </MyTableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Typography>
  );
};

export default PollSummaryTable;
