import { React, Grid, Button2 } from '../../collections/Imports';
import { AddCircleRounded } from '@material-ui/icons';

const AddInviteeButton = (props) => {
  const { setShowForm } = props;
  return (
    <Button2
      onClick={() => {
        setShowForm(true);
      }}
      width="100%"
      height="60px"
      backgroundColor="#F8F8F8"
      color="#0B2F64"
    >
      <Grid container spacing={1} direction="column" justify="center">
        <Grid item>
          <AddCircleRounded />
        </Grid>
        <Grid item>Add Member</Grid>
      </Grid>
    </Button2>
  );
};

export default AddInviteeButton;
