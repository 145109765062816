import {
  React,
  useEffect,
  useState,
  Box,
  Grid,
  Card,
  Button2,
  // Link,
} from '../../collections/Imports';
// import VotingPageStyles from './VotingPageStyles';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { Document, Page, pdfjs } from 'react-pdf';
import ControlPanel from './ControlPanel';
// import GetAppIcon from '@material-ui/icons/GetApp';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const ImageCard = (props) => {
  const { currentQuestion } = props;

  // const classes = VotingPageStyles();
  const [scale, setScale] = useState(1.0);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pdfFile, setPdfFile] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [showCard, setShowCard] = useState(false);
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  useEffect(() => {
    if (currentQuestion) {
      const { question_file } = currentQuestion;
      let binary = '';
      if (question_file.file.data.data) {
        let bytes = [].slice.call(new Uint8Array(question_file.file.data.data));
        bytes.forEach((b) => (binary += String.fromCharCode(b)));
        let pdfData = window.btoa(binary);
        let contentType = question_file.file.contentType;

        setPdfFile('data:' + contentType + ';base64,' + pdfData);
      } else {
        setPdfFile(null);
      }
    }
    setIsLoading(false);
  }, [currentQuestion]);

  return (
    <>
      {showCard ? (
        <Card
          style={{
            width: '100%',
            height: '400px',
            borderRadius: '10px',
            textAlign: 'center',
            overflow: 'scroll',
            filter: isLoading ? 'blur(2px)' : 'none',
          }}
        >
          <section id="pdf-section">
            <ControlPanel
              scale={scale}
              setScale={setScale}
              numPages={numPages}
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
            />

            <Box
              style={{
                marginTop: '5px',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Document file={pdfFile} onLoadSuccess={onDocumentLoadSuccess}>
                <Page pageNumber={pageNumber} scale={scale} />
              </Document>
            </Box>
          </section>
        </Card>
      ) : null}

      {/* view button */}
      {currentQuestion.question_file._id ? (
        <Grid
          container
          spacing={2}
          justify="flex-end"
          alignItems="center"
          style={{ backgroundColor: '', paddingTop: '15px' }}
        >
          <Grid item>
            <Button2
              width="200px"
              onClick={() => {
                setShowCard((prev) => !prev);
              }}
            >
              {showCard ? (
                <VisibilityOffIcon style={{ marginRight: '5px' }} />
              ) : (
                <VisibilityIcon style={{ marginRight: '5px' }} />
              )}
              {showCard ? 'Hide Attachment' : 'View Attachment'}
            </Button2>
          </Grid>
          {/* <Grid item>
            <Link
              target="_blank"
              href={pdfFile}
              rel="noreferrer"
              style={{ textDecoration: 'none' }}
            >
              <Button2 width="200px">
                <GetAppIcon style={{ marginRight: '5px' }} />
                Download PDF
              </Button2>
            </Link>
          </Grid> */}
        </Grid>
      ) : null}
    </>
  );
};

export default ImageCard;
