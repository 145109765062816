const handleShareChange = ({ e, index, questions, page, setQuestions }) => {
  let currentQuestion = questions[page - 1];

  currentQuestion = {
    ...currentQuestion,
    options: currentQuestion.options.map((option, idx) => {
      if (idx === index) {
        return { ...option, shares: e.target.value };
      }
      return option;
    }),
  };
  const newQuestions = questions.map((question) => {
    if (question._id === currentQuestion._id) {
      return currentQuestion;
    }
    return question;
  });

  setQuestions(newQuestions);
};

const handleRadioChange = ({ index, questions, page, setQuestions }) => {
  let currentQuestion = questions[page - 1];
  currentQuestion = {
    ...currentQuestion,
    options: currentQuestion.options.map((option, idx) => {
      if (idx === index) {
        return {
          ...option,
          status: 'voted',
        };
      }
      return {
        ...option,
        status: null,
      };
    }),
  };

  const newQuestions = questions.map((question) => {
    if (question._id === currentQuestion._id) {
      return currentQuestion;
    }
    return question;
  });
  setQuestions(newQuestions);
};

const handleRadioChangeNew = ({
  currentQuestion,
  index,
  questions,
  setQuestions,
}) => {
  currentQuestion = {
    ...currentQuestion,
    options: currentQuestion.options.map((option, idx) => {
      if (idx === index) {
        return {
          ...option,
          status: 'voted',
        };
      }
      return {
        ...option,
        status: null,
      };
    }),
  };

  const newQuestions = questions.map((question) => {
    if (question._id === currentQuestion._id) {
      return currentQuestion;
    }
    return question;
  });
  setQuestions(newQuestions);
};

const handleCheckboxChange = ({
  index,
  setShowNextButton,
  questions,
  page,
  setQuestions,
}) => {
  setShowNextButton(true);
  let currentQuestion = questions[page - 1];

  currentQuestion = {
    ...currentQuestion,
    options: currentQuestion.options.map((option, idx) => {
      if (idx === index) {
        return {
          ...option,
          status: option.status === 'voted' ? null : 'voted',
        };
      }
      return option;
    }),
  };

  const newQuestions = questions.map((question) => {
    if (question._id === currentQuestion._id) {
      return currentQuestion;
    }
    return question;
  });
  setQuestions(newQuestions);
};

const handleCheckboxChangeNew = ({
  index,
  currentQuestion,
  questions,
  setQuestions,
}) => {
  currentQuestion = {
    ...currentQuestion,
    options: currentQuestion.options.map((option, idx) => {
      if (idx === index) {
        return {
          ...option,
          status: option.status === 'voted' ? null : 'voted',
        };
      }
      return option;
    }),
  };

  const newQuestions = questions.map((question) => {
    if (question._id === currentQuestion._id) {
      return currentQuestion;
    }
    return question;
  });
  setQuestions(newQuestions);
};

const handleRankChange = ({
  e,
  index,
  questions,
  setQuestions,
  currentQuestion,
  setError,
  setErrorText,
  error,
  rankingsArray,
}) => {
  let totalRank = currentQuestion.options.length;

  let tempError = false;

  if (e.target.value <= 0) {
    tempError = true;
    setErrorText('Enter Valid Rank');
  }
  if (e.target.value > totalRank) {
    tempError = true;
    setErrorText(`Enter Valid Rank`);
  }
  if (e.target.value === '') {
    tempError = false;
    setErrorText('Enter Valid Rank');
  }

  if (rankingsArray.includes(e.target.value)) {
    tempError = true;
    setErrorText('Enter Valid Rank');
  }

  var tempArray = error.map((item, idx) => {
    if (index === idx) {
      item.error = tempError;
    }
    return item;
  });

  setError(tempArray);

  currentQuestion = {
    ...currentQuestion,
    options: currentQuestion.options.map((option, idx) => {
      if (idx === index) {
        return {
          ...option,
          shares: e.target.value,
          status: 'voted',
        };
      }
      return option;
    }),
  };

  const newQuestions = questions.map((question) => {
    if (question._id === currentQuestion._id) {
      return currentQuestion;
    }
    return question;
  });
  setQuestions(newQuestions);
};

const handleShareChangeNew = ({
  e,
  questions,
  setQuestions,
  currentQuestion,
  setError,
  totalShares,
  setErrorText,
}) => {
  if (e.target.value <= 0) {
    setError(true);
    setErrorText('Shares should be greater than zero');
  } else {
    setError(false);
    setErrorText(null);
  }

  if (e.target.value > totalShares) {
    setError(true);
    setErrorText('Total Shares should be less than or equal to total holdings');
  }

  if (e.target.value === '') {
    setError(true);
    setErrorText('Mandatory filed cant be Empty');
  }

  currentQuestion = {
    ...currentQuestion,
    options: currentQuestion.options.map((option, idx) => {
      if (idx === 2) {
        return {
          ...option,
          shares: e.target.value,
          status: 'voted',
        };
      }
      return option;
    }),
  };

  const newQuestions = questions.map((question) => {
    if (question._id === currentQuestion._id) {
      return currentQuestion;
    }
    return question;
  });

  setQuestions(newQuestions);
  // computeSharesNew({ currentQuestion });
};

const handleShareChangeWithoutPagination = ({
  e,
  index,
  questions,
  setQuestions,
  currentQuestion,
  computeShares,
  setError,
  setErrorText,
  totalShares,
  error,
}) => {
  let { options } = currentQuestion
  let total = e.target.value ? parseInt(e.target.value) : 0;
  if (options && options.length > 0) {
    for (let i = 0; i < options.length; i++) {
      if (index !== i)
        total += options[i].shares ? parseInt(options[i].shares) : 0
    }
  }

  let tempError = false;

  // if (total > totalShares) {
  //   tempError = true;
  //   setErrorText(`Total share should be less than or equal to your share`);
  // }

  if (e.target.value < 0) {
    tempError = true;
    setErrorText('Enter valid Shares');
  }
  // if (e.target.value > totalShares) {
  //   tempError = true;
  //   setErrorText(`Enter valid Shares`);
  // }
  if (e.target.value === '') {
    tempError = true;
    setErrorText('Enter Valid Shares');
  }
  let tempArray = []
  if (error && error.length > 0) {
    tempArray = error.map((item, idx) => {
      if (index === idx) {
        item.error = tempError;
      }
      return item;
    });
  }
  setError(tempArray);

  if (total <= totalShares) {
    currentQuestion = {
      ...currentQuestion,
      options: currentQuestion.options.map((option, idx) => {
        if (idx === index) {
          // if (e.target.value <= 0) {
          //   setError(true);
          //   setErrorText('Share should be greater than zero');
          // } else {
          //   setError(false);
          //   setErrorText(null);
          // }
          // if (e.target.value > totalShares) {
          //   setError(true);
          //   setErrorText(`Total Share should be less than ${totalShares}`);
          // }
          // if (e.target.value === '') {
          //   setError(true);
          //   setErrorText('Mandatory filed cant be Empty');
          // }
          return {
            ...option,
            shares: e.target.value && parseInt(e.target.value) > -1 ? e.target.value : '',
            status: 'voted',
          };
        }
        return option;
      }),
    };

    const newQuestions = questions.map((question) => {
      if (question._id === currentQuestion._id) {
        return currentQuestion;
      }
      return question;
    });
    setQuestions(newQuestions);
    computeShares(newQuestions);
  }
};

const onChangeHandlers = {
  handleCheckboxChange,
  handleRadioChange,
  handleShareChange,
  handleRadioChangeNew,
  handleShareChangeNew,
  handleCheckboxChangeNew,
  handleRankChange,
  handleShareChangeWithoutPagination,
};

export default onChangeHandlers;
