import {
  useState,
  Button,
  FormControl,
  Grid,
  TextField,
  InviteMembersStyle,
  useEffect,
  Typography,
  InviteMemberServices,
  GlobalContext,
  IconButton,
} from '../../collections/Imports';
import { DeleteForeverRounded } from '@material-ui/icons';
import { SnackbarContext } from '../../providers/SnackBarContext';
import { useFormik } from 'formik';
import { validationSchema, validationSchemaShareholder } from './validationSchema';
import { useCallback, useContext } from 'react';
import useAPIError from '../../hooks/useAPIError';
import ConfirmDialog from '../../AlertNotification/ConfirmDialog';
import FormHelperText from '@material-ui/core/FormHelperText';

const EditInviteeForm = (props) => {
  const { addError } = useAPIError();
  const [appData] = useContext(GlobalContext);
  const { setSnackbar } = useContext(SnackbarContext);
  const [sameEmail, setSameEmail] = useState(false);

  const inviteClasses = InviteMembersStyle();
  const {
    currentInvitee,
    setEditing,
    setValidParticipants,
    validParticipants,
  } = props;

  const [invitee, setInvitee] = useState(currentInvitee);

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
  });

  const formik = useFormik({
    initialValues: invitee,
    validationSchema: [1, 2, 6, 7].includes(appData.user_role_id) ? validationSchemaShareholder : validationSchema,
    onSubmit: (values) => {
      updateInvitee(values);
    },
  });

  useEffect(() => {
    setInvitee(currentInvitee);
  }, [currentInvitee]);

  const deleteInvitee = (id) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    InviteMemberServices.removeInviteeById(id)
      .then((res) => {
        if (res.status === 200) {

          console.log(res);
          setEditing(false);
          setValidParticipants(
            validParticipants.filter((member) => member.id !== id)
          );
        }
        setSnackbar('User Deleted Successfully.', 'success');
      })
      .catch((err) => {
        console.log("failed to delete member")
        // addError(`failed to delete member, ${err}`);
      });
  };

  const updateInvitee = useCallback(
    (updatedInvitee) => {
      let sameEmailTemp = validParticipants.filter((invitee) =>
        invitee.id !== updatedInvitee.id
          ? invitee.email === updatedInvitee.email
          : null
      );
      setSnackbar('User Details Updated Successfully', 'success');
      //For Home Buyers election_type_id === 6
      // setSameEmail(sameEmailTemp.length === 0 ? true : false);

      if (sameEmailTemp.length === 0 || true) {
        InviteMemberServices.editInvitee(invitee.id, updatedInvitee)
          .then((res) => {
            if (res.code === 'success') {
              setEditing(false);
              setValidParticipants(
                validParticipants.map((invitee) => {
                  return invitee.id === updatedInvitee.id
                    ? updatedInvitee
                    : invitee;
                })
              );
            }
          })
          .catch((err) => {
            console.log("Failed to edit member details");
            // addError(`Failed to edit member details ${err}`);
          });
      }
    },
    [addError, invitee.id, setEditing, setValidParticipants, validParticipants]
  );

  if ([1, 2, 6, 7].includes(appData.user_role_id)) {
    var array = ['name', 'email', 'share', 'phone'];
  } else {
    array = ['name', 'email', 'phone'];
  }

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        console.log("evveny : ", event)
        formik.handleSubmit();
      }}
    >
      <Typography variant="h6" gutterBottom component="div">
        Edit Details
      </Typography>
      <Grid container spacing={2} justify="space-between">
        {array.map((item, index) => {
          return (
            <Grid item sm xs component={FormControl} key={index}>
              <TextField
                id={item}
                label={item}
                variant="outlined"
                type="text"
                name={item}
                value={formik.values[item]}
                onChange={formik.handleChange}
                error={formik.touched[item] && Boolean(formik.errors[item])}
                helperText={formik.touched[item] && formik.errors[item]}
              />
              {/* <FormHelperText style={{ color: 'red', paddingLeft: '10px' }}>
                {!sameEmail && item === 'email' && formik.touched[item]
                  ? 'Each member must have unique email'
                  : null}
              </FormHelperText> */}
            </Grid>
          );
        })}
      </Grid>

      <Grid container alignItems="center" justify="space-between" spacing={2}>
        <Grid item sm={1}>
          <IconButton
            onClick={() => {
              setConfirmDialog({
                isOpen: true,
                title: 'Are you sure to delete this member ?',
                subTitle: "You can't undo this operation",
                onConfirm: () => {
                  deleteInvitee(invitee.id);
                },
              });
            }}
            style={{ color: 'red' }}
          >
            <DeleteForeverRounded />
          </IconButton>
        </Grid>
        <Grid item sm={8} style={{ color: 'red', fontWeight: '500' }}>
          Remove Invitee
        </Grid>
        <Grid item sm={3}>
          <Button
            style={{ backgroundColor: '#0B2F64' }}
            className={inviteClasses.addUpdatebtn}
            type="submit"
            variant="contained"
            color="primary"
          >
            Update User
          </Button>
          <Button onClick={() => setEditing(false)}>Cancel</Button>
        </Grid>
      </Grid>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </form>
  );
};

export default EditInviteeForm;
