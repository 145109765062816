const PencilSquare = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.5 23.4375C14.9864 23.4375 17.371 22.4498 19.1291 20.6916C20.8873 18.9335 21.875 16.5489 21.875 14.0625C21.875 11.5761 20.8873 9.19153 19.1291 7.43337C17.371 5.67522 14.9864 4.6875 12.5 4.6875C10.0136 4.6875 7.62903 5.67522 5.87087 7.43337C4.11272 9.19153 3.125 11.5761 3.125 14.0625C3.125 16.5489 4.11272 18.9335 5.87087 20.6916C7.62903 22.4498 10.0136 23.4375 12.5 23.4375ZM12.5 25C15.4008 25 18.1828 23.8477 20.234 21.7965C22.2852 19.7453 23.4375 16.9633 23.4375 14.0625C23.4375 11.1617 22.2852 8.3797 20.234 6.32852C18.1828 4.27734 15.4008 3.125 12.5 3.125C9.59919 3.125 6.8172 4.27734 4.76602 6.32852C2.71484 8.3797 1.5625 11.1617 1.5625 14.0625C1.5625 16.9633 2.71484 19.7453 4.76602 21.7965C6.8172 23.8477 9.59919 25 12.5 25Z"
          fill="#1171FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.4998 7.03125C12.707 7.03125 12.9057 7.11356 13.0522 7.26007C13.1987 7.40659 13.281 7.6053 13.281 7.8125V14.0625C13.281 14.184 13.2527 14.3039 13.1982 14.4125L10.8545 19.1C10.809 19.1925 10.7456 19.2752 10.6681 19.3431C10.5905 19.411 10.5003 19.4629 10.4026 19.4958C10.3049 19.5287 10.2016 19.542 10.0988 19.5348C9.9959 19.5276 9.89549 19.5001 9.80331 19.4539C9.71113 19.4077 9.62901 19.3437 9.56167 19.2656C9.49434 19.1875 9.44313 19.0969 9.41099 18.9989C9.37885 18.901 9.36643 18.7976 9.37442 18.6948C9.38242 18.592 9.41069 18.4918 9.45759 18.4L11.7185 13.8781V7.8125C11.7185 7.6053 11.8008 7.40659 11.9474 7.26007C12.0939 7.11356 12.2926 7.03125 12.4998 7.03125Z"
          fill="#1171FF"
        />
        <path
          d="M1.34356 8.41699C0.939274 8.06533 0.611665 7.63425 0.381101 7.15057C0.150537 6.66688 0.0219493 6.14093 0.00333461 5.60543C-0.0152801 5.06993 0.0764761 4.53632 0.272897 4.03779C0.469318 3.53926 0.766204 3.08648 1.14509 2.70759C1.52398 2.3287 1.97676 2.03182 2.47529 1.8354C2.97382 1.63898 3.50743 1.54722 4.04293 1.56583C4.57843 1.58445 5.10438 1.71304 5.58807 1.9436C6.07175 2.17416 6.50283 2.50177 6.85449 2.90606C4.47941 4.11191 2.54941 6.04191 1.34356 8.41699ZM18.1451 2.90606C18.4968 2.50177 18.9279 2.17416 19.4115 1.9436C19.8952 1.71304 20.4212 1.58445 20.9567 1.56583C21.4922 1.54722 22.0258 1.63898 22.5243 1.8354C23.0228 2.03182 23.4756 2.3287 23.8545 2.70759C24.2334 3.08648 24.5303 3.53926 24.7267 4.03779C24.9231 4.53632 25.0149 5.06993 24.9963 5.60543C24.9777 6.14093 24.8491 6.66688 24.6185 7.15057C24.3879 7.63425 24.0603 8.06533 23.6561 8.41699C22.4502 6.04191 20.5202 4.11191 18.1451 2.90606Z"
          fill="#1171FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.1969 22.1031C18.2695 22.0304 18.3557 21.9726 18.4506 21.9333C18.5455 21.8939 18.6472 21.8736 18.75 21.8736C18.8528 21.8736 18.9545 21.8939 19.0494 21.9333C19.1444 21.9726 19.2306 22.0304 19.3031 22.1031L20.8656 23.6656C21.0123 23.8123 21.0947 24.0113 21.0947 24.2188C21.0947 24.4262 21.0123 24.6252 20.8656 24.7719C20.7189 24.9186 20.52 25.001 20.3125 25.001C20.105 25.001 19.9061 24.9186 19.7594 24.7719L18.1969 23.2094C18.1241 23.1368 18.0664 23.0506 18.027 22.9557C17.9876 22.8608 17.9674 22.759 17.9674 22.6562C17.9674 22.5535 17.9876 22.4517 18.027 22.3568C18.0664 22.2619 18.1241 22.1757 18.1969 22.1031ZM6.80314 22.1031C6.73056 22.0304 6.64435 21.9726 6.54944 21.9333C6.45452 21.8939 6.35277 21.8736 6.25001 21.8736C6.14725 21.8736 6.0455 21.8939 5.95058 21.9333C5.85567 21.9726 5.76946 22.0304 5.69688 22.1031L4.13439 23.6656C3.98769 23.8123 3.90527 24.0113 3.90527 24.2188C3.90527 24.4262 3.98769 24.6252 4.13439 24.7719C4.28108 24.9186 4.48005 25.001 4.68751 25.001C4.89497 25.001 5.09394 24.9186 5.24064 24.7719L6.80314 23.2094C6.87589 23.1368 6.93361 23.0506 6.973 22.9557C7.01238 22.8608 7.03266 22.759 7.03266 22.6562C7.03266 22.5535 7.01238 22.4517 6.973 22.3568C6.93361 22.2619 6.87589 22.1757 6.80314 22.1031ZM8.59376 0.78125C8.59376 0.57405 8.67607 0.375336 8.82258 0.228823C8.9691 0.08231 9.16781 0 9.37501 0L15.625 0C15.8322 0 16.0309 0.08231 16.1774 0.228823C16.3239 0.375336 16.4063 0.57405 16.4063 0.78125C16.4063 0.98845 16.3239 1.18716 16.1774 1.33368C16.0309 1.48019 15.8322 1.5625 15.625 1.5625H9.37501C9.16781 1.5625 8.9691 1.48019 8.82258 1.33368C8.67607 1.18716 8.59376 0.98845 8.59376 0.78125Z"
          fill="#1171FF"
        />
        <path
          d="M10.9375 1.5625H14.0625V4.6875H10.9375V1.5625Z"
          fill="#1171FF"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="25" height="25" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PencilSquare;
