import {
  Card,
  Grid,
  Typography,
  Radio,
  Checkbox,
  TextField,
  VotingPageStyles,
  useState,
  useEffect,
  Box,
} from '../../collections/Imports';
import GetOptionImage from './GetOptionImage';
import onChangeHandlers from './OnChangeHandlers';

const OptionCardIBC = (props) => {
  const { currentQuestion, electionType, questions, setQuestions, setValid } =
    props;
  const classes = VotingPageStyles();
  const [error, setError] = useState([]);
  const [errorText, setErrorText] = useState(null);

  const [rankingsArray, setRankingsArrray] = useState([]);

  useEffect(() => {
    if (currentQuestion?.questionType?.question_type_id === 4) {
      const array = [];
      for (let i = 0; i < currentQuestion.options.length; i++) {
        array.push({ index: i, error: false });
      }
      setError(array);
    }
  }, [currentQuestion.questionType.question_type_id]);

  useEffect(() => {
    let errorArray = error?.filter((item) => (item.error ? item.error : null));
    setValid(!(errorArray.length > 0));
  }, [error]);

  useEffect(() => {
    var rankArray = [];
    currentQuestion.options.map((option) => {
      return rankArray.push(option.shares);
    });
    setRankingsArrray(rankArray);
  }, [currentQuestion]);

  return (
    <Grid
      container
      spacing={2}
      justify="center"
      alignItems="center"
      style={{
        flexGrow: 1,
        fontSize: '18px',
      }}
    >
      <Grid item xs={12} sm={12}></Grid>
      <Grid item sm={8} xs={12}>
        {currentQuestion?.options?.map((item, index) => {
          return (
            <>

              <Grid container>
                <Grid item sm={1} xs={12} style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  paddingRight: '1rem',
                  paddingBottom: '1rem',
                  paddingLeft: '0.5rem',
                }}>
                  <Box
                    style={{
                      fontWeight: '500',
                      color: '#fff',
                      background: "gray"
                      , borderRadius: '50%',
                      textAlign: 'center'
                      , border: '2px solid gray',
                      fontSize: '1rem',
                      width: '25px', display: 'flex', alignItems: 'center',
                      height: '25px', justifyContent: 'center',
                      padding: '0.8rem'
                    }}
                  >{String.fromCharCode(65 + index)}
                  </Box>
                </Grid>
                <Grid item sm={11} xs={12}>
                  <Card className={classes.optionCard} key={item.option_id}>
                    <Grid
                      container
                      spacing={2}
                      justify="space-between"
                      alignItems="center"
                      style={{
                        paddingTop: '10px',
                        paddingBottom: '10px',
                        padding: '1rem',
                      }}
                    >
                      <Grid item sm={1} xs={1}>
                        {currentQuestion?.questionType.question_type_id === 2 ? (
                          <Checkbox
                            classes={{
                              checked: classes.checked
                            }}
                            checked={item.status === 'voted'}
                            onChange={(e) => {
                              onChangeHandlers.handleCheckboxChangeNew({
                                index,
                                questions,
                                setQuestions,
                                currentQuestion,
                              });
                            }}
                            value={item.option_id}
                            key={item.option_id}
                          />
                        ) : currentQuestion?.questionType.question_type_id === 1 ? (
                          <Radio
                            classes={{
                              checked: classes.checked
                            }}
                            checked={item.status === 'voted'}
                            onChange={(e) =>
                              onChangeHandlers.handleRadioChangeNew({
                                currentQuestion,
                                index,
                                questions,
                                setQuestions,
                              })
                            }
                            value={item.option_id}
                            key={item.option_id}
                            name="radio-button"
                          />
                        ) : null}
                      </Grid>

                      <Grid item sm={6} xs={12} style={{ marginLeft: '12px' }}>
                        <Typography>{item.value}</Typography>
                      </Grid>
                      <Grid item sm={1} xs={12} style={{ marginLeft: '5px' }}>
                        {currentQuestion &&
                          currentQuestion.option_images?.length > 0 ? (
                          <GetOptionImage
                            currentQuestion={currentQuestion}
                            index={index}
                          />
                        ) : null}
                      </Grid>
                      <Grid item sm={3} xs={12} style={{ paddingLeft: '1rem' }}>
                        {electionType === 3 ||
                          currentQuestion?.questionType.question_type_id === 4 ? (
                          <Grid style={{ paddingRight: '5px', }}>
                            <TextField
                              key={item.option_id}
                              variant="outlined"
                              name="rank"
                              id="rank"
                              label="Rank"
                              placeholder="Enter rank"
                              type="number"
                              value={item.shares}
                              helperText={error[index]?.error ? errorText : null}
                              error={error[index]?.error}
                              onChange={(e) => {
                                onChangeHandlers.handleRankChange({
                                  e,
                                  index,
                                  questions,
                                  setQuestions,
                                  currentQuestion,
                                  setError,
                                  setErrorText,
                                  error,
                                  rankingsArray,
                                });
                              }}
                            />
                          </Grid>
                        ) : null}

                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
              </Grid>

            </>
          );

        })}
      </Grid>
    </Grid>

  );
};

export default OptionCardIBC;
