import {
  React,
  useState,
  Grid,
  MyPollStyle,
  CreatePollstyle,
  Typography,
  moment,
  Calendar,
  Alarm,
  Button,
  axiosPost,
  Snackbar,
  Alert,
  Box,
} from '../../collections/Imports';
import useAPIError from '../../hooks/useAPIError';

import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
// import { LocalConvenienceStoreOutlined } from '@material-ui/icons';

const ChangeSchedueModal = (props) => {
  const { addError } = useAPIError();
  const [start_date, setStartDate] = useState(props.poll.start_date);
  const [end_date, setEndDate] = useState(props.poll.end_date);
  const [start_time, setStartTime] = useState(
    moment(props.poll.start_time, 'hh:mm')
  );
  const [end_time, setEndTime] = useState(moment(props.poll.end_time, 'hh:mm'));
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  // const [enableLoading, setEnableLoading] = useState(true);
  const [severity, setSeverity] = useState('error');

  const classes = MyPollStyle();
  const classescreatepollstyle = CreatePollstyle();

  const updateSchedule = () => {
    const { poll_id } = props;
    if (poll_id) {
      axiosPost('api/polls/update-schedule', {
        poll_id,
        start_date,
        end_date,
        start_time: moment(start_time).format('HH:mm:ss'),
        end_time: moment(end_time).format('HH:mm:ss'),
      })
        .then((res) => {
          if (props.enableStartDate) props.setPollChanged((prev) => !prev);
          else props.fetchPolls();

          setSeverity('success');
          setOpen(true);
          setMessage('Updated schedule successfully');
          setOpen(false);
          // console.log('res : ', res);
        })
        .catch((err) => {
          addError(`LOAD_DATA_ERROR: ${err}`);
        });
    } else {
      addError('Invalid poll`');
    }
  };
  const handleModal = () => {
    props.handleClose(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box className={classes.paper} style={{ border: '5px solid #007bff' }}>
      {props.enableStartDate ? (
        <Grid container className={classescreatepollstyle.polltiming}>
          <Grid item xs={12} sm={2}>
            <Typography className={classescreatepollstyle.pollstarting}>
              Poll Starting
            </Typography>
          </Grid>
          <Grid item xs={12} sm={5}>
            <Typography className={classescreatepollstyle.formlabeText}>
              Poll Starting Date
            </Typography>

            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                className={classescreatepollstyle.dateNtime}
                style={{ padding: '5px' }}
                disableToolbar
                variant="outlined"
                format="dd/MM/yyyy"
                margin="normal"
                id="date-picker-inline"
                value={start_date}
                name="start_date"
                onChange={(e) => setStartDate(e)}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                InputProps={{ disableUnderline: true }}
                keyboardIcon={<Calendar />}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} sm={5}>
            <Typography className={classescreatepollstyle.formlabeText}>
              Poll Starting Time
            </Typography>

            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardTimePicker
                className={classescreatepollstyle.dateNtime}
                style={{ padding: '5px' }}
                margin="normal"
                id="time-picker"
                value={start_time}
                onChange={(e) => setStartTime(e)}
                KeyboardButtonProps={{
                  'aria-label': 'change time',
                }}
                InputProps={{ disableUnderline: true }}
                keyboardIcon={<Alarm />}
              />
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>
      ) : null}
      <Grid container className={classescreatepollstyle.polltiming}>
        <Grid item xs={12} sm={2}>
          <Typography className={classescreatepollstyle.pollstarting}>
            Poll Ending
          </Typography>
        </Grid>
        <Grid item xs={12} sm={5}>
          <Typography className={classescreatepollstyle.formlabeText}>
            Poll Ending Date
          </Typography>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              className={classescreatepollstyle.dateNtime}
              style={{ padding: '5px' }}
              disableToolbar
              variant="outlined"
              format="dd/MM/yyyy"
              margin="normal"
              id="date-picker-inline"
              value={end_date}
              onChange={(e) => setEndDate(e)}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              InputProps={{ disableUnderline: true }}
              keyboardIcon={<Calendar />}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={12} sm={5}>
          <Typography className={classescreatepollstyle.formlabeText}>
            Poll Ending Time
          </Typography>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardTimePicker
              className={classescreatepollstyle.dateNtime}
              style={{ padding: '5px' }}
              margin="normal"
              id="time-picker"
              value={end_time}
              onChange={(e) => setEndTime(e)}
              KeyboardButtonProps={{
                'aria-label': 'change time',
              }}
              InputProps={{ disableUnderline: true }}
              keyboardIcon={<Alarm />}
            />
          </MuiPickersUtilsProvider>
        </Grid>
      </Grid>
      <Box style={{ margin: '20px', float: 'right' }}>
        <Button
          className={classes.Button}
          style={{ margin: '10px' }}
          variant="contained"
          onClick={updateSchedule}
        >
          SAVE
        </Button>

        <Button
          onClick={handleModal}
          className={classes.Button}
          variant="contained"
        >
          CANCEL
        </Button>
      </Box>

      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        style={{ marginTop: 30 }}
      >
        <Alert
          onClose={handleClose}
          variant="filled"
          severity={severity}
          style={{ fontSize: 20 }}
        >
          {message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ChangeSchedueModal;
