import React, { useState, useEffect } from 'react';

import {
  Select,
  DashBoardStyles,
  MenuItem,
  axiosGet,
  FormControl,
} from '../../collections/Imports';
import useAPIError from '../../hooks/useAPIError';

const MenuHeading = (props) => {
  const [election_type_id, setElectionTypeId] = useState(0);
  const [question_type, setQuestion_type] = useState();
  const [question_type_id, setQuestionTypeId] = useState(1);
  const { addError } = useAPIError();
  const { onChangeMenu } = props;

  const handleChange = (event, feild) => {
    setQuestionTypeId(event.target.value);
    onChangeMenu(event.target.value);
  };

  useEffect(() => {
    let selected_poll_id = localStorage.getItem('selected_poll_id');
    if (selected_poll_id) {
      axiosGet('api/polls/id/' + selected_poll_id)
        .then((result) => {
          if (result) {
            const { poll } = result;
            const { election_type } = poll;
            setElectionTypeId(parseInt(election_type.election_type_id));
            if (parseInt(election_type.election_type_id) === 3) {
              onChangeMenu(3);
              setQuestionTypeId(3);
            } else {
              setQuestionTypeId(1);
              onChangeMenu(1);
            }
            if (props.question_type_id) {
              onChangeMenu(props.question_type_id);
              setQuestionTypeId(props.question_type_id)
            }

          }
        })
        .catch((err) => {
          addError('Error fetching poll : ', err);
        });
    }
  }, [addError]);

  useEffect(() => {
    axiosGet('api/question-types/all/')
      .then((result) => {
        console.log("QuestuonTypes:--", result?.questionTypes)
        setQuestion_type(result?.questionTypes);
      })
      .catch((err) => {
        addError('Error fetching question types : ', err);
      });
  }, [addError]);

  useEffect(() => {
    if (props.question_type_id) {
      onChangeMenu(props.question_type_id);
      setQuestionTypeId(props.question_type_id)
    } else {
      onChangeMenu(1)
      setQuestionTypeId(1)
    }
  }, [])
  const classes = DashBoardStyles();
  return (
    <FormControl variant="outlined" className={classes.multiselect}>
      <Select
        name="questionType"
        onLoad={handleChange}
        onChange={handleChange}
        value={question_type_id}
        style={{ width: '160%' }}
      >
        {question_type &&
          question_type.map(({ question_type_id, _id, question_type }) => {
            if (election_type_id === 2) {
              if (question_type_id === 1) {
                return (
                  <MenuItem key={_id} value={question_type_id}>
                    {question_type}
                  </MenuItem>
                );
              } else {
                return null;
              }
            } else if (election_type_id === 3) {
              if (question_type_id === 3 || question_type_id === 5) {
                return (
                  <MenuItem key={_id} value={question_type_id}>
                    {question_type}
                  </MenuItem>
                );
              }
            } else {
              if (question_type_id === 3 || question_type_id === 5) {
                return null;
              } else {
                return (
                  <MenuItem key={_id} value={question_type_id}>
                    {question_type}
                  </MenuItem>
                );
              }
            }
          })}
      </Select>
    </FormControl>
  );
};

export default MenuHeading;
