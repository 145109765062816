import {
  React,
  useEffect,
  InviteMembersStyle,
  Paper,
  CardContent,
  useState,
  AddInviteeForm,
  PollDetails,
  DashboardScreen,
  InviteMemberServices,
  GlobalContext,
  useContext,
  axiosGet,
  Grid,
  Typography,
  SpinGif
} from '../../collections/Imports';

import AddFile from './AddFile';
import useAPIError from '../../hooks/useAPIError';
import InviteTable from './InviteTable/InviteTable';
import InviteNav from './InviteNav';
import AddInviteeButton from './AddInviteeButton';
import { useHistory } from 'react-router-dom'

const InviteMembers = () => {
  const { addError } = useAPIError();
  const classes = InviteMembersStyle();
  const [showForm, setShowForm] = useState(false);
  const [validParticipants, setValidParticipants] = useState([]);
  const [appData, setAppData] = useContext(GlobalContext);
  const [pollStatus, setPollStatus] = useState('');
  const [plan, setPlan] = useState([])
  const [poll, setPoll] = useState(null)
  const [getValidFlag, setGetValidFlag] = useState(false);
  const [enableLoading, setEnableLoading] = useState(true);
  const history = useHistory();

  useEffect(() => {
    try {
      let currentUser = JSON.parse(localStorage.getItem('currentUser'));
      axiosGet('api/polls/id/' + appData.selected_poll_id || localStorage.getItem('selected_poll_id'))
        .then((res) => {
          const { poll } = res
          const { user_plan } = poll
          setAppData({
            ...appData,
            poll,
          });
          setPoll(poll)
          setPlan(user_plan)
          if (poll.rta_id) {
            if (poll.election_type._id === "60844acbc18867305001c508" && poll.rta_id._id !== currentUser._id) {
              history.push('/dashboard/mypoll')
            }
          }
          setEnableLoading(false);
        })
        .catch((err) => {
          console.log("Unable to fetch polls")
          // addError(`Unable to fetch poll detials: ${err}`);
        });
    } catch (err) {
      console.log("Error : ", err)
    }

  }, [appData.selected_poll_id])


  const getValidParticipants = () => {
    InviteMemberServices.getValid(appData.selected_poll_id, {
      setValidParticipants,
      addError,
    });
  };

  useEffect(() => {
    let poll = JSON.parse(localStorage.getItem('selected_poll'));
    setPollStatus(poll.status);
  }, []);

  useEffect(() => {
    getValidParticipants();
  }, [getValidFlag]);

  return (
    <DashboardScreen>
      {
        enableLoading ? <Grid container justify="center" alignItems="center" direction="column">
          <Grid item>
            <img src={SpinGif} alt="spinner" />
          </Grid>
          <Grid item>
            <Typography variant="h5"></Typography>
          </Grid>
        </Grid> :
          <>
            <InviteNav pollStatus={pollStatus} />
            <CardContent>
              <PollDetails />

              {pollStatus === 'closed' ? null : (
                <AddFile
                  getValidParticipants={getValidParticipants}
                  validParticipants={validParticipants}
                  pollStatus={pollStatus}
                  plan={plan}
                  poll={poll}
                />
              )}

              {validParticipants.length > 0 ? (
                <InviteTable
                  setDeleteFlag={setGetValidFlag}
                  validParticipants={validParticipants}
                  setValidParticipants={setValidParticipants}
                  plan={plan}
                  poll={poll}
                />
              ) : null}

              {showForm ? (
                <Paper className={classes.AddPaper} elevation={3} component="div">
                  <AddInviteeForm
                    setValidParticipants={setValidParticipants}
                    validParticipants={validParticipants}
                    setShowForm={setShowForm}
                    setGetValidFlag={setGetValidFlag}
                    plan={plan}
                    poll={poll}
                    user_role_id={appData.user_role_id}
                  />
                </Paper>
              ) : null}

              {![2, 6].includes(appData.user_role_id) ? (
                <AddInviteeButton setShowForm={setShowForm} />
              ) : null}
            </CardContent>

          </>
      }
    </DashboardScreen>
  );
};

export default InviteMembers;
