import { makeStyles } from '@material-ui/core/styles';

export const TitleBox = makeStyles((theme) => ({
  TitleBand: {
    width: '100%',
    backgroundColor: '#0B2F64',
    color: '#fff',
    padding: '1rem',
    marginTop: '0px',
  },
  CardPadding: {
    padding: '16px',
  },
}));
export default TitleBox;
