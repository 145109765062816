import { lighten, makeStyles } from "@material-ui/core";

export const InviteTableToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "#0b2f64"
      ? {
          color: "#fff",
          backgroundColor: "#0b2f64",
        }
      : {
          color: "#fff",
          backgroundColor: "#0b2f64",
        },
  title: {
    flex: "1 1 100%",
  },
  sendButton: {
    backgroundColor: "rgb(11 47 100)",
    color: "rgb(255 255 255)",
    border: "1px solid",
    width: "30%",
    height: "45px",
    fontWeight: "500",
    borderRadius: "4px!important",
    "&:hover": {
      color: "rgb(11 47 100)",
      backgroundColor: "rgb(255 255 255)",
      border: "1px solid",
    },
  },
}));
