import { makeStyles } from '@material-ui/core/styles';

const SignUpStyles = makeStyles((theme) => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    [theme.breakpoints.up(800 + theme.spacing(3) * 2)]: {
      width: 470,
      // paddingRight:'4rem'
    },
  },
  card: {
    background: '#fff',
    boxShadow: '0px 0px 20px rgba(57, 57, 57, 0.15)',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    padding: `${theme.spacing(5)}px ${theme.spacing(5)}px ${theme.spacing(
      5
    )}px`,
  },
  imgapp:{
    width:'80%',
    [theme.breakpoints.down ('sm')]: {
      width:'100%',
    },
  },
  selectcontrol: {
    minWidth: '100%',
    // border: '1px solid #E5E5E5',
    background: '#FFFFFF',
    // boxShadow: 'inset 0px 0px 5px 1px rgb(144 144 144 / 15%)',
    boxSizing: 'border-box',
    borderRadius: '10px',
    marginRight: '5px',
    '& .MuiOutlinedInput-input': {
      padding: '12.5px 14px',
    },
    '& .MuiInputBase-root': {
      paddingRight: '10px',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #1171FF',
    },
  },
  paper: {},
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    marginTop: theme.spacing(3),
  },
  fileInput: {
    padding: '10px',
    marginTop: '20px',
    width: '100%',
    // border: '1px solid #E5E5E5',
    background: '#FFFFFF',
    // boxShadow: 'inset 0px 0px 5px 1px rgb(0 0 0 / 15%)',
    "& .MuiInputBase-input": {
      height: "1.5em"
    },
  },
}));

export default SignUpStyles;
