import {
  NavLink,
  useParams,
  Typography,
  Button,
  Card,
  Grid,
  LoginStyles,
  SignUpStyles,
  OtpInput,
  axiosPost,
  React,
  useState,
  useEffect,
  Box,
  GlobalContext,
  useContext,
} from "../../collections/Imports";
import download_app from "../../../img/download_app.svg";
import useAPIError from "../../hooks/useAPIError";
import { SnackbarContext } from "../../providers/SnackBarContext";
import { Helmet } from "react-helmet";

const VerifySignUp = (props) => {
  const { addError } = useAPIError();
  const [clickable, setClickable] = useState(false);
  const { setSnackbar } = useContext(SnackbarContext);
  const { email } = useParams();
  const classes = SignUpStyles();
  const classes1 = LoginStyles();
  const [otp, setOtp] = useState("");
  const [appData] = useContext(GlobalContext);
  const [seconds, setSeconds] = useState(60);

  const handleOtpChange = (e) => {
    setOtp(e);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    axiosPost("api/auth/signup_verify", {
      email: props.match.params.email,
      otp,
    })
      .then((res) => {
        if (res.code === "signup_verified") {
          setSnackbar("Successfully verified your account", "success");
          props.history.push("/login");
        } else if (res.code === "otp_expired") {
          setSnackbar(
            "OTP is expired. Please, click resend OTP button for new one","info"
          );
        } else {
          setSnackbar("SignUp failed!", "error");
        }
      })
      .catch((err) => {
        addError("Error : ", err);
      });
  };

  const makeOtpInvalid = () => {
    const email = appData.user_email || localStorage.getItem("email");
    axiosPost("api/auth/signup/make-otp-invalid", { email })
      .then((result) => {
        console.log("result : ", result);
      })
      .catch((err) => {
        console.log("Err : ", err);
      });
  };

  const resendOtp = () => {
    axiosPost("api/auth/reset/signup/otp", {
      email,
    })
      .then((res) => {
        setOtp("");
        resetOtp(60);
      })
      .catch((err) => {
        addError("Error resend : ", err);
      });
  };

  const resetOtp = (seconds) => {
    if (seconds > 0) {
      setTimeout(() => setSeconds(seconds - 1), 1000);
    } else {
      setSeconds(0);
      setClickable(true);
    }
  };

  useEffect(() => {
    if (seconds > 0) {
      setTimeout(() => setSeconds(seconds - 1), 1000);
    } else {
      setSeconds(0);
      makeOtpInvalid();
      setClickable(true);
    }
  }, [seconds]);

  return (
    <Box className={classes1.bg}>
       <Helmet>
        <meta charSet="utf-8" />
        {/* <title>EazyVC Voting Platform - Contact US</title> */}
        <meta
          name="title"
          content="The easy way to Vote Online on EazyVC’s Blockchain-secured Voting Platform."
        />
        <meta
          name="description"
          content="EazyVC is a Blockchain-based eVoting platform for Corporates, Government Bodies, Education Institutions, Associations & Clubs, Housing Societies, NGO, Trusts and more."
        />
      </Helmet>
      <Grid container spacing={2} className={classes1.gridContainer}>
        <Grid className={classes1.imgnText} item xs={12} sm={6}>
          <img
            src={download_app}
            className="image_mobile"
            alt="EazyVC Mobile Optimized Online Voting Software "
          />
          <Typography className={classes1.loginText}>
            {" "}
            Welcome to the
          </Typography>
          <Typography className={classes1.loginText}>
            <span style={{ color: "#fff" }}>Most Secure </span> E-Voting
            Platform
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Grid className={classes.main}>
            <Card className={classes.card}>
              <Typography
                className={classes1.LoginHeading}
                component="h1"
                variant="h5"
              >
                OTP verification
              </Typography>
              <Typography
                className={classes1.digitText}
                component="h3"
                variant="h6"
              >
                Enter 6 Digit OTP
              </Typography>
              <Grid container justify="center">
                <Grid item xs={12}>
                  <form className={classes.form} onSubmit={handleSubmit}>
                    <Box style={{ display: "flex", justifyContent: "center" }}>
                      <OtpInput
                        className={classes1.otpInputbox}
                        inputStyle={{ width: "2em" }}
                        shouldAutoFocus={true}
                        value={otp}
                        onChange={handleOtpChange}
                        numInputs={6}
                        placeholder={true}
                      />
                    </Box>
                  </form>
                </Grid>
              </Grid>
              <Typography className={classes1.messageText}>
                The OTP has been sent to your{" "}
                {/* <NavLink to="/"> {props.match.params.phone} </NavLink>and */}
                registered e-mail address{" "}
                <NavLink to="/"> {props.match.params.email} </NavLink>
              </Typography>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                className={classes1.submit}
                onClick={handleSubmit}
              >
                Register
              </Button>
              <Grid container justify="space-between" alignItem="center">
                <Grid item style={{fontWeight:'500',fontFamily:'inherit'}}>OTP Expired in {seconds}</Grid>
                <Grid item>
                  {clickable ? (
                    <NavLink style={{fontWeight:'500',fontFamily:'inherit'}} to="#" onClick={resendOtp}>
                      Resend OTP
                    </NavLink>
                  ) : (
                    <NavLink
                      to="#"
                      onClick={(e) => e.preventDefault()}
                      style={{ opacity: 0.3 }}
                    >
                      Resend OTP
                    </NavLink>
                  )}
                </Grid>
              </Grid>
              <Grid
                container
                justify="space-between"
                alignItem="right"
                style={{ marginTop: "2rem" }}
              >
                <Grid item />
                <Grid item>
                  {/* <NavLink to="/"> Entered wrong details?</NavLink> */}
                </Grid>
              </Grid>
              <form />
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
export default VerifySignUp;
