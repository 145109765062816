import React, { useEffect } from "react";
import {
  Typography,
  Card,
  Grid,
  useState,
  useContext,
  GlobalContext,
  PollDetails,
  Box,
  axiosPost,
  useParams,
  moment,
  Button2,
  MyTableCell,
  TableRow,
  BoldTableCell,
  TableContainer,
  axiosGet,
  NavLink,
  SpinGif,
} from "../../collections/Imports";

import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Mobile from "../../../img/Mobile.webp";
import ScrutinizerStyle from "./ScrutinizerStyle";
import useAPIError from "../../hooks/useAPIError";
import ChangeSchedueModal from "../MyPolls/ChangeScheduleModal";
// import { ImportantDevices } from '@material-ui/icons';

function Scrutinizer(props) {
  const { addError } = useAPIError();

  const classes = ScrutinizerStyle();
  const [appData, setAppData] = useContext(GlobalContext);
  const [open, setOpen] = useState(false);
  const [pollStatus, setPollStatus] = useState(null);
  const [enableLoading, setEnableLoading] = useState(true);
  const [poll, setPoll] = useState({});
  const [poll_id, setPollID] = useState();
  // const [scrutinizer, setScrutinizer] = useState();
  const [participants, setParticipants] = useState([]);
  const [totalWeightage, setTotalWeightage] = useState(0);
  const [totalCastedVotes] = useState(0);
  const [totalCastedVotesWeightage, setTotalCastedVotesWeightage] = useState(0);
  const [pollChanged, setPollChanged] = useState(false);

  const params = useParams();

  const setLocalStorage = (scrutinizer, poll) => {
    localStorage.setItem("scrutinizer_email", scrutinizer.scrutinizer_email);
    localStorage.setItem("scrutinizer_name", scrutinizer.scrutinizer_name);
    localStorage.setItem("selected_poll_id", poll._id);
    localStorage.setItem("selected_poll", JSON.stringify(poll));
    setPollID(poll._id);
    setPoll(poll);
    // setScrutinizer(scrutinizer);
  };

  const removeLocalStorage = () => {
    localStorage.clear();
  };

  const checkPollStatus = (poll) => {
    var status = "";
    if (poll) {
      var { start_date, end_date, start_time, end_time } = poll;
      start_date = moment(start_date).format("YYYY.MM.DD");
      var start = moment(
        start_date + " " + start_time,
        moment.defaultFormat
      ).format();

      end_date = moment(end_date).format("YYYY.MM.DD");
      var end = moment(
        end_date + " " + end_time,
        moment.defaultFormat
      ).format();

      if (moment().diff(start, "s") < 0) {
        status = "Scheduled";
      } else if (moment().diff(end, "s") > 0) {
        status = "Closed";
      } else if (
        moment().diff(start, "s") >= 0 &&
        moment().diff(end, "s") < 0
      ) {
        status = "Started";
      }
    }
    setPollStatus(status);
  };

  const getParticipants = async () => {
    let poll_id =
      appData && appData.selected_poll_id
        ? appData.selected_poll_id
        : localStorage.getItem("selected_poll_id");
    await axiosGet("api/polls/participants/" + poll_id)
      .then((res) => {
        const participants = res && res.participants ? res.participants : [];
        var totalWeightage = 0;
        participants.forEach((p) => {
          totalWeightage +=
            p && p.share_id.share_value
              ? parseFloat(p.share_id.share_value)
              : 0;
        });
        setParticipants(participants);
        setTotalWeightage(totalWeightage);
      })
      .catch((err) => {
        addError(`LOAD_DATA_ERROR: ${err}`);
      });
  };

  const getCastedVotes = () => {
    let poll_id =
      appData && appData.selected_poll_id
        ? appData.selected_poll_id
        : localStorage.getItem("selected_poll_id");
    axiosGet("api/votes/votes-by-poll/" + poll_id)
      .then((res) => {
        const votes = res && res.votes ? res.votes : [];

        var totalCastedVotesWeightage = 0;
        votes.forEach((v) => {
          totalCastedVotesWeightage +=
            v.share_id && v.share_id.share_value
              ? parseFloat(v.share_id.share_value)
              : 0;
        });
        setTotalCastedVotesWeightage(totalCastedVotesWeightage);
      })
      .catch((err) => {
        addError(`LOAD_DATA_ERROR: ${err}`);
      });
  };

  const getPollById = (poll_id) => {
    if (poll_id) {
      axiosGet("api/polls/id/" + poll_id)
        .then((polls) => {
          const poll = polls && polls.poll ? polls.poll : null;
          // console.log('Poll After get fetch : ', poll);
          setAppData({
            ...appData,
            selected_poll_id: poll._id,
            poll,
          });
          setPollID(poll._id);
          setPoll(poll);
          localStorage.setItem("selected_poll_id", poll._id);
          localStorage.setItem("selected_poll", JSON.stringify(poll));
          checkPollStatus(poll);
        })
        .catch((err) => {
          addError(`LOAD_DATA_ERROR: ${err}`);
        });
    } else {
      addError("Error in fecthing data...");
    }
  };
  useEffect(() => {
    const getData = async () => {
      const { token } = params;
      axiosPost("api/auth/scrutinizer/verify/token", { token })
        .then((res) => {
          if (res) {
            const { scrutinizer, poll } = res;
            const { scrutinizer_email, scrutinizer_name } = scrutinizer;
            // await getPollById(poll._id)
            setAppData({
              ...appData,
              scrutinizer_email,
              scrutinizer_name,
            });
            // setScrutinizer(scrutinizer);
            setLocalStorage(scrutinizer, poll);
          } else {
            addError("Error : removing local strorage");
            removeLocalStorage();
          }
          setEnableLoading(false);
        })
        .catch((err) => {
          removeLocalStorage();
          setEnableLoading(false);
        });

      checkPollStatus(poll);
      getParticipants();
      getCastedVotes();
    };

    getData();
  }, []);

  useEffect(() => {
    const poll_id = localStorage.getItem("selected_poll_id");
    getPollById(poll_id);
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Box className={classes.Maindiv}>
      {enableLoading ? (
        <Grid
          container
          justify="center"
          alignItems="center"
          direction="column"
          style={{ paddingTop: "100px" }}
        >
          <Grid item>
            <img src={SpinGif} alt="spinner" />
          </Grid>
          <Grid item>
            <Typography variant="h5">Fetching Information...</Typography>
          </Grid>
        </Grid>
      ) : null}
      <Grid item xs={12} sm={12} style={{ margin: "auto" }}>
        <Box className={classes.main}>
          <Card className={classes.card}>
            <img src={Mobile} alt="Logo" className={classes.imgmobile} />
            <Typography
              className={classes.LoginHeading}
              component="h1"
              variant="h5"
            >
              Your Poll is{" "}
              <span style={{ color: "#007bff" }}>{pollStatus}</span>
            </Typography>
            <Box style={{ margin: "0 auto" }}>
              <PollDetails
                poll_id={poll_id}
                scrutinizer={true}
                pollChanged={pollChanged}
                style={{ marginBottom: "20px" }}
              />

              <Grid style={{ marginTop: 20 }}>
                <TableContainer>
                  <TableRow>
                    <MyTableCell style={{ color: "blue" }}>
                      Total Voters
                    </MyTableCell>
                    <BoldTableCell>
                      {participants && participants.length > 0
                        ? participants.length
                        : 0}
                    </BoldTableCell>
                    <MyTableCell style={{ color: "red" }}>
                      Total Vote weightage
                    </MyTableCell>
                    <BoldTableCell>
                      {totalWeightage ? totalWeightage : 0}
                    </BoldTableCell>
                  </TableRow>
                  <TableRow>
                    <MyTableCell style={{ color: "blue" }}>
                      Total Vote cast
                    </MyTableCell>
                    <BoldTableCell>
                      {totalCastedVotes ? totalCastedVotes : 0}
                    </BoldTableCell>
                    <MyTableCell style={{ color: "red" }}>
                      Total Vote Percentage
                    </MyTableCell>
                    <BoldTableCell>
                      {totalCastedVotesWeightage && totalWeightage
                        ? (
                          (totalCastedVotesWeightage / totalWeightage) *
                          100
                        ).toFixed(2)
                        : 0}{" "}
                      %
                    </BoldTableCell>
                  </TableRow>
                </TableContainer>
              </Grid>
            </Box>
            {pollStatus === "Scheduled" ? (
              <NavLink
                to="#"
                type="submit"
                style={{
                  textAlign: "center",
                  fontSize: "20px",
                  marginTop: "15px",
                }}
                onClick={handleOpen}
              >
                Change Schedule
              </NavLink>
            ) : null}
            {pollStatus === "Started" ? (
              <NavLink
                to="#"
                style={{
                  textAlign: "center",
                  fontSize: "20px",
                  marginTop: "15px",
                }}
                type="submit"
                onClick={handleOpen}
              >
                Change Schedule
              </NavLink>
            ) : null}

            {pollStatus === "Closed" ? (
              <Box align="center" style={{ marginTop: "30px" }}>
                <Button2
                  type="submit"
                  variant="contained"
                  backgroundColor="#1171FF"
                  width="50%"
                  height="60px"
                  // className={classes.submit}
                  onClick={() => props.history.push("/dashboard/pollresult")}
                >
                  GO TO RESULT
                </Button2>
              </Box>
            ) : null}
            <Grid md={4} lg={3} sm={12} style={{ maxHeight: "maxHeight" }}>
              <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 1000,
                }}
                style={{
                  marginLeft: "20%",
                  marginTop: "10%",
                  maxWidth: "300px",
                }}
              >
                <Fade>
                  <ChangeSchedueModal
                    poll_id={poll_id}
                    poll={poll}
                    handleClose={handleClose}
                    setPollChanged={setPollChanged}
                    enableStartDate={true}
                  />
                </Fade>
              </Modal>
            </Grid>
          </Card>
        </Box>
      </Grid>
    </Box>
  );
}

export default Scrutinizer;
