import {
  useState,
  Typography,
  Button,
  FormControl,
  TextField,
  Card,
  Grid,
  LoginStyles,
  axiosPost,
  React,
  Box,
  useContext,
} from "../../collections/Imports";
import download_app from "../../../img/download_app.svg";
import useAPIError from "./../../hooks/useAPIError";
import { SnackbarContext } from "../../providers/SnackBarContext";
import { Helmet } from "react-helmet";

const ForgotPassword = (props) => {
  const { addError } = useAPIError();
  const classes = LoginStyles();
  const initialState = { email: "", phone: "", password: "" };
  const [values] = useState({ initialState });
  const { setSnackbar } = useContext(SnackbarContext);

  const [state, setState] = useState({
    formValues: {
      password: "",
      repassword: "",
    },
    errorData: {},
    errorMessage: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    validateUserInfo();
    const email = localStorage.getItem("email");
    const user = localStorage.getItem("user");
    if (validateUserInfo()) {
      axiosPost(
        "api/auth/reset/password/update",
        {
          email,
          user,
          password: state.formValues.password,
        },
        { headers: { "Content-Type": "application/json" } }
      )
        .then((res) => {
          if (res.status === 200) {
            localStorage.setItem("isAuthenticated", true);
            setSnackbar(
              "Password is changed. Redirecting to Login page",
              "success"
            );
            localStorage.clear();
            props.history.push("/login");
          } else {
            setSnackbar("failed to do signup", "error");
          }
        })
        .catch((err) => {
          addError(`${err}`);
        });
    }
  };

  const validateUserInfo = () => {
    let isFormValid = true;
    let formValues = state.formValues;
    state.errorData = {
      password: [],
      repassword: [],
    };

    if (formValues.password.trim().length === 0) {
      isFormValid = false;
      state.errorData.password = ["* Password field cannot be empty."];
    } else if (formValues.password.trim().length < 8) {
      isFormValid = false;
      state.errorData.password = ["* Password must be 8 characters long."];
    } else if (formValues.password !== formValues.repassword) {
      isFormValid = false;
      state.errorData.repassword = ["* Confirm Password must match."];
    }

    let tempState = state;

    setState({
      ...state,
      ...tempState,
    });

    return isFormValid;
  };

  const handleChange = (event, field) => {
    if (
      state.errorData &&
      state.errorData[field] &&
      state.errorData[field][0]
    ) {
      state.errorData[field][0] = null;
    }
    const test = state;
    if (field === "rememberme") {
      test.formValues[field] = event.target.checked;
    } else {
      test.formValues[field] = event.target.value;
    }
    setState({ ...state, ...test });
  };

  return (
    <Box className={classes.bg}>
       <Helmet>
        <meta charSet="utf-8" />
        {/* <title>EazyVC Voting Platform - Contact US</title> */}
        <meta
          name="title"
          content="The easy way to Vote Online on EazyVC’s Blockchain-secured Voting Platform."
        />
        <meta
          name="description"
          content="EazyVC is a Blockchain-based eVoting platform for Corporates, Government Bodies, Education Institutions, Associations & Clubs, Housing Societies, NGO, Trusts and more."
        />
      </Helmet>
      <Grid container spacing={2} className={classes.gridContainer}>
        <Grid className={classes.imgnText} item xs={12} sm={6}>
          <img
            src={download_app}
            style={{ width: "80%" }}
            alt="EazyVC Mobile Optimized Online Voting Software "
          />
          <Typography className={classes.loginText}> Welcome to the</Typography>
          <Typography className={classes.loginText}>
            <span style={{ color: "#fff" }}>Most Secure </span> E-Voting
            Platform
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box className={classes.main}>
            <Card className={classes.card}>
              <Typography component="h1" variant="h5">
                Reset Password
              </Typography>
              <form className={classes.form} onSubmit={handleSubmit}>
                <FormControl margin="normal" required fullWidth>
                  <Typography>New Password</Typography>
                  <TextField
                    className={classes.inputfield}
                    placeholder="Enter New Password"
                    variant="outlined"
                    id="password"
                    name="password"
                    type="password"
                    value={values.password}
                    onChange={(event) => handleChange(event, "password")}
                  />
                </FormControl>
                <span style={{ color: "red" }}>
                  {state.errorData?.password && state.errorData?.password[0]}
                </span>
                <FormControl margin="normal" required fullWidth>
                  <Typography>Confrim Password </Typography>
                  <TextField
                    className={classes.inputfield}
                    placeholder="Enter Confrim Password"
                    variant="outlined"
                    name="repassword"
                    type="password"
                    id="repassword"
                    value={values.repassword}
                    onChange={(event) => handleChange(event, "repassword")}
                  />
                </FormControl>
                <span style={{ color: "red" }}>
                  {state.errorData?.repassword &&
                    state.errorData?.repassword[0]}
                </span>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  submit
                </Button>
              </form>
            </Card>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ForgotPassword;
