import {
  Toolbar,
  AppBar,
  Menu,
  Button,
  IconButton,
  MenuItem,
  useState,
  useHistory,
  Logo,
  auth,
  NavLink,
  NavbarAuthStyles,
  Box
} from '../../collections/Imports';
import MenuIcon from '@material-ui/icons/Menu';

export default function ButtonAppBar({ handleAuthenticateChange }) {
  const classes = NavbarAuthStyles();
  const history = useHistory();
  const [anchorElProduct, setProductAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);

  let plans = localStorage.getItem('plans');
  let poll_limit = 0;
  let number_of_polls = 0;
  if (plans) {
    plans = JSON.parse(plans);
    poll_limit = plans && plans.length > 0 ? plans[0].total_polls : 10;
    number_of_polls = 0; //Number(localStorage.getItem('number_of_polls'));
  }

  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleMyAccountClick = event => {
    setProductAnchorEl(event.currentTarget);
  };

  const handleProductClose = () => {
    setProductAnchorEl(null);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMobileMenuOpen = event => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleLogout = () => {
    auth.logout();
    handleAuthenticateChange();
    history.push('/login');
  };

  const handleChangePassword = () => {
    handleAuthenticateChange();
    history.push('/change-password');
    setMobileMoreAnchorEl(null);
    handleProductClose()
  };

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem className={classes.hemMenu}
        component={NavLink}
        to="/dashboard/mypoll"
        onClick={handleMobileMenuClose}
        style={{ textDecoration: 'none' }}
      >
        MY POLLS
      </MenuItem>
      <MenuItem className={classes.hemMenu}
        component={NavLink}
        to="/dashboard/profile"
        onClick={handleMobileMenuClose}
      >
        MY PROFILE
      </MenuItem>

      <MenuItem className={classes.hemMenu}
        component={NavLink}
        to="/dashboard/subscriptions-history"
        onClick={handleMobileMenuClose}
        style={{ textDecoration: 'none' }}
      >
        SUBSCRIPTION HISTORY
      </MenuItem>
      <MenuItem className={classes.hemMenu}
        component={NavLink}
        to="/dashboard/createpoll"
        onClick={handleMobileMenuClose}
        style={{ textDecoration: 'none' }}
      >
        CREATE POLL
      </MenuItem>
      <MenuItem className={classes.hemMenu}
        component={NavLink}
        to="/dashboard/subscriptions"
        onClick={handleProductClose}
      >
        SUBSCRIPTIONS PRICING
      </MenuItem>
      <MenuItem className={classes.hemMenu}
        onClick={handleChangePassword}>CHANGE PASSWORD</MenuItem>
      <MenuItem className={classes.hemMenu}
        onClick={handleLogout}>LOGOUT</MenuItem>
    </Menu>
  );

  return (
    <Box className={classes.root}>
      <AppBar className={classes.root} position="fixed">
        <Toolbar>
          {/* <span className={classes.title}>
            <img src={Logo} alt="logo" />
            <p style={{ fontWeight: 600 }}>A Product of Votechain Pvt Ltd</p>
          </span> */}
          <NavLink to="/" className={classes.title} style={{ textDecoration: 'none' }}>
            <img src={Logo} alt="logo" />
            <p style={{ fontWeight: 600, color: '#000' }}>A Product of Votechain Private Limited</p>
          </NavLink>
          <Box
            className={classes.sectionDesktop}
            style={{ fontSize: '1rem', margin: 'auto' }}
          >
            For support please write to
            {' '}
            <a href="mailto:enquiry@eazyvc.com"> enquiry@eazyvc.com </a>
            we are happy to help you
          </Box>

          <Box className={classes.sectionDesktop}>
            <Button style={{ backgroundColor: 'transparent', }}
              color="primary"
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleMyAccountClick}

            >
              MY ACCOUNT
            </Button>

            <Menu
              id="simple-menu"
              anchorEl={anchorElProduct}
              open={Boolean(anchorElProduct)}
              onClose={handleProductClose}

            >
              <MenuItem
                className={classes.hemMenu}
                component={NavLink}
                to="/dashboard/mypoll"
                onClick={handleProductClose}
              >
                MY POLL
              </MenuItem>

              <MenuItem className={classes.hemMenu}
                component={NavLink}
                to="/dashboard/profile"
                onClick={handleProductClose}
              >
                MY PROFILE
              </MenuItem>
              <MenuItem className={classes.hemMenu}
                component={NavLink}
                to="/dashboard/subscriptions-history"
                onClick={handleProductClose}
              >
                SUBSCRIPTION HISTORY
              </MenuItem>
              <MenuItem className={classes.hemMenu}
                component={NavLink}
                to="/dashboard/createpoll"
                onClick={handleMobileMenuClose}
                style={{ textDecoration: 'none' }}
              >
                CREATE POLL
              </MenuItem>
              <MenuItem className={classes.hemMenu}
                component={NavLink}
                to="/dashboard/subscriptions"
                onClick={handleProductClose}
              >
                SUBSCRIPTIONS PRICING
              </MenuItem>
              <MenuItem className={classes.hemMenu}
                onClick={handleChangePassword}>
                CHANGE PASSWORD
              </MenuItem>
              <MenuItem className={classes.hemMenu}
                onClick={handleLogout}>LOGOUT</MenuItem>
            </Menu>
          </Box>

          <Box className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            {renderMobileMenu}
          </Box>
        </Toolbar>
      </AppBar>

    </Box>
  );
}
