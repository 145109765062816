import {
  React,
  Grid,
  Typography,
  NavLink,
  TitleBox,
} from '../../collections/Imports';
import { ArrowBackRounded } from '@material-ui/icons';

const InviteNav = ({ pollStatus }) => {
  const titleClass = TitleBox();
  return (
    <Grid
      container
      className={titleClass.TitleBand}
      justify="space-between"
      alignItems="center"
    >
      <Grid item>
        {pollStatus === 'started' ? null : (
          <NavLink
            to="/dashboard/addquestions"
            style={{ color: '#fff', textDecoration: 'none' }}
          >
            <ArrowBackRounded style={{ width: '30px', height: '30px' }} />
          </NavLink>
        )}
      </Grid>

      <Grid item sm={10}>
        <Typography variant="h5" style={{ paddingLeft: '15px' }}>
          Invite Members
        </Typography>
      </Grid>

      <Grid item sm={1}>
        <Typography variant="h5" component="h2">
          3/3
        </Typography>
      </Grid>
    </Grid>
  );
};

export default InviteNav;
