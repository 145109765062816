import {
  React,
  useEffect,
  useState,
  useContext,
  axiosGet,
  Grid,
  CardContent,
  NavLink,
  MyPollStyle,
  AppsIcon,
  GlobalContext,
  Button2,
  SpinGif,
  Typography,
  useHistory,
} from "../../collections/Imports";
import useAPIError from "../../hooks/useAPIError";
import { getPollStatus } from "../CreatePoll/helpers/getPollStatus";
import CreatorTable from "./CreatorTable/CreatorTable";
import MyPollTable from "./MyPollTable";

const CreatorPoll = (props) => {
  const { addError } = useAPIError();
  const classes = MyPollStyle();
  const [polls, setPolls] = useState([]);
  const [appData, setAppData] = useContext(GlobalContext);
  const [enableLoading, setEnableLoading] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [totalCount, setTotalCount] = useState({});
  const [makeEnable, setMakeEnable] = useState(false);
  const history = useHistory();

  useEffect(() => {
    console.log("appData.plansFetch : ", appData.activePlans)
    if (appData.activePlans.length > 0) {
      setMakeEnable(true);
    } else {
      setMakeEnable(false);
    }
  }, [appData.plansFetch])

  const fetchPolls = async () => {
    setEnableLoading(true);
    await axiosGet("api/polls/user")
      .then(({ Polls }) => {
        let allStatusCounts = {
          all: 0,
          started: 0,
          closed: 0,
          scheduled: 0,
          expired: 0,
        };
        Polls?.map((item) => {
          const { status, statusCount } = getPollStatus(item, allStatusCounts);
          item.status = status;
          allStatusCounts = statusCount;
          return item;
        });
        setTotalCount(allStatusCounts);
        setPolls(Polls);
        localStorage.setItem("number_of_polls", Polls.length);
        // setAppData({
        //   ...appData,
        //   selected_poll: polls ? polls[currentIndex] : null,
        //   selected_poll_index: currentIndex,
        // });

        setEnableLoading(false);
      })
      .catch((err) => {
        addError(`Fail to load polls: ${err}`);
      });
  };


  // i am trying this but loading take Time

  // useEffect(() => {
  //   fetchPolls();
  //   if (appData.activePlans.length > 0) {
  //     setMakeEnable(true);
  //   } else {
  //     setMakeEnable(false);
  //   }
  // }, [appData.plansFetch])

  //we need to hide this code then uncomment my code 

  useEffect(() => {
    fetchPolls();
  }, []);

  return enableLoading ? (
    <Grid container justify="center" alignItems="center" direction="column">
      <Grid item>
        <img src={SpinGif} alt="spinner" />
      </Grid>
      <Grid item>
        <Typography variant="h5">Fetching Polls...</Typography>
      </Grid>
    </Grid>
  ) : polls && polls.length > 0 ? (
    <CardContent>
      <MyPollTable statusCount={totalCount} />
      <Grid
        container
        alignItems="center"
        justify="flex-end"
        spacing={2}
        className={classes.createPollBtn}
      >
        <Grid item>
          <Button2
            width="200px"
            variant="contained"
            // disabled={!makeEnable}
            onClick={() => {
              history.push("/dashboard/createpoll");
            }}
          >
            <AppsIcon style={{ marginRight: "10px" }} />
            CREATE NEW POLL
          </Button2>
        </Grid>
      </Grid>
      {polls && polls.length > 0 ? (
        <CreatorTable
          polls={polls}
          setCurrentIndex={setCurrentIndex}
          fetchPolls={fetchPolls}
          setAppData={setAppData}
          appData={appData}
          setPolls={setPolls}
        />
      ) : null}
    </CardContent>
  ) : makeEnable ? (
    <CardContent>
      <Grid
        container
        spacing={0}
        align="center"
        justify="center"
        direction="column"
        className={classes.NoPollGrid}
      >
        <h1>There are no polls</h1>
        <NavLink
          to="/dashboard/createpoll"
          style={{ marginLeft: 0, textDecoration: "none" }}
        >
          <Button2 className={classes.responsivebtn} variant="contained">
            <AppsIcon style={{ marginRight: "20px", fontSize: "40px" }} />
            CREATE NEW POLL
          </Button2>
        </NavLink>
      </Grid>
    </CardContent>
  ) : (
    <CardContent>
      <Grid
        container
        spacing={0}
        align="center"
        justify="center"
        direction="column"
        className={classes.NoPollGrid}
      >
        <h1>There are no active plans</h1>
        <NavLink
          to="/dashboard/subscriptions"
          style={{ marginLeft: 0, textDecoration: "none", marginTop: 30 }}
        >
          <Button2 className={classes.responsivebtn} variant="contained">
            {/* <AppsIcon style={{ marginRight: '20px', fontSize: '40px' }} /> */}
            Get Subscription
          </Button2>
        </NavLink>
      </Grid>
    </CardContent>
  );
};

export default CreatorPoll;
