import { isEqual } from 'lodash';
import { axiosGet, axiosPost } from './Axios';

function logout() {
  localStorage.removeItem('currentUser');
  localStorage.removeItem('token');
  localStorage.clear();
}

function validateUser() {
  return new Promise((resolve) => {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser === null) resolve(false);
    if (currentUser) {
      axiosGet(`api/user/email/${currentUser.email}`).then((res) => {
        resolve(
          res
            ? isEqual(JSON.stringify(currentUser), JSON.stringify(res.user))
            : false,
        );
      });
    }
  });
}

function validateUserWithToken() {
  return new Promise((resolve) => {
    let token;
    let currentUser;
    if (localStorage.getItem('token')) token = localStorage.getItem('token');

    if (localStorage.getItem('currentUser')) currentUser = JSON.parse(localStorage.getItem('currentUser'));

    if (token) {
      axiosPost('api/user/token/decode', { token })
        .then((res) => {
          if (isEqual(currentUser, res.user)) resolve(true);
          else resolve(false);
        })
        .catch((err) => {
          resolve(false);
        });
    } else {
      resolve(false);
    }
  });
}

const auth = {
  logout,
  validateUser,
  validateUserWithToken,
};

export default auth