import {
  React,
  Grid,
  Typography,
  NavLink,
  TitleBox,
} from '../../collections/Imports';
import { ArrowBackRounded } from '@material-ui/icons';

const Heading = () => {
  const titleClass = TitleBox();
  return (
    <Grid
      container
      className={titleClass.TitleBand}
      justify="space-between"
      alignItems="center"
    >
      <Grid item>
        <NavLink
          to={{
            pathname: '/dashboard/editpoll',
            state: { title: 'hidebutton', fromPage: 'add-question' },
          }}
          style={{ color: '#fff', textDecoration: 'none' }}
        >
          <ArrowBackRounded style={{ width: '30px', height: '30px' }} />
        </NavLink>
      </Grid>

      <Grid item sm={10}>
        <Typography variant="h5" style={{ paddingLeft: '15px' }}>
          Add Questions
        </Typography>
      </Grid>

      <Grid item sm={1}>
        <Typography variant="h5" component="h2">
          2/3
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Heading;
