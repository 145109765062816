import {
  useState,
  Typography,
  FormControl,
  TextField,
  Card,
  Grid,
  LoginStyles,
  NavLink,
  axiosPost,
  auth,
  React,
  useEffect,
  useContext,
  GlobalContext,
  Box,
  Button,
  CircularProgress,
} from '../../collections/Imports';
import download_app from '../../../img/download_app.svg';
import useAPIError from './../../hooks/useAPIError';
import { SnackbarContext } from '../../providers/SnackBarContext';
import { FormHelperText } from '@material-ui/core';
import { Helmet } from "react-helmet";

const Login = (props) => {
  const classes = LoginStyles();
  const { addError } = useAPIError();
  const { setSnackbar } = useContext(SnackbarContext);
  const [appData, setAppData] = useContext(GlobalContext);

  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    formValues: {
      email: '',
      password: '',
      phone: '',
    },
    errorData: {},
    errorMessage: '',
  });

  useEffect(() => {
    setAppData({
      ...appData,
      is_voter: false
    })
  }, [])

  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();

    if (validateUserInfo()) {
      axiosPost('api/auth/signin', {
        email: state.formValues.email,
        password: state.formValues.password,
      })
        .then((res) => {
          if (res.status === 200) {
            const { user } = res;
            let user_role =
              user && user.user_role ? user.user_role.user_role : 'Company';
            let user_role_id =
              user && user.user_role ? user.user_role.user_role_id : 1;
            localStorage.setItem('currentUser', JSON.stringify(user));
            setAppData({
              ...appData,
              user_id: user._id,
              user_email: user.email,
              user_phone: user.phone,
              user_name: user.name,
              user_role,
              user_role_id,
            });
            localStorage.setItem('user_role', user_role);
            localStorage.setItem('user_role_id', user_role_id);
            setSnackbar('Enter OTP sent to your mail', 'info');
            setLoading(false);
            props.history.push('/verify/email');
          } else if (res.code === 'invalid_credential') {
            setSnackbar('Invalid credentials', 'error');
          } else if (res.code === 'email_not_exists') {
            state.errorData.email = ["!Email id doesn't exists"];
          } else {
            addError('Login failed');
          }
          let tempState = state;

          setState({
            ...state,
            ...tempState,
          });
          setLoading(false);
        })
        .catch((err) => {
          setSnackbar('Invalid email or password.', 'error');
          setLoading(false);
        });
    }
  };

  const validateUserInfo = () => {
    let isFormValid = true;
    let formValues = state.formValues;
    state.errorData = {
      email: [],
      password: [],
    };

    if (formValues.email.trim().length === 0) {
      isFormValid = false;
      state.errorData.email = ['*Email field cannot be empty.'];
      setLoading(false);
    }
    if (formValues.email.trim().length !== 0) {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (re.test(formValues.email) === true) {
        state.errorData.email = [];
      } else {
        state.errorData.email = ['*Invalid email'];
        setLoading(false);
      }
    }

    if (formValues.password.trim().length === 0) {
      isFormValid = false;
      state.errorData.password = ['* Password field cannot be empty.'];
      setLoading(false);
    } else if (formValues.password.trim().length < 8) {
      state.errorData.password = ['Password must be 8 characters long.'];
      setLoading(false);
    }

    let tempState = state;

    setState({
      ...state,
      ...tempState,
    });

    return isFormValid;
  };

  const handleChange = (event, field) => {
    if (
      state.errorData &&
      state.errorData[field] &&
      state.errorData[field][0]
    ) {
      state.errorData[field][0] = null;
    }
    const test = state;
    if (field === 'rememberme') {
      test.formValues[field] = event.target.checked;
    } else {
      test.formValues[field] = event.target.value;
    }
    setState({ ...state, ...test });
  };

  useEffect(() => {
    auth.validateUserWithToken().then((res) => {
      if (res) props.history.push('/dashboard/mypoll');
      else {
        auth.validateUser().then((res) => {
          if (res) {
            props.history.push('/verify/email');
          }
        });
      }
    });
  });

  return (
    <Box className={classes.bg}>
       <Helmet>
        <meta charSet="utf-8" />
        <title>EazyVC Voting Platform - Login</title>
        <meta
          name="title"
          content="The easy way to Vote Online on EazyVC’s Blockchain-secured Voting Platform."
        />
        <meta
          name="description"
          content="EazyVC is a Blockchain-based eVoting platform for Corporates, Government Bodies, Education Institutions, Associations & Clubs, Housing Societies, NGO, Trusts and more."
        />
      </Helmet>
      <Grid container spacing={2} className={classes.gridContainer}>
        <Grid className={classes.imgnText} item xs={12} sm={6}>
          <img src={download_app} className={classes.imgapp1} alt="left-img" />
          <Typography className={classes.loginText}> Welcome to the</Typography>
          <Typography className={classes.loginText}>
            <span style={{ color: '#fff' }}>Most Secure </span> E-Voting
            Platform
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box className={classes.main}>
            <Card className={classes.card}>
              <Typography component="h1" variant="h5" gutterBottom>
                Login to your account
              </Typography>
              <form className={classes.form} onSubmit={handleSubmit}>
                <FormControl margin="normal" required fullWidth>
                  <TextField
                    className={classes.inputfield}
                    placeholder="Enter email address"
                    variant="outlined"
                    id="email"
                    name="email"
                    type="email"
                    label="Email"
                    error={state.errorData?.email && state.errorData?.email[0]}
                    onChange={(event) => handleChange(event, 'email')}
                  />
                  <FormHelperText style={{ color: 'red', fontSize: '14px' }}>
                    {state.errorData?.email && state.errorData?.email[0]}
                  </FormHelperText>
                </FormControl>
                <FormControl margin="normal" required fullWidth>
                  <TextField
                    className={classes.inputfield}
                    placeholder="Enter Password"
                    variant="outlined"
                    name="password"
                    type="password"
                    id="password"
                    label="Password"
                    error={
                      state.errorData?.password && state.errorData?.password[0]
                    }
                    onChange={(event) => handleChange(event, 'password')}
                  />
                  <FormHelperText style={{ color: 'red', fontSize: '14px' }}>
                    {state.errorData?.password && state.errorData?.password[0]}
                  </FormHelperText>
                </FormControl>
                <Button
                fullWidth
                variant="contained"
                // color="primary"
                  disabled={loading}
                  type="submit"
                  width="100%"
                  className={classes.submit}
                >
                  {loading && (
                    <CircularProgress
                      size={20}
                      style={{ color: '#000', marginRight: '5px' }}
                    />
                  )}
                  {loading && (
                    <Box component="span" style={{ color: '#000',fontWeight:'600' }}>
                      {' '}
                      please wait
                    </Box>
                  )}
                  {!loading && <Box>SEND OTP</Box>}
                </Button>{' '}
                <NavLink className={classes.Forgotlink} to="/verify_email">
                  Forgot Password?
                </NavLink>
                <Box align="center">
                  Don't have an account?
                  <NavLink to="/signup">&nbsp; SignUp right now!</NavLink>
                </Box>
              </form>
            </Card>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Login;
