import {
  Grid,
  Link,
  Box,
  Typography,
  Button,
  InviteMembersStyle,
  InviteMemberServices,
  useContext,
  useState,
  CircularProgress,
  GlobalContext,
  _,
} from "../../collections/Imports";
import path from "path";

import * as XLSX from "xlsx";

import { SnackbarContext } from "../../providers/SnackBarContext";

const AddFile = (props) => {
  const { setSnackbar } = useContext(SnackbarContext);
  const classes = InviteMembersStyle();
  const { getValidParticipants, pollStatus } = props;
  const [loading, setLoading] = useState(false);
  const [appData] = useContext(GlobalContext);
  var { plan } = props;
  var invalid = false;
  var member_limit = 0;
  var plan_type = null;
  if (plan) {
    member_limit =
      plan && plan.total_voters_limit ? plan.total_voters_limit : 0;
    plan_type = plan && plan.plan_type ? plan.plan_type : "other";
  }

  const fileCheck = (data) => {
    if (data.length - 2 > member_limit) {
      invalid = true;
      return alert(
        `You have uploaded ${data.length - 2} voters
         which exceeds your current plan `
      );
    }

    const isEmail = (value) => {
      if (value) {
        value.toString().toLowerCase();
        const re =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        return re.test(value);
      }
    };

    var participantList = [];

    for (let i = 2; i < data.length; i++) {
      let participant = {};
      let is_email = false;

      for (let j = 0; j < data[i].length; j++) {
        let key = data[1][j] ? data[1][j].toLowerCase() : null;
        let value = data[i][j];

        //convert email address to lower if present
        if (data[1][j] && data[1][j].toLowerCase() === "email_id") {
          value = value && isEmail(value) ? value.toLowerCase() : value;
        }

        //to check if email_id column is present or not
        if (
          is_email === false &&
          data[1][j] &&
          data[1][j].toLowerCase() === "email_id"
        ) {
          is_email = true;
          value = value && isEmail(value) ? value.toLowerCase() : value;
        }

        //to check if column names are null or not
        if (key === null) {
          invalid = true;

          return alert(
            `Column name can't be empty.Must have one these like
              email_id, voter_name, shares, category etc `
          );
        }

        if (
          ![
            "email_id",
            "voter_name",
            "shares",
            "voting_share_amount",
            "category",
            "phone",
          ].includes(key.toLowerCase())
        ) {
          invalid = true;
          return alert(
            `Column name Must have one these like
           email_id, voter_name, shares, category etc `
          );
        }

        //to remove empty field
        if (!isEmail(value) && data[1][j].toLowerCase() === "email_id") {
          invalid = true;
          return alert(`Check email format ${value} is wrong format`);
        }

        //to check if mandatory columns value is null or not
        if (
          data[0][j] &&
          data[0][j].toLowerCase() === "mandatory" &&
          (data[i][j] == null || data[i][j] === "undefined")
        ) {
          invalid = true;
          return alert(
            `Mandatory field can't be null. Check row no ${i + 1
            } and column no ${j + 1}`
          );
        }

        participant = { ...participant, [key]: value };
      }
      participantList.push(participant);
    }
  };

  const handleFile = (file) => {
    setLoading(true);
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    reader.onload = async (e) => {
      /* Parse data */
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });

      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];

      /* Convert array of arrays */
      let data = XLSX.utils.sheet_to_json(ws, { header: 1, blankrows: false });
      for (let i = 0; i < data.length; i++) {
        let temp = data[i];
        if (temp.length < 2 && data[i] !== null) {
          data.splice(i, 1);
        }
      }
      console.log("Members raw data : ", data);
      fileCheck(data);

      if (!invalid) {
        const list = data.slice(2).map((x, i) => {
          let currentUser = JSON.parse(localStorage.getItem("currentUser"));
          if (
            currentUser.user_role_id === 1 ||
            currentUser.user_role_id === 6 ||
            currentUser.user_role_id === 2
          ) {
            let share = x[2]
            if (share && share.toString().includes('%')) {
              share = parseFloat(share.toString().replace(/\D/g, ""));
            }
            return {
              email: x[0],
              name: x[1],
              share,
              phone: x[3],
            };
          }

          return {
            email: x[0],
            name: x[1],
            phone: x[2],
          };
        });

        InviteMemberServices.addInvitee(list)
          .then((res) => {
            if (res.message === "success") {
              setLoading(false);
              setSnackbar("Participants Added", "success");
              getValidParticipants();
            }
          })
          .catch((err) => {
            setSnackbar(`Failed to add new members ${err} `, `error`);
          });
      } else {
        setLoading(false);
      }
    };

    if (rABS) reader.readAsBinaryString(file);
    else reader.readAsArrayBuffer(file);
  };

  const DragDropFile = ({ handleFile, children }) => {
    const suppress = (e) => {
      e.stopPropagation();
      e.preventDefault();
    };
    const handleDrop = (e) => {
      e.stopPropagation();
      e.preventDefault();
      const files = e.dataTransfer.files;
      if (files && files[0]) handleFile(files[0]);
    };

    return (
      <Box onDrop={handleDrop} onDragEnter={suppress} onDragOver={suppress}>
        {children}
      </Box>
    );
  };

  const DataInput = ({ handleFile }) => {
    const handleChange = (e) => {
      if (e.target.files[0]) {
        const check = [".xlsx", ".xlsb", ".xlsm", ".xls", ".csv"].includes(
          path.extname(e.target.files[0].name)
        );
        if (check) {
          const files = e.target.files;
          if (files && files[0]) handleFile(files[0]);
        } else {
          return alert("Invalid file format upload .xlsx .xls .csv etc");
        }
      } else {
        return alert("Invalid file format upload .xlsx .xls .csv etc");
      }
    };

    return (
      <input
        type="file"
        className="form-control"
        id="file"
        // accept={SheetJSFT}
        onChange={handleChange}
        hidden
      />
    );
  };

  const getLink = () => {
    let currentUser = JSON.parse(localStorage.getItem("currentUser"));
    if (plan_type === "shareholder")
      return `${process.env.REACT_APP_SERVER_API}api/file/sample/shareholder`
    else if (plan_type === "insolvency")
      return `${process.env.REACT_APP_SERVER_API}api/file/sample/ibc`
    else if (currentUser && currentUser.user_role_id === 3)
      return `${process.env.REACT_APP_SERVER_API}api/file/sample/housing`
    else if (currentUser && currentUser.user_role_id === 4)
      return `${process.env.REACT_APP_SERVER_API}api/file/sample/university`
    else
      return `${process.env.REACT_APP_SERVER_API}api/file/sample/others`
  }
  return (
    <>
      <Grid
        container
        direction="row"
        justify="flex-end"
        alignItems="center"
        spacing={2}
        style={{
          marginTop: "15px",
          marginBottom: "15px",
          backgroundColor: "",
        }}
      >
        <Grid item>
          <Typography>
            Note: To understand the excel format for list of participants please
            download
            <Link
              href={getLink()}
              style={{
                paddingRight: "5px",
                paddingLeft: "5px",
                fontSize: "16px",
                fontWeight: "500",
                color: "rgb(11 47 100)",
                textDecoration: "underline",
              }}
            >
              Sample file
            </Link>
          </Typography>
        </Grid>

        <Grid item>
          <Button
            className={classes.uploadButton}
            variant="contained"
            component="label"
            style={{ textTransform: "capitalize" }}
            disabled={pollStatus === "closed" ? true : false}
          >
            {loading && (
              <CircularProgress size={20} style={{ marginRight: "5px" }} />
            )}
            {loading && <Box component="span"> Please wait</Box>}
            {!loading && <Box> Upload Excel Sheet</Box>}

            <DragDropFile handleFile={handleFile}>
              <DataInput handleFile={handleFile} />
            </DragDropFile>
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default AddFile;
