import { IconButton, Toolbar, Tooltip, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { DeleteRoundedIcon } from '../../../collections/Imports';
import { CreatorTableToolbarStyles } from './CreatorTableToolbarStyles';

const CreatorTableToolbar = (props) => {
  const classes = CreatorTableToolbarStyles();
  const { numSelected, onDeletePoll } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          My Polls{' '}
        </Typography>
      )}

      {numSelected > 0 ? (
        <>
          <Tooltip title="delete invitee">
            <IconButton aria-label="delete invitee" onClick={onDeletePoll}>
              <DeleteRoundedIcon />
            </IconButton>
          </Tooltip>
        </>
      ) : null}
    </Toolbar>
  );
};

export default CreatorTableToolbar;
