export const colorPicker = (status) => {
  switch (status) {
    case 'started':
      return 'primary';
    case 'closed':
      return 'secondary';
    // case 'scheduled':
    //   return 'default';
    case 'expired':
      return 'default';
    default:
      break;
  }
};
