import {
  PollDetails,
  Card,
  Grid,
  CardContent,
  Typography,
  VotingPageStyles,
  useHistory,
  useEffect,
  moment
} from '../../collections/Imports';

const VoteCardTop = (props) => {
  const classes = VotingPageStyles();
  const { voter, shares, sharePercentage, poll } = props;
  const history = useHistory()

  useEffect(() => {
    if (poll) {
      let start = moment(moment(poll.start_date).format('YYYY.MM.DD') + ' ' + poll.start_time, moment.defaultFormat).format();

      if (moment().diff(moment(start), 'seconds') < 1) {
        localStorage.clear()
        history.push('/')
      }
    }
  })

  return (
    <Card className={classes.card}>
      <Grid container spacing={2} justify="space-between" alignItems="center">
        <Grid item sm={9} xs={12}>
          <CardContent>
            <PollDetails />
          </CardContent>
        </Grid>
        <Grid item sm={3} xs={12}>
          <CardContent className={classes.cardContent}>
            <Typography style={{ fontSize: '22px', wordWrap: 'break-word' }}>{voter.name}</Typography>
            <Typography style={{ fontSize: '20px', wordWrap: 'break-word' }}>{voter.email}</Typography>
            {/* <Typography style={{ fontSize: '20px', color: '#1171FF' }}>
              {shares && parseInt(shares) > 1 ? <span style={{ color: 'black' }}> Voting Shares %: </span> : null} */}
            {poll && poll.election_type &&
              (poll.election_type.election_type_id === 2 || poll.election_type.election_type_id === 6) && (
                <Typography style={{ fontSize: '20px', color: '#1171FF' }}>
                  {sharePercentage && sharePercentage > 0 ? <span style={{ color: 'black' }}> Voting Share : </span> : null}
                  {sharePercentage && sharePercentage > 0 ? sharePercentage.toFixed(2) : null} %
                </Typography>
              )
            }
            {poll && poll.election_type && poll.election_type.election_type_id === 3 && (
              <Typography style={{ fontSize: '20px', color: '#1171FF' }}>
                {shares && parseInt(shares) > 0 ? <span style={{ color: 'black' }}> Voting Shares: </span> : null}
                {shares && parseInt(shares) > 0 ? shares : null}
              </Typography>
            )
            }
            {/* {shares && parseInt(shares) > 1 ? shares : null}
            </Typography> */}
          </CardContent>
        </Grid>
      </Grid>
    </Card>
  );
};

export default VoteCardTop;
