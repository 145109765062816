import { makeStyles } from '@material-ui/core/styles';

const NavbarAuthStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: '#FFFFFF',
    color: 'black',
  },
  hemMenu: {
    fontsize: '12px',
    fontWeight: '500',
    fontFamily: 'inherit',
  },


  menuButton: {
    marginRight: theme.spacing(2),
  },

  title: {
    // flexGrow: 1,
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },

  sectionDesktop: {
    // position: 'absolute',
    right: '1rem',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },

  sectionMobile: {
    position: 'absolute',
    right: '1rem',
    [theme.breakpoints.between('md', 'xl')]: {
      display: 'none',
    },
  },
}));

export default NavbarAuthStyles;
