import {
  Card,
  Grid,
  Typography,
  Radio,
  TextField,
  VotingPageStyles,
  useState,
  useEffect,
} from '../../collections/Imports';
import onChangeHandlers from './OnChangeHandlers';
import ShareholderNew from './ShareholderNew';

const OptionCardNew = (props) => {
  const {
    totalShares,
    currentQuestion,
    questions,
    setQuestions,
    shares,
    computeShares,
    setValid,
  } = props;

  const classes = VotingPageStyles();
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState(null);

  useEffect(() => {
    setValid(!error);
  }, [error]);

  return (
    <>
      <Grid
        container
        spacing={2}
        justify="center"
        alignItems="center"
        style={{ paddingTop: '20px' }}
      >
        {currentQuestion?.questionType.question_type_id !== 3 ? (
          <Grid item xs={12} sm={3}>
            <Typography
              className={classes.heading2}
            >{`Total shares: ${totalShares}`}</Typography>
          </Grid>
        ) : null}

        {currentQuestion?.questionType.question_type_id === 3 ? (
          <Grid item xs={12} sm={6}>
            <ShareholderNew
              currentQuestion={currentQuestion}
              questions={questions}
              setQuestions={setQuestions}
              shares={shares}
              computeShares={computeShares}
              totalShares={totalShares}
              setValid={setValid}
            />
          </Grid>
        ) : (
          <Grid item xs={12} sm={3}>
            {currentQuestion?.options?.map((item, index) => {
              return index === 2 ? (
                <TextField
                  key={item.option_id}
                  variant="outlined"
                  name="shares"
                  label="vote shares"
                  placeholder="Enter vote shares"
                  type="number"
                  id="shares"
                  value={item.shares}
                  defaultValue={totalShares}
                  helperText={errorText}
                  error={error}
                  onChange={(e) => {
                    onChangeHandlers.handleShareChangeNew({
                      e,
                      questions,
                      setQuestions,
                      currentQuestion,
                      totalShares,
                      setError,
                      setErrorText,
                    });
                  }}
                />
              ) : null;
            })}
          </Grid>
        )}
      </Grid>
      {/* </Grid> */}

      {currentQuestion?.questionType.question_type_id !== 3 ? (
        <Grid container spacing={2} justify="center" alignItems="center">
          <Grid item sm={6} xs={12}>
            {currentQuestion?.options?.map((item, index) =>
              // {
              //  return item.value !== 'Abstain' ? (
              <Card
                style={{
                  border: '1px solid #1b6dc5',
                }}
                className={classes.optionCard}
                key={item.option_id}
              >
                <Grid
                  container
                  justify="space-between"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item sm={1} xs={4}>
                    <Radio
                    classes={{
                      checked: classes.checked
                    }}
                      checked={item.status === 'voted'}
                      onChange={() =>
                        onChangeHandlers.handleRadioChangeNew({
                          currentQuestion,
                          index,
                          questions,
                          setQuestions,
                        })
                      }
                      value={item.option_id}
                      key={item.option_id}
                      name="radio-button"
                    />
                  </Grid>

                  <Grid item sm={6} xs={8}>
                    <Typography variant="h5">{item.value}</Typography>
                  </Grid>
                </Grid>
              </Card>
              // ) : null;
              // }
            )}
          </Grid>
        </Grid>
      ) : null}
    </>
  );
};

export default OptionCardNew;
