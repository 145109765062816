import { Grid, Chip, Box } from '../../collections/Imports';
import { Switch } from './Constants';

const QuestionGridNew = (props) => {
  const { currentQuestion, index, electionType } = props;

  return (
    <Grid
      container
      justify="space-around"
      alignItems="center"
      style={{ background: '#0b2f64', marginTop: '5px' }}
    >
      <Grid
        item
        sm={10}
        xs={8}
        style={{
          padding: '1rem',
          color: 'white',
          fontSize: '26px',
          lineHeight: '29px',
        }}
      >
        {electionType === 2 || electionType === 3
          ? `Resolution ${index + 1}`
          : `Question ${index + 1}`}
      </Grid>
      <Grid item sm={2} xs={4}>
        <Chip
          label={Switch(currentQuestion?.question_type_id)}
          style={{ color: '#0b2f64', backgroundColor: 'white' }}
        />
      </Grid>
      <Grid
        item
        sm={12}
        xs={12}
        style={{
          fontSize: '32px',
          lineHeight: '36px',
          background: 'aliceblue',
          padding: '1rem',
        }}
      >
        <Box dangerouslySetInnerHTML={{ __html: currentQuestion?.question }} />
      </Grid>
    </Grid>
  );
};

export default QuestionGridNew;
