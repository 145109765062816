import {
  //MyPollStyle,
  MyTableCell,
  Grid,
  Box,
} from '../../collections/Imports';

const MyPollTable = (props) => {
  const { statusCount } = props;
  //const classes = MyPollStyle();
  return (
    <Box>
      <Grid container>
        <Grid item xs={6} sm={3}>
          {<MyTableCell color="blue" style={{fontWeight:'500'}}>Total Polls</MyTableCell>}
        </Grid>
        <Grid item xs={6} sm={3}>
          <MyTableCell align="right">
            {statusCount ? statusCount.all : 0}
          </MyTableCell>
        </Grid>
        <Grid item xs={6} sm={3}>
          <MyTableCell color="#3f51b5" style={{fontWeight:'500'}}>Started Polls</MyTableCell>
        </Grid>
        <Grid item xs={6} sm={3}>
          <MyTableCell align="right">
            {' '}
            {statusCount ? statusCount.started : 0}{' '}
          </MyTableCell>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={6} sm={3}>
          {<MyTableCell color="#AA2222" style={{fontWeight:'500'}}>Closed Polls</MyTableCell>}
        </Grid>
        <Grid item xs={6} sm={3}>
          <MyTableCell align="right">
            {' '}
            {statusCount ? statusCount.closed : 0}{' '}
          </MyTableCell>
        </Grid>
        <Grid item xs={6} sm={3}>
          <MyTableCell color="#60A045" style={{fontWeight:'500'}}>Scheduled Polls</MyTableCell>
        </Grid>
        <Grid item xs={6} sm={3}>
          <MyTableCell align="right">
            {' '}
            {statusCount ? statusCount.scheduled : 0}{' '}
          </MyTableCell>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MyPollTable;
