import {
  Grid,
  Typography,
  Paper,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  TableContainer,
} from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { PollDetails, Pdf } from "../../collections/Imports.js";
import { DownloadButton } from "./PollResultStyle";
import { orderBy, uniqBy } from "lodash";
import moment from "moment";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import htmlToPdfmake from "html-to-pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import pdfMake from "pdfmake";
import { element } from "prop-types";

const CreatePdf = (props) => {
  const [winnerVote, setWinnerVote] = useState(null);
  const [winnerPercentage, setWinnerPercentage] = useState(null);
  // const exportPdf = () => {
  //     let count = 1;
  //     const pdf = jsPDF('p', 'mm', 'a4');
  //     var html2can = (can, wid, hei, con) => {
  //         if (con === 1) {
  //             pdf.addImage(can, 'JPEG', 5, 5, wid, hei);
  //         }
  //         else if (con === poll.length) {
  //             pdf.addPage("a4", "m");
  //             pdf.addImage(can, 'JPEG', 5, 5, wid, hei);
  //             pdf.save("test.pdf");
  //         }
  //         else {
  //             pdf.addPage("a4", "m");
  //             pdf.addImage(can, 'JPEG', 5, 5, wid, hei);
  //         }
  //     }

  //     var htm = (element1) => {
  //         html2canvas(element1, { logging: true, letterRendering: true, useCORS: true }).then(canvas => {
  //             var imgWidth = 200;
  //             var imgHeight = canvas.height * imgWidth / canvas.width;
  //             var name1 = canvas.toDataURL('image/jpeg');
  //             html2can(name1, imgWidth, imgHeight, count);
  //             count++;
  //         });
  //     }
  //     for (var i = 1; i <= poll_records.length; i++) {
  //         const input = document.getElementById('solid' + i)
  //         htm(input);
  //     }
  // }

  const classes = useStyles();
  const inputRef = useRef();
  const [poll_records, setPollRecords] = useState([]);
  const [poll, setPoll] = useState(null);
  const [election_type, setElectionType] = useState(null);
  const [all_invitees, setAllInvitees] = useState([]);
  const [total_voter_score, setTotalVoterScore] = useState();
  const [notVotedCount, setNotVotedCount] = useState(0);
  const [notVotedPercentage, setNotVotedPercentage] = useState(0);
  const [height, setHeight] = useState(768);
  const [pdfOptions, setPdfOptions] = useState({});
  const [totalPages, setTotalPages] = useState(0);
  const colors = [
    "#007bff",
    "#ffc107",
    "#dc3545",
    "#1aa0b5",
    "#15bb3b",
    "#D00000",
    "#808000",
  ];

  useEffect(() => {
    if (props && props.poll_records.length > 0) {
      setPollRecords(props.poll_records);
      const { poll, poll_result } = props.poll_records[0];
      setElectionType(poll.election_type.election_type_id);
      setAllInvitees(props.all_invitees);
      setPoll(poll);
      if (poll_result && poll_result.total_voter_score)
        setTotalVoterScore(poll_result.total_voter_score);
    }
  }, [props]);

  useEffect(() => {
    let currentHeight = document.getElementById("mainDiv").scrollHeight;
    if (currentHeight !== height) {
      setHeight(currentHeight);
      setPdfOptions({
        unit: "in",
        format: [630, parseInt(currentHeight)],
      });
    }
  });

  const getRank = (invitees, option, invitee) => {
    let index = invitees.findIndex(
      (i) => i.selected_option === option._id && i.voter_id === invitee.voter_id
    );
    if (index > -1) {
      return invitees[index].rank;
    } else {
      return "";
    }
  };

  const exportPdf = async () => {
    let dataArray = [];
    for (let i = 0; i < poll_records.length; i++) {
      const input = document.getElementById(`solid${i}`);
      dataArray.push(input.innerHTML);
    }

    const doc = new jsPDF();
    let documentDefinition = {
      content: [],
      pageBreakBefore: function (currentNode) {
        if (
          currentNode?.text &&
          currentNode?.text === "                        "
        )
          setTotalPages((prev) => prev - 1);

        return (
          currentNode?.text && currentNode?.text === "                        "
        );
      },
    };

    for (let i = 0; i < dataArray.length; i++) {
      var html = htmlToPdfmake(dataArray[i]);
      documentDefinition.content.push(html);
    }
    setTotalPages(dataArray.length);
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    pdfMake.createPdf(documentDefinition).open();
  };

  const renderMembers = (poll_record) => {
    let { poll, poll_result, invitees } = poll_record;
    if (
      poll &&
      poll.election_type.election_type_id === 2 &&
      poll_result.questionType.question_type_id !== 4
    ) {
      let { options } = poll_result;
      options = orderBy(options, ["percentage"], ["desc"]);
      poll_result.options = options;
      let data = [];

      for (let i = 0; i < options.length; i++) {
        let temp = {
          title: options[i].value,
          value: options[i].percentage ? options[i].percentage : 0,
          color: colors[i],
        };
        data.push(temp);
      }
      let inviteeOptions = {};
      let distinctInvitees = [];
      for (let i = 0; i < invitees.length; i++) {
        let { voter_id, selected_option } = invitees[i];
        if (inviteeOptions[voter_id]) {
          inviteeOptions[voter_id].push(selected_option);
        } else {
          inviteeOptions[voter_id] = [selected_option];
          distinctInvitees.push(invitees[i]);
        }
      }
      invitees = uniqBy(invitees, (i) => i.voter_id);
      return (
        <>
          <TableContainer component={Paper} style={{ marginBottom: 20 }}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: 70 }}>Sr. No</TableCell>
                  <TableCell style={{ width: 220 }}>Name</TableCell>
                  <TableCell align="right" style={{ width: 100 }}>
                    Share
                  </TableCell>
                  {poll_result &&
                    poll_result.options &&
                    poll_result.options.map((option, index) => (
                      <TableCell align="center">{option.value}</TableCell>
                    ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {invitees &&
                  invitees.length > 0 &&
                  invitees.map((invitee, index) => (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        {index + 1 + ") "}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {invitee.name}
                      </TableCell>
                      <TableCell align="right" style={{ fontWeight: "bold" }}>
                        {parseFloat(invitee.share).toFixed(2)}
                      </TableCell>
                      {poll_result &&
                        poll_result.options &&
                        poll_result.options.map((option, index) => (
                          <TableCell align="center">
                            {poll &&
                            poll.election_type.election_type_id === 3 ? (
                              <span
                                style={{
                                  padding: "5px 12px 5px 12px",
                                  backgroundColor: colors[index],
                                  borderRadius: "20%",
                                  fontWeight: "bold",
                                }}
                              >
                                {option.score_value}
                              </span>
                            ) : inviteeOptions[invitee.voter_id].includes(
                                option._id
                              ) ? (
                              <span
                                style={{
                                  padding: "5px 12px 5px 12px",
                                  backgroundColor: colors[index],
                                  borderRadius: "20%",
                                  fontWeight: "bold",
                                }}
                              >
                                YES
                              </span>
                            ) : (
                              <span style={{ padding: "5px" }}>NO</span>
                            )}
                          </TableCell>
                        ))}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <br />
          <br />
        </>
      );
    } else if (
      poll &&
      poll.election_type.election_type_id === 2 &&
      poll_result.questionType.question_type_id === 4
    ) {
      let { options } = poll_result;
      options = orderBy(options, ["percentage"], ["desc"]);
      poll_result.options = options;
      let data = [];

      for (let i = 0; i < options.length; i++) {
        let temp = {
          title: options[i].value,
          value: options[i].percentage ? options[i].percentage : 0,
          color: colors[i],
        };
        data.push(temp);
      }
      let inviteeOptions = {};
      let distinctInvitees = [];
      for (let i = 0; i < invitees.length; i++) {
        let { voter_id, selected_option } = invitees[i];
        if (inviteeOptions[voter_id]) {
          inviteeOptions[voter_id].push(selected_option);
        } else {
          inviteeOptions[voter_id] = [selected_option];
          distinctInvitees.push(invitees[i]);
        }
      }
      let uniqueInvitees = uniqBy(invitees, (i) => i.voter_id);
      return (
        <>
          <TableContainer component={Paper} style={{ marginBottom: 20 }}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: 70 }}>Sr. No</TableCell>
                  <TableCell style={{ width: 220 }}>Name</TableCell>
                  <TableCell align="right" style={{ width: 100 }}>
                    Share
                  </TableCell>
                  {poll_result &&
                    poll_result.options &&
                    poll_result.options.map((option, index) => (
                      <TableCell align="center">{option.value}</TableCell>
                    ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {uniqueInvitees &&
                  uniqueInvitees.length > 0 &&
                  uniqueInvitees.map((invitee, index) => (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        {index + 1 + ") "}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {invitee.name}
                      </TableCell>
                      <TableCell align="right" style={{ fontWeight: "bold" }}>
                        {parseFloat(invitee.share).toFixed(2)}
                      </TableCell>
                      {poll_result &&
                        poll_result.options &&
                        poll_result.options.map((option, index) => (
                          <TableCell align="center">
                            {getRank(invitees, option, invitee)}
                          </TableCell>
                        ))}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <br />
          <br />
        </>
      );
    }
  };

  // const renderNotVotedMembers = () => {
  //     if (all_invitees.length > 0) {
  //         return (<>
  //             <TableContainer component={Paper} style={{ marginBottom: 20 }}>
  //                 <Table className={classes.table} aria-label="simple table">
  //                     <TableHead>
  //                         <TableRow>
  //                             <TableCell style={{ width: 70 }}>Sr. No</TableCell>
  //                             <TableCell style={{ width: 220 }}>Name</TableCell>
  //                             <TableCell style={{ width: 220 }}>Email</TableCell>
  //                         </TableRow>
  //                     </TableHead>
  //                     <TableBody>
  //                         {all_invitees &&
  //                             all_invitees.length > 0 &&
  //                             all_invitees.map((invitee, index) => (
  //                                 !invitee.isVoted ?
  //                                     <TableRow key={index}>
  //                                         <TableCell component="th" scope="row">
  //                                             {index + 1 + ') '}

  //                                         </TableCell>
  //                                         <TableCell component="th" scope="row">
  //                                             {invitee.voter_id.name}
  //                                         </TableCell>
  //                                         <TableCell align="left" style={{ fontWeight: 'bold' }}>
  //                                             {invitee.voter_id.email}
  //                                         </TableCell>

  //                                     </TableRow> : null
  //                             ))}
  //                     </TableBody>
  //                 </Table>
  //             </TableContainer>
  //             <br /><br />
  //         </>
  //         )
  //     }

  // }

  const getNonVotedPercentage = (record) => {
    // let notVotedList = all_invitees.filter((a) => a.is_voted === false)
    // let notVotedShare = 0
    // for (let i = 0; i < notVotedList.length; i++) {
    //     let temp = notVotedList[i]
    //     const { share_id, voter_id } = temp
    //     notVotedShare += share_id ? parseInt(share_id.share_value) : 0
    // }
    // setNotVotedPercentage(notVotedShare / total_voter_score * 100)

    let { poll_result: current_poll_result } = record;

    let options = current_poll_result ? current_poll_result.options : [];

    let notVoted = 100;
    for (let i = 0; i < options.length; i++) {
      let option = options[i];
      let overall_percentage = option.overall_percentage
        ? option.overall_percentage
        : 0;
      notVoted -= overall_percentage.toFixed(2);
    }
    return notVoted ? notVoted.toFixed(2) : "0.00";
  };

  const getNonVotedCount = (record) => {
    let notVotedList = all_invitees.filter((a) => a.is_voted === false);
    setNotVotedCount(notVotedList.length);
  };

  useEffect(() => {
    getNonVotedCount();
  }, [total_voter_score]);

  const getWinner = () => {
    for (let i = 0; i < poll_records[0]?.poll_result?.options.length; i++) {
      if (poll_records[0]?.poll_result?.options[i].percentage > 50) {
        setWinnerVote(poll_records[0]?.poll_result.options[i].value);
        setWinnerPercentage(poll_records[0]?.poll_result.options[i].percentage);
        break;
      } else {
        setWinnerVote(null);
        setWinnerPercentage(null);
      }
    }
  };

  useEffect(() => {
    getWinner();
  }, [poll_records]);

  return (
    <div className={classes.root} id="mainDiv">
      <Grid
        container
        style={{
          marginBottom: 10,
          width: "100%",
          display: "flex",
          justifyContent: "Right",
          paddingRight: 10,
        }}
      >
        {/* <Pdf
                    targetRef={inputRef}
                    filename="Pollresult.pdf"
                    options={pdfOptions}
                    x={0.5}
                    y={0.5}
                    scale={0.8}
                >
                    {({ toPdf }) => (
                        <DownloadButton
                            onClick={toPdf}
                            variant="outlined"
                            // color="primary"
                            style={{ color: '#000', fontSize: "1rem", marginRight: '20px' }}
                        >
                            Download as PDF
                        </DownloadButton>
                    )}
                </Pdf> */}
        <DownloadButton
          onClick={() => exportPdf()}
          variant="outlined"
          style={{ color: "#000", fontSize: "1rem" }}
        >
          Save as PDF
        </DownloadButton>
      </Grid>
      <Grid
        container
        style={{
          // paddingLeft: '20px',
          // paddingRight: '20px',
          width: "100%",
        }}
        ref={inputRef}
      >
        {/* <Grid item sm={12} style={{ paddingLeft: '1rem', marginTop: '2rem' }}>
                    <Typography variant="h5" style={{ color: '#000', fontWeight: '500' }}>Questions</Typography>
                </Grid> */}
        <Grid
          container
          style={{ padding: "1rem", paddingTop: 0, border: "1px solid #000" }}
          id="poll_result"
        >
          {poll_records && poll_records.length > 0
            ? poll_records.map((record, index) => (
                <div
                  className="pdf-pagebreak-before"
                  style={{
                    width: "100%",
                    paddingRight: "10%",
                    pageBreakAfter: "always",
                  }}
                  id={"solid" + index}
                >
                  <Grid container>
                    <Grid item sm={12}>
                      <Typography variant="h5" style={{ fontWeight: "bold" }}>
                        {localStorage.getItem("currentUser")
                          ? JSON.parse(localStorage.getItem("currentUser")).name
                          : ""}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container style={{ marginLeft: "-15px" }}>
                    <Grid item sm={12}>
                      <PollDetails />
                      <Typography style={{ margin: "10px" }} variant="h6">
                        Winner : {String(winnerVote)} (
                        {parseFloat(winnerPercentage).toFixed(2)}%)
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item sm={12}>
                    <Typography
                      style={{ marginTop: "10px" }}
                      variant="h6"
                      dangerouslySetInnerHTML={{
                        __html:
                          record.poll_result && record.poll_result.question,
                      }}
                    />
                  </Grid>
                  {record.poll_result.options.map((option, optIndex) => (
                    <Grid
                      container
                      style={{
                        margin: "10px",
                        marginTop: 0,
                        width: "100%",
                        paddingTop: 0,
                        paddingLeft: "2%",
                        marginBottom: 10,
                      }}
                    >
                      <Grid container item xs={12}>
                        <Grid
                          style={{
                            width: "70%",
                            color: "#000",
                            fontSize: "14px",
                            fontWeight: "500",
                          }}
                        >
                          {String.fromCharCode(65 + optIndex)} .{option.value}
                        </Grid>
                        <Grid
                          style={{
                            textAlign: "right",
                            float: "right",
                            width: "28%",
                            color: "#000",
                            fontWeight: "bold",
                          }}
                        >
                          {/* {'% of CoC Members Voted' + ' / % Of Total Voting Share '} */}
                          {"% Of Total Voting Share "}
                        </Grid>
                      </Grid>
                      <Grid container item xs={12}>
                        <Grid
                          style={{
                            width: "70%",
                            color: "#000",
                            fontSize: "14px",
                            fontWeight: "500",
                          }}
                        ></Grid>
                        <Grid
                          style={{
                            textAlign: "right",
                            float: "right",
                            width: "28%",
                            color: "#000",
                          }}
                        >
                          {/* {option.percentage.toFixed(2) + '%' + ' / ' + option.overall_percentage.toFixed(2) + '%'} */}
                          {option.overall_percentage.toFixed(2) + "%"}
                        </Grid>
                      </Grid>
                      <Grid
                        style={{
                          height: "10px",
                          color: "#000",
                          borderRadius: "5px",
                          width: "100%",
                          backgroundColor: "#D3D3D3",
                        }}
                      >
                        <Grid
                          style={{
                            color: "#000",
                            height: "10px",
                            borderRadius: "5px",
                            width: option.percentage.toFixed(2) + "%",
                            backgroundColor: colors[optIndex],
                          }}
                        ></Grid>
                      </Grid>
                    </Grid>
                  ))}

                  {notVotedCount ? (
                    <Grid
                      container
                      style={{
                        width: "100%",
                        textAlign: "right",
                        justifyContent: "right",
                        paddingBottom: 20,
                      }}
                    >
                      <Typography variant="p">
                        <br />
                        Count of CoC Members who did not vote : {notVotedCount}.
                        % of CoC members who did not vote:{" "}
                        {getNonVotedPercentage(record)}%.
                        {/* Total no. of {notVotedCount} voters of {notVotedPercentage.toFixed()}% have not been caste vote */}
                      </Typography>
                    </Grid>
                  ) : null}
                  <br />
                  <br />
                  {poll && poll._id ? (
                    <Grid
                      container
                      style={{
                        width: "100%",
                        bottom: 0,
                        paddingBottom: 10,
                        bottom: 50,
                      }}
                    >
                      <Grid
                        item
                        sm={12}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          paddingLeft: "1rem",
                        }}
                      >
                        <Typography variant="p">
                          Page {index + 1} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </Typography>
                        <Typography variant="p" style={{ marginLeft: 20 }}>
                          Votechain Private Limited
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </Typography>
                        <Typography
                          variant="p"
                          style={{ float: "right", marginLeft: 20 }}
                        >
                          Date - {moment(poll.end_date).format("Do MMM, YYYY")}{" "}
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </Typography>
                        <Typography
                          variant="p"
                          style={{
                            float: "right",
                            marginLeft: 20,
                            marginRight: 0,
                          }}
                        >
                          Time -{" "}
                          {moment(poll.end_time, "hh:mm:ss").format("hh:mm a")}{" "}
                          (IST)
                        </Typography>
                      </Grid>
                    </Grid>
                  ) : null}

                  {index !== poll_records.length - 1 && (
                    <p style={{ display: "none" }}>
                      {"                        "}
                    </p>
                  )}
                </div>
              ))
            : null}

          {/* {
                        poll && poll._id ?

                            <Grid container style={{ bottom: 0, paddingBottom: 10 }}>
                                <Grid item sm={5} style={{ paddingLeft: '1rem' }}>
                                    Page 1
                                </Grid>
                                <Grid item sm={4} style={{ marginRight: '-10px' }}>
                                    <Typography
                                        variant='p'
                                        style={{ float: 'right' }}
                                    >
                                        Date - {moment(poll.end_date).format('Do MMM, YYYY')}
                                    </Typography>
                                </Grid>
                                <Grid item sm={3} style={{ marginRight: 0 }}>
                                    <Typography
                                        variant='p'
                                        style={{ float: 'right', marginRight: 0 }}
                                    >
                                        Time - {moment(poll.end_time, 'hh:mm:ss').format('hh:mm a')} (IST)
                                    </Typography>
                                </Grid>
                            </Grid>
                            : null
                    } */}
        </Grid>

        {/* <Grid item sm={12} style={{ paddingLeft: '1rem', marginTop: '2rem' }}>
                    <Typography variant="h5">Members Table</Typography>
                </Grid>
                <Grid container style={{ padding: '1rem', paddingTop: 0 }}>
                    {
                        poll_records ? poll_records.map((poll_record, index) =>
                            renderMembers(poll_record)
                        ) : null
                    }
                </Grid> */}
        {/* {
                    election_type ?
                        <>
                            <Grid item sm={12} style={{ paddingLeft: '1rem', marginTop: '2rem' }}>
                                <Typography variant="h5">Not Voted members</Typography>
                            </Grid>
                            <Grid container style={{ padding: '1rem', paddingTop: 0 }}>
                                {renderNotVotedMembers()}
                            </Grid>
                        </> : null
                } */}
      </Grid>
    </div>
  );
};

export default CreatePdf;

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 1000,
    margin: "0 auto",
    marginTop: "5rem",
    background: "#fff",
    padding: "1rem",
    height: window.innerHeight - 100,
    overflowY: "scroll",
    paddingBottom: 20,
    border: "8px solid darkblue",
  },
}));
