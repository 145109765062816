import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  makeStyles,
  IconButton,
  Button,
  Box,
} from "@material-ui/core";
import NotListedLocationIcon from "@material-ui/icons/NotListedLocation";
import { CircularProgress } from "../collections/Imports.js";

const useStyles = makeStyles((theme) => ({
  dialog: {
    padding: theme.spacing(2),
    position: "absolute",
    top: theme.spacing(5),
  },
  dialogTitle: {
    textAlign: "center",
  },
  dialogContent: {
    textAlign: "center",
  },
  dialogAction: {
    justifyContent: "center",
  },
  titleIcon: {
    backgroundColor: "#0B2F64",
    color: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: "#0B2F64",
      cursor: "default",
    },
    "& .MuiSvgIcon-root": {
      fontSize: "3rem",
      color: "#fff",
    },
  },
}));

export default function ConfirmDialog(props) {
  const { loading } = props;
  const { confirmDialog, setConfirmDialog } = props;
  const classes = useStyles();

  return (
    <Dialog open={confirmDialog.isOpen} classes={{ paper: classes.dialog }}>
      <DialogTitle className={classes.dialogTitle}>
        <IconButton disableRipple className={classes.titleIcon}>
          <NotListedLocationIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Typography variant="h6">{confirmDialog.title}</Typography>
        <Typography variant="subtitle2">{confirmDialog.subTitle}</Typography>
      </DialogContent>
      <DialogActions className={classes.dialogAction}>
        {/* <Button
                    text="Yes"
                    color="secondary"
                    onClick={confirmDialog.onConfirm}>Yes</Button> */}
        <Button
          text="Yes"
          color="primary"
          style={{ color: "#000" }}
          disabled={loading}
          onClick={confirmDialog.onConfirm}
        >
          {loading && (
            <CircularProgress
              size={20}
              style={{ color: "#000", marginRight: "5px" }}
            />
          )}
          {loading && (
            <Box component="span" style={{ color: "#000" }}>
              {" "}
              please wait
            </Box>
          )}
          {!loading && <Box>Yes</Box>}
        </Button>
        <Button
          text="No"
          color="default"
          onClick={() => setConfirmDialog({ ...confirmDialog, isOpen: false })}
        >
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
}
