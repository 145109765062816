import {
  Grid,
  Typography,
  Chip,
  Box,
  VotingPageStyles,
} from '../../collections/Imports';
import { Switch } from './Constants';

const QuestionGrid = (props) => {
  const { currentQuestion } = props;
  const classes = VotingPageStyles();
  return (
    <>
      <Grid
        container
        spacing={2}
        justify="space-between"
        alignItems="center"
        style={{ background: '#0b2f64', padding: '1rem', marginTop: '10px' }}
      >
        <Grid item sm={10}>
          <Typography style={{ color: '#fff' }} className={classes.heading2}>
            Question
          </Typography>
        </Grid>
        <Grid item sm={2}>
          <Chip
            label={Switch(currentQuestion?.question_type_id)}
            style={{ color: '#0b2f64', backgroundColor: 'white' }}
          />
        </Grid>
      </Grid>

      <Grid
        container
        spacing={2}
        justify="space-between"
        alignItems="center"
        style={{ background: 'aliceblue', padding: '1rem' }}
      >
        <Grid
          item
          sm={12}
          style={{
            fontSize: '32px',
            lineHeight: '36px',
          }}
        >
          <Box
            dangerouslySetInnerHTML={{ __html: currentQuestion?.question }}
          />
        </Grid>
        {/* <Grid item xs={12} sm={3}></Grid>
        <Grid item xs={12} sm={3}></Grid>
        <Grid item xs={12} sm={3}></Grid> */}
      </Grid>
    </>
  );
};

export default QuestionGrid;
