import {
  Card,
  Grid,
  Typography,
  TextField,
  VotingPageStyles,
  useState,
  useEffect,
} from '../../collections/Imports';
import onChangeHandlers from './OnChangeHandlers';

const ShareholderNew = ({
  currentQuestion,
  questions,
  setQuestions,
  shares,
  computeShares,
  totalShares,
  setValid,
}) => {
  const classes = VotingPageStyles();
  const [error, setError] = useState([]);
  const [errorText, setErrorText] = useState(null);

  useEffect(() => {
    const array = [];
    for (let i = 0; i < currentQuestion.options.length; i++) {
      array.push({ index: i, error: false });
    }
    setError(array);
  }, []);

  useEffect(() => {
    if (error && error.length > 0) {
      let errorArray = error.filter((item) => (item.error ? item.error : null));
      setValid(!(errorArray.length > 0));
    }
  }, [error]);

  useEffect(() => {
    let totalCount = 0;
    currentQuestion.options.forEach((option, idx) => {
      if (idx !== 2) {
        totalCount = totalCount + Number(option.shares);
      }
    });
    if (totalCount > totalShares) {
      setError([{ index: 1, error: true }]);
      setErrorText(`Total Share should be less than or eqaul to ${totalShares}`);
    }
  }, [currentQuestion.options, totalShares]);

  return currentQuestion?.options?.map((item, index) => {
    return (
      <Card
        style={{
          border: '2px solid #1b6dc5',
          marginTop:'20px'
        }}
        className={classes.optionCard}
        key={item.option_id}
      >
        <Grid
          container
          spacing={2}
          justify="space-around"
          alignItems="center"
          style={{
            paddingTop: '20px',
            paddingBottom: '20px',
            padding:'1rem'
          }}
        >
          <Grid item sm={6} xs={12}>
            <Typography>{item.value}</Typography>
          </Grid>
          <Grid item sm={3} xs={12}>
            <TextField
              key={item.option_id}
              variant="outlined"
              name="shares"
              type="number"
              id="shares"
              placeholder="Enter vote shares"
              label={item.value === 'Abstain' ? null : 'vote shares'}
              value={item.value === 'Abstain' ? shares : item.shares}
              disabled={item.value === 'Abstain' ? true : false}
              helperText={error[index]?.error ? errorText : null}
              error={error[index]?.error}
              onChange={(e) => {
                onChangeHandlers.handleShareChangeWithoutPagination({
                  e,
                  index,
                  questions,
                  setQuestions,
                  computeShares,
                  currentQuestion,
                  totalShares,
                  setError,
                  setErrorText,
                  error,
                });
              }}
            />
          </Grid>
        </Grid>
      </Card>
    );
  });
};

export default ShareholderNew;
