import { Grid, Pagination } from '../../collections/Imports';
// import { ArrowBackRounded } from '@material-ui/icons';

const ModalPagination = (props) => {
  const { questions, page, setPage, setShowNextButton } = props;
  const handlePageChange = (e, value) => {
    setPage(value);
    setShowNextButton(false);
  };
  return (
    <Grid
      container
      justify="flex-end"
      style={{ paddingTop: '10px' }}
      alignItems="center"
    >
      <Grid item>
        <Pagination
          count={questions.length}
          page={page}
          onChange={handlePageChange}
        />
      </Grid>
    </Grid>
  );
};

export default ModalPagination;
