import {
  FormControl,
  Grid,
  TextField,
  InviteMemberServices,
  IconButton,
  useContext,
} from '../../collections/Imports';
import RemoveIcon from '@material-ui/icons/Remove';
import { useFormik } from 'formik';
import validationSchemaAdd from './validationSchemaAdd';
import AddIcon from '@material-ui/icons/Add';
import { Fab, Tooltip } from '@material-ui/core';
import { SnackbarContext } from '../../providers/SnackBarContext';

const AddInviteeForm = (props) => {
  const { plan } = props
  let member_limit = 0

  if (plan) {
    member_limit = plan && plan.total_voters_limit ? plan.total_voters_limit : 0
  }

  const { setShowForm, setGetValidFlag, validParticipants } = props;
  const initialFormState = {
    name: '',
    email: '',
    phone: '',
    share: ''
  };

  const { setSnackbar } = useContext(SnackbarContext);

  const formik = useFormik({
    initialValues: initialFormState,
    validationSchema: validationSchemaAdd,
    onSubmit: (values) => {
      if (validParticipants.length >= member_limit) {
        return alert(`voters exceeds your current plan `);
      } else {
        InviteMemberServices.addMember(values)
          .then((res) => {
            if (res.code === 'add_invitee_success') {
              setSnackbar('Participant Added', 'success');
              setShowForm(false);
              setGetValidFlag(prev => !prev);
            }
          })
          .catch((err) => {
            setSnackbar(`failed to add new members ${err} `, `error`);
          });
      }
    },
  });

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        formik.handleSubmit();
      }}
    >
      <Grid container spacing={2} alignItems="center" justify="space-around">
        {['name', 'email', 'share', 'phone'].map((item) => {
          return props.user_role_id == 1 && item == 'share' ? (
            <Grid item xs={12} sm={3} component={FormControl}>
              <TextField
                id={item}
                label={item}
                variant="outlined"
                type="text"
                name={item}
                value={formik.values[item]}
                onChange={formik.handleChange}
                error={formik.touched[item] && Boolean(formik.errors[item])}
                helperText={formik.touched[item] && formik.errors[item]}
              />
            </Grid>)
            :
            (<Grid item xs={12} sm={3} component={FormControl}>
              <TextField
                id={item}
                label={item}
                variant="outlined"
                type="text"
                name={item}
                value={formik.values[item]}
                onChange={formik.handleChange}
                error={formik.touched[item] && Boolean(formik.errors[item])}
                helperText={formik.touched[item] && formik.errors[item]}
              />
            </Grid>)
        })}

        <Grid item sm={1}>
          <Tooltip title="Add member" placement="bottom">
            <IconButton aria-label="add" type="submit">
              <Fab style={{ background: 'rgb(11, 47, 100)', color: '#fff' }} aria-label="add">
                <AddIcon fontSize="large" />
              </Fab>
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item sm={1}>
          <Tooltip title="cancel" placement="bottom">
            <IconButton
              aria-label="cancel"
              onClick={() => {
                setShowForm(false);
              }}
            >
              <Fab style={{ background: 'brown', color: '#fff' }} aria-label="remove">
                <RemoveIcon fontSize="large" />
              </Fab>
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    </form>
  );
};

export default AddInviteeForm;
