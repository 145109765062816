import React from "react";
import { Route, Redirect } from "react-router-dom";
import { GlobalContext, useContext } from './collections/Imports'

const PrivateRoute = ({ component: Component, ...rest }) => {
  const [appData] = useContext(GlobalContext)

  return (
    < Route
      {...rest}
      render={(props) => {
        const currentUser = JSON.parse(localStorage.getItem("currentUser"));
        const token = localStorage.getItem("token");

        if (!currentUser) {
          localStorage.clear()
          return (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          );
        } else if (currentUser && appData.from_location === '/getin' && props.location.pathname === '/welcome') {
          return (
            <Component {...props} />
          )
        } else if (currentUser && !token) {
          const { email } = currentUser;
          return (
            <Redirect
              to={{
                pathname: "/verify/email/" + email,
                state: { from: props.location },
              }}
            />
          );
        }
        return <Component {...props} />;
      }}
    />
  )
}


export default PrivateRoute;
