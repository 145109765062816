import {
  NavLink,
  React,
  useEffect,
  useState,
  useContext,
  Grid,
  Card,
  Avatar,
  Typography,
  DashBoardStyles,
  GlobalContext,
  axiosPost,
  Container,
  TitleBox,
  axiosGet,
} from "../../collections/Imports";
import { AccountBox, Dashboard, Poll, Subscriptions } from "@material-ui/icons";
import { useLocation } from "react-router";
import DescriptionIcon from "@material-ui/icons/Description";
import MuiListItem from "@material-ui/core/ListItem";
import { withStyles } from "@material-ui/core/styles";
import ReceiptIcon from '@material-ui/icons/Receipt';

window.React = React;
const ListItem = withStyles({
  root: {
    display: "flex",
    alignItems: "center",
    color: "#000000",
    "&:hover": {
      backgroundColor: "#1171FF",
      borderTopLeftRadius: "30px",
      borderBottomRightRadius: "10px",
      color: "#fff",
    },
  },
  selected: {},
})(MuiListItem);

const ScrutinizerMenu = [
  {
    id: 1,
    title: "Dashboard",
    user: "",
    link: "/dashboard/mypoll",
    icon: <Dashboard style={{ marginRight: "" }} />,
  },
  {
    id: 2,
    title: "My Profile",
    user: "",
    link: "/dashboard/profile",
    icon: <AccountBox style={{ marginRight: "" }} />,
  },
];

const Menu = [
  {
    id: 1,
    title: "My Poll",
    user: "",
    link: "/dashboard/mypoll",
    icon: <Dashboard style={{ marginRight: "" }} />,
  },
  {
    id: 2,
    title: "My Profile",
    user: "",
    link: "/dashboard/profile",
    icon: <AccountBox style={{ marginRight: "" }} />,
  },
  {
    id: 3,
    title: "Subscription History",
    user: "",
    link: "/dashboard/subscriptions-history",
    icon: <DescriptionIcon style={{ marginRight: "" }} />,
  },
  {
    id: 4,
    title: "Create Poll",
    user: "",
    link: "/dashboard/createpoll",
    icon: <Poll style={{ marginRight: "" }} />,
  },
  {
    id: 5,
    title: "Subscriptions Pricing",
    user: "",
    link: "/dashboard/subscriptions",
    icon: <Subscriptions style={{ marginRight: "" }} />,
  },
  // {
  //   id: 6,
  //   title: "Invoice",
  //   user: "",
  //   link: "/dashboard/invoice",
  //   icon: <ReceiptIcon style={{ marginRight: "" }} />,
  // },
];

const SideCard = () => {
  const [SelectedBtn, setSelectedBtn] = useState("1");
  const classes = DashBoardStyles();
  let location = useLocation();
  let token = localStorage.getItem('token')
  //States
  const [appData, setAppData] = useContext(GlobalContext);
  const [city, setCity] = useState({});
  const [country, setCountry] = useState({});
  const [imgSrc, setImgSrc] = useState("");
  const [makeEnable, setMakeEnable] = useState(false);
  //States end
  const showSideCardFor = [
    "/dashboard/mypoll",
    "/dashboard/invite",
    "/dashboard/createpoll",
    "/dashboard/profile",
    "/dashboard/addquestions",
    "/dashboard/Scrutinizerprofile",
    "/dashboard/editpoll",
    "/dashboard/allvoter",
    "/dashboard/pollresult",
    "/dashboard/subscriptions-history",
    "/dashboard/subscriptions",
  ];

  useEffect(() => {
    const { pathname } = location
    let index = Menu.findIndex((m) => m.link === pathname)
    if (index > -1) {
      setSelectedBtn(Menu[index].id)
    }
  }, [location.pathname])

  useEffect(() => {
    if (token) {
      axiosGet('api/plans/user')
        .then((res) => {
          setAppData({
            ...appData,
            activePlans: res.plans,
            plansFetch: !appData.plansFetch
          })
          if (res.plans.length > 0) {
            setMakeEnable(true);
          } else {
            setMakeEnable(false);
          }
        })
        .catch((err) => {
          console.log("Error : ", err)
        })
    }
  }, [token, location.pathname])

  useEffect(() => {
    axiosPost("api/user/get-profile", { user_id: appData.user_id })
      .then((result) => {
        if (result.user) {
          const { user } = result;
          setCity(user.city);
          if (user && user.country)
            setCountry(user.country);

          if (user && user.profile_pic) {
            const { img } = user.profile_pic;
            let binary = "";
            let bytes = [].slice.call(new Uint8Array(img.data.data));
            bytes.forEach((b) => (binary += String.fromCharCode(b)));
            let imageData = window.btoa(binary);
            let contentType = img.contentType;
            let imgSrc = "data:image/" + contentType + ";base64, " + imageData;
            setImgSrc(imgSrc);
          }
        }
      })
      .catch((err) => {
        console.log("error : ", err);
      });
  }, [appData.flag]);

  const getCityAndCountry = () => {
    let data = "";
    try {
      if (typeof city !== "undefined" && city.city_id)
        data += city.name + ", ";
      if (typeof country !== "undefined" && country.country_id)
        data += country.emoji + " " + country.name;
    } catch (e) {
      if (typeof city !== "undefined" && typeof country !== "undefined")
        data = city + ", " + country;
      else if (typeof city !== "undefined") data = city;
      else if (typeof country !== "undefined") data = country;
    }
    return (
      <Typography className={classes.sidecardSubTitle} gutterBottom>
        {data}
      </Typography>
    );
  };

  const titleClass = TitleBox();
  return showSideCardFor.includes(location.pathname) || location.pathname.includes('invoice') ? (
    <Container
      maxWidth="lg"
      className={classes.root}
      style={{ marginTop: "80px" }}
    >
      <Grid container alignItems="flex-start">
        <Grid
          item
          xs={12}
          style={{
            position: "fixed",
          }}
        >
          <Card className={classes.sidecard}>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
              style={{}}
            >
              <Grid
                container
                className={titleClass.TitleBand}
                justify="center"
                alignItems="center"
                style={{ paddingBottom: "15px" }}
              >
                <Grid item>
                  <Typography variant="h5">
                    {appData.user_role ? appData.user_role : ""}
                  </Typography>
                </Grid>
              </Grid>

              <Grid item style={{ backgroundColor: "" }}>
                <Avatar
                  src={imgSrc}
                  alt="Profile image"
                  className={classes.media}
                />
              </Grid>
              <Grid item>
                <Typography align="center" className={classes.sidecardTitle}>
                  {appData.user_name ? appData.user_name : "Username"}
                </Typography>
                {getCityAndCountry()}
              </Grid>

              <Grid item>
                {appData.user_role && parseInt(appData.user_role_id) === 2
                  ? ScrutinizerMenu.map((item, index) => (
                    <NavLink
                      key={index}
                      to={item.link}
                      style={{ textDecoration: "none" }}
                    >
                      <ListItem
                        className={classes.menuItem}
                        onClick={(e) => {
                          setSelectedBtn(item.id);
                        }}
                        style={{
                          backgroundColor:
                            SelectedBtn == item.id ? `#1171FF` : "#fff",
                          borderTopLeftRadius:
                            SelectedBtn == item.id ? "30px" : "0px",
                          borderBottomRightRadius:
                            SelectedBtn == item.id ? "10px" : "0px",
                          color: SelectedBtn == item.id ? `white` : "black",
                        }}
                      >
                        {item.icon}
                        <Typography
                          style={{
                            fontSize: "18px",
                            fontWeight: "normal",
                            lineHeight: "21.6px",
                            marginLeft: "20px",
                          }}
                        >
                          {item.title}
                        </Typography>
                      </ListItem>
                    </NavLink>
                  ))
                  : Menu.map((item, index) =>
                  (
                    <NavLink
                      key={index}
                      to={item.link}
                      style={{ textDecoration: "none" }}
                    >
                      <ListItem
                        className={classes.menuItem}
                        onClick={(e) => {
                          setSelectedBtn(item.id);
                        }}
                        style={{
                          backgroundColor:
                            SelectedBtn == item.id ? `#1171FF` : "#fff",
                          borderTopLeftRadius:
                            SelectedBtn == item.id ? "30px" : "0px",
                          borderBottomRightRadius:
                            SelectedBtn == item.id ? "10px" : "0px",
                          color:
                            SelectedBtn == item.id ? `white` : "black",
                        }}
                      >
                        {item.icon}
                        <Typography
                          style={{
                            fontSize: "18px",
                            fontWeight: "normal",
                            lineHeight: "21.6px",
                            marginLeft: "20px",
                          }}
                        >
                          {item.title}
                        </Typography>
                      </ListItem>
                    </NavLink>
                  )
                  )}
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </Container>
  ) : null;
};

export default SideCard;
