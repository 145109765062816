import { createMuiTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

export const chipTheme = createMuiTheme({
  palette: {
    secondary: {
      main: '#AA2222',
    },
  },
});

export const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  polltitle: {
    textTransform: 'capitalize',
    fontWeight: '500',
    whiteSpace: 'normal',
    paddingLeft: 10,
    // '&:hover': {
    //   whiteSpace: 'normal',
    // },
  },

  chip: {
    fontSize: '14px',
    fontWeight: '500',
    width: '100px',
    textTransform: 'capitalize',
  },
});
