import {
  React,
  useState,
  Typography,
  Button,
  FormControl,
  TextField,
  Checkbox,
  Card,
  Grid,
  LoginStyles,
  download_app,
  SignUpStyles,
  NavLink,
  axiosPost,
  useEffect,
  auth,
  useContext,
  GlobalContext,
  CircularProgress,
  Box,
  // ExpandMoreIcon,
  // MenuItem,
  // Select,
  // InputLabel,
  axiosGet,
} from "../../collections/Imports";
import SignupRoles from "./SignupRoles";
import { SnackbarContext } from "../../providers/SnackBarContext";
import { FormHelperText } from "@material-ui/core";
import { Helmet } from "react-helmet";
import Autocomplete from "@mui/material/Autocomplete";

const SignUp = (props) => {
  const [loading, setLoading] = useState(false);
  const { setSnackbar } = useContext(SnackbarContext);
  const [checked, setChecked] = useState(false);
  const [checkedErr, setcheckedErr] = useState("");
  const classes = SignUpStyles();
  const classes1 = LoginStyles();
  const initialState = { name: "", email: "", phone: "", password: "" };
  const [values] = useState({ initialState });
  const [appData, setAppData] = useContext(GlobalContext);
  const [cinNo, setCinNo] = useState();
  const [panNo, setPanNo] = useState();
  const [ip_registration_number, setIPRegistartionNumber] = useState('')
  const [country, setCountry] = useState(null);
  const [countryList, setCountryList] = useState([]);
  const [isIndiaFlag, setIsIndiaFlag] = useState(null);
  const [state, setState] = useState({
    formValues: {
      email: "",
      password: "",
      phone: "",
      name: "",
      cinNo: "",
      panNo: "",
      file: "",
    },
    errorData: {},
    errorMessage: "",
  });

  useEffect(() => {
    axiosGet("api/countries/all")
      .then((res) => {
        const { countries } = res;
        setCountryList(countries);
      })
      .catch((err) => {
        console.log("error : ", err);
      });
  }, []);

  const setCountryData = (e, newValue) => {
    setCountry(newValue);
    if (newValue && newValue.country_id == 101) {
      setIsIndiaFlag(true);
    } else {
      setIsIndiaFlag(false);
    }
  };
  const setData = (values) => {
    setAppData({
      ...appData,
      userInfo: values,
    });
  };
  const handleSubmit = (e) => {
    console.log(country, "countrycountrycountrycountrycountry");
    setLoading(true);
    e.preventDefault();
    const { selected_user_role_id } = appData.userInfo;
    var formData = new FormData();
    var imagefile = document.querySelector("#file");

    if (
      // selected_user_role_id === "609e210d48e38de36b0a213b" ||
      selected_user_role_id === "609e211648e38de36b0a213c"
    ) {
      formData.append("file", imagefile.files[0]);
    }

    formData.append("name", state.formValues.name);
    formData.append("email", state.formValues.email);
    formData.append("phone", state.formValues.phone);
    formData.append("password", state.formValues.password);
    formData.append("cinNo", cinNo);
    formData.append("ip_registration_number", ip_registration_number)
    formData.append("country", JSON.stringify(country));
    formData.append("panNo", panNo);
    formData.append("selected_user_role_id", selected_user_role_id);

    validateUserInfo();
    if (validateUserInfo()) {
      axiosPost("api/auth/signup", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
        .then((res) => {
          if (res.status === 200) {
            setAppData({
              ...appData,
              user_email: state.formValues.email,
              user_phone: state.formValues.phone,
            });
            localStorage.setItem("email", state.formValues.email);
            localStorage.setItem("phone", state.formValues.phone);
            setSnackbar(
              "signup success now redirecting to otp screen",
              "success"
            );
            setLoading(false);
            props.history.push(
              "/verify-signup/email/" + state.formValues.email
            );
          } else if (res.code === "email_exists") {
            state.errorData.email = ["* Email id already exits"];
            setLoading(false);
            setSnackbar(
              "Email already exists. Please go to login page.",
              "error"
            );
            setLoading(false);
          } else if (res.code === "phone_exists") {
            state.errorData.phone = ["* Phone number already exits"];
            setLoading(false);
            setSnackbar("Phone number already exists.", "info");
            setLoading(false);
          } else {
            setSnackbar("Failed to do signup", "error");
            setLoading(false);
          }

          let tempState = state;

          setState({
            ...state,
            ...tempState,
          });
        })

        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }

    if (checked === false) {
      setcheckedErr("You must accept the terms and condition", "info");
      setLoading(false);
      return;
    } else {
      setcheckedErr("");
      setLoading(false);
    }
    setLoading(false);
  };

  useEffect(() => {
    auth.validateUserWithToken().then((res) => {
      if (res) props.history.push("/dashboard/mypoll");
      else {
        auth.validateUser().then((res) => {
          if (res) {
            const currentUser = JSON.parse(localStorage.getItem("currentUser"));
            props.history.push("/verify-signup/email/" + currentUser.email);
          }
        });
      }
    });
  });

  const validateUserInfo = () => {
    let isFormValid = true;
    let formValues = state.formValues;
    state.errorData = {
      email: [],
      password: [],
      name: [],
      phone: [],
    };

    // name validation - 22/12/2021

    if (formValues.name.trim().length === 0) {
      isFormValid = false;
      state.errorData.name = ["* Name field cannot be empty."];
    }

    if (formValues.name.trim().length !== 0) {
      const nameRe = /^[A-Za-z ]+$/;

      if (nameRe.test(formValues.name) === true) {
        state.errorData.email = [""];
      } else {
        state.errorData.name = ["* Name invalid."];
      }
    }

    //end name validation - 22/12/2021

    if (formValues.email.trim().length === 0) {
      isFormValid = false;
      state.errorData.email = ["* Email field cannot be empty."];
    }

    if (formValues.email.trim().length !== 0) {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (re.test(formValues.email) === true) {
        state.errorData.email = [];
      } else {
        state.errorData.email = ["* invalid email"];
      }
    }
    if (formValues.phone.trim().length === 0) {
      isFormValid = false;
      state.errorData.phone = ["* Phone field cannot be empty."];
    } else if (isNaN(formValues.phone.trim())) {
      isFormValid = false;
      state.errorData.phone = [
        "* Phone field cannot contain characters except numbers.",
      ];
    } else if (formValues.phone.trim().length !== 10) {
      isFormValid = false;
      state.errorData.phone = ["* Phone field should be exact 10 digits."];
    }

    if (formValues.password.trim().length === 0) {
      isFormValid = false;
      state.errorData.password = ["* Password field cannot be empty."];
    } else if (formValues.password.trim().length < 8) {
      isFormValid = false;
      state.errorData.password = ["* Password must be 8 characters long."];
    } else if (/[0-9]{10}/.test(formValues.phone) === false) {
      isFormValid = false;
      state.errorData.phone = ["* Phone number must be 10 characters long."];
    } else if (/(0|91)?[6-9][0-9]{9}/.test(formValues.phone) === false) {
      isFormValid = false;
      state.errorData.phone = ["* Invalid phone number"];
    }

    let tempState = state;

    setState({
      ...state,
      ...tempState,
    });

    return isFormValid;
  };

  const handleChange = (event, field) => {
    if (
      state.errorData &&
      state.errorData[field] &&
      state.errorData[field][0]
    ) {
      state.errorData[field][0] = null;
    }
    const test = state;
    if (field === "rememberme") {
      test.formValues[field] = event.target.checked;
    } else {
      test.formValues[field] = event.target.value;
    }
    setState({ ...state, ...test });
  };

  return (
    <div className={classes1.bg}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>EazyVC Voting Platform - Signup</title>
        <meta
          name="title"
          content="The easy way to Vote Online on EazyVC’s Blockchain-secured Voting Platform."
        />
        <meta
          name="description"
          content="EazyVC is a Blockchain-based eVoting platform for Corporates, Government Bodies, Education Institutions, Associations & Clubs, Housing Societies, NGO, Trusts and more."
        />
      </Helmet>
      <Grid container spacing={2} className={classes1.gridContainer}>
        <Grid className={classes1.imgnText} item xs={12} sm={6}>
          <img
            src={download_app}
            className={classes.imgapp}
            alt="EazyVC Mobile Optimized Online Voting Software "
          />
          <Typography className={classes1.loginText}>
            {" "}
            Welcome to the
          </Typography>
          <Typography className={classes1.loginText}>
            <span style={{ color: "#fff" }}>Most Secure </span> E-Voting
            Platform
          </Typography>
        </Grid>
        <Grid className={classes1.imgnText} item xs={12} sm={6}>
          <Grid className={classes.main}>
            <Card className={classes.card}>
              <Typography component="h1" variant="h5">
                Create an account
              </Typography>
              <form className={classes.form} onSubmit={handleSubmit}>
                <FormControl margin="normal" required fullWidth>
                  <TextField
                    className={classes1.inputfield}
                    placeholder="Enter Full Name"
                    variant="outlined"
                    id="name"
                    name="name"
                    type="text"
                    value={values.name}
                    label="Name"
                    onChange={(event) => handleChange(event, "name")}
                    autoFocus
                    error={state.errorData?.name && state.errorData?.name[0]}
                  />
                  <FormHelperText style={{ color: "red", fontSize: "14px" }}>
                    {state.errorData?.name && state.errorData?.name[0]}
                  </FormHelperText>
                </FormControl>

                <FormControl margin="normal" required fullWidth>
                  <TextField
                    className={classes1.inputfield}
                    placeholder="Enter email address"
                    variant="outlined"
                    id="email"
                    name="email"
                    label="Email"
                    type="email"
                    error={state.errorData?.email && state.errorData?.email[0]}
                    value={values.email}
                    onChange={(event) => handleChange(event, "email")}
                  />
                  <FormHelperText style={{ color: "red", fontSize: "14px" }}>
                    {state.errorData?.email && state.errorData?.email[0]}
                  </FormHelperText>
                </FormControl>

                <FormControl margin="normal" fullWidth>
                  <TextField
                    className={classes1.inputfield}
                    placeholder="Enter Phone Number"
                    variant="outlined"
                    id="phone"
                    name="phone"
                    type="tel"
                    error={state.errorData?.email && state.errorData?.phone[0]}
                    label="Phone"
                    inputProps={{
                      maxLength: 10,
                    }}
                    value={values.phone}
                    onChange={(event) => handleChange(event, "phone")}
                  />
                  <FormHelperText style={{ color: "red", fontSize: "14px" }}>
                    {state.errorData?.phone && state.errorData?.phone[0]}
                  </FormHelperText>
                </FormControl>

                {/* Add country field in sign up start*/}
                {/* 
                <FormControl
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  margin="normal"
                  fullWidth
                > */}
                {/* <Select
                    required
                    onChange={(e) => setCountryData(e)}
                    value={country ? country.country_id : "0"}
                    inputProps={{
                      IconComponent: ExpandMoreIcon,
                    }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Country"
                  >
                    {countryList
                      ? countryList.map((c) => (
                        <MenuItem key={c.name} value={c.country_id}>
                          {c.name}
                        </MenuItem>
                      ))
                      : null}
                  </Select> */}
                <FormControl
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  margin="normal"
                  fullWidth
                >
                  <Autocomplete
                    variant="outlined"
                    margin="normal"
                    id="country-select-demo"
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    options={countryList}
                    autoHighlight
                    getOptionLabel={(option) => option.name}
                    //  onInputChange={(e) => setCountryData(e)}
                    onChange={
                      (event, newValue) => {
                        setCountryData(event, newValue);
                      }
                      //   (event, newValue) => {
                      //   setValue(newValue);
                      // }
                    }
                    // // value={country ? country.country_id : "0"}
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        <img
                          loading="lazy"
                          width="20"
                          src={`https://flagcdn.com/w20/${option.name.toLowerCase()}.png`}
                          srcSet={`https://flagcdn.com/w40/${option.name.toLowerCase()}.png 2x`}
                          alt=""
                        />
                        {/* {console.log("check option attribute", option)} */}
                        {option.emoji} {option.name}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        onChange={(e) => setCountryData(e)}
                        {...params}
                        label="Choose a country"
                        variant="outlined"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password", // disable autocomplete and autofill
                        }}
                      />
                    )}
                  />
                </FormControl>
                {/* Add country field in sign up end*/}

                <FormControl margin="normal" required fullWidth>
                  <TextField
                    className={classes1.inputfield}
                    placeholder="Enter Password"
                    variant="outlined"
                    id="password"
                    name="password"
                    type="password"
                    error={
                      state.errorData?.password && state.errorData?.password[0]
                    }
                    label="Password"
                    value={values.password}
                    onChange={(event) => handleChange(event, "password")}
                  />
                  <FormHelperText style={{ color: "red", fontSize: "14px" }}>
                    {state.errorData?.password && state.errorData?.password[0]}
                  </FormHelperText>
                </FormControl>

                <SignupRoles
                  setData={setData}
                  setCinNo={setCinNo}
                  setPanNo={setPanNo}
                  setIPRegistartionNumber={setIPRegistartionNumber}
                  isIndiaFlag={isIndiaFlag}
                  country={country}
                />
                <Typography align="center" component="p" variant="body1">
                  <Checkbox
                    required
                    color="primary"
                    onClick={(e) => setChecked(!checked)}
                    inputProps={{ "aria-label": "secondary checkbox" }}
                  />
                  I agree to{" "}
                  <NavLink to="/privacypolicy"> Privacy Policy</NavLink> and{" "}
                  <NavLink to="/privacypolicy">Terms & Conditions</NavLink>
                </Typography>
                <span style={{ color: "red", paddingLeft: "8px" }}>
                  {checkedErr}
                </span>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  className={classes1.submit}
                  // style={{ color: "#fff" }}
                  disabled={loading}
                >
                  {loading && (
                    <CircularProgress
                      size={20}
                      style={{ color: "#000", marginRight: "5px" }}
                    />
                  )}
                  {loading && (
                    <Box
                      component="span"
                      style={{ color: "#000", fontWeight: "600" }}
                    >
                      {" "}
                      please wait
                    </Box>
                  )}
                  {!loading && <Box>Verify details</Box>}
                </Button>
                <Box
                  style={{
                    padding: "10px",
                    textAlign: "center",
                    fontSize: "15px",
                    textTransform: "uppercase",
                  }}
                >
                  <NavLink
                    to="/login"
                    style={{ fontWeight: "500", fontFamily: "inherit" }}
                  >
                    Already have an account?
                  </NavLink>
                </Box>
              </form>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default SignUp;
