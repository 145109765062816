import {makeStyles} from '@material-ui/core/styles';

const ScrutinizerStyle = makeStyles (theme => ({
  main: {
    margin: 'auto',
    width: 'auto',
    padding: '40px',
    display: 'block',

    [theme.breakpoints.up (400 + theme.spacing.unit * 3 * 2)]: {},
  },
  Maindiv: {
    backgroundColor: '#1171FF',
    padding: '180px',
    backgroundSize: 'cover',
    position: 'relative',
    width: '100%',
    [theme.breakpoints.down ('md')]: {
      width: '100%',
      padding: '0px',
      paddingTop: '20rem',
      height: 'auto',
    },
  },

  card: {
    width: '720px',
    margin: 'auto',
    background: '#fff',
    boxShadow: '0px 0px 20px rgba(57, 57, 57, 0.15)',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    padding: '105px 40px 50px',
    [theme.breakpoints.down ('sm')]: {
      width: 'auto',
      padding: '0',
      paddingBottom: '20px',
      paddingTop: '100px',
    },
  },

  imgmobile: {
    width: 'auto',
    height: '361px',
    position: 'absolute',
    margin: '-300px 135px auto',
    [theme.breakpoints.down (400 + theme.spacing (3) * 2)]: {
      margin: '-300px -50px auto',
      width: '100%',
    },
  },

  submit: {
    marginTop: theme.spacing.unit * 3,
    background: '#1171FF',
    boxShadow: '0px 4px 20px rgb(11 47 100 / 40%)',
    borderRadius: '12px',
    padding: '12px',
    fontWeight: '600',
    fontSize: '12px',
    width: '50%',
    margin: '0 auto',
  },

  inputfield: {
    background: '#FFFFFF',
    border: '1px solid #E5E5E5',
    boxSizing: 'border-box',
    borderRadius: '10px',
    '& .MuiOutlinedInput-input': {
      padding: '10.5px 14px',
    },
  },

  LoginHeading: {
    marginTop: '20px',
    textAlign: 'center',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '22px',
    lineHeight: '114px',
    color: '#414448',
  },
}));

export default ScrutinizerStyle;
