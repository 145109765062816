import VotingServices from './VotingServices';

const onVoteClick = ({ voter, questions, addError, setSnackbar, history, setLoading, setIsOpen }) => {
  setLoading(true)
  questions.forEach((question) => {
    delete question.question_file;
    delete question.option_images;
  });

  VotingServices.addVote(voter._id, questions, addError)
    .then((res) => {
      console.log("res : ", res)
      if (res.code === 'success') {
        console.log(res.message);
        setSnackbar('Successfully casted vote', 'info');
        setLoading(false)
        history.push('/thank-you');
      } else {
        setSnackbar('Server Error. Please try again', 'error');
        setLoading(false)
        setIsOpen(false)
      }
    })
    .catch((err) => {
      addError(`${err}`);
      setLoading(false)
    });
};

const onBackClick = ({ setPage, showNextButton, setShowNextButton, page }) => {
  setPage(page - 1);
  if (showNextButton) {
    setShowNextButton(false);
  }
};

const onNextClick = ({ setPage, setShowNextButton, page }) => {
  setPage(page + 1);
  setShowNextButton(false);
};

const onSkipClick = ({ page, questions, setQuestions }) => {
  let currentQuestion = questions[page - 1];
  if (currentQuestion.questionType.question_type_id === 3 || currentQuestion.questionType.question_type_id === 4) {
    currentQuestion = {
      ...currentQuestion,
      options: currentQuestion.options.map((option) => {
        return {
          ...option,
          status: null,
          shares: '',
        };
      }),
    };
  } else {
    currentQuestion = {
      ...currentQuestion,
      options: currentQuestion.options.map((option) => {
        return {
          ...option,
          status: null,
        };
      }),
    };
  }

  const newQuestions = questions.map((question) => {
    if (question._id === currentQuestion._id) {
      return currentQuestion;
    }
    return question;
  });
  setQuestions(newQuestions);
};

const onResetClick = ({ currentQuestion, questions, setQuestions }) => {
  if (currentQuestion.questionType.question_type_id === 3 || currentQuestion.questionType.question_type_id === 4) {
    currentQuestion = {
      ...currentQuestion,
      options: currentQuestion.options.map((option) => {
        return {
          ...option,
          status: null,
          shares: '',
        };
      }),
    };
  } else {
    currentQuestion = {
      ...currentQuestion,
      options: currentQuestion.options.map((option) => {
        return {
          ...option,
          status: null,
        };
      }),
    };
  }

  const newQuestions = questions.map((question) => {
    if (question._id === currentQuestion._id) {
      return currentQuestion;
    }
    return question;
  });
  setQuestions(newQuestions);
};

const onClickHandlers = {
  onResetClick,
  onSkipClick,
  onNextClick,
  onBackClick,
  onVoteClick,
};

export default onClickHandlers;
