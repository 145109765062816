import React, { useEffect, useState, useContext } from 'react';
import ViewQuestion from './ViewQuestion';
import AddQuestionForm from './AddQuestionForm';
import {
  CardContent,
  DashboardScreen,
  PollDetails,
  Heading,
  Title,
  GlobalContext,
  axiosGet,
  Grid,
  SpinGif,
  Typography
} from '../../collections/Imports';
import { useHistory } from 'react-router-dom'
import useAPIError from '../../hooks/useAPIError';

const AddQuestion = () => {
  const [question_changed, setQuestionChanged] = useState(true);
  const [selected_question, setSelectedQuestion] = useState(null);
  const [show_form, setShowForm] = useState(false);
  const [questions_data, setQuestionsData] = useState(false);
  const [appData, setAppData] = useContext(GlobalContext);
  const [enableLoading, setEnableLoading] = useState(true);
  const history = useHistory();
  const { addError } = useAPIError();

  useEffect(() => {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const { poll } = appData
    if (poll) {
      if (poll.rta_id) {
        if (poll.election_type._id === "60844acbc18867305001c508" && poll.rta_id._id !== currentUser._id) {
          history.push('/dashboard/mypoll')
        }
      }
      setEnableLoading(false);
    } else {
      axiosGet('api/polls/id/' + appData.selected_poll_id || localStorage.getItem('selected_poll_id'))
        .then((res) => {
          const { poll } = res
          setAppData({
            ...appData,
            poll,
          });
          if (poll.rta_id) {
            if (poll.election_type._id === "60844acbc18867305001c508" && poll.rta_id._id !== currentUser._id) {
              history.push('/dashboard/mypoll')
            }
          }
          setEnableLoading(false);
        })
        .catch((err) => {
          addError(`Unable to fetch poll detials: ${err}`);
        });
    }
  }, [appData.selected_poll_id])
  return (
    <DashboardScreen>
      {
        enableLoading ? <Grid container justify="center" alignItems="center" direction="column">
          <Grid item>
            <img src={SpinGif} alt="spinner" />
          </Grid>
          <Grid item>
            <Typography variant="h5">Fetching Details...</Typography>
          </Grid>
        </Grid> :
          <>
            <Heading />
            <CardContent>
              <PollDetails />

              <Title />

              <ViewQuestion
                setQuestionChanged={setQuestionChanged}
                selected_question={selected_question}
                setSelectedQuestion={setSelectedQuestion}
                setShowForm={setShowForm}
                question_changed={question_changed}
                questions_data={questions_data}
                setQuestionsData={setQuestionsData}
              />

              <AddQuestionForm
                setQuestionChanged={setQuestionChanged}
                setSelectedQuestion={setSelectedQuestion}
                selected_question={selected_question}
                show_form={show_form}
                setShowForm={setShowForm}
                questions_data={questions_data}
                setQuestionsData={setQuestionsData}
              />
            </CardContent>
          </>
      }

    </DashboardScreen>
  );
};

export default AddQuestion;
