const UploadIcon = () => {
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.625 10C0.79076 10 0.949732 10.0659 1.06694 10.1831C1.18415 10.3003 1.25 10.4593 1.25 10.625V15C1.25 15.3315 1.3817 15.6495 1.61612 15.8839C1.85054 16.1183 2.16848 16.25 2.5 16.25H17.5C17.8315 16.25 18.1495 16.1183 18.3839 15.8839C18.6183 15.6495 18.75 15.3315 18.75 15V10.625C18.75 10.4593 18.8158 10.3003 18.9331 10.1831C19.0503 10.0659 19.2092 10 19.375 10C19.5408 10 19.6997 10.0659 19.8169 10.1831C19.9342 10.3003 20 10.4593 20 10.625V15C20 15.6631 19.7366 16.2989 19.2678 16.7678C18.7989 17.2366 18.163 17.5 17.5 17.5H2.5C1.83696 17.5 1.20107 17.2366 0.732233 16.7678C0.263392 16.2989 0 15.6631 0 15V10.625C0 10.4593 0.065848 10.3003 0.183058 10.1831C0.300269 10.0659 0.45924 10 0.625 10ZM6.25 6.06752C6.3672 6.18469 6.52615 6.25051 6.69187 6.25051C6.8576 6.25051 7.01654 6.18469 7.13375 6.06752L10 3.20002L12.8663 6.06627C12.9239 6.12597 12.9929 6.17358 13.0691 6.20634C13.1454 6.23909 13.2274 6.25633 13.3104 6.25705C13.3934 6.25778 13.4757 6.24196 13.5525 6.21054C13.6293 6.17911 13.6991 6.1327 13.7577 6.07402C13.8164 6.01534 13.8628 5.94555 13.8943 5.86874C13.9257 5.79193 13.9415 5.70963 13.9408 5.62665C13.9401 5.54366 13.9228 5.46165 13.8901 5.3854C13.8573 5.30914 13.8097 5.24018 13.75 5.18252L10.4425 1.87502C10.3844 1.81682 10.3155 1.77064 10.2395 1.73913C10.1636 1.70762 10.0822 1.69141 10 1.69141C9.91779 1.69141 9.83639 1.70762 9.76046 1.73913C9.68453 1.77064 9.61556 1.81682 9.5575 1.87502L6.25 5.18252C6.1918 5.24058 6.14562 5.30955 6.11411 5.38548C6.0826 5.46141 6.06638 5.54281 6.06638 5.62502C6.06638 5.70723 6.0826 5.78863 6.11411 5.86457C6.14562 5.9405 6.1918 6.00947 6.25 6.06752Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 2.5C10.1658 2.5 10.3247 2.56585 10.4419 2.68306C10.5592 2.80027 10.625 2.95924 10.625 3.125V13.125C10.625 13.2908 10.5592 13.4497 10.4419 13.5669C10.3247 13.6842 10.1658 13.75 10 13.75C9.83424 13.75 9.67527 13.6842 9.55806 13.5669C9.44085 13.4497 9.375 13.2908 9.375 13.125V3.125C9.375 2.95924 9.44085 2.80027 9.55806 2.68306C9.67527 2.56585 9.83424 2.5 10 2.5Z"
        fill="black"
      />
    </svg>
  );
};

export default UploadIcon;
