import {
  React,
  Grid,
  FormControl,
  MenuItem,
  Select,
  TextField,
  Typography,
  // FormControlLabel,
  // Switch,
  Button,
  Calendar,
  Alarm,
  ExpandMoreIcon,
  CreatePollstyle,
  useState,
  Chip,
  useEffect,
  CardContent,
  NavLink,
  Button2,
} from '../../collections/Imports';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardTimePicker, KeyboardDatePicker } from '@material-ui/pickers';
import Tour from 'reactour';
import './style.css';
import { tourConfig } from './constants';

const CreatePollForm = (props) => {
  const accentColor = '#12448f';
  const [isTourOpen, setTourOpen] = useState(false);

  const closeTour = () => {
    setTourOpen(false);
  };
  const openTour = () => {
    setTourOpen(true);
  };

  const {
    issuerEmail,
    issuerList,
    election_type,
    isEdit,
    disabled,
    electionTypeData,
    // disablePollType,
    disablePollCategory,
    poll_category,
    pollCategoryData,
    poll_title,
    validationData,
    // pollTypeData,
    // poll_type,
    scrutinizer,
    scrutinizers,
    handleDateChange,
    start_date,
    start_time,
    end_date,
    end_time,
    calculateDaysHours,
    renderEditButton,
    blockchain_security,
    setBlockchainSecurity,
    handlePoll,
    rtaList,
    rtaEmail,
    company_name,
    cin_no,
    userPlans,
    user_plan
  } = props;
  const classes = CreatePollstyle();
  const userRole = JSON.parse(localStorage.getItem('currentUser'));
  const userRoleId = userRole.user_role_id;

  useEffect(() => {
    let index = userPlans.findIndex((u) => u._id === user_plan)
    if (index > -1) {
      let plan = userPlans[index]
      if (plan.total_voters_limit < 50)
        setBlockchainSecurity(false)
      else
        setBlockchainSecurity(true)
    }
  }, [user_plan])

  const getMenu = (type) => {
    if (type.election_type_id === 1) {
      const currentUser = JSON.parse(localStorage.getItem("currentUser"))
      return (
        <MenuItem key={type._id} value={type._id}>
          {currentUser.user_role.user_role}
        </MenuItem>
      )
    } else {
      return (
        <MenuItem key={type._id} value={type._id}>
          {type.election_type}
        </MenuItem>
      );
    }
  }
  return (
    !props.enableCreatePoll && !isEdit ?
      <CardContent>
        <br /><br /><br /><br /><br /><br />
        <Grid
          container
          spacing={0}
          align="center"
          justify="center"
          direction="column"
          className={classes.NoPollGrid}
        >
          <h1>There are no active plans</h1>
          <NavLink
            to="/dashboard/subscriptions"
            style={{ marginLeft: 0, textDecoration: "none", marginTop: 30 }}
          >
            <Button2 className={classes.responsivebtn} variant="contained">
              {/* <AppsIcon style={{ marginRight: '20px', fontSize: '40px' }} /> */}
              Get Subscription
            </Button2>
          </NavLink>
        </Grid>
      </CardContent> :
      <>
        <Grid container style={{ padding: '1rem' }}>
          <Grid item xs={12} sm={6}>
            <Typography
              data-tut="reactour__pollBasicDetails"
              className={classes.basicHeading}
              variant="h6"
              gutterBottom
            >
              Basic Poll Details
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} align="right">
            <Button variant="outlined" color="primary" onClick={openTour}>
              Launch Tour
            </Button>
          </Grid>
        </Grid>

        <Tour
          onRequestClose={closeTour}
          disableInteraction={false}
          steps={tourConfig}
          isOpen={isTourOpen}
          maskClassName="mask"
          className="helper"
          rounded={5}
          accentColor={accentColor}
          DisableFocusLock={true}
          highlightedMaskClassName="disableInteraction"
          onAfterOpen={(target) => (document.body.style.color = '#636363a6')}
        />

        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
          className={classes.createPollForm}
        >
          <Grid container>
            <Grid item xs={12} sm={6} data-tut="reactour__electionType">
              <Typography className={classes.formlabeText}>
                Election Type<span style={{ color: 'red', fontSize: 20, display: 'inline', paddingTop: 5 }}>*</span>
              </Typography>

              <FormControl variant="outlined" className={classes.selectcontrol}>
                <Select
                  name="election_type"
                  onChange={handlePoll}
                  value={election_type}
                  inputProps={{
                    IconComponent: ExpandMoreIcon,
                  }}
                  // disabled={electionTypeData.length < 2 ? true : (isEdit || disabled)}
                >
                  {electionTypeData &&
                    electionTypeData.map((type) => getMenu(type))
                  }
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} data-tut="reactour__pollCategory">
              <Typography className={classes.formlabeText}>
                Poll Category
              </Typography>
              <FormControl variant="outlined" className={classes.selectcontrol}>
                <Select
                  name="poll_category"
                  disabled={isEdit || disablePollCategory}
                  onChange={handlePoll}
                  value={poll_category}
                  inputProps={{
                    IconComponent: ExpandMoreIcon,
                  }}
                >
                  {pollCategoryData &&
                    pollCategoryData.map((category) => {
                      return (
                        <MenuItem key={category._id} value={category._id}>
                          {category.poll_category}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Grid>
            {/* add company field and cin_no for ibc only */}
            {userRoleId == 1 ? (
              <Grid container>
                <Grid
                  item
                  sm={6}
                  xs={12}
                  style={{ marginTop: '1rem' }}
                >
                  <Typography className={classes.formlabeText}>
                    Company Name<span style={{ color: 'red', fontSize: 20, display: 'inline', paddingTop: 5 }}>*</span>
                  </Typography>
                  <TextField
                    placeholder="Enter Company Name"
                    className={classes.textfieldcontrol}
                    variant="outlined"
                    name="company_name"
                    style={{ minWidth: '90%' }}
                    value={company_name}
                    disabled={disabled}
                    onChange={handlePoll}
                  />
                  {validationData[2] ? (
                    <Typography style={{ color: 'red' }}>
                      Please specify Company Name
                    </Typography>
                  ) : null}
                </Grid>
                <Grid
                  item
                  sm={6}
                  xs={12}
                  style={{ marginTop: '1rem' }}
                >
                  <Typography className={classes.formlabeText}>
                    CIN No<span style={{ color: 'red', fontSize: 20, display: 'inline', paddingTop: 5 }}>*</span>
                  </Typography>
                  <TextField
                    placeholder="Enter CIN No"
                    className={classes.textfieldcontrol}
                    variant="outlined"
                    name="cin_no"
                    style={{ minWidth: '90%' }}
                    value={cin_no}
                    disabled={disabled}
                    onChange={handlePoll}
                  />
                  {validationData[3] ? (
                    <Typography style={{ color: 'red' }}>
                      Please specify CIN No
                    </Typography>
                  ) : null}
                  {validationData[6] ? (
                    <Typography style={{ color: 'red' }}>
                      Please Enter Valid CIN No
                    </Typography>
                  ) : null}
                </Grid>
              </Grid>) : null
            }

            <Grid container>
              <Grid
                item
                sm={6}
                xs={12}
                style={{ marginTop: '1rem' }}
                data-tut="reactour__pollTitle"
              >
                <Typography className={classes.formlabeText}>
                  Poll Title<span style={{ color: 'red', fontSize: 20, display: 'inline', paddingTop: 5 }}>*</span>
                </Typography>
                <TextField
                  placeholder="Enter Poll Title"
                  className={classes.textfieldcontrol}
                  variant="outlined"
                  name="poll_title"
                  style={{ minWidth: '90%' }}
                  value={poll_title}
                  disabled={disabled}
                  onChange={handlePoll}
                />
                {validationData[0] ? (
                  <Typography style={{ color: 'red' }}>
                    Please specify poll title
                  </Typography>
                ) : null}
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography
                  style={{ marginTop: '16px' }}
                  className={classes.formlabeText}
                >
                  Choose Your Plan
                  <span style={{ color: 'red', fontSize: 20, display: 'inline', paddingTop: 5 }}>*</span>
                </Typography>
                <FormControl
                  variant="outlined"
                  className={classes.selectcontrol}
                >
                  <Select
                    name="user_plan"
                    onChange={(e) => handlePoll(e)}
                    value={user_plan}
                    defaultChecked={user_plan}
                    inputProps={{
                      IconComponent: ExpandMoreIcon,
                    }}
                    disabled={isEdit || disabled}
                  >
                    {userPlans &&
                      userPlans.map((item) => {
                        return parseInt(item.poll_counter) < (item.total_polls) ? (
                          <MenuItem key={item._id} value={item._id}>
                            {`${item.total_voters_limit} voters, ${item.total_polls} polls (avail. ${item.total_polls - item.poll_counter})`}
                          </MenuItem>
                        ) : null
                      })}
                  </Select>
                  {validationData[5] ? (
                    <Typography style={{ color: 'red' }}>
                      Please choose your plan
                    </Typography>
                  ) : null}
                </FormControl>
              </Grid>


              {/* <Grid
              item
              sm={6}
              xs={12}
              style={{ marginTop: '1rem' }}
              data-tut="reactour__PollType"
            >
              <Typography className={classes.formlabeText}>
                Poll Type
              </Typography>
              <FormControl
                variant="outlined"
                className={classes.selectcontrol}
                style={{ minWidth: '90%' }}
              >
                <Select
                  name="poll_type"
                  onChange={handlePoll}
                  value={poll_type}
                  disabled={isEdit || disablePollType}
                  inputProps={{
                    IconComponent: ExpandMoreIcon,
                  }}
                >
                  <MenuItem selected disabled value="Select">
                    <Typography>Select</Typography>
                  </MenuItem>
                  {pollTypeData &&
                    pollTypeData.map((type) => {
                      return (
                        <MenuItem key={type._id} value={type._id}>
                          {type.poll_type}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Grid>
         
          */}
            </Grid>

            <Grid container>
              {election_type === '60844acbc18867305001c508' &&
                userRoleId === 1 ? (
                <Grid item xs={12} sm={6}>
                  <Typography
                    className={classes.formlabeText}
                    style={{ marginTop: '10px' }}
                  >
                    Select RTA
                  </Typography>
                  <FormControl
                    variant="outlined"
                    className={classes.selectcontrol}
                  >
                    <Select
                      name="rtaList"
                      onChange={(e) => handlePoll(e)}
                      value={rtaEmail}
                      inputProps={{
                        IconComponent: ExpandMoreIcon,
                      }}
                      disabled={isEdit || disabled}
                    >
                      {rtaList &&
                        rtaList.map((item) => {
                          return (
                            <MenuItem key={item._id} value={item.email}>
                              {item.name}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </Grid>
              ) : null}

              {election_type === '60844acbc18867305001c508' ? (
                <Grid item xs={12} sm={6}>
                  <Typography
                    style={{ marginTop: '10px' }}
                    className={classes.formlabeText}
                  >
                    Add Scrutnizer<span style={{ color: 'red', fontSize: 20, display: 'inline', paddingTop: 5 }}>*</span>
                  </Typography>
                  <FormControl
                    variant="outlined"
                    className={classes.selectcontrol}
                  >
                    <Select
                      name="scrutinizer"
                      onChange={(e) => handlePoll(e)}
                      value={scrutinizer}
                      defaultChecked={scrutinizer}
                      inputProps={{
                        IconComponent: ExpandMoreIcon,
                      }}
                      disabled={isEdit || disabled}
                    >
                      {scrutinizers &&
                        scrutinizers.map((item) => {
                          return (
                            <MenuItem key={item._id} value={item._id}>
                              {item.email}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                  {validationData[4] ? (
                    <Typography style={{ color: 'red' }}>
                      Please add scrutinizer
                    </Typography>
                  ) : null}
                </Grid>
              ) : null}

              {userRoleId === 6 ? (
                <Grid item xs={12} sm={6}>
                  <Typography
                    className={classes.formlabeText}
                    style={{ marginTop: '10px' }}
                  >
                    Select Issuer
                  </Typography>
                  <FormControl
                    variant="outlined"
                    className={classes.selectcontrol}
                  >
                    <Select
                      name="issuerList"
                      onChange={(e) => handlePoll(e)}
                      value={issuerEmail}
                      inputProps={{
                        IconComponent: ExpandMoreIcon,
                      }}
                      disabled={isEdit || disabled}
                    >
                      {issuerList &&
                        issuerList.map((item) => {
                          return (
                            <MenuItem key={item._id} value={item.email}>
                              {item.name}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                  {/* {validationData[4] ? (
            <span style={{ color: 'red' }}>Please add rta</span>
          ) : null} */}
                </Grid>
              ) : null}
            </Grid>

            <Grid container className={classes.polltiming}>
              <Grid item xs={12} sm={2}>
                <Typography className={classes.pollstarting}>
                  Poll Starting<span style={{ color: 'red', fontSize: 20, display: 'inline', paddingTop: 5 }}>*</span>
                </Typography>
              </Grid>

              <Grid item xs={12} sm={5} data-tut="reactour__PollStartingDate">
                <Typography
                  style={{ color: 'blue' }}
                  className={classes.formlabeText}
                >
                  Poll Starting Date
                </Typography>

                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    className={classes.dateNtime}
                    style={{ padding: '5px' }}
                    disableToolbar
                    variant="outlined"
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    value={start_date}
                    name="start_date"
                    disabled={disabled}
                    onChange={(e) => handleDateChange(e, 'startDate')}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    InputProps={{ disableUnderline: true }}
                    keyboardIcon={<Calendar />}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={12} sm={5} data-tut="reactour__PollStartingTime">
                <Typography
                  style={{ color: 'red' }}
                  className={classes.formlabeText}
                >
                  Poll Starting Time
                </Typography>

                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardTimePicker
                    className={classes.dateNtime}
                    style={{ padding: '5px' }}
                    margin="normal"
                    id="time-picker"
                    value={start_time}
                    disabled={disabled}
                    onChange={(e) => handleDateChange(e, 'startTime')}
                    KeyboardButtonProps={{
                      'aria-label': 'change time',
                    }}
                    InputProps={{ disableUnderline: true }}
                    keyboardIcon={<Alarm />}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
          </Grid>

          <Grid container className={classes.polltiming}>
            <Grid item xs={12} sm={2}>
              <Typography className={classes.pollstarting}>
                Poll Ending<span style={{ color: 'red', fontSize: 20, display: 'inline', paddingTop: 5 }}>*</span>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={5} data-tut="reactour__PollEndingDate">
              <Typography
                style={{ color: 'blue' }}
                className={classes.formlabeText}
              >
                Poll Ending Date
              </Typography>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  className={classes.dateNtime}
                  style={{ padding: '5px' }}
                  disableToolbar
                  variant="outlined"
                  format="dd/MM/yyyy"
                  margin="normal"
                  id="date-picker-inline"
                  value={end_date}
                  disabled={disabled}
                  onChange={(e) => handleDateChange(e, 'endDate')}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  InputProps={{ disableUnderline: true }}
                  keyboardIcon={<Calendar />}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} sm={5} data-tut="reactour__PollEndingTime">
              <Typography
                style={{ color: 'red' }}
                className={classes.formlabeText}
              >
                Poll Ending Time
              </Typography>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardTimePicker
                  className={classes.dateNtime}
                  style={{ padding: '5px' }}
                  margin="normal"
                  id="time-picker"
                  disabled={disabled}
                  value={end_time}
                  onChange={(e) => handleDateChange(e, 'endTime')}
                  KeyboardButtonProps={{
                    'aria-label': 'change time',
                  }}
                  InputProps={{ disableUnderline: true }}
                  keyboardIcon={<Alarm />}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>

          {calculateDaysHours()}

          {renderEditButton()}
        </form>
      </>
  );
};

export default CreatePollForm;
