import {
  React,
  DashboardScreen,
  GlobalContext,
  useContext,
} from '../../collections/Imports';

import CreatorPoll from './CreatorPoll';
import { MyPollNav } from './MyPollNav';
import ScrutinizerPoll from './ScrutinizerPoll';

const MyPolls = () => {
  const [appData] = useContext(GlobalContext);
  return (
    <DashboardScreen>
      <MyPollNav />

      {appData.user_role && appData.user_role_id === 2 ? (
        <ScrutinizerPoll />
      ) : (
        <CreatorPoll />
      )}
    </DashboardScreen>
  );
};

export default MyPolls;
