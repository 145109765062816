import {
  useState,
  NavLink,
  Typography,
  Card,
  Grid,
  LoginStyles,
  SignUpStyles,
  OtpInput,
  axiosPost,
  auth,
  React,
  useEffect,
  useContext,
  useHistory,
  GlobalContext,
  Box,
  Button2,
  axiosGet
} from "../../collections/Imports";
import useAPIError from "./../../hooks/useAPIError";
import download_app from "../../../img/download_app.svg";
import { SnackbarContext } from "../../providers/SnackBarContext";
import { Link } from "@material-ui/core";
import { Helmet } from "react-helmet";

const VerifySignIn = (props) => {
  const classes = SignUpStyles();
  const classes1 = LoginStyles();

  const { addError } = useAPIError();
  const { setSnackbar } = useContext(SnackbarContext);

  const history = useHistory();
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));
  const email = currentUser ? currentUser.email : "";

  const [clickable, setClickable] = useState(false);
  const [otp, setOtp] = useState("");
  const [appData, setAppData] = useContext(GlobalContext);
  const [locationKeys, setLocationKeys] = useState([]);
  const [seconds, setSeconds] = useState(60);
  const [loginDisable, setLoginDisable] = useState(false);

  const handleOtpChange = (e) => {
    setOtp(e);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axiosPost("api/auth/signin_verify", {
      email,
      otp,
    })
      .then((res) => {
        if (res.code === "signin_verified") {
          localStorage.setItem("token", res.token);
          setAppData({
            ...appData,
            token: res.token,
            isAuthenticated: true,
          });

          setSnackbar("Login Successfully!", "success");

          props.handleAuthenticateChange();
          props.history.push("/dashboard/mypoll");
        } else if (res.code === "otp_expired") {
          setSnackbar(
            "Otp expired. Please, click resend button for new one",
            "info"
          );
        } else {
          setSnackbar("Please check your OTP again!!!", "error");
        }
      })
      .catch((err) => { });
  };

  const makeOtpInvalid = () => {
    const email = appData.user_email;
    axiosPost("api/auth/signin/make-otp-invalid", { email })
      .then((result) => {
        console.log("result : ", result);
      })
      .catch((err) => {
        console.log("Err : ", err);
      });
  };

  const resendOtp = () => {
    axiosPost("api/auth/reset/signin/otp", {
      email,
    })
      .then((res) => {
        setOtp("");
        setLoginDisable(false);
        resetOtp(60);
        setSnackbar("OTP resent to your mail", "info");
      })
      .catch((err) => {
        addError("Error sending OTP : ");
      });
  };

  useEffect(() => {
    auth.validateUserWithToken().then((res) => {
      if (res) {
        axiosGet(`api/plans/user`)
          .then(({ plans }) => {
            setAppData({
              ...appData,
              activePlans: plans
            })
            plans.length > 0
              ? props.history.push("/dashboard/mypoll")
              : props.history.push("/dashboard/subscriptions");
          })
          .catch((err) => console.log(err));
      }
    });
    auth.validateUser().then((res) => {
      if (!res) props.history.push("/login");
    });
  }, []);

  const resetOtp = (seconds) => {
    if (seconds > 0) {
      setTimeout(() => setSeconds(seconds - 1), 1000);
    } else {
      setSeconds(0);
      setClickable(true);
    }
  };

  useEffect(() => {
    if (seconds > 0) {
      setTimeout(() => setSeconds(seconds - 1), 1000);
    } else {
      setSeconds(0);
      setLoginDisable(true);
      makeOtpInvalid();
      setClickable(true);
    }
  }, [seconds]);

  useEffect(() => {
    return history.listen((location) => {
      if (history.action === "PUSH") {
        setLocationKeys([location.key]);
      }

      if (
        location.pathname === "/login" ||
        location.pathname === "/" ||
        location.pathname === "/aboutus" ||
        location.pathname === "/signup" ||
        location.pathname === "/pricingplan"
      ) {
        localStorage.clear();
      }

      if (history.action === "POP") {
        if (locationKeys[1] === location.key) {
          // Handle forward event
          localStorage.clear();
          props.history.push("/login");
        } else {
          // Handle back event
          localStorage.clear();
          props.history.push("/login");
        }
      }
    });
  }, [locationKeys]);

  return (
    <Box className={classes1.bg}>
       <Helmet>
        <meta charSet="utf-8" />
        {/* <title>EazyVC Voting Platform - Contact US</title> */}
        <meta
          name="title"
          content="The easy way to Vote Online on EazyVC’s Blockchain-secured Voting Platform."
        />
        <meta
          name="description"
          content="EazyVC is a Blockchain-based eVoting platform for Corporates, Government Bodies, Education Institutions, Associations & Clubs, Housing Societies, NGO, Trusts and more."
        />
      </Helmet>
      <Grid container spacing={2} className={classes1.gridContainer}>
        <Grid className={classes1.imgnText} item xs={12} sm={6}>
          <img
            src={download_app}
            style={{ width: "80%" }}
            alt="EazyVC Mobile Optimized Online Voting Software "
          />
          <Typography className={classes1.loginText}>
            {" "}
            Welcome to the
          </Typography>
          <Typography className={classes1.loginText}>
            <span style={{ color: "#fff" }}>Most Secure </span> E-Voting
            Platform
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Grid className={classes.main}>
            <Card className={classes.card}>
              <Typography component="h1" variant="h5" align="center" style={{fontWeight:'500',fontFamily:'inherit'}}>
                OTP Verification
              </Typography>
              <Typography
                className={classes1.digitText}
                variant="h6"
                align="center"
              >
                Enter 6 Digit OTP
              </Typography>
              <Grid container justify="center" alignItem="center">
                <Grid item xs={12}>
                  <form onSubmit={(e) => handleSubmit(e)}>
                    <Box style={{ display: "flex", justifyContent: "center" }}>
                      <OtpInput
                        className={classes1.otpInputbox}
                        inputStyle={{ width: "2em" }}
                        shouldAutoFocus={true}
                        value={otp}
                        onChange={handleOtpChange}
                        numInputs={6}
                        placeholder={true}
                      />
                    </Box>
                  </form>
                </Grid>
              </Grid>
              <Typography className={classes1.messageText}>
                The OTP has been sent to your registered e-mail address
                <Link href="#" underline="none">
                  {" "}
                  {appData.user_email}
                </Link>
              </Typography>
              <Button2
                type="submit"
                width="100%"
                className={
                  loginDisable ? classes1.disabledSubmit : classes1.submit
                }
                onClick={handleSubmit}
                disabled={loginDisable}
              >
                Login
              </Button2>
              <Grid container justify="space-between" alignItem="center">
                <Grid item align="center">
                  <Typography variant="p">{`OTP Expire in ${seconds} sec`}</Typography>
                </Grid>
                <Grid item>
                  {clickable ? (
                    <NavLink to="#" onClick={resendOtp}>
                      Resend OTP
                    </NavLink>
                  ) : (
                    <NavLink
                      to="#"
                      onClick={(e) => e.preventDefault()}
                      style={{ opacity: 0.1 }}
                    >
                      Resend OTP
                    </NavLink>
                  )}
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
export default VerifySignIn;
