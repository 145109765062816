import {
  Container,
  Grid,
  VotingPageStyles,
  useState,
  useEffect,
  useContext,
  GlobalContext,
} from '../../collections/Imports';
import useAPIError from '../../hooks/useAPIError';
import MainVoteCard from './MainVoteCard';

import VoteCardTop from './VoteCardTop';
import VotingServices from './VotingServices';

const VotingPage = (props) => {
  const { addError } = useAPIError();
  const classes = VotingPageStyles();
  const [shares, setShares] = useState(0);
  const [sharePercentage, setSharePercentage] = useState(0)
  const [appData] = useContext(GlobalContext);
  const [voter, setVoter] = useState(null);
  const [poll, setPoll] = useState(null)
  const [voter_id, setVoterId] = useState(null)

  const poll_id =
    appData && appData.selected_poll_id
      ? appData.selected_poll_id
      : localStorage.getItem('selected_poll_id');



  useEffect(() => {
    try {
      const voter = JSON.parse(localStorage.getItem('voter'));
      const voter_id = voter._id;
      setVoter(voter)
      setVoterId(voter_id)
    } catch (e) {
      console.log("E : ", e)
      alert("Not authorised. Please re-login.")
      props.history.push('/')
    }
  }, [])
  
  useEffect(() => {
    const params = {
      voter_id,
      poll_id,
    };

    VotingServices.getShares(params, setShares, setSharePercentage, addError);
    VotingServices.getElectionType(poll_id, setPoll, addError)

  }, [poll_id, voter_id, addError]);

  return (
    <Container className={classes.root}>
      {
        voter &&
        <>
          <Grid item style={{ paddingBottom: '20px' }}>
            <VoteCardTop voter={voter} shares={shares} sharePercentage={sharePercentage} poll={poll} />
          </Grid>
          <Grid item>
            <MainVoteCard voter={voter} shares={shares} />
          </Grid>
        </>
      }
    </Container>
  );
};

export default VotingPage;
