import onClickHandlers from './OnClickHandlers';
import {
  Grid,
  Button2,
  Modal,
  Backdrop,
  Fade,
  VotingPageStyles,
  useState,
  IconButton,
} from '../../collections/Imports';
import PreviewModal from './PreviewModal';
import ReplayIcon from '@material-ui/icons/Replay';
import Tooltip from '@material-ui/core/Tooltip';
import ConfirmDialog from '../../AlertNotification/ConfirmDialog'
const ButtonGridNew = (props) => {
  const classes = VotingPageStyles();
  const [open, setOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(true)
  const [loading, setLoading] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
  });

  const {
    questions,
    voter,
    addError,
    setSnackbar,
    handleModalClose,
    history,
    setQuestions,
    currentQuestion,
  } = props;

  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      spacing={2}
      style={{ paddingBottom: '100px' }}
    >
      <Grid item>
        <Tooltip title="Reset Response" placement="right">
          <IconButton
            aria-label="Reset Response"
            onClick={() =>
              onClickHandlers.onResetClick({
                currentQuestion,
                questions,
                setQuestions,
              })
            }
          >
            <ReplayIcon
              style={{ color: '#0b2f64', width: '30px', height: '30px' }}
            />
            Reset
          </IconButton>
        </Tooltip>
      </Grid>

      {questions.indexOf(currentQuestion) === questions.length - 1 ? (
        <>
          {/* <Grid item>
            <Tooltip title="Reset Response" placement="right">
              <IconButton
                aria-label="Reset Response"
                onClick={() =>
                  onClickHandlers.onResetClick({
                    currentQuestion,
                    questions,
                    setQuestions,
                  })
                }
              >
                <ReplayIcon
                  style={{ color: '#0b2f64', width: '30px', height: '30px' }}
                />
                Reset
              </IconButton>
            </Tooltip>
        </Grid> */}
          <Grid item>
            <Button2 onClick={() => setOpen(true)} width="150px">
              Preview
            </Button2>
          </Grid>
          <Grid item>
            <Button2 onClick={() => {
              console.log("Tests")
              setIsOpen(true)
              setConfirmDialog({
                isOpen,
                title: 'Are you sure to Submit Your Vote ?',
                subTitle: "You can't undo this operation",
                onConfirm: () => {
                  onClickHandlers.onVoteClick({
                    handleModalClose,
                    voter,
                    questions,
                    addError,
                    setSnackbar,
                    history,
                    setIsOpen,
                    setLoading
                  })
                },
              });
            }} width="150px">
              Submit
            </Button2>
            <Modal
              className={classes.modal}
              open={open}
              onClose={handleModalClose}
              closeAfterTransition
              disableBackdropClick={true}
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={open}>
                <PreviewModal
                  questions={questions}
                  PreviewModalClose={() => setOpen(false)}
                />
              </Fade>
            </Modal>
          </Grid>
        </>
      ) : null}


      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
        loading={loading}
        setLoading={setLoading}
      />
    </Grid>
  );
};

export default ButtonGridNew;
