import { Button } from '../Imports';
import { styled } from '@material-ui/styles';

const Button2 = styled(({ color, ...other }) => <Button {...other} />)({
  color: (props) => props.color || '#F8F8F8',
  backgroundColor: (props) => props.backgroundColor || '#0B2F64',
  width: (props) => props.width || '30%',
  height: (props) => props.height || '45px',
  varient: (props) => props.varient || 'contained',
  fontWeight: (props) => props.fontWeight || '500',
  fontStyle: 'normal',
  lineHeight: '21.6px',
  textAlign: 'center',
  fontSize: '15px',
  boxShadow: '0px 4px 20px rgba(11, 47, 100, 0.4)',
  borderRadius: '25px',

  '&:hover': {
    backgroundColor: (props) => props.color || '#F8F8F8',
    color: (props) => props.backgroundColor || '#0B2F64',
  },
});

export default Button2;
