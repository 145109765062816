import React, { useRef } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Box } from '../../collections/Imports';
function CkEditor({ value, onChangeCkEditor }) {
  const questionElRef = useRef(null);

  return (
    <Box style={{ paddingRight: '20px' }}>
      <CKEditor
        editor={ClassicEditor}
        data={value}
        inputRef={questionElRef}
        multiline
        rows={4}
        variant="outlined"
        onChange={(event, editor) => {
          const data = editor.getData();
          onChangeCkEditor(data)
        }}
        style={{ minWidth: "95%" }}
        config={{
          toolbar: ["bold", "italic", "bulletedList"],
        }}
      />
    </Box>
  );
}

export default CkEditor;
