import { Button, makeStyles } from "../Imports";

const button1Styles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
    fontSize: "18px",
    color: "#1171FF",
    fontWeight: "400",
    width: "277px",
    height: "69px",
    backgroundColor: "#F8F8F8",
    boxShadow: "0px 4px 20px rgba(11, 47, 100, 0.4)",
    borderRadius: "15px",
  },
}));

const Button1 = (props) => {
  const { variant, value, onClick } = props;
  const classes = button1Styles();

  return (
    <Button
      onClick={onClick}
      className={classes.root}
      variant={variant || "contained"}
    >
      {value}
    </Button>
  );
};

export default Button1;
