import { makeStyles } from '@material-ui/core/styles';

export const CreatorTableStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },

  PollStatus: {
    boxShadow: '1px 2px rgb(220,220,220)',
    borderRadius: '10px',
    marginBottom: '10px',
    border: '1px solid rgb(220,220,220)',
    padding: '13px 20px 7px 10px',
    marginTop: '15px',
    fontSize: '15px',

    '&:hover': {
      border: '1px solid #1171FF',
    },
  },

  dateNtime: {
    border: '1px solid #E5E5E5',
    minWidth: '86%',
    background: '#FFFFFF',
    /* box-shadow: inset 0px 0px 5px 1px rgb(144 144 144 / 15%); */
    boxSizing: 'border-box',
    borderRadius: '5px',
  },
}));
