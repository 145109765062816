import { moment } from '../../../collections/Imports';

export const getPollStatus = (poll, statusCount) => {
  let { start_date, end_date, start_time, end_time, participantCount } = poll;

  start_date = moment(start_date).format('YYYY.MM.DD');

  var start = moment(
    start_date + ' ' + start_time,
    moment.defaultFormat
  ).format();

  end_date = moment(end_date).format('YYYY.MM.DD');
  var end = moment(end_date + ' ' + end_time, moment.defaultFormat).format();
  let status = '';

  statusCount.all = parseInt(statusCount.all) + 1;
  if (moment().diff(start, 's') < 0) {
    status = 'scheduled';
    statusCount.scheduled = parseInt(statusCount.scheduled) + 1;
  } else if (moment().diff(end, 's') > 0) {
    if (participantCount) {
      status = 'closed';
      statusCount.closed = parseInt(statusCount.closed) + 1;
    } else {
      status = 'expired';
      statusCount.expired = parseInt(statusCount.expired) + 1;
    }
    // status = 'closed';
    // statusCount.closed = parseInt(statusCount.closed) + 1;
  } else if (moment().diff(start, 's') >= 0 && moment().diff(end, 's') < 0) {
    status = 'started';
    statusCount.started = parseInt(statusCount.started) + 1;
    // if (participantCount) {
    //   status = 'started';
    //   statusCount.started = parseInt(statusCount.started) + 1;
    // } else {
    //   status = 'expired';
    //   statusCount.expired = parseInt(statusCount.expired) + 1;
    // }
  }

  const pollStatus = { status, statusCount };
  return pollStatus;
};
