import {
  IconButton,
  Toolbar,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { Button2 } from "../../../collections/Imports.js";
import DeleteIcon from "@material-ui/icons/Delete";
import { InviteTableToolbarStyles } from "./InviteTableToolbarStyles";
import { useContext, useState } from "react";
import InviteMemberServices from "../../DashboardServices/InviteMemberServices";
import { SnackbarContext } from "../../../providers/SnackBarContext";
import ConfirmDialog from "../../../AlertNotification/ConfirmDialog";

const InviteTableToolbar = (props) => {
  const classes = InviteTableToolbarStyles();
  const { numSelected, selected, setDeleteFlag, validParticipants } = props;
  // const [mailAlert, setMailAlert] = useState(false);
  const { setSnackbar } = useContext(SnackbarContext);

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  // const onAlertButtonClick = (event) => {
  //   setConfirmDialog({
  //     ...confirmDialog,
  //     isOpen: false,
  //   });

  //   onSubmit(event);
  //   setMailAlert(false);
  // };

  const onSubmit = (event) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });

    setDeleteFlag(true);
    event.preventDefault();
    InviteMemberServices.sendMails(selected)
      .then((res) => {
        if (res.code === "email_sent") {
          setSnackbar("Mail sent successfully", "success");
          setDeleteFlag(prev => !prev);
        }
      })
      .catch((err) => {
        setSnackbar(err, "could not send mails!");
      });
  };

  const onDeleteMany = () => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });

    setDeleteFlag(true);
    InviteMemberServices.removeInvitees(selected)
      .then((res) => {
        console.log(res);
        setDeleteFlag(false);
        setSnackbar("All Members Deleted.", "success");
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <Toolbar
        className={
          (classes.root,
          {
            [classes.highlight]: numSelected > 0,
          })
        }
      >
        {numSelected > 0 ? (
          <Typography
            className={classes.title}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
        ) : (
          <Typography
            className={classes.title}
            variant="h6"
            id="tableTitle"
            component="div"
            style={{ marginLeft: "-6px" }}
          >
            Invite Members
          </Typography>
        )}
        {numSelected > 0 ? (
          <>
            <Typography
              className={classes.title}
              color="inherit"
              variant="subtitle1"
              component="div"
            >
              Please click on the Send to invite voters.
            </Typography>
            <Button2
              onClick={() => {
                setConfirmDialog({
                  isOpen: true,
                  title: "Check member details properly before sending mail ?",
                  subTitle: "You can't undo this operation",
                  onConfirm: (e) => {
                    onSubmit(e);
                  },
                });
              }}
              className={classes.sendButton}
            >
              Send
            </Button2>
            <Tooltip title="delete invitees" placement="top">
              <IconButton
                onClick={() => {
                  setConfirmDialog({
                    isOpen: true,
                    title: "Are you sure to delete this member ?",
                    subTitle: "You can't undo this operation",
                    onConfirm: () => {
                      onDeleteMany();
                    },
                  });
                }}
              >
                <DeleteIcon style={{ color: "#fff" }} />
              </IconButton>
            </Tooltip>
          </>
        ) : null}
      </Toolbar>

      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
};

export default InviteTableToolbar;
