import React, { lazy, Suspense } from "react";
import { SideCard, useEffect, useState } from "./components/collections/Imports";
import { Switch, Route } from "react-router-dom";
import { Box, CssBaseline, MuiThemeProvider } from "@material-ui/core";
import { GlobalContextProvider } from "./components/contexts/GlobalContext";
import { SnackbarContainer } from "./components/providers/SnackBarContext";
import theme from "./AppTheme";

import auth from "./helpers/authentication";
import Navbar from "./components/header/NavBar/Navbar";
import Homepage from "./components/pages/Home_Page/Home.js";

import "../src/App.css";

const APIErrorNotification = lazy(() => import("./components/APIErrorNotification"));

const SignUp = lazy(() => import("./components/pages/SignUp/SignUp"));
const VerifySignUp = lazy(() => import("./components/pages/SignUp/VerifySignUp"));
const Login = lazy(() => import("./components/pages/Login/Login"));
const ForgotPassword = lazy(() => import("./components/pages/Login/ForgotPassword"));
const VerifySignIn = lazy(() => import("./components/pages/Login/VerifySignIn"));
const VerifyEmail = lazy(() => import("./components/pages/Login/VerifyEmail"));
const VerifyForgetPassword = lazy(() => import("./components/pages/Login/VerifyForgetPassword"));
const PrivacyPolicy = lazy(() => import("./components/pages/Home_Page/PrivacyPolicy"));
const Services = lazy(() => import("./components/pages/Home_Page/Services"));
const ServiceMain = lazy(() => import("./components/pages/Home_Page/ServiceMain.js"));
const TermsAndConditions = lazy(() => import("./components/pages/Home_Page/TermsAndConditions"));
const ContactUs = lazy(() => import("./components/pages/Home_Page/ContactUs"));
const Aboutus = lazy(() => import("./components/pages/Home_Page/Aboutus"));

const BlogDashboard = lazy(() => import("./components/pages/Home_Page/Blogs/BlogDashboard"));
const SingleBlog = lazy(() => import("./components/pages/Home_Page/Blogs/SingleBlog"));
const EVotingForIBCBlog = lazy(() => import("./components/pages/Home_Page/Blogs/EVotingForIBCBlog"));

const Getin = lazy(() => import("./components/pages/Login/Getin"));
const VotingPage = lazy(() => import("./components/pages/VotingPage"));
const ThankYouPage = lazy(() => import("./components/pages/ThankYou/ThankYouPage"));
const InvalidURL = lazy(() => import("./components/pages/InvalidURL"));
const Pricing = lazy(() => import("./components/pages/Pricing"));

//private routes
const NavbarAuth = lazy(() => import("./components/header/NavbarAuth/NavbarAuth"));
const CreatePoll = lazy(() => import("./components/pages/CreatePoll/index.js"));
const Scrutinizer = lazy(() => import("./components/pages/Scrutinizer/Scrutinizer.js"));
const InviteMembers = lazy(() => import("./components/pages/InviteMembers/InviteMembers"));
const UserProfile = lazy(() => import("./components/pages/UserProfile"));
const AddQuestion = lazy(() => import("./components/pages/AddQuestion/index.js"));
const PollResult = lazy(() => import("./components/pages/PollResult"));
const Welcome = lazy(() => import("./components/pages/Welcome/Welcome"));
const MyPolls = lazy(() => import("./components/pages/MyPolls/index.js"));
const PrivateRoute = lazy(() => import("./components/PrivateRoute"));
const ChangePassword = lazy(() => import("./components/pages/Login/ChangePassword"));
const APIErrorProvider = lazy(() => import("./components/providers/APIErrorProvider"));
const Snackbar = lazy(() => import("./components/SnackBar"));

const ScrutinizerProfile = lazy(() => import("./components/pages/Scrutinizer/ScrutinizerProfile"));
const AllVoter = lazy(() => import("./components/pages/Scrutinizer/AllVoter"));
const Subscriptions = lazy(() => import("./components/pages/Subscriptions"));
const PricingContact = lazy(() => import("./components/pages/Subscriptions/PricingContact"));
const Invoice = lazy(() => import("./components/pages/Subscriptions/Invoice"));
const SubscriptionHistory = lazy(() => import("./components/pages/SubscriptionHistory"));

window.React = React;
const App = () => {
  const [isAuthenticated, setAuthenticated] = useState(false);
  const [navbarStatusChange, setNavbarStatusChange] = useState(false);

  useEffect(() => {
    localStorage.setItem("India", "yes");
  }, [])

  useEffect(() => {
    auth.validateUserWithToken().then((res) => {
      setAuthenticated(res);
    });
  }, [navbarStatusChange]);

  const handleAuthenticateChange = () => {
    setNavbarStatusChange((navbarStatusChange) => !navbarStatusChange);
  };

  useEffect(() => {
    document.addEventListener("click", (e) => {
      if (e.ctrlKey || e.shiftKey) {
        e.preventDefault();
        return false;
      }
    });
  }, []);

  return (
    <MuiThemeProvider theme={theme}>
      <Suspense fallback={<div></div>}>
        <GlobalContextProvider>
          <SnackbarContainer>
            <APIErrorProvider>
              <CssBaseline />

              <Box
                className="App"
              // onContextMenu={onRightClick}
              >
                <Snackbar />
                {isAuthenticated ? (
                  <NavbarAuth
                    handleAuthenticateChange={handleAuthenticateChange}
                  />
                ) : (
                  <Navbar handleAuthenticateChange={handleAuthenticateChange} />
                )}

                {isAuthenticated ? <SideCard /> : null}

                <Switch>
                  <Route exact path="/" component={Homepage} />
                  <Route exact path="/signup" component={SignUp} />
                  <Route exact path="/login" component={Login} />
                  <Route exact path="/privacypolicy"
                    component={PrivacyPolicy}
                  />
                  <Route exact path="/services" component={ServiceMain} />
                  <Route exact path="/services/evoting-for-irps"
                    component={Services}
                  />
                  <Route exact path="/termsandconditions"
                    component={TermsAndConditions}
                  />
                  <Route exact path="/contactus" component={ContactUs} />
                  <Route exact path="/aboutus" component={Aboutus} />

                  <Route exact path="/blogs" component={BlogDashboard} />
                  <Route exact path="/singleblog" component={SingleBlog} />
                  <Route exact path="/blogs/e-voting-for-ibc" component={EVotingForIBCBlog} />

                  <Route exact path="/verify/email"
                    component={(props) => (
                      <VerifySignIn {...props} handleAuthenticateChange={handleAuthenticateChange} />
                    )}
                  />

                  <Route exact path="/verify-signup/email/:email" component={VerifySignUp} />

                  <Route exact path="/voter/:link_id" component={Getin} />

                  <Route exact path="/forgot_password" component={ForgotPassword} />
                  <Route exact path="/verify_email" component={VerifyEmail} />
                  <Route exact path="/verify_forget_password" component={VerifyForgetPassword} />
                  <Route exact path="/voting" component={VotingPage} />
                  <Route exact path="/thank-you"
                    component={(props) => (
                      <ThankYouPage
                        {...props}
                        handleAuthenticateChange={handleAuthenticateChange}
                      />
                    )}
                  />
                  <Route exact path="/welcome" component={Welcome} />

                  <PrivateRoute exact path="/dashboard/subscriptions-history"
                    component={SubscriptionHistory}
                  />

                  <Route exact path="/dashboard/scrutinizer/:token"
                    component={Scrutinizer}
                  />

                  <Route exact path="/pricingplan" component={Pricing} />

                  <PrivateRoute exact path="/dashboard/subscriptions"
                    component={Subscriptions}
                  />
                  <PrivateRoute exact path="/pricing-contact"
                    component={PricingContact}
                  />
                  <PrivateRoute exact path="/dashboard/invite"
                    component={InviteMembers}
                  />
                  <PrivateRoute exact path="/dashboard/profile"
                    component={UserProfile}
                  />
                  <PrivateRoute exact path="/dashboard/Scrutinizerprofile"
                    component={ScrutinizerProfile}
                  />
                  <PrivateRoute exact path="/dashboard/allvoter"
                    component={AllVoter}
                  />
                  <PrivateRoute exact path="/dashboard/createpoll"
                    component={CreatePoll}
                  />
                  <PrivateRoute exact path="/dashboard/addquestions"
                    component={AddQuestion}
                  />
                  <PrivateRoute exact path="/dashboard/pollresult"
                    component={PollResult}
                  />
                  <PrivateRoute exact path="/change-password"
                    component={ChangePassword}
                  />

                  <PrivateRoute path="/dashboard/mypoll" component={MyPolls} />
                  <PrivateRoute
                    path="/dashboard/invoice/:plan_id/:is_razor"
                    component={Invoice}
                  />
                  <PrivateRoute
                    path="/dashboard/invoice/:plan_id"
                    component={Invoice}
                  />
                  <PrivateRoute exact path="/dashboard/editpoll"
                    component={(props) => (
                      <CreatePoll {...props} isEditable={true} />
                    )}
                  />
                  <Route to="/" component={InvalidURL} />
                </Switch>
              </Box>
              <APIErrorNotification />
            </APIErrorProvider>
          </SnackbarContainer>
        </GlobalContextProvider>
      </Suspense>
    </MuiThemeProvider>
  );
};

export default App;
