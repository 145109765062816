import { styled } from '@material-ui/styles';
import { TableCell } from '../../collections/Imports';

const BoldTableCell = styled(TableCell)({
  fontSize: '18px',
  fontStyle: 'normal',
  fontWeight: '400',
  color: '',
  borderBottom: 'none',
  paddingTop: '0px',
  paddingBottom: '5px',
  paddingRight: '40px,',
});

export default BoldTableCell;
