import { makeStyles } from '@material-ui/core/styles';

const CreatePollstyle = makeStyles((theme) => ({
  notchedOutline: {
    borderColor: 'white !important',
  },
  basicHeading: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '24px',
    color: '#121212',
  },
  formlabeText: {
    textTransform: 'capitalize',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '18px',
    lineHeight: '35px',
    color: '#414448',
    '&.MuiOutlinedInput-input ': {
      padding: '12.5px 14px',
    },
  },
  pollheading: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '25px',
    lineHeight: '43px',
    color: '#1171FF',
    paddingLeft: '1.8rem',
  },
  polltiming: {
    marginTop: '20px',
    borderBottom: 'none',
  },
  titleControl: {
    '& .MuiOutlinedInput-input': {
      padding: '12.5px 14px',
    },
  },

  selectcontrol: {
    minWidth: '90%',
    // border: '1px solid #E5E5E5',
    background: '#FFFFFF',
    // boxShadow: 'inset 0px 0px 5px 1px rgb(144 144 144 / 15%)',
    boxSizing: 'border-box',
    borderRadius: '10px',
    marginRight: '5px',
    '& .MuiOutlinedInput-input': {
      padding: '12.5px 14px',
    },
    '& .MuiInputBase-root': {
      paddingRight: '10px',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #1171FF',
    },
    '& .MuiSelect-iconOutlined': {
      color: '#1171ff',
      transform: 'scale(1.3)',
    },
  },
  Arrow: {
    marginRight: '5px',
    marginLeft: '5px',

    paddingRight: '5px',
  },
  dateNtime: {
    minWidth: '90%',
    border: '1px solid #E5E5E5',

    background: '#FFFFFF',
    // boxShadow: 'inset 0px 0px 5px 1px rgb(144 144 144 / 15%)',
    boxSizing: 'border-box',
    borderRadius: '5px',
    marginTop: '2px',
    marginBottom: '2px',

    '& .MuiOutlinedInput-input': {
      padding: '12.5px 14px',
    },
    '& .MuiFormControl-marginNormal': {
      marginTop: '2px',
      marginBottom: '2px',
    },
  },

  pollstarting: {
    marginBottom: '29px',
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '24px',
    color: '#121212',
    paddingLeft: '3px',
    marginTop: '15px',
  },
  processBtn: {
    display: 'flex',
    justifyContent: 'center',
    width: '30%',
    margin: '0 auto',
    padding: '10px',
    marginTop: '50px',
    marginBottom: '20px',
    background: '#1171FF',
    borderRadius: '15px',
  },

  createPollForm: {
    padding: '2.5rem',
    paddingTop: '0px',
    [theme.breakpoints.down('sm')]:{
      padding: '2rem',
     }
  },

  message: {
    textAlign: 'center',
    marginTop: '30px',
    marginBottom: '30px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '18px',
    lineHeight: '24px',
    color: '#000000',
  },

  radiobtnlabel: {
    fontSize: '18px',
    lineHeight: '23px',
    color: '#000000',
    paddingLeft: '3px',
  },
  companyText: {
    boxSizing: 'border-box',
    borderRadius: '10px',
    // border: '1px solid #E5E5E5',
    background: '#FFFFFF',
    // boxShadow: 'inset 0px 0px 5px 1px rgb(144 144 144 / 15%)',
    '&.MuiAutocomplete-root': {
      width: '90%',
    },
    '& .MuiOutlinedInput-root': {
      padding: '3px',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #1171FF',
    },
  },
  textfieldcontrol: {
    background: '#FFFFFF',
    // border: '1px solid #E5E5E5',
    boxSizing: 'border-box',
    borderRadius: '10px',
    // boxShadow: 'inset 0px 0px 5px 1px rgb(144 144 144 / 15%)',
    '&:hover .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #1171FF',
    },
    '& .MuiOutlinedInput-input': {
      padding: '12.5px 14px',
    },
  },

  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  paperScrutinizer: {
    margin: '10px',
    padding: '10px',
    // boxShadow:
    //   '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
  },
  // updateButton:{
  //   width:'100%',
  //   [theme.breakpoints.up('sm')]:{
  //     minWidth:'30%',
  //   }
  // },
  // selectIcon:{
  //   marginRight:'5px',
  //   '&..MuiSelect-nativeInput ~ svg':{
  //     PaddingRight:'10px'
  //   }
  // }
}));

export default CreatePollstyle;
