const Trash = () => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.3125 10.3125C10.5611 10.3125 10.7996 10.4113 10.9754 10.5871C11.1512 10.7629 11.25 11.0014 11.25 11.25V22.5C11.25 22.7486 11.1512 22.9871 10.9754 23.1629C10.7996 23.3387 10.5611 23.4375 10.3125 23.4375C10.0639 23.4375 9.8254 23.3387 9.64959 23.1629C9.47377 22.9871 9.375 22.7486 9.375 22.5V11.25C9.375 11.0014 9.47377 10.7629 9.64959 10.5871C9.8254 10.4113 10.0639 10.3125 10.3125 10.3125ZM15 10.3125C15.2486 10.3125 15.4871 10.4113 15.6629 10.5871C15.8387 10.7629 15.9375 11.0014 15.9375 11.25V22.5C15.9375 22.7486 15.8387 22.9871 15.6629 23.1629C15.4871 23.3387 15.2486 23.4375 15 23.4375C14.7514 23.4375 14.5129 23.3387 14.3371 23.1629C14.1613 22.9871 14.0625 22.7486 14.0625 22.5V11.25C14.0625 11.0014 14.1613 10.7629 14.3371 10.5871C14.5129 10.4113 14.7514 10.3125 15 10.3125ZM20.625 11.25C20.625 11.0014 20.5262 10.7629 20.3504 10.5871C20.1746 10.4113 19.9361 10.3125 19.6875 10.3125C19.4389 10.3125 19.2004 10.4113 19.0246 10.5871C18.8488 10.7629 18.75 11.0014 18.75 11.25V22.5C18.75 22.7486 18.8488 22.9871 19.0246 23.1629C19.2004 23.3387 19.4389 23.4375 19.6875 23.4375C19.9361 23.4375 20.1746 23.3387 20.3504 23.1629C20.5262 22.9871 20.625 22.7486 20.625 22.5V11.25Z"
        fill="#F04F1E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.1875 5.625C27.1875 6.12228 26.99 6.59919 26.6383 6.95083C26.2867 7.30246 25.8098 7.5 25.3125 7.5H24.375V24.375C24.375 25.3696 23.9799 26.3234 23.2766 27.0266C22.5734 27.7299 21.6196 28.125 20.625 28.125H9.375C8.38044 28.125 7.42661 27.7299 6.72335 27.0266C6.02009 26.3234 5.625 25.3696 5.625 24.375V7.5H4.6875C4.19022 7.5 3.71331 7.30246 3.36167 6.95083C3.01004 6.59919 2.8125 6.12228 2.8125 5.625V3.75C2.8125 3.25272 3.01004 2.77581 3.36167 2.42417C3.71331 2.07254 4.19022 1.875 4.6875 1.875H11.25C11.25 1.37772 11.4475 0.900806 11.7992 0.549175C12.1508 0.197544 12.6277 0 13.125 0L16.875 0C17.3723 0 17.8492 0.197544 18.2008 0.549175C18.5525 0.900806 18.75 1.37772 18.75 1.875H25.3125C25.8098 1.875 26.2867 2.07254 26.6383 2.42417C26.99 2.77581 27.1875 3.25272 27.1875 3.75V5.625ZM7.72125 7.5L7.5 7.61063V24.375C7.5 24.8723 7.69754 25.3492 8.04918 25.7008C8.40081 26.0525 8.87772 26.25 9.375 26.25H20.625C21.1223 26.25 21.5992 26.0525 21.9508 25.7008C22.3025 25.3492 22.5 24.8723 22.5 24.375V7.61063L22.2787 7.5H7.72125ZM4.6875 5.625V3.75H25.3125V5.625H4.6875Z"
        fill="#F04F1E"
      />
    </svg>
  );
};

export default Trash;
