import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { Box } from "@material-ui/core";
import { useContext } from "react";
import { GlobalContext } from "../../../contexts/GlobalContext";
import Tooltip from "@mui/material/Tooltip";
const InviteTableHead = (props) => {
  const [appData] = useContext(GlobalContext);

  var headCells = [];
  if ([1, 2, 6, 7].includes(appData.user_role_id)) {
    headCells = [
      { id: "name", numeric: false, disablePadding: true, label: "Name" },
      { id: "email", numeric: false, disablePadding: false, label: "Email" },
      {
        id: "share",
        numeric: false,
        disablePadding: false,
        label: "Voting Share",
      },
      { id: "phone", numeric: false, disablePadding: false, label: "Phone" },
      { id: "status", numeric: false, disablePadding: false, label: "Status" },
      { id: "edit", numeric: false, disablePadding: false, label: "Edit" },
    ];
  } else {
    headCells = [
      { id: "name", numeric: false, disablePadding: true, label: "Name" },
      { id: "email", numeric: false, disablePadding: false, label: "Email" },
      { id: "phone", numeric: false, disablePadding: false, label: "Phone" },
      { id: "status", numeric: false, disablePadding: false, label: "Status" },
      { id: "edit", numeric: false, disablePadding: false, label: "Edit" },
    ];
  }

  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    setOrder,
    setOrderBy,
  } = props;

  const onRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Tooltip title="Select All" placement="top">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ "aria-label": "select all invitees" }}
              className={classes.checkbox}
            />
          </Tooltip>
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.id === "edit" ? (
              <>
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box className={classes.visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}{" "}
              </>
            ) : (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box className={classes.visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default InviteTableHead;
