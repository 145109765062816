import { styled } from '@material-ui/styles';
import { TableCell } from '../../collections/Imports';

const MyTableCell = styled(({ props, ...other }) => <TableCell {...other} />)({
  // overrides: {
  //   MuiTableCell: {
  //     root: {
  //       textTransform: (props) => props.textTransform || '',
  //     },
  //   },
  // },
  color: (props) => props.color || '',
  fontSize: (props) => props.fontSize || '18px',
  fontWeight: (props) => props.fontWeight || '400',
  borderBottom: 'none',
  paddingTop: '5px',
  paddingBottom: (props) => props.paddingBottom || '5px',
});

export default MyTableCell;
