import { axiosGet, moment, _ } from '../../collections/Imports';
export const getElectionTypeData = ({
  setDisablePollCategory,
  // setDisablePollType,
  setEndDate,
  setStartDate,
  setStartTime,
  setEndTime,
  setElectionType,
  isEditable,
  setElectionTypeData,
  userPlanType,
  setElectionId,
  addError,
}) => {
  axiosGet('api/election-types/all')
    .then((result) => {
      const { electionTypes } = result;
      let currentUser = JSON.parse(localStorage.getItem('currentUser'));
      const { user_role } = currentUser;
      var eTypes = electionTypes;
      if (isEditable) {
        eTypes = electionTypes;
      } else if (
        user_role &&
        (parseInt(user_role.user_role_id) === 1 ||
          parseInt(user_role.user_role_id) === 6)
      ) {
        if (userPlanType === 'insolvency') {
          eTypes = electionTypes.filter((e) => {
            return (
              parseInt(e.election_type_id) === 2 || parseInt(e.election_type_id) === 6
            );
          });
          setStartDate(moment().add(1, 'days'));
          setEndDate(moment().add(2, 'days'));
          setDisablePollCategory(true);
          // setDisablePollType(true);
        } else {
          eTypes = electionTypes.filter((e) => {
            return (
              parseInt(e.election_type_id) === 3
            );
          });
          setElectionId(3)
          setStartDate(moment().add(1, 'days'));
          setEndDate(moment().add(4, 'days'));
        }

        setStartTime(moment('10:00:00 am', 'hh:mm:ss a'));
        setEndTime(moment('05:00:00 pm', 'hh:mm:ss a'));
      } else {
        eTypes = electionTypes.filter((e) => {
          return (
            parseInt(e.election_type_id) === 1
          );
        });
      }

      const eType = eTypes ? eTypes[0]._id : '';
      if (!isEditable) setElectionType(eType);

      setElectionTypeData(eTypes);
    })
    .catch((err) => {
      addError(`LOAD_DATA_ERROR: ${err}`);
    });
};

export const getPollTypeData = ({
  isEditable,
  setPollType,
  setPollTypeData,
  addError,
}) => {
  axiosGet('api/poll-types/all')
    .then((result) => {
      const { pollTypes } = result;
      const pType = pollTypes ? pollTypes[1]._id : '';

      if (!isEditable) setPollType(pType);
      setPollTypeData(pollTypes);
    })
    .catch((err) => {
      addError(`LOAD_DATA_ERROR: ${err}`);
    });
};

export const getPollCategoryData = ({
  isEditable,
  setPollCategory,
  setPollCategoryData,
  addError,
  setPollCategoryOriginalData,
  userPlanType,
  election_type,
  electionTypeData
}) => {
  let tempElectionType = null
  if (election_type) {
    [tempElectionType] = electionTypeData.filter((e) => {
      return e._id === election_type
    })
  }
  axiosGet('api/poll-categories/all')
    .then((result) => {
      const { pollCategories } = result;
      const pCat = pollCategories ? pollCategories[0]._id : '';
      if (!isEditable) setPollCategory(pCat);
      let pollCatData = pollCategories

      if (userPlanType === 'shareholder') {
        pollCatData = pollCategories.filter(pc => {
          return parseInt(pc.poll_category_id) > 8 && parseInt(pc.poll_category_id) < 12
        })
        if (pollCatData && pollCatData.length > 0) {
          let tempCat = pollCatData[0]._id
          setPollCategory(tempCat)
        }
      }
      setPollCategoryData(pollCatData);
      setPollCategoryOriginalData(pollCategories);
    })
    .catch((err) => {
      addError(`LOAD_DATA_ERROR: ${err}`);
    });
};

export const getDaysAndHours = async ({
  start_date,
  start_time,
  end_date,
  end_time,
  setSnackbar,
  setStartTime,
  setStartDate,
  election_type,
  poll_category,
  setEndTime,
  setEndDate,
  isLoading,
}) => {
  // let addHours = 0;
  let start = moment(
    moment(start_date).format('YYYY.MM.DD') +
    ' ' +
    moment(start_time).format('HH:mm:ss'),
    moment.defaultFormat
  ).format();
  let end = moment(
    moment(end_date).format('YYYY.MM.DD') +
    ' ' +
    moment(end_time).format('HH:mm:ss'),
    moment.defaultFormat
  ).format();

  if (moment(start).diff(moment(), 'minutes') < 0) {
    setSnackbar('Poll start date cannot be less than current date', 'warning');
    if (election_type === '6084478f9fefeb2c55b5fb39') {
      setStartDate(moment().add(1, 'days'));
      setStartTime(moment('10:00:00 am', 'hh:mm:ss a'));
    } else {
      setStartDate(moment());
      setStartTime(moment());
    }
  }

  if (
    election_type === '6084478f9fefeb2c55b5fb39' &&
    moment(end).diff(moment(start), 'hours') < 24
  ) {
    setSnackbar("Poll duration can't be less than 24 hours", 'warning');
    setStartTime(moment('10:00:00 am', 'hh:mm:ss a'));
    setEndDate(moment(start).add(1, 'days'));
    setEndTime(moment('05:00:00 pm', 'hh:mm:ss a'));
  }
  if (
    poll_category === '609f7b77a05bd07565d31aa2' ||
    poll_category === '609f7b85a05bd07565d31aa3' ||
    poll_category === '609f7b98a05bd07565d31aa4'
  ) {
    if (moment(start).diff(moment(), 'hours') < 24) {
      setSnackbar("You can't use today's date for shareholder", 'warning');
      setStartDate(moment().add(1, 'days'));
      setStartTime(moment('10:00:00 am', 'hh:mm:ss a'));
    }
    if (
      poll_category === '609f7b98a05bd07565d31aa4' &&
      moment(end).diff(moment(start), 'days') !== 30
    ) {
      setSnackbar('In Postal Ballot, poll duration should be 3O days', 'warning');
      setEndDate(moment(start).add(30, 'days'));
      setEndTime(moment('05:00:00 pm', 'hh:mm:ss a'));
    } else if (
      poll_category !== '609f7b98a05bd07565d31aa4' &&
      moment(end).diff(moment(start), 'hours') !== 72
    ) {
      setSnackbar(
        'In AGM and EGM,  start date and end date difference must be 3 days',
        'warning'
      );
      setEndDate(moment(start).add(72, 'hours'));
      setStartTime(moment('10:00:00 am', 'hh:mm:ss a'));
      setEndTime(moment('05:00:00 pm', 'hh:mm:ss a'));
    }
  } else if (start_date && start_time && end_date && end_time) {

    if (moment(end).diff(start, 'minutes') <= 0) {
      !isLoading &&
        setSnackbar(
          'Poll end date cannot be smaller than or equal to start date.',
          'warning'
        );
      setEndDate(start_date);
      setEndTime(moment(start_time, 'hh:mm:ss a').add(1, 'hours'));
    }
  }
};
