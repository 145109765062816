import { makeStyles } from '@material-ui/core/styles';

const InviteMembersStyle = makeStyles((theme) => ({
  dashTitleheading: {
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '30px',
    lineHeight: '43px',
    // color: '#1171FF',
    color: 'white',
  },
  boldHeading: {
    fontWeight: '500',
    fontSize: '24px',
    marginBottom: '1.5rem',
  },

  uploadButton: {
    background: '#FFFFFF',
    border: '1px solid #0B2F64',
    boxSizing: 'border-box',
    borderRadius: '15px',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '15px',
    lineHeight: '22px',
    color: '#0B2F64',
    padding: '10px',
    paddingLeft: '1rem',
    paddingRight: '1rem',
  },

  subbtn: {
    background: '#1171FF',
    boxShadow: '0px 4px 20px rgba(11, 47, 100, 0.4)',
    paddingLeft: '4rem',
    paddingRight: '4rem',
    borderRadius: '10px',
  },

  addInviteppr: {
    border: '1px dashed ',
    borderRadius: '10px',
    padding: '10px',
  },

  labelText: {
    fontSize: '18px',
    lineHeight: '21px',
    marginBottom: '5px',
  },
  textFields: {
    background: '#FFFFFF',
    border: '1px solid #E5E5E5',
    boxShadow: 'inset 0px 0px 5px 1px rgba(0, 0, 0, 0.15)',
    borderRadius: '10px',
  },
  addUpdatebtn: {
    '& .MuiButtonBase-root': {
      background: '#0B2F64',
      boxShadow: '0px 4px 20px rgb(11 47 100 / 40%)',
      color: '#fff',
    },
  },

  AddPaper: {
    // '& .MuiPaper-root': {
    padding: '10px',
    background: '#FFFFFF',
    border: '1px solid #E5E5E5',
    // boxSizing: 'border-box',
    boxShadow: '0px 2px 5px 1px rgb(0 0 0 / 10%)',
    borderRadius: '10px',
    marginBottom: '1rem',
  },
}));

export default InviteMembersStyle;
