import {
  Container,
  VotingPageStyles,
  useContext,
  useHistory,
  SpinGif,
  Box,
  useState,
} from '../../collections/Imports';
import useAPIError from '../../hooks/useAPIError';
import ModalPagination from './ModalPagination';
import ImageCard from './ImageCard';
import OptionCard from './OptionCard';
import ButtonGrid from './ButtonGrid';
import QuestionGrid from './QuestionGrid';
import { SnackbarContext } from '../../providers/SnackBarContext';
import ButtonGridNew from './ButtonGridNew';
import OptionCardNew from './OptionCardNew';
import QuestionGridNew from './QuestionGridNew';
import OptionCardIBC from './OptionCardIBC';
// import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
// import CancelIcon from '@material-ui/icons/Cancel';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
const ModalContent = (props) => {
  const {
    totalShares,
    handleModalClose,
    questions,
    isLoading,
    setQuestions,
    voter,
    electionType,
  } = props;

  const { addError } = useAPIError();
  const { setSnackbar } = useContext(SnackbarContext);
  let history = useHistory();
  const classes = VotingPageStyles();

  const [showNextButton, setShowNextButton] = useState(false);
  const [page, setPage] = useState(1);
  const currentQuestion = questions[page - 1];
  const [shares, setShares] = useState(totalShares);
  const [valid, setValid] = useState(true);

  const computeShares = (newQuestions) => {
    const currentQuestion = newQuestions[page - 1];
    let sharesLeft = totalShares;
    currentQuestion.options.forEach((op) => {
      sharesLeft -= Number(op.shares || 0);
      setShares(sharesLeft);
    });
  };

  return isLoading ? (
    <Box
      style={{
        zIndex: 1000,
        textAlign: 'center',
        justifyContent: 'center',
      }}
    >
      <img src={SpinGif} alt="spinner" />
    </Box>
  ) : (
    <Container className={classes.paper}>
      <button style={{background:'none',border:'none', position:'relative',top:'0',right:'0'}} onClick={props.handleModalClose}><CancelOutlinedIcon style={{fontSize: '2.0rem',
color: 'red'}}/></button>

      {electionType === 2 || electionType === 3 ? (
        questions.map((question, index) => {
          return (
            <>
              <QuestionGridNew
                currentQuestion={question}
                index={index}
                electionType={electionType}
              />
              {question && question.question_file ? (
                <ImageCard currentQuestion={question} />
              ) : null}
              {electionType === 3 ? (
                <OptionCardNew
                  totalShares={totalShares}
                  setShowNextButton={setShowNextButton}
                  currentQuestion={question}
                  questions={questions}
                  setQuestions={setQuestions}
                  shares={shares}
                  computeShares={computeShares}
                  setValid={setValid}
                />
              ) : electionType === 2 ? (
                <OptionCardIBC
                  currentQuestion={question}
                  questions={questions}
                  setQuestions={setQuestions}
                  setValid={setValid}
                />
              ) : null}

              <ButtonGridNew
                valid={valid}
                questions={questions}
                currentQuestion={question}
                voter={voter}
                addError={addError}
                setSnackbar={setSnackbar}
                handleModalClose={handleModalClose}
                history={history}
                setQuestions={setQuestions}
              />
            </>
          );
        })
      ) : (
        <>
          <ModalPagination
            page={page}
            setPage={setPage}
            setShowNextButton={setShowNextButton}
            questions={questions}
          />
          {currentQuestion && currentQuestion.question_file ? (
            <ImageCard currentQuestion={currentQuestion} />
          ) : null}
          <QuestionGrid currentQuestion={currentQuestion} />
          <OptionCard
            currentQuestion={currentQuestion}
            electionType={electionType}
            setShowNextButton={setShowNextButton}
            questions={questions}
            page={page}
            setQuestions={setQuestions}
            totalShares={totalShares}
            shares={shares}
            setShares={setShares}
            computeShares={computeShares}
            setValid={setValid}
          />
          <ButtonGrid
            valid={valid}
            questions={questions}
            showNextButton={showNextButton}
            page={page}
            setPage={setPage}
            voter={voter}
            addError={addError}
            setSnackbar={setSnackbar}
            handleModalClose={handleModalClose}
            history={history}
            setShowNextButton={setShowNextButton}
            setQuestions={setQuestions}
          />
        </>
      )}
    </Container>
  );
};

export default ModalContent;
