// import { ArrowBackRounded } from '@material-ui/icons';
import {
  DashBoardStyles,
  Grid,
  CardContent,
  Container,
  Card,

  // NavLink,
  // Typography,
  // useState,
} from '../../collections/Imports';

// const dashTitle = [
//   { title: 'Create Poll', linkto: null, arrow: false },
//   { title: 'Add Questions', linkto: '/dashboard/mypoll', arrow: true },
//   { title: 'Invite Members', linkto: '/addQuestions', arrow: true },
// ];

const DashboardScreen = (props) => {
  const classes = DashBoardStyles();

  // const [dashNav, setdashNav] = useState({
  //   title: '',
  //   linkto: '',
  //   arrow: false,
  // });

  return (
    <Container maxWidth="lg">
      <Grid container alignItems="flex-start">
        <Grid item xs={12}>
          <Card className={classes.card}>
            <CardContent style={{ padding: '0px' }}>
              {props.children}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default DashboardScreen;
