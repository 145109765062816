import { makeStyles } from "@material-ui/core/styles";

const GetinStyles = makeStyles((theme) => ({
  main: {
    margin: "auto",
    width: "auto",
    padding: '20px',
    display: "block",

    [theme.breakpoints.up(400 + theme.spacing(3) * 2)]: {
      width: 470,
    },
  },
  Maindiv: {
    height: '110vh',
    backgroundColor: '#1171FF',
    padding: '20px',
    marginTop: '64px',
    [theme.breakpoints.down("sm")]: {
      padding: '10px',
    marginTop: '50px', 
    },

  },

  card: {
    margin: 'auto',
    background: "#fff",
    boxShadow: "0px 0px 20px rgba(57, 57, 57, 0.15)",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    padding: `${theme.spacing(5)}px ${theme.spacing(5)}px ${theme.spacing(3)}px`,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    marginTop: theme.spacing(3),
    background: "#1171FF",
    boxShadow: "0px 4px 20px rgb(11 47 100 / 40%)",
    borderRadius: "12px",
    padding: "12px",
    marginBottom: '2rem',
    fontWeight: '600'
  },
  inputfield: {
    background: "#FFFFFF",
    border: "1px solid #E5E5E5",
    boxSizing: "border-box",
    // boxShadow: "inset 0px 0px 5px 1px rgba(0, 0, 0, 0.15)",
    borderRadius: "10px",
    '& .MuiOutlinedInput-input': {
      padding: '10.5px 14px',
    }
  },
  LoginHeading: {
    marginTop: "30px",
    textAlign: "center",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "22px",
    lineHeight: "43px",
    color: "#414448",
  },
  InputLabel: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "15px",
    lineHeight: "21px",
    color: "#414448",
  },
}));

export default GetinStyles;
