import { createMuiTheme } from '@material-ui/core';
// import BackLogo from '../src/img/Logo-Mark-1.svg';

const theme = createMuiTheme({
  palette: {
    background: {
      // default: '#E5E5E5',
      default: '#000',
    },
  },
  typography: {
    fontFamily: 'Barlow',
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          // backgroundImage: `url(${BackLogo})`,
          backgroundRepeat: 'no-repeat',
          // minHeight: '1200px',
        },
      },
    },
  },
});

export default theme;
