import onClickHandlers from './OnClickHandlers';
import {
  Grid,
  Button2,
  Box,
  Modal,
  Backdrop,
  VotingPageStyles,
  useState,
  IconButton,
} from '../../collections/Imports';
import PreviewModal from './PreviewModal';
import ReplayIcon from '@material-ui/icons/Replay';
import Tooltip from '@material-ui/core/Tooltip';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ConfirmDialog from '../../AlertNotification/ConfirmDialog'
const ButtonGrid = (props) => {
  const classes = VotingPageStyles();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
  });

  const {
    questions,
    showNextButton,
    page,
    setPage,
    voter,
    addError,
    setSnackbar,
    handleModalClose,
    history,
    setShowNextButton,
    setQuestions,
    valid,
  } = props;

  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      spacing={2}
      style={{ paddingBottom: '100px' }}
    >
      {questions.length === 1 ? (
        <Button2
          disabled={!valid}
          onClick={() => {
            setConfirmDialog({
              isOpen: true,
              title: 'Are you sure to Submit Your Vote ?',
              subTitle: "You can't undo this operation",
              onConfirm: () => {
                onClickHandlers.onVoteClick({
                  handleModalClose,
                  voter,
                  questions,
                  addError,
                  setSnackbar,
                  history,
                  setLoading
                })
              },
            });
          }}
          width="150px"
        >
          SUBMIT
        </Button2>
      ) : (
        <>
          <Grid item>
            {page !== 1 ? (
              <Tooltip title="Previous Question" placement="left">
                <IconButton
                  aria-label="Previous Question"
                  onClick={() =>
                    onClickHandlers.onBackClick({
                      setPage,
                      showNextButton,
                      setShowNextButton,
                      page,
                    })
                  }
                >
                  <ArrowBackIcon
                    style={{ color: '#0b2f64', width: '30px', height: '30px' }}
                  />
                </IconButton>
              </Tooltip>
            ) : null}
          </Grid>
          <Grid item>
            <Tooltip title="Reset Response" placement="right">
              <IconButton
                aria-label="Reset Response"
                onClick={() =>
                  onClickHandlers.onSkipClick({
                    setPage,
                    setShowNextButton,
                    page,
                    questions,
                    setQuestions,
                  })
                }
              >
                <ReplayIcon
                  style={{ color: '#0b2f64', width: '30px', height: '30px' }}
                />
                Reset
              </IconButton>
            </Tooltip>
          </Grid>

          <Grid item>
            {page !== questions.length ? (
              <Tooltip title="Next Question" placement="top">
                <IconButton
                  type="submit"
                  onClick={() =>
                    onClickHandlers.onNextClick({
                      setPage,
                      setShowNextButton,
                      page,
                    })
                  }
                >
                  <ArrowForwardIcon
                    style={{ color: '#0b2f64', width: '30px', height: '30px' }}
                  />
                </IconButton>
              </Tooltip>
            ) : null}
          </Grid>
          <Grid item>
            {questions.length === page ? (
              <Box>
                <Button2 onClick={() => setOpen(true)} width="150px">
                  Preview
                </Button2>
                <Modal
                  className={classes.modal}
                  open={open}
                  onClose={handleModalClose}
                  disableBackdropClick={true}
                  BackdropComponent={Backdrop}
                >
                  <PreviewModal
                    questions={questions}
                    PreviewModalClose={() => setOpen(false)}
                  />
                </Modal>
              </Box>
            ) : null}
          </Grid>

          <Grid item>
            {questions.length === page ? (
              <Button2
                disabled={!valid}

                onClick={() => {
                  setConfirmDialog({
                    isOpen: true,
                    title: 'Are you sure to Submit Your Vote ?',
                    subTitle: "You can't undo this operation",
                    onConfirm: () => {
                      onClickHandlers.onVoteClick({
                        handleModalClose,
                        voter,
                        questions,
                        addError,
                        setSnackbar,
                        history,
                        setLoading
                      })
                    },
                  });
                }}
                width="150px"
              >
                SUBMIT
              </Button2>
            ) : null}
          </Grid>
        </>
      )}

      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
        loading={loading}
        setLoading={setLoading}
      />
    </Grid>
  );
};

export default ButtonGrid;
