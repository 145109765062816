import {
  useState,
  useContext,
  useParams,
  Typography,
  Button,
  FormControl,
  TextField,
  Card,
  Grid,
  React,
  GlobalContext,
  useEffect,
  axiosPost,
  Box,
  SpinGif,
  NavLink,
  moment,
  CardContent,
} from "../../collections/Imports";
import useAPIError from "./../../hooks/useAPIError";
import GetinStyles from "./GetinStyles";
import Logo from "../../../../src/img/logo-f.png";
import { SnackbarContext } from "../../providers/SnackBarContext";
import { Helmet } from "react-helmet";

const Getin = (props) => {
  const { addError } = useAPIError();
  const params = useParams();
  const classes = GetinStyles();

  const { setSnackbar } = useContext(SnackbarContext);
  const [appData, setAppData] = useContext(GlobalContext);

  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [poll_id, setPollId] = useState(null)
  const [voter_id, setVoterId] = useState(null)

  const [enableLoading, setEnableLoading] = useState(true);
  const [casted_vote, setCastedVote] = useState(false);
  const [name, setName] = useState(null);

  const [clickable, setClickable] = useState(false);
  const [seconds, setSeconds] = useState(null);
  const [notStarted, setNotStarted] = useState(false);
  const [pollClosed, setPollClosed] = useState(false);
  const [pollStart, setPollStart] = useState(null);

  const [daysDiff, setDaysDiff] = useState(0);
  const [hoursDiff, setHoursDiff] = useState(0);
  const [remMinutes, setRemMinutes] = useState(null);
  const [remSeconds, setRemSeconds] = useState(null);
  const [showGetOTP, setShowGetOTP] = useState(true)
  const [buttonLoading, setButtonLoading] = useState(false)

  const handleChange = (e) => {
    if (e.target.id === "otp") setOtp(e.target.value);
  };

  useEffect(() => {
    setAppData({
      ...appData,
      is_voter: true,
    });
  }, []);
  // Resent Otp Code start

  const makeOtpInvalid = () => {
    const email = appData.voter_email;
    axiosPost("api/auth/signin/make-otp-invalid", { email })
      .then((result) => {
        console.log("result : ", result);
      })
      .catch((err) => {
        console.log("Err : ", err);
      });
  };

  const resendOtp = () => {
    setClickable(false);
    axiosPost("api/auth/voter/reset/otp", { email })
      .then((res) => {
        setOtp("");
        resetOtp(60);
        setSnackbar("OTP resent to your mail", "info");
      })
      .catch((err) => {
        addError(`${err.name}: ${err.message}`);
      });
  };

  const resetOtp = (seconds) => {
    if (seconds > 0) {
      setTimeout(() => setSeconds(seconds - 1), 1000);
    } else {
      makeOtpInvalid();
      setSeconds(0);
      setClickable(true);
    }
  };
  useEffect(() => {
    if (seconds > 0) {
      setTimeout(() => setSeconds(seconds - 1), 1000);
    } else if (seconds !== null) {
      makeOtpInvalid();
      setSeconds(0);
      setClickable(true);
    }
  }, [seconds]);

  const handleOnSubmit = (e) => {
    e.preventDefault();
    if (showGetOTP) {
      setButtonLoading(true)
      axiosPost('api/auth/voter/get-otp', { voter_id })
        .then((res) => {
          setButtonLoading(false)
          if (res && res.code === 'otp_sent') {
            setShowGetOTP(false);
            setSeconds(60)
            setSnackbar("OTP is sent to your email.", "info");
          } else if (res && res.code === 'invalid_voter') {
            setSnackbar("Voter details not found.", "error");
          } else {
            setSnackbar("Server error. Please, try again!!!", "error");
          }
        })
        .catch((err) => {
          setButtonLoading(false)
          addError(`${err.name}: ${err.message}`);
        })
    } else {
      axiosPost("api/auth/voter/login", { email, otp, voter_id })
        .then((res) => {
          if (res.status === 200 && res.code === "success") {
            const { voter, token } = res;
            localStorage.setItem("voter", JSON.stringify(voter));
            localStorage.setItem("token", token);

            setAppData({
              ...appData,
              token: token,
              voter_name: voter.name,
              voter_email: voter.email,
              voter_id: voter._id,
              from_location: "/getin",
              to_location: "/welcome",
            });
            props.history.push("/welcome");
          } else if (res.code === "otp_expired") {
            setSnackbar("OTP Expired. Please, Click on Resend", "error");
          } else {
            setSnackbar("Invalid credentials. Please, try again!!!", "error");
          }
        })
        .catch((err) => {
          setSnackbar("Something went wrong. Please, try again!!!", "error");
        });
    }

  };

  useEffect(() => {
    const { link_id } = params;
    localStorage.clear();
    axiosPost("api/auth/voter/verify/token", { link_id })
      .then((res) => {
        if (res.code === "poll_closed") {
          setPollClosed(true);
        } else if (res.code === "not_started") {
          let start = moment(
            moment(res.start_date).format("YYYY.MM.DD") + " " + res.start_time,
            moment.defaultFormat
          ).format();

          setPollStart(start);
          setNotStarted(true);
          let hoursDiff = moment(start).diff(moment(), "hours");
          let daysDiff = moment(start).diff(moment(), "days");
          let minutesDiff = moment(start).diff(moment(), "minutes");

          setDaysDiff(daysDiff);
          setHoursDiff(hoursDiff);
          setRemMinutes(minutesDiff);
        } else if (res.code === "casted_vote") {
          let { name } = res;
          setName(name);
          setCastedVote(true);
          localStorage.clear()
        } else if (res.code === "TokenExpiredError") {
          setSnackbar("Your link is expired", "warning");
          localStorage.clear();
          props.history.push("/");
        } else if (res.code === "unauthorised") {
          setSnackbar(
            "You are not authorised. Redirecting to login page.",
            "warning"
          );
          localStorage.clear();
          props.history.push("/");
        } else if (res && res.decoded) {
          const { decoded, token } = res;
          console.log("Res : ", res)
          const { voter, poll } = decoded;
          setEmail(voter.email);
          setPollId(decoded.poll_id)
          setVoterId(voter._id)
          localStorage.setItem("selected_poll_id", decoded.poll_id);
          localStorage.setItem("voter", JSON.stringify(voter));
          localStorage.setItem("token", token);
          setAppData({
            ...appData,
            selected_poll_id: decoded.poll_id,
            voter_email: voter.email,
            voter_name: voter.name,
            voter_id: voter._id,
            poll
          });
          // setSnackbar("OTP is sent to your email.", "info");
          setShowGetOTP(true)
        }
        setEnableLoading(false);
      })
      .catch((err) => {
        addError(`${err}`);
        setEnableLoading(false);
      });
  }, []);

  useEffect(() => {
    if (notStarted && pollStart) {
      let hoursDiff = moment(pollStart).diff(moment(), "hours");
      let daysDiff = moment(pollStart).diff(moment(), "days");
      let minutesDiff = moment(pollStart).diff(moment(), "minutes");
      setDaysDiff(daysDiff);
      setHoursDiff(hoursDiff);
      setRemMinutes(minutesDiff);
    }
  }, [notStarted, pollStart]);

  useEffect(() => {
    if (daysDiff === 0 && hoursDiff === 0) {
      setRemMinutes(moment(pollStart).diff(moment(), "minutes"));
      setRemSeconds(moment(pollStart).diff(moment(), "seconds") % 60);
    }
    setTimeout(
      () => setRemSeconds(moment(pollStart).diff(moment(), "seconds") % 60),
      1000
    );
  }, [daysDiff, hoursDiff, remMinutes, remSeconds]);

  useEffect(() => {
    if (remMinutes === 0 && remSeconds === 0) window.location.reload(false);
    else if (remMinutes === 0)
      setSnackbar("Please, refresh page for login.", "info");
  }, [remSeconds]);

  return (
    <Box className={classes.Maindiv}>
       <Helmet>
        <meta charSet="utf-8" />
        {/* <title>EazyVC Voting Platform - Contact US</title> */}
        <meta
          name="title"
          content="The easy way to Vote Online on EazyVC’s Blockchain-secured Voting Platform."
        />
        <meta
          name="description"
          content="EazyVC is a Blockchain-based eVoting platform for Corporates, Government Bodies, Education Institutions, Associations & Clubs, Housing Societies, NGO, Trusts and more."
        />
      </Helmet>
      <Grid item xs={12} sm={12}>
        <Box className={classes.main}>
          <Card className={classes.card}>
            <img
              src={Logo}
              alt="Logo"
              style={{ width: "100px", height: "auto", margin: "auto" }}
            ></img>
            <Typography
              className={classes.LoginHeading}
              component="h1"
              variant="h5"
            >
              Welcome to EazyVC
            </Typography>
            <Typography style={{ textAlign: "center", fontSize: '15px', fontFamily: 'inherit', fontWeight: '500' }} variant="caption">
              The most secure platform for online voting.
            </Typography>
            <form className={classes.form} onSubmit={handleOnSubmit}>
              {enableLoading ? (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  align="center"
                  justify="center"
                  style={{ margin: "auto" }}
                >
                  <img src={SpinGif} alt="spinner" />
                </Grid>
              ) : casted_vote ? (
                <Box>
                  <Typography
                    variant="h5"
                    gutterBottom
                    style={{
                      textAlign: "center",
                      fontWeight: 500,
                      color: "#414448",
                    }}
                  >
                    Hi, <span style={{ color: "#1171FF" }}>{name}</span>
                  </Typography>

                  <Typography
                    variant="h6"
                    gutterBottom
                    style={{
                      textAlign: "center",
                      fontWeight: 500,
                      color: "#fd5c63",
                    }}
                  >
                    Your Vote has already been cast
                  </Typography>
                </Box>
              ) : notStarted || pollClosed ? (
                <>
                  {notStarted ? (
                    <CardContent>
                      <Typography
                        style={{
                          textAlign: "center",
                          margin: "0 auto",
                          display: "flex",
                          justifyContent: "center",
                          fontSize: 18,
                        }}
                        variant="caption"
                        gutterBottom
                      >
                        Your voting session will start in....
                      </Typography>
                      <Typography
                        style={{
                          marginTop: "10px",
                          textAlign: "center",
                          fontWeight: 500,
                          color: "#414448",
                        }}
                        variant="h5"
                      >
                        {parseInt(daysDiff) || parseInt(hoursDiff)
                          ? parseInt(daysDiff) +
                          " Days & " +
                          parseInt(hoursDiff) +
                          "Hours"
                          : "" + remMinutes + ":" + remSeconds}
                      </Typography>
                    </CardContent>
                  ) : (
                    <CardContent>
                      <Typography
                        style={{
                          marginTop: "10px",
                          textAlign: "center",
                          fontWeight: 500,
                          color: "red",
                        }}
                        variant="h5"
                      >
                        Sorry, Poll is closed
                      </Typography>
                    </CardContent>
                  )}
                </>
              ) : (
                <>
                  <FormControl
                    margin="normal"
                    required
                    fullWidth
                    style={{ textAlign: "left" }}
                  >
                    <Typography className={classes.InputLabel}>
                      User ID
                    </Typography>
                    <TextField
                      className={classes.inputfield}
                      placeholder="Enter Text Here"
                      variant="outlined"
                      id="email"
                      value={email}
                      disabled
                    />
                  </FormControl>

                  {
                    !showGetOTP &&
                    <FormControl
                      margin="normal"
                      required
                      fullWidth
                      style={{ textAlign: "left" }}
                    >
                      <Typography className={classes.InputLabel}>OTP </Typography>
                      <TextField
                        className={classes.inputfield}
                        placeholder="Enter OTP"
                        variant="outlined"
                        id="otp"
                        value={otp}
                        onChange={handleChange}
                      />
                    </FormControl>
                  }

                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    disabled={buttonLoading}
                  >
                    {showGetOTP ? 'GET OTP' : 'GET IN'}
                  </Button>
                </>
              )}
            </form>
            {!enableLoading && !casted_vote ? (
              <Grid container justify="space-between" alignItem="center">
                <Grid item align="center">
                  <Typography variant="p">
                    {seconds > 0 ? `OTP Expire in ${seconds} sec` : null}
                  </Typography>
                </Grid>
                <Grid item>
                  {clickable && (
                    <NavLink to="#" onClick={() => resendOtp()}>
                      Resend OTP
                    </NavLink>
                  )}
                </Grid>
              </Grid>
            ) : null}
          </Card>
        </Box>
      </Grid>
    </Box>
  );
};

export default Getin;
