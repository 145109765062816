import { AddCircleRounded } from '@material-ui/icons';
import React, { useContext, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import moment from 'moment';

import {
  AddQuestionStyles,
  axiosGet,
  axiosPost,
  axiosPatch,
  Button2,
  Button,
  Box,
  FormGroup,
  Grid,
  Paper,
  TableCell,
  TableRow,
  TextField,
  Typography,
  DashBoardStyles,
  Trash,
  UploadIcon,
  QuestionTitle,
  Input,
  CkEditor,
  Avatar,
  AttachmentIcon,
  Chip,
  CircularProgress,
} from '../../collections/Imports';
import useAPIError from '../../hooks/useAPIError';

import PageviewIcon from '@material-ui/icons/Image';
import ConfirmDialog from '../../AlertNotification/ConfirmDialog';
import { SnackbarContext } from '../../providers/SnackBarContext';
const AddQuestion = (props) => {
  const [buttonLoading, setButtonLoading] = useState(false);
  const { addError } = useAPIError();
  const { setSnackbar } = useContext(SnackbarContext);
  const classes = DashBoardStyles();
  const classes1 = AddQuestionStyles();
  const [showForm, setShowForm] = useState(false);
  const [election_type_id, setElectionTypeId] = useState(0);
  const [option_images, setOptionImages] = useState([false]);
  const [edit_flag, setEditFlag] = useState(false);
  const [selected_question, setSelectedQuestion] = useState(null);
  const [question_options, setQuestionOptions] = useState(['', '']);
  const [question_options_IBC, setQuestionOptionsIBC] = useState(['Yes', 'No', 'Abstain']);
  const [show_file, setShowFile] = useState('');

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
  });

  let [uploadedImg, setUploadedImg] = useState([]);

  var poll_id = localStorage.getItem('selected_poll_id');

  const [addQuestion, setAddQuestion] = useState({
    question: '',
    questionType: 1,
    question_type_id: 1,
    editQuestionId: '',
    images: '',
    question_file: '',
    newQuestionFlag: false,
    isSkippable: false,
  });

  const checkConditions = ({ question, options, questionType }) => {

    if (question === null || question === undefined || question === '' || (question && question.trim().length < 1)) {
      setSnackbar('Question is required', 'error');
      return false
    }

    if (options.length < 2) {
      setSnackbar('Atleast two options required');
      return false
    }
    if (questionType !== 3 || questionType !== 5) {
      for (let i = 0; i < options.length; i++) {
        let option = options[i]
        if (option === null || option === undefined || option === '' || (option && option.trim().length < 1)) {
          if (i < 2) {
            setSnackbar('Atleast two options required', 'info');
            return false
          }
        }
      }
    }
    return true;
  }

  const onAddQuestion = () => {
    setButtonLoading(true);
    console.log("Election --", election_type_id)
    var { question, questionType, isSkippable, question_type_id } = addQuestion
    question = question.replace(/<!--.*?-->/g, '');
    let options = question_options;
    if (election_type_id == 2 || election_type_id == 3 || election_type_id == 6) {
      options = question_options_IBC
    }
    if (checkConditions({ question, options, questionType })) {
      var formData = new FormData();
      let question_file = document.querySelector('#question_file');
      // setButtonLoading(false)
      if (question_file) {
        formData.append('question_file', question_file.files[0]);
      }
      if (election_type_id !== 2 && election_type_id !== 3 && election_type_id !== 6) {
        for (let i = 0; i < options.length; i++) {
          let imagefile = document.querySelector('#raised-button-file' + i);
          formData.append('file', imagefile.files[0]);
          formData.append('options', options[i]);
        }
      } else {
        options = ['Yes', 'No', 'Abstain'];
        for (let i = 0; i < options.length; i++) {
          formData.append('options', options[i]);
        }
      }
      formData.append('question', question);
      formData.append('questionType', questionType);
      formData.append('question_type_id', question_type_id);
      formData.append('poll_id', poll_id);
      formData.append('isSkippable', isSkippable);

      if (options && options.length > 1) {
        axiosPost('api/question', formData, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'multipart/form-data',
          },
        })
          .then((result) => {
            setButtonLoading(false);
            if (result) {
              setAddQuestion({
                question: '',
                questionType: null,
                question_type_id: null,
                editQuestionId: '',
                images: [],
                question_file: [],
              });
              setShowForm(false);
              setQuestionOptions(['', '']);
              props.setQuestionChanged(true);
            }
          })
          .catch((err) => {
            setButtonLoading(false);
          });
      }
    } else {
      setButtonLoading(false);
    }
  };

  const handleChange = (event) => {
    setAddQuestion({ ...addQuestion, [event.target.name]: event.target.value });
    event.stopPropagation();
    event.preventDefault();
  };

  useEffect(() => {
    let selected_poll_id = localStorage.getItem('selected_poll_id');
    if (selected_poll_id) {
      axiosGet('api/polls/id/' + selected_poll_id)
        .then((result) => {
          if (result) {
            const { poll } = result;
            const { election_type } = poll;
            setElectionTypeId(parseInt(election_type.election_type_id));
          }
        })
        .catch((err) => {
          console.log('Error fetching poll : ', err);
        });
    }
  }, []);

  const handleOptionChange = (event, index) => {
    var prevState = question_options;

    prevState[index] = event.target.value;
    question_options[index] = event.target.value;
    setQuestionOptions([...prevState]);
  };

  const handleAddOption = () => {
    let prevState = question_options;
    prevState.push('');
    setQuestionOptions([...prevState]);
  };

  const onEditQuestion = () => {
    setButtonLoading(true);
    const id = props.selected_question;
    var question = addQuestion.question;
    question = question.replace(/<!--.*?-->/g, '');
    const questionType = addQuestion.questionType;
    var options = question_options;
    const isSkippable = addQuestion.isSkippable
    var formData = new FormData();
    let question_file = document.querySelector('#question_file')

    if (election_type_id == 2 || election_type_id == 3 || election_type_id == 6) {
      options = question_options_IBC
    }

    if (checkConditions({ question, options, questionType })) {
      if (question_file) {
        formData.append('question_file', question_file?.files[0]);
      }

      if (election_type_id !== 2 && election_type_id !== 3 || election_type_id !== 6) {
        for (let i = 0; i < options.length; i++) {
          let imagefile = document.querySelector('#raised-button-file' + i);
          formData.append('file', imagefile.files[0]);
          formData.append('options', options[i]);
        }
      } else {
        options = ['Yes', 'No', 'Abstain'];
        for (let i = 0; i < options.length; i++) {
          formData.append('options', options[i]);
        }
      }
      formData.append('question', question);
      formData.append('questionType', questionType);
      formData.append('poll_id', poll_id);
      formData.append('isSkippable', isSkippable);

      if (options && options.length > 1) {
        axiosPatch(`api/question/${id}`, formData)
          .then((result) => {
            setButtonLoading(false)
            if (result) {
              setAddQuestion({
                ...addQuestion,
                question: '',
                questionType: '',
                question_type_id: null,
                images: '',
                question_file: '',
                id: '',
                isSkippable: false,
                newQuestionFlag: !addQuestion.newQuestionFlag,
              });
              setQuestionOptions(['', '']);
              setEditFlag(true);
              setShowForm(false);
              props.setSelectedQuestion(null);
              props.setQuestionChanged(true);
            } else {
              addError(`Error in updating quetsion. Please try again.`);
            }
          })
          .catch((err) => {
            setButtonLoading(false)
            addError(`${err}`);
          });
      }
    } else {
      setButtonLoading(false);
    }
  };

  const onDiscardHandle = () => {
    setShowForm(false);
    props.setShowForm(false);
    setAddQuestion({
      ...addQuestion,
      question: '',
      questionType: '',
      question_type_id: null,
      id: '',
      images: '',
      question_file: '',
      isSkippable: false,
    });
    setQuestionOptions(['', '']);
    // setSelectedQuestion(null)
    props.setSelectedQuestion(null);
  };

  const handleDeleteOption = (index) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    const tempArray = question_options;
    if (tempArray.length > 2) {
      tempArray.splice(index, 1);
      setQuestionOptions(tempArray);
    }
    let new_option_image = uploadedImg;
    if (index > -1) {
      while (new_option_image[index]) {
        new_option_image[index] = new_option_image[index + 1];
        index++;
      }
    }
    setOptionImages(new_option_image);
    setUploadedImg(new_option_image);
    setSnackbar('Option Deleted Successfully.', 'success');
  };

  const onChangeMenu = (value) => {
    setAddQuestion({ ...addQuestion, questionType: value });
  };

  const onChangeCkEditor = (value) => {
    value = value.replace(/&lt;!--.*?--&gt;/g, '');
    setAddQuestion({ ...addQuestion, question: value });
  };

  const uploadFile = (e, index) => {
    var reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    let ImageData = uploadedImg;

    reader.onloadend = function (e) {
      ImageData = { ...ImageData, [index]: reader.result };
      setUploadedImg(ImageData);
    };

    setAddQuestion({ ...addQuestion, images: uploadedImg });
  };

  const onChangeSkippable = (value) => {
    setAddQuestion({
      ...addQuestion,
      isSkippable: value,
    });
  };

  useEffect(() => {
    // console.log('props.selected_question  : ', props.selected_question);
    if (props.selected_question !== selected_question) {
      let id = props.selected_question;
      setSelectedQuestion(id);
      if (id) {
        // console.log('Working question get');
        axiosGet(`api/question/${id}`)
          .then(async (result) => {
            if (result) {
              const data = result.question;
              console.log("Data : ", data)
              var { question_file } = result.question;
              if (question_file && typeof question_file.file !== undefined) {
                setShowFile(question_file);
              } else {
                setShowFile('');
              }

              setAddQuestion({
                ...addQuestion,
                question: data.question,
                questionType: data.questionType,
                question_file: data.question_file,
                question_type_id: data.questionType.question_type_id,
                images: data.option_images,
                editQuestionId: data._id,
                isSkippable: false,
              });
              console.log("setAddQuestion : ", addQuestion)
              setQuestionOptions(data.options);
              setEditFlag(true);

              let newImageData = [];
              for (let index = 0; index < data.option_images.length; index++) {
                let image_buffer = data.option_images[index].img.data.data;
                let image_src = URL.createObjectURL(
                  new Blob([new Uint8Array(image_buffer)], {
                    type: 'image/png',
                  })
                );
                newImageData.push(image_src);
              }
              setOptionImages(newImageData);

              setShowForm(true);
            } else {
              addError(`Error fetching question data`);
            }
          })
          .catch((err) => {
            addError(`LOAD_DATA_ERROR: ${err}`);
          });
      } else {
        setShowForm(false);
        props.setShowForm(false);
      }
    }
  }, [props.selected_question]);

  const handleDelete = () => {
    setShowFile(null);
  };

  const handleAddMoreQuestion = () => {
    setEditFlag(false);
    setAddQuestion({
      question: '',
      questionType: null,
      question_type_id: null,
      editQuestionId: '',
      images: '',
      question_file: '',
      newQuestionFlag: false,
      isSkippable: false,
    });
    setOptionImages(['', '']);
    setUploadedImg([]);
    setShowFile('');
    props.setSelectedQuestion(null);
    setShowForm(true);
  };
  return (
    <Grid item>
      {/* <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={open}
      >
        <Alert severity="error">{message}</Alert>
      </Snackbar> */}
      {(props.show_form || showForm) && (
        <FormGroup className={classes.root} autoComplete="on">
          <Paper className={classes1.addQuestionfromPaper}>
            <Grid
              container
              style={{
                marginLeft: '10px',
                marginTop: '5px',
                marginBottom: '5px',
              }}
            >
              <Grid item xs={12} sm={8}>
                <Typography className={classes1.questionlabel}>
                  Question
                </Typography>
                <CkEditor
                  value={addQuestion.question}
                  onChangeCkEditor={onChangeCkEditor}
                />
              </Grid>
              <QuestionTitle
                onChangeMenu={onChangeMenu}
                onChangeSkippable={onChangeSkippable}
                question_type_id={addQuestion.question_type_id}
              />
            </Grid>
            {show_file ? (
              // <TextField value={addQuestion.question_file} />
              <Box style={{ marginLeft: '10px' }}>
                <Chip
                  icon={<AttachmentIcon />}
                  label={show_file.name}
                  onClick={() => console.log(show_file.name)}
                  onDelete={handleDelete}
                  color="secondary"
                />
              </Box>
            ) : (
              <Box style={{ marginLeft: '10px' }}>
                <Input
                  className={classes.fileInput}
                  style={{
                    padding: '10px',
                    border: '2px solid #d4d4d4',
                    borderRadius: '5px',
                  }}
                  inputProps={{ accept: 'application/pdf' }}
                  name="question_file"
                  disableUnderline={true}
                  id="question_file"
                  type="file"
                  onChange={handleChange}
                />
                <span style={{ paddingLeft: '5px', fontSize: '16px' }}>
                  Optional
                </span>
              </Box>
            )}

            <Grid container style={{ marginTop: '20px' }}>
              <Grid
                item
                xs={12}
                sm={12}
                style={{ marginLeft: '10px', marginRight: '10px' }}
              >
                <Typography style={{ marginLeft: '2px' }}>Options</Typography>
                {question_options && (election_type_id == 3 || election_type_id == 2 || election_type_id == 6) && (
                  <Box>
                    {question_options_IBC.map((q, index) =>
                      <Paper
                        key={index}
                        className={classes1.questionPapercell}
                      >
                        <Grid
                          container
                          justify="center"
                          alignItems="center"
                          style={{ padding: '1rem' }}
                          spacing={2}
                        >
                          <Grid>
                            <Box
                              style={{
                                color: '#fff',
                                background: 'gray',
                                fontWeight: '500'
                                , borderRadius: '50%',
                                textAlign: 'center'
                                , border: '1px solid gray',
                                fontSize: '1rem',
                                width: '25px', display: 'flex', alignItems: 'center',
                                height: '25px', justifyContent: 'center'
                              }}
                            >{String.fromCharCode(65 + index)}
                            </Box>

                          </Grid>
                          <Grid item sm={8}>
                            <Box className={classes1.tablecelltext}>
                              <input
                                value={question_options_IBC[index]}
                                className={classes1.inputcell}
                                disabled
                              />
                            </Box>
                          </Grid>
                          <Grid item sm={3}>
                          </Grid>
                        </Grid>
                      </Paper>
                    )}
                  </Box>
                )}
                {question_options.length > 0 && election_type_id !== 3 &&
                  election_type_id !== 2 && election_type_id !== 6
                  ? question_options.map((value, index) => {
                    return (
                      <Paper
                        key={index}
                        className={classes1.questionPapercell}
                      >
                        <Grid
                          container
                          justify="center"
                          alignItems="center"
                          style={{ padding: '1rem' }}
                          spacing={2}
                        >
                          <Grid>
                            <Box
                              style={{
                                color: '#fff',
                                background: 'gray',
                                fontWeight: '500'
                                , borderRadius: '50%',
                                textAlign: 'center'
                                , border: '1px solid gray',
                                fontSize: '1rem',
                                width: '25px', display: 'flex', alignItems: 'center',
                                height: '25px', justifyContent: 'center'
                              }}
                            >{String.fromCharCode(65 + index)}
                            </Box>

                          </Grid>
                          <Grid item sm={8}>
                            <Box className={classes1.tablecelltext}>
                              <input
                                value={question_options[index]}
                                className={classes1.inputcell}
                                onChange={(event) =>
                                  handleOptionChange(event, index)
                                }
                              />
                            </Box>
                          </Grid>
                          <Grid item sm={1}>
                            <Box className={classes1.tabledeleteicon}>
                              {question_options.length !== 2 ? (
                                <Box>
                                  <Button
                                    onClick={() => {
                                      setConfirmDialog({
                                        isOpen: true,
                                        title:
                                          'Are you sure to delete this option ?',
                                        subTitle:
                                          "You can't undo this operation",
                                        onConfirm: () => {
                                          handleDeleteOption(index);
                                        },
                                      });
                                    }}
                                  >
                                    <Trash />
                                  </Button>
                                </Box>
                              ) : null}
                            </Box>
                          </Grid>
                          <Grid item sm={1}>
                            <Box className={classes1.tablepublishicon}>
                              <Avatar
                                src={
                                  option_images[index]
                                    ? option_images[index]
                                    : uploadedImg[index]
                                }
                                style={{ width: '50px', height: '50px' }}
                              >
                                <PageviewIcon />
                              </Avatar>
                            </Box>
                          </Grid>
                          <Grid item sm={1}>
                            <Box className={classes1.tablepublishicon}>
                              <TextField
                                accept=".png, .jpg, .jpeg"
                                inputProps={{ accept: 'image/*' }}
                                className={classes.input}
                                style={{ display: 'none' }}
                                id={`raised-button-file${index}`}
                                multiple
                                type="file"
                                onChange={(e) => uploadFile(e, index)}
                              />
                            </Box>
                            <Box className={classes1.tablepublishiconLabel}>
                              <label htmlFor={`raised-button-file${index}`}>
                                <UploadIcon style={{ color: '#1171FF' }} />
                              </label>
                            </Box>
                          </Grid>
                        </Grid>
                      </Paper>
                    );
                  })
                  : null}
              </Grid>
            </Grid>
            {election_type_id !== 3 && election_type_id != 2 ? (
              <Grid container style={{ marginTop: '10px', padding: '10px' }}>
                <Grid item xs={12}>
                  <Paper
                    component="div"
                    justify="center"
                    align="center"
                    variant="outlined"
                    elevated={3}
                    className={classes1.paperbg}
                  >
                    <Button
                      onClick={() => {
                        handleAddOption();
                      }}
                    >
                      <AddCircleRounded
                        style={{ marginRight: 10, color: '#1171FF' }}
                      />
                      Add More Options
                    </Button>
                  </Paper>
                </Grid>
              </Grid>
            ) : null}

            <Grid
              container
              style={{
                paddingLeft: '20px',
                paddingRight: '20px',
                marginBottom: '5px',
                paddingTop: '5px',
              }}
              alignItems="center"
            >
              <Grid item xs={12} sm={9} style={{ color: 'red' }}>
                <Button
                  style={{ color: '#007bff', marginTop: '20px' }}
                  onClick={() => {
                    onDiscardHandle();
                  }}
                >
                  {'DISCARD'}
                </Button>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Button
                  disabled={buttonLoading}
                  className={classes1.Addbtn}
                  onClick={() => {
                    edit_flag ? onEditQuestion() : onAddQuestion();
                  }}
                  variant="contained"
                >
                  {buttonLoading && (
                    <CircularProgress
                      size={20}
                      style={{ color: '#000', marginRight: '5px' }}
                    />
                  )}
                  {buttonLoading && (
                    <Box component="span" style={{ color: '#000', fontWeight: '600' }}>
                      {' '}
                      please wait
                    </Box>
                  )}
                  {!buttonLoading && (
                    <Box>{edit_flag ? 'SAVE' : 'ADD'}</Box>
                  )}
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </FormGroup>
      )}

      {!showForm && (
        <Paper
          component="div"
          justify="center"
          align="center"
          variant="outlined"
          elevated={3}
          className={classes1.paperbg}
        >
          <Button
            onClick={() => {
              handleAddMoreQuestion();
            }}
          >
            <Typography>
              <AddCircleRounded
                style={{ color: '#1171FF', padddingRight: '1rem' }}
              />
            </Typography>
            {/* <Typography>ADD MORE QUESTION</Typography> */}
            <Typography style={{ fontWeight: '500' }}>ADD QUESTION</Typography>
          </Button>
        </Paper>
      )}

      {props.questions_data && props.questions_data.length > 0 ? (
        <Box align="center" style={{ marginTop: '10px' }}>
          <NavLink to="/dashboard/invite" style={{ textDecoration: 'none' }}>
            <Button2 variant="contained">PROCEED</Button2>
          </NavLink>
        </Box>
      ) : null}

      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </Grid>
  );
};

export default AddQuestion;
