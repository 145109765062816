import {
  React,
  useState,
  useEffect,
  useContext,
  Grid,
  Button2,
  Box,
  Typography,
  DashboardScreen,
  CreatePollstyle,
  axiosGet,
  axiosPost,
  axiosPatch,
  moment,
  GlobalContext,
  CircularProgress,
  SpinGif,
  CardContent,
  NavLink
} from "../../collections/Imports";
import { useHistory } from "react-router-dom";
import useAPIError from "../../hooks/useAPIError";
import { SnackbarContext } from "../../providers/SnackBarContext";
import CreatePollNav from "./CreatePollNav";
import CreatePollForm from "./CreatePollForm";
import {
  getDaysAndHours,
  getElectionTypeData,
  getPollCategoryData,
  // getPollTypeData
} from "./getters";

const CreatePoll = (props) => {
  const [buttonLoading, setButtonLoading] = useState(false);
  const { isEditable } = props;
  const { addError } = useAPIError();
  const { setSnackbar } = useContext(SnackbarContext);
  const classes = CreatePollstyle();
  const userRole = JSON.parse(localStorage.getItem("currentUser"));
  const userRoleId = userRole.user_role_id;
  const history = useHistory();

  const [appData, setAppData] = useContext(GlobalContext);
  const [validationData, setValidationData] = useState([]);
  const [election_type, setElectionType] = useState("Select Election Type");
  const [poll_category, setPollCategory] = useState("");
  const [enableCreatePoll, setEnableCreatePoll] = useState(false)
  //add compony and  cin state -date 24/12/2021
  const [company_name, setcompanyName] = useState("");
  const [cin_no, setcinNo] = useState("");

  // const [poll_type, setPollType] = useState('')
  const [pollTitle, setPollTitle] = useState("");
  const [start_date, setStartDate] = useState(moment().format("YYYY-MM-DD"));
  const [end_date, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [start_time, setStartTime] = useState(moment().add(5, "minutes"));
  const [end_time, setEndTime] = useState(moment().add(65, "minutes"));
  const [blockchain_security, setBlockchainSecurity] = useState(true);
  const [video_call, setvideo_call] = useState(false);
  const [disablePollCategory, setDisablePollCategory] = useState(false);
  // const [disablePollType, setDisablePollType] = useState(false)
  const [isLoading, setIsLoading] = useState(false);

  // database
  const [electionTypeData, setElectionTypeData] = useState([]);
  // const [pollTypeData, setPollTypeData] = useState([])
  const [pollCategoryOriginalData, setPollCategoryOriginalData] = useState([]);
  const [pollCategoryData, setPollCategoryData] = useState([]);
  const [dateChanged, setDateChanged] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [editData, setEditData] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const [scrutinizers, setScrutinizers] = useState([]);
  const [scrutinizer, setScrutinizer] = useState("");

  const [rtaList, setRtaList] = useState([]);
  const [rtaEmail, setRtaEmail] = useState("");

  const [issuerList, setIssuerList] = useState([]);
  const [issuerEmail, setIssuerEmail] = useState("");

  const [userPlans, setUserPlans] = useState([]);
  const [user_plan, setUserPlan] = useState("");

  const [electionId, setElectionId] = useState(
    [3, 4, 7].includes(appData.user_role_id) ? 1 : 2
  );
  const [catName, setCatName] = useState("AGM");

  const createPoll = () => {
    setButtonLoading(true);
    const validationData = [false, false, false, false, false, false];

    let currentUser = JSON.parse(localStorage.getItem("currentUser"));
    let tempTitle = ''
    if (electionId === 2) {
      tempTitle = `(IBC)-${pollTitle}`;
    } else if (electionId === 6) {
      tempTitle = `(Home Buyer)-${pollTitle}`;
    }
    else if (electionId === 3) {
      tempTitle = `(${catName[0].poll_category === undefined
        ? catName
        : catName[0].poll_category
        })-${pollTitle}`;
    } else {
      tempTitle =
        currentUser && currentUser.user_role
          ? currentUser.user_role.user_role + " - " + pollTitle
          : pollTitle;
    }

    console.log("Temp --", tempTitle)

    let sendData = {
      // poll_type,
      election_type,
      poll_category,
      poll_title: tempTitle,
      start_date,
      end_date,
      start_time: moment(start_time).format("HH:mm:ss"),
      end_time: moment(end_time).format("HH:mm:ss"),
      blockchain_security,
      video_call,
      created_by_id: currentUser._id,
      poll_description: "Description",
      scrutinizer,
      rtaEmail,
      issuerEmail,
      user_plan,
      company_name,
      cin_no,
    };

    if (pollTitle === undefined || pollTitle.length < 1 || pollTitle === "") {
      setButtonLoading(false);
      validationData[0] = true;
      setSnackbar("Please specify poll title", "warning");
    } else {
      validationData[0] = false;
    }

    //add validation for cin and company

    if (
      company_name === undefined ||
      company_name.length < 1 ||
      company_name === ""
    ) {
      setButtonLoading(false);
      validationData[2] = true;
    } else {
      validationData[2] = false;
    }

    let cinReg = new RegExp(
      "^([L|U]{1})([0-9]{5})([A-Za-z]{2})([0-9]{4})([A-Za-z]{3})([0-9]{6})$"
    );

    let indianLLPNumberRegex = /^[A-Z]{2}-\d{4}-[A-Z]\d*$/;

    let llpNumberRegex = /^[A-Z]{3}\d{4}$/;

    if (cin_no === undefined || cin_no.length < 1 || cin_no === "") {
      setButtonLoading(false);
      validationData[3] = true;
    } else if (!cinReg.test(cin_no.toUpperCase())
      && !indianLLPNumberRegex.test(cin_no.toUpperCase())
      && !llpNumberRegex.test(cin_no.toUpperCase())
    ) {
      setButtonLoading(false);
      validationData[6] = true;
    } else {
      validationData[3] = false;
    }

    if (user_plan === undefined || user_plan === null || user_plan === "") {
      setButtonLoading(false);
      validationData[5] = true;
      setSnackbar("Please choose your plan", "warning");
    } else {
      validationData[5] = false;
    }

    if (election_type === "60844acbc18867305001c508" && !scrutinizer) {
      validationData[4] = true;
      setButtonLoading(false);
    }

    if (electionId === 1) {
      validationData[3] = false
      validationData[2] = false
    }

    setValidationData(validationData);

    if (!validationData.includes(true)) {
      axiosPost("api/polls/", sendData, {})
        .then((res) => {
          const { Poll } = res;
          const selected_poll_id = Poll && Poll._id ? Poll._id : null;
          localStorage.setItem("selected_poll_id", selected_poll_id);
          localStorage.setItem("selected_poll", JSON.stringify(Poll));
          setAppData({
            ...appData,
            poll: Poll,
            selected_poll_id,
            pollCounterFlag: !appData.pollCounterFlag,
          });
          setButtonLoading(false);
          setSnackbar("Poll created successfully", "success");
          if (
            Poll.election_type === "60844acbc18867305001c508" &&
            Poll.rta_id &&
            Poll.rta_id !== currentUser._id
          ) {
            history.push("/dashboard/mypoll");
          } else {
            history.push("/dashboard/addquestions");
          }
        })
        .catch((err) => {
          console.log("err : ", err);
          setButtonLoading(false);
        });
    } else {
      console.log("Validate Data not satisfied - ", validationData)
    }
  };

  useEffect(() => {
    setIsEdit(isEditable ? isEditable : false);
  }, [isEditable]);

  const setPollValues = (poll) => {
    if (poll) {
      setElectionType(poll.election_type._id);
      if (poll.election_type.election_type_id === 2 || poll.election_type.election_type_id === 6) {
        // setDisablePollType(true)
        setDisablePollCategory(true);
      }
      // else if (poll.election_type.election_type_id === 3) {
      //   setDisablePollType(true)
      // }
      setUserPlan(poll.user_plan._id);
      setPollCategory(poll.poll_category._id);
      setPollTitle(poll.poll_title);
      // setPollType(poll.poll_type._id)
      setEndDate(poll.end_date);
      setEndTime(moment(poll.end_time, "hh:mm"));
      setStartDate(poll.start_date);
      setStartTime(moment(poll.start_time, "hh:mm"));
      setBlockchainSecurity(poll.blockchain_security);
      setcompanyName(poll?.company?.company_name)
      setcinNo(poll?.company?.company_cin)
      setUserPlans([poll?.user_plan])
    }
  };

  const updatePoll = () => {
    setButtonLoading(true);
    const validationData = [false, false, false, false];
    const poll_id =
      appData.selected_poll_id || localStorage.getItem("selected_poll_id");
    let currentUser = JSON.parse(localStorage.getItem("currentUser"));
    let sendData = {
      // poll_type,
      election_type,
      poll_category,
      poll_title: pollTitle,
      start_date,
      end_date,
      start_time: moment(start_time).format("HH:mm:ss"),
      end_time: moment(end_time).format("HH:mm:ss"),
      blockchain_security,
      video_call,
      created_by_id: currentUser._id,
      poll_description: "Description",
      user_plan,
      company_name,
      cin_no,
    };
    if (pollTitle === undefined || pollTitle.length < 1 || pollTitle === "") {
      setButtonLoading(false);
      validationData[0] = true;
    } else {
      validationData[0] = false;
    }

    //add validation for cin and company

    if (
      company_name === undefined ||
      company_name.length < 1 ||
      company_name === ""
    ) {
      setButtonLoading(false);
      validationData[2] = true;
    } else {
      validationData[2] = false;
    }
    if (cin_no === undefined || cin_no.length < 1 || cin_no === "") {
      setButtonLoading(false);
      validationData[3] = true;
    } else {
      validationData[3] = false;
    }

    setValidationData(validationData);

    if (!validationData.includes(true)) {
      axiosPatch("api/polls/id/" + poll_id, sendData, {})
        .then((res) => {
          const { Poll } = res;
          const selected_poll_id = Poll && Poll._id ? Poll._id : null;

          setAppData({
            ...appData,
            selected_poll_id,
          });
          setButtonLoading(false);
          history.push("/dashboard/addquestions");
        })
        .catch((err) => {
          console.log("err : ", err);
          setButtonLoading(false);
        });
    }
  };

  useEffect(() => {
    const getIssuers = () => {
      axiosGet("api/user/creator-list")
        .then((res) => {
          setIssuerList(res.users);
        })
        .catch((err) => {
          addError(`LOAD_DATA_ERROR: ${err}`);
        });
    };
    if (userRoleId === 6) {
      getIssuers();
    }
  }, [addError, userRoleId]);

  useEffect(() => {
    const getRtaList = () => {
      axiosGet("api/user/rta-list")
        .then((res) => {
          setRtaList(res.users);
        })
        .catch((err) => {
          addError(`LOAD_DATA_ERROR: ${err}`);
        });
    };
    const getScrutinizers = () => {
      axiosGet("api/user/scrutinizer-list")
        .then((res) => {
          setScrutinizers(res.users);
        })
        .catch((err) => {
          addError(`LOAD_DATA_ERROR: ${err}`);
        });
    };
    if (election_type === "60844acbc18867305001c508") {
      getRtaList();
      getScrutinizers();
    }
  }, [addError, election_type]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const getUserPlans = () => {
        const { activePlans } = appData;
        if (activePlans.length > 0) {
          setUserPlans(activePlans);
          setUserPlan(activePlans[0]._id);
          setEnableCreatePoll(true)
        }
        getElectionTypeData({
          setDisablePollCategory,
          setEndDate,
          setStartDate,
          setStartTime,
          setEndTime,
          setElectionType,
          isEditable,
          setElectionTypeData,
          userPlanType: activePlans.length > 0 ? activePlans[0]?.plan_type : null,
          setElectionId,
          addError,
        });

        getPollCategoryData({
          isEditable,
          setPollCategory,
          setPollCategoryData,
          addError,
          setPollCategoryOriginalData,
          userPlanType: activePlans.length > 0 ? activePlans[0]?.plan_type : null,
          election_type,
          electionTypeData,
        });
      };

      getUserPlans();
      setIsLoading(false);
    };
    fetchData();
  }, [addError, appData.activePlans]);

  useEffect(() => {
    if (isEditable) {
      const poll_id =
        appData.selected_poll_id || localStorage.getItem("selected_poll_id");

      axiosGet("api/polls/id/" + poll_id)
        .then((res) => {
          if (res) {
            const { poll } = res;
            setPollValues(poll);
          }
        })
        .catch((err) => {
          addError(`cannot get poll ${err}`);
        });
    }
  }, [addError, appData.selected_poll_id, isEditable]);

  useEffect(() => {
    getDaysAndHours({
      start_date,
      start_time,
      end_date,
      end_time,
      setSnackbar,
      setStartTime,
      setStartDate,
      election_type,
      poll_category,
      setEndTime,
      setEndDate,
      isLoading,
    });
  }, [dateChanged]);

  const checkElectionType = (value) => {
    const index = electionTypeData.findIndex((e) => {
      return (
        e._id === value &&
        (e.election_type_id === 2 || e.election_type_id === 3 || e.election_type_id === 6)
      );
    });

    if (index !== -1) {
      let electionType = electionTypeData[index];
      if (parseInt(electionType.election_type_id) === 3) {
        let pollCategories = pollCategoryOriginalData.filter((pc) => {
          return (
            parseInt(pc.poll_category_id) > 8 &&
            parseInt(pc.poll_category_id) < 12
          );
        });
        setPollCategoryData(pollCategories);
        setPollCategory(pollCategories[0]._id);
        // setDisablePollType(true)
        setDisablePollCategory(false);
        setStartDate(moment().add(1, "days"));
        setEndDate(moment().add(4, "days"));
        setStartTime(moment("10:00:00 am", "hh:mm:ss a"));
        setEndTime(moment("05:00:00 pm", "hh:mm:ss a"));
      } else if (parseInt(electionType.election_type_id) === 2
        || parseInt(electionType.election_type_id) === 6) {
        setPollCategoryData(pollCategoryOriginalData);
        setPollCategory(pollCategoryOriginalData[0]._id);
        // setPollType(pollTypeData[1]._id)
        // setDisablePollType(true)
        setDisablePollCategory(true);
        setStartDate(moment().add(1, "days"));
        setEndDate(moment().add(3, "days"));
        setStartTime(moment("10:00:00 am", "hh:mm:ss a"));
        setEndTime(moment("05:00:00 pm", "hh:mm:ss a"));
      } else {
        setPollCategoryData(pollCategoryOriginalData);
        setPollCategory(pollCategoryOriginalData[0]._id);
        // setDisablePollType(true)
      }
      // let pollIndex = pollTypeData.findIndex(poll => {
      //   return poll.poll_type.toLowerCase() === 'private'
      // })
      // setPollType(pollTypeData[pollIndex]._id)
    } else {
      setPollCategoryData(pollCategoryOriginalData);
      setPollCategory(pollCategoryOriginalData[0]._id);
      // setDisablePollType(false)
    }
  };

  const handleDateChange = async (event, eventType) => {
    switch (eventType) {
      case "startDate":
        setStartDate(event);
        break;
      case "startTime":
        setStartTime(event);
        break;
      case "endDate":
        setEndDate(event);
        break;
      case "endTime":
        setEndTime(event);
        break;
      default:
        console.log("Deafult");
    }
    setDateChanged((prev) => !prev);
  };

  const renderEditButton = () => {
    if (props.location.state) {
      if (props.location.state.fromPage === "add-question") {
        if (!editData) {
          setDisabled(true);
          setEditData(true);
        }

        return (
          <Grid
            container
            justify="center"
            alignItems="center"
            spacing={2}
            style={{
              backgroundColor: "",
              marginTop: "30px",
              marginLeft: "30px",
            }}
          >
            <Grid item sm={4}>
              <Button2
                onClick={() => {
                  setDisabled(false);
                }}
                width="70%"
              >
                Edit
              </Button2>
            </Grid>

            {disabled ? null : (
              <Grid item sm={4}>
                <Button2
                  disabled={buttonLoading}
                  onClick={updatePoll}
                  width="200px"
                >
                  {buttonLoading && (
                    <CircularProgress
                      size={20}
                      style={{ color: "#000", marginRight: "5px" }}
                    />
                  )}
                  {buttonLoading && (
                    <Box
                      component="span"
                      style={{ color: "#000", fontWeight: "600" }}
                    >
                      {" "}
                      please wait
                    </Box>
                  )}
                  {!buttonLoading && <Box>PROCEED</Box>}
                </Button2>
              </Grid>
            )}
            <Grid item sm={4}>
              <Button2
                onClick={() => {
                  history.push("/dashboard/addquestions");
                }}
                width="70%"
              >
                SKIP
              </Button2>
            </Grid>
          </Grid>
        );
      }
    } else if (props.location.pathname === "/dashboard/editpoll") {
      return (
        <Box align="center" style={{ marginTop: "10px" }}>
          <Button2
            disabled={buttonLoading}
            onClick={updatePoll}
            width="30%"
          // className={classes.updateButton}
          >
            {buttonLoading && (
              <CircularProgress
                size={20}
                style={{ color: "#000", marginRight: "5px" }}
              />
            )}
            {buttonLoading && (
              <Box
                component="span"
                style={{ color: "#000", fontWeight: "600" }}
              >
                {" "}
                please wait
              </Box>
            )}
            {!buttonLoading && <Box>Update</Box>}
          </Button2>
        </Box>
      );
    } else {
      return (
        <Box align="center" style={{ marginTop: "10px" }}>
          <Button2
            disabled={buttonLoading}
            onClick={createPoll}
            variant="contained"
            width="200px"
          >
            {buttonLoading && (
              <CircularProgress
                size={20}
                style={{ color: "#000", marginRight: "5px" }}
              />
            )}
            {buttonLoading && (
              <Box
                component="span"
                style={{ color: "#000", fontWeight: "600" }}
              >
                {" "}
                please wait
              </Box>
            )}
            {!buttonLoading && <Box>PROCEED</Box>}
          </Button2>
        </Box>
      );
    }
  };

  const calculateDaysHours = () => {
    let start = moment(
      moment(start_date).format("YYYY.MM.DD") +
      " " +
      moment(start_time).format("HH:mm:ss"),
      moment.defaultFormat
    ).format();
    let end = moment(
      moment(end_date).format("YYYY.MM.DD") +
      " " +
      moment(end_time).format("HH:mm:ss"),
      moment.defaultFormat
    ).format();

    let timeDiff = moment(end).diff(start, "hours");
    let daysDiff = timeDiff / 24;
    let hoursDiff = timeDiff % 24;

    return (
      <Grid item xs={12} sm={12}>
        <Typography className={classes.message}>
          Your poll will be open for a total duration of{" "}
          <span style={{ color: "#1171ff" }}>
            {parseInt(daysDiff)} Days & {parseInt(hoursDiff)} Hours
          </span>
        </Typography>
      </Grid>
    );
  };

  const handlePoll = (e) => {
    setValidationData([false, false, false]);

    if (e.target.name === "election_type" && !isEdit) {
      setElectionType(e.target.value);
      let current_election_type = electionTypeData.filter((type) =>
        type._id === e.target.value ? type.election_type : null
      );

      setElectionId(current_election_type[0].election_type_id);

      // setDisablePollType(false)
      checkElectionType(e.target.value);
    }

    if (e.target.name === "issuerList" && !isEdit) {
      setIssuerEmail(e.target.value);
    }
    if (e.target.name === "rtaList" && !isEdit) {
      setRtaEmail(e.target.value);
    }
    if (e.target.name === "poll_title") {
      setPollTitle(e.target.value);
    }

    //add compony and  cin onchange -date 24/12/2021

    if (e.target.name === "company_name") {
      setcompanyName(e.target.value);
    }
    if (e.target.name === "cin_no") {
      setcinNo(e.target.value);
    }

    if (e.target.name === "user_plan") {
      setUserPlan(e.target.value);
    }
    if (e.target.name === "poll_category" && !isEdit) {
      setPollCategory(e.target.value);
      let currentCategory = pollCategoryData.filter((type) =>
        type._id === e.target.value ? type.poll_category : null
      );
      setCatName(currentCategory);

      if (e.target.value === "609f7b98a05bd07565d31aa4") {
        setStartDate(moment().add(1, "days"));
        setEndDate(moment().add(31, "days"));
        setStartTime(moment("10:00:00 am", "hh:mm:ss a"));
        setEndTime(moment("05:00:00 pm", "hh:mm:ss a"));
      }
      if (
        e.target.value === "609f7b77a05bd07565d31aa2" ||
        e.target.value === "609f7b85a05bd07565d31aa3"
      ) {
        setStartDate(moment().add(1, "days"));
        setEndDate(moment().add(4, "days"));
        setStartTime(moment("10:00:00 am", "hh:mm:ss a"));
        setEndTime(moment("05:00:00 pm", "hh:mm:ss a"));
      }
    }

    // if (e.target.name === 'poll_type' && !isEdit) {
    //   setPollType(e.target.value)
    // }
    if (e.target.name === "start_date") {
      setStartDate(e.target.value);
    }
    if (e.target.name === "end_date") {
      setEndDate(e.target.value);
    }
    if (e.target.name === "start_time") {
      setStartTime(e.target.value);
    }
    if (e.target.name === "end_time") {
      setEndTime(e.target.value);
    }
    if (e.target.name === "blockchain_security") {
      setBlockchainSecurity(true);
    }
    if (e.target.name === "video_call") {
      setvideo_call(!video_call);
    }
    if (e.target.name === "scrutinizer" && !isEdit) {
      setScrutinizer(e.target.value);
    }
  };

  return (
    <DashboardScreen>
      {isLoading ? (
        <Box
          style={{
            width: "60%",
            position: "absolute",
            marginTop: "15%",
            filter: "none",
            zIndex: 1000,
            textAlign: "center",
          }}
        >
          <img src={SpinGif} alt="spinner" />
        </Box>
      ) : null}
      <CreatePollNav isEditable={isEditable} props={props} />
      <CreatePollForm
        rtaEmail={rtaEmail}
        issuerList={issuerList}
        issuerEmail={issuerEmail}
        rtaList={rtaList}
        // isLoading={isLoading}
        election_type={election_type}
        isEdit={isEdit}
        disabled={disabled}
        electionTypeData={electionTypeData}
        // disablePollType={disablePollType}
        disablePollCategory={disablePollCategory}
        poll_category={poll_category}
        pollCategoryData={pollCategoryData}
        poll_title={pollTitle}
        validationData={validationData}
        // pollTypeData={pollTypeData}
        // poll_type={poll_type}
        scrutinizer={scrutinizer}
        scrutinizers={scrutinizers}
        handleDateChange={handleDateChange}
        start_date={start_date}
        start_time={start_time}
        end_date={end_date}
        end_time={end_time}
        calculateDaysHours={calculateDaysHours}
        renderEditButton={renderEditButton}
        blockchain_security={blockchain_security}
        setBlockchainSecurity={setBlockchainSecurity}
        handlePoll={handlePoll}
        userPlans={userPlans}
        user_plan={user_plan}
        company_name={company_name}
        cin_no={cin_no}
        enableCreatePoll={enableCreatePoll}
      />
    </DashboardScreen>
  );
};

export default CreatePoll;
