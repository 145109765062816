export const alphabetArray = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
];

export const Switch = (key) => {
  switch (key) {
    case '2':
      return 'Multiple Option';
    case '1':
      return 'Single Option';
    case '4':
      return 'Ranking';
    case '3':
      return 'Shareholder';
    case '5':
      return 'Single Choice';
    default:
      break;
  }
};

export const INSTRUCTIONS = [
  'Read the instructions carefully',

  // 'Validate your information like name, email ID, and shares value in case you are a Shareholder',

  // 'The question type is defined below the questions themselves',

  'For a Single option, you can select only one option',
  'For Multiple options question type, you can select more than one option',
  'To reset your selected options you can click on the reset button to clear your selection',

  // 'For the Shareholder, use your number of shares completely to vote for Yes, or No or Abstain',

  'To navigate questions click on the Next (->) arrow Previous  (<-) button respectively',
  'To skip click on skip button icon if you do not want to attempt the questions',

  'Before submission, click on the preview button to view your responses, once submitted you will not be allowed to change back your responses',

  'To submit your vote, click on Submit button, Once submitted, a message shall be displayed "Your Vote has been submitted successfully" and you will be redirected to thank you screen',

  'Once you click on submit button, the vote will be cast and the link will get expired, you will not be able to login back',

  // 'To view, attachments click on the view attachment',
];
