const PencilSquare = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29.0657 3.63726C29.2409 3.81299 29.3393 4.051 29.3393 4.29914C29.3393 4.54727 29.2409 4.78528 29.0657 4.96101L27.1101 6.91851L23.3601 3.16851L25.3157 1.21101C25.4915 1.03526 25.7299 0.936523 25.9785 0.936523C26.2271 0.936523 26.4655 1.03526 26.6413 1.21101L29.0657 3.63539V3.63726ZM25.7845 8.24226L22.0345 4.49226L9.26009 17.2685C9.1569 17.3717 9.07921 17.4975 9.03322 17.636L7.52384 22.1623C7.49647 22.2448 7.49258 22.3333 7.51262 22.4179C7.53265 22.5025 7.57582 22.5798 7.63729 22.6413C7.69877 22.7028 7.77613 22.7459 7.86073 22.766C7.94532 22.786 8.03383 22.7821 8.11634 22.7548L12.6426 21.2454C12.7809 21.1999 12.9067 21.1229 13.0101 21.0204L25.7845 8.24413V8.24226Z"
        fill="#0b2f64"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.875 25.3125C1.875 26.0584 2.17132 26.7738 2.69876 27.3012C3.22621 27.8287 3.94158 28.125 4.6875 28.125H25.3125C26.0584 28.125 26.7738 27.8287 27.3012 27.3012C27.8287 26.7738 28.125 26.0584 28.125 25.3125V14.0625C28.125 13.8139 28.0262 13.5754 27.8504 13.3996C27.6746 13.2238 27.4361 13.125 27.1875 13.125C26.9389 13.125 26.7004 13.2238 26.5246 13.3996C26.3488 13.5754 26.25 13.8139 26.25 14.0625V25.3125C26.25 25.5611 26.1512 25.7996 25.9754 25.9754C25.7996 26.1512 25.5611 26.25 25.3125 26.25H4.6875C4.43886 26.25 4.2004 26.1512 4.02459 25.9754C3.84877 25.7996 3.75 25.5611 3.75 25.3125V4.6875C3.75 4.43886 3.84877 4.2004 4.02459 4.02459C4.2004 3.84877 4.43886 3.75 4.6875 3.75H16.875C17.1236 3.75 17.3621 3.65123 17.5379 3.47541C17.7137 3.2996 17.8125 3.06114 17.8125 2.8125C17.8125 2.56386 17.7137 2.3254 17.5379 2.14959C17.3621 1.97377 17.1236 1.875 16.875 1.875H4.6875C3.94158 1.875 3.22621 2.17132 2.69876 2.69876C2.17132 3.22621 1.875 3.94158 1.875 4.6875V25.3125Z"
        fill="#0b2f64"
      />
    </svg>
  );
};

export default PencilSquare;
