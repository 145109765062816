import { SpinGif, Grid, Typography } from "../../collections/Imports";
import { Helmet } from "react-helmet";
import React, { Suspense } from "react";
import { Box } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import CookieConsent, {
  Cookies,
  getCookieConsentValue,
} from "react-cookie-consent";
localStorage.setItem(Cookies, getCookieConsentValue);
sessionStorage.setItem(Cookies, getCookieConsentValue);
const Footer = React.lazy(() => import("./../../../components/footer/Footer"));
const CarouselBox = React.lazy(() => import("./CarouselBox"));
const FeatureContainer = React.lazy(() => import("./FeatureContainer"));
const ServiceContainer = React.lazy(() => import("./ServiceContainer"));
const DownloadBox = React.lazy(() => import("./DownloadBox"));
const FeatureBox = React.lazy(() => import("./FeatureBox"));

const Home = () => {
  return (
    <Box style={{ backgroundColor: "#f8f8f8" }}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>EazyVC Voting Platform</title>
        <meta
          name="title"
          content="Online Voting Platform | Online Voting IBC | Online Voting App
          ."
        />
        <meta
          name="description"
          content="EazyVC is the best online voting platform & app. We provide online voting services on our Blockchain-based highly secure online voting system.
          ."
        />
        <meta
          name="keywords"
          content="online voting platform, online voting ibc, online voting app, online voting website, online voting service, voting system online
          .
          "
        />
         <meta property="og:site_name" content="EazyVC" />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://eazyvc.com/" />
          <meta property="og:title" content="The easy way to Vote Online on EazyVC’s Blockchain-secured Voting Platform." />
          <meta property="og:description"
            content="EazyVC is a Blockchain-based eVoting platform for Corporates, Government Bodies, Education Institutions, Associations & Clubs, Housing Societies, NGO, Trusts and more." />
          <meta property="og:image" content="https://eazyvc.com/static/media/mobile-blockchain.816ba9f5.png" />
          <meta property="og:image:secure_url" content="https://eazyvc.com/static/media/mobile-blockchain.816ba9f5.png" />
          <meta property="og:image:width" content="300" />
          <meta property="og:image:height" content="500" />
      </Helmet>
      <Suspense
        fallback={
          <Grid
            container
            justify="center"
            alignItems="center"
            direction="column"
            style={{ position: "absolute" }}
          >
            <Grid item>
              <img src={SpinGif} alt="spinner" />
            </Grid>
            <Grid item>
              <Typography variant="h5">Loading...</Typography>
            </Grid>
          </Grid>
        }
      >
        <CarouselBox />
        <FeatureBox />
        <FeatureContainer />
        <ServiceContainer />
        <DownloadBox />
        <Footer />
      </Suspense>
      <CookieConsent
        location="bottom"
        cookieName="myAwesomeCookieName2"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4E503B", fontSize: "13px" }}
        expires={150}
        SameSite="none"
      >
        This site uses cookies.By continuing to use this website, you agree to
        their use. See <NavLink to="/privacypolicy">Privacy Policy</NavLink> for
        more info.
      </CookieConsent>
    </Box>
  );
};
export default Home;
