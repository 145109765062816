export const tourConfig = [
  {
    selector: '[data-tut="reactour__pollBasicDetails"]',
    content: `Ok, Let's Start With The Name of The Tour That Is About To Begin.`,
  },
  {
    selector: '[data-tut="reactour__electionType"]',
    content: `Select Election Type`,
  },
  {
    selector: '[data-tut="reactour__pollCategory"]',
    content: `Select Poll Category If Required`,
  },
  {
    selector: '[data-tut="reactour__pollTitle"]',
    content: `Write Sutable Title For Your Poll`,
  },
  {
    selector: '[data-tut="reactour__PollType"]',
    content: `If Required Select Poll Type`,
  },
  {
    selector: '[data-tut="reactour__PollStartingDate"]',
    content: `Select Start Date for Your Poll`,
  },
  {
    selector: '[data-tut="reactour__PollStartingTime"]',
    content: `Select Start Time for Your Poll`,
  },
  {
    selector: '[data-tut="reactour__PollEndingDate"]',
    content: `Select End Date for Your Poll`,
  },
  {
    selector: '[data-tut="reactour__PollEndingTime"]',
    content: `Select End Time for Your Poll`,
  },
  {
    selector: '[data-tut="reactour__bloackChain"]',
    content: `You Can Enable Blockchain Security For Your Poll If You Have Subscription`,
  },
];
