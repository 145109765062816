import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import { Box, Chip, Collapse, TableCell, TableRow } from "@material-ui/core";
import EditInviteeForm from "../EditInviteeForm";
import { PencilSquare, useContext } from "../../../collections/Imports";
import CancelIcon from "@material-ui/icons/Cancel";
import { Fragment, useState } from "react";
import { InviteTableStyles } from "./InviteTableStyles";
import { GlobalContext } from "../../../contexts/GlobalContext";
import Tooltip from "@mui/material/Tooltip";
const Row = ({
  row,
  index,
  isItemSelected,
  labelId,
  selected,
  setSelected,
  setValidParticipants,
  validParticipants,
}) => {
  const classes = InviteTableStyles();
  const [editOpen, setEditOpen] = useState(false);
  const [appData] = useContext(GlobalContext);

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  return (
    <Fragment key={index}>
      <TableRow
        hover
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={row.id}
        selected={!row.status ? isItemSelected : true}
        className={classes.SelectedTableRow}
      >
        <TableCell padding="checkbox">
          <Tooltip title="Select" placement="top">
            <Checkbox
              checked={isItemSelected}
              inputProps={{ "aria-labelledby": labelId }}
              onClick={(event) => handleClick(event, row.id)}
              className={classes.checkbox}
            />
          </Tooltip>
        </TableCell>
        <TableCell component="th" id={labelId} scope="row" padding="none">
          {row.name}
        </TableCell>
        <TableCell align="left">
          {row.email}
          {row.emailStatus ? <Box style={{ color: "green" }}>sent</Box> : null}
        </TableCell>
        {[1, 2, 6, 7].includes(appData.user_role_id) ? (
          <TableCell align="left">{row.share}</TableCell>
        ) : null}

        <TableCell align="left">{row.phone}</TableCell>
        <TableCell align="left">
          {row.status ? (
            <Chip color="primary" label="VOTED" className={classes.chip} />
          ) : (
            <Chip
              style={{
                backgroundColor: "#0b2f64",
                color: "white",
              }}
              label="NOT VOTED"
              className={classes.chip}
            />
          )}
        </TableCell>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => {
              setEditOpen((editOpen) => !editOpen);
            }}
          >
            {editOpen ? <CancelIcon /> : <PencilSquare />}
          </IconButton>
        </TableCell>
      </TableRow>
      {editOpen ? (
        <TableRow>
          <TableCell colSpan={7}>
            <Collapse in={editOpen} timeout="auto" unmountOnExit>
              <EditInviteeForm
                setEditing={setEditOpen}
                currentInvitee={row}
                setValidParticipants={setValidParticipants}
                validParticipants={validParticipants}
              />
            </Collapse>
          </TableCell>
        </TableRow>
      ) : null}
    </Fragment>
  );
};

export default Row;
