import React, { useEffect, useState } from 'react';

import {
  AddQuestionStyles,
  axiosGet,
  axiosDelete,
  Button,
  Box,
  Grid,
  IconButton,
  Paper,
  Trash,
  PencilSquare,
  Chip, useContext
} from '../../collections/Imports';
import ConfirmDialog from '../../AlertNotification/ConfirmDialog';
import { SnackbarContext } from '../../providers/SnackBarContext';
import useAPIError from '../../hooks/useAPIError';
const ViewQuestion = (props) => {
  const { addError } = useAPIError();
  const classes = AddQuestionStyles();
  const { setSnackbar } = useContext(SnackbarContext);
  const [question_changed, setQuestionChanged] = useState(true);
  const poll_id =
    localStorage.getItem('selected_poll_id') || '607eb0bf02eb2612eca5c812';

  const [addQuestion, setAddQuestion] = useState({
    question: '',
    questionType: 1,
    questionOptions: ['', ''],
    editQuestionId: '',
    images: '',
    question_file: '',
    editFlag: false,
    newQuestionFlag: false,
    isSkippable: false,
  });

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
  });

  // useEffect(() => {
  //   let selected_poll_id = localStorage.getItem('selected_poll_id');
  //   if (selected_poll_id) {
  //     axiosGet('api/polls/id/' + selected_poll_id)
  //       .then((result) => {
  //         if (result) {
  //           const { poll } = result;
  //         }
  //       })
  //       .catch((err) => {
  //         console.log('Error fetching poll : ', err);
  //       });
  //   }
  // }, []);

  const onDeleteFromDB = (_id) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    axiosDelete(`api/question/${_id}`)
      .then((result) => {
        if (result) {
          const index = props.questions_data.findIndex(function (q) {
            return q._id === _id;
          });
          if (index > -1) {
            let tempQData = [];
            for (let i = 0; i < props.questions_data.length; i++) {
              if (i != index) tempQData.push(props.questions_data[i]);
            }
            props.setQuestionsData(tempQData);
          }
          props.setShowForm(false);
          props.setSelectedQuestion(null);
          setAddQuestion({
            ...addQuestion,
            question: '',
            questionType: '',
            questionOptions: ['', ''],
            images: '',
            question_file: '',
            id: '',
            isSkippable: false,
          });
        }
        setSnackbar('Question Deleted Successfully.', 'success');
      }
      )
      .catch((err) => {
        addError(`${err}`);
      });
  };

  useEffect(() => {
    if (props.question_changed || question_changed) {
      const poll_id = localStorage.getItem('selected_poll_id')
      axiosGet('api/question/poll-id/' + poll_id)
        .then((result) => {
          console.log("result --- ", result)
          if (result) {
            // console.log(result);
            var records = result && result.records ? result.records : [];
            records = records.filter(function (r) {
              return !r.isDeleted;
            });
            props.setQuestionsData(records);
            setQuestionChanged(false);
            props.setQuestionChanged(false);
          } else {
            addError(`No question available for this pole`);
          }
        })
        .catch((err) => {
          console.log("Error --- ", err)
          addError(`LOAD_DATA_ERROR: ${err}`);
        });
    }
  }, [props.question_changed]);

  const onClickEditQuestion = (id) => {
    props.setSelectedQuestion(id);
    // props.setShowForm(true);
  };

  return (
    <>
      {props.questions_data &&
        props.questions_data.length > 0 &&
        props.questions_data.map((value, index) =>
          value.isDeleted === false ? (
            <Paper
              spacing={2}
              elevation={3}
              justify="center"
              className={classes.ListQuestion}
              style={{ marginBottom: '1rem' }}
            >
              <Grid
                container
                className={classes.tableRow}
                style={{
                  backgroundColor: '',
                  padding: '15px',
                  border: '10px',
                  alignItems: 'center',
                }}
                justify="space-between"
                alignitems="center"
              >
                <Grid item sm={1}>
                  {index + 1}
                </Grid>
                <Grid item sm={5} xs={12}>
                  <Box dangerouslySetInnerHTML={{ __html: `<span class="${classes.questionSplit}">${value.question}</span>` }} />
                </Grid>
                <Grid item sm={4}>
                  <Chip
                    label={
                      value.questionType && value.questionType.question_type
                        ? value.questionType.question_type
                        : value.questionType
                    }
                    style={{ minWidth: '105px', fontWeight: '500', backgroundColor: 'rgb(11, 47, 100)', color: '#fff' }}
                  />
                </Grid>
                <Grid item sm={1}>
                  <Button
                    style={{ color: '#F04F1E' }}
                    onClick={() => {
                      setConfirmDialog({
                        isOpen: true,
                        title: 'Are you sure to delete this Question?',
                        subTitle: "You can't undo this operation",
                        onConfirm: () => {
                          onDeleteFromDB(value._id);
                        },
                      });
                    }}
                  >
                    <Trash />
                  </Button>
                </Grid>
                <Grid item sm={1} className={classes.editIcon}>
                  <IconButton onClick={() => onClickEditQuestion(value._id)}>
                    <PencilSquare />
                  </IconButton>
                </Grid>
              </Grid>
            </Paper>
          ) : null
        )}

      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
};

export default ViewQuestion;
