import {
  React,
  Grid,
  Typography,
  TitleBox,
  GlobalContext,
  useContext,
} from '../../collections/Imports';

export const MyPollNav = () => {
  const [appData] = useContext(GlobalContext);
  const classes1 = TitleBox();

  return (
    <Grid container alignItems="center" justify="space-between">
      <Grid item xs={12} className={classes1.TitleBand}>
        <Typography variant="h5" component="h2" style={{ marginLeft: '1rem' }}>
          {appData.user_role && appData.user_role_id === 2
            ? 'Scrutinizer Polls'
            : 'My Polls'}
        </Typography>
      </Grid>
    </Grid>
  );
};
