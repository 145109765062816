import { axiosDelete, axiosPost, axiosGet, axiosPut } from '../../collections/Imports';

const removeInviteeById = (id) => {
  let poll_id = localStorage.getItem('selected_poll_id');
  return axiosDelete(`api/polls/add-invitees/${poll_id}/${id}`);
};

const removeInvitees = (data) => {
  let poll_id = localStorage.getItem('selected_poll_id');
  return axiosPost(`api/polls/remove-invitees`, {
    poll_id: poll_id,
    voter_ids: data,
  });
};

// const editInvitee = (id, data) => {
//   let poll_id = localStorage.getItem('selected_poll_id');
//   return http.put(`/polls/add-invitees/${poll_id}`, { id, data });
// };

// const sendMails = (data) => {
//   let poll_id = localStorage.getItem('selected_poll_id');
//   return http.post(`/polls/send-invite-links/${poll_id}`, { voters: data });
// };

// const addInvitee = (data) => {
//   let poll_id = localStorage.getItem('selected_poll_id');
//   return http.post(`/polls/add-invitees/${poll_id}`, data);
// };

// const addMember = (data) => {
//   let poll_id = localStorage.getItem('selected_poll_id');
//   return http.post(`/polls/add-invitee/${poll_id}`, data);
// };

// const getValid = async (poll_id, { setValidParticipants, addError }) => {
//   try {
//     const res = await http.get(`/polls/participants/${poll_id}`);
//     if (res.data) {
//       const validArray = [];
//       res.data.participants?.forEach((item) => {
//         validArray.push({
//           email: item.voter_id.email,
//           name: item.voter_id.name,
//           share: item.share_id?.share_value,
//           status: item.is_voted,
//           category: '',
//           phone: item.voter_id && item.voter_id.phone ? item.voter_id.phone : '',
//           id: item.voter_id._id,
//           emailStatus: item.is_sent_mail,
//         });
//       });
//       setValidParticipants(validArray);
//     }
//   } catch (err) {
//     console.log("Error : ", err)
//     // addError(`Could not get participants!`);
//   }
// };

const editInvitee = (id, data) => {
  let poll_id = localStorage.getItem('selected_poll_id');
  return axiosPut(`api/polls/add-invitees/${poll_id}`, { id, data });
};

const sendMails = (data) => {
  let poll_id = localStorage.getItem('selected_poll_id');
  return axiosPost(`api/polls/send-invite-links/${poll_id}`, { voters: data });
};

const addInvitee = (data) => {
  let poll_id = localStorage.getItem('selected_poll_id');
  return axiosPost(`api/polls/add-invitees/${poll_id}`, data);
};

const addMember = (data) => {
  let poll_id = localStorage.getItem('selected_poll_id');
  return axiosPost(`api/polls/add-invitee/${poll_id}`, data);
};

const getValid = async (poll_id, { setValidParticipants, addError }) => {
  try {
    axiosGet(`api/polls/participants/${poll_id}`)
      .then((res) => {
        if (res) {
          const validArray = [];
          res.participants?.forEach((item) => {
            validArray.push({
              email: item.voter_id.email,
              name: item.voter_id.name,
              share: item.share_id?.share_value,
              status: item.is_voted,
              category: '',
              phone: item.voter_id && item.voter_id.phone ? item.voter_id.phone : '',
              id: item.voter_id._id,
              emailStatus: item.is_sent_mail,
            });
          });
          setValidParticipants(validArray);
        }
      })
      .catch((err) => {
        console.log("Error : ", err)
      })
  } catch (err) {
    console.log("Error : ", err)
  }
};
const InviteMemberServices = {
  getValid,
  sendMails,
  removeInviteeById,
  addInvitee,
  editInvitee,
  removeInvitees,
  addMember,
};
export default InviteMemberServices;
