import React from "react";
import {
  useEffect,
  useState,
  Grid,
  CardContent,
  Paper,
  Table,
  TableContainer,
  TableRow,
  TableCell,
  Typography,
  PieChart,
  KeyboardArrowLeftRoundedIcon,
  KeyboardArrowRightRoundedIcon,
  DashboardScreen,
  TableBody,
  TableHead,
  NavLink,
  ArrowBackRounded,
  axiosGet,
  PollResultStyle,
  Alert,
  AlertTitle,
  _, //lodash
  Box,
  TitleBox,
  PollDetails,
  Modal,
  useContext,
  GlobalContext,
} from "../../collections/Imports.js";
import { orderBy, uniqBy, findIndex, sortBy } from "lodash";
import {
  MyCard,
  DownloadButton,
  Bullet,
  Para1,
  Para2,
  Para3,
  Para4,
  Percentage,
  OtherPercentage,
} from "./PollResultStyle.js";

import ReactExport from "react-export-excel";

import CreatePdf from "./CreatePdf.js";
import moment from "moment/moment.js";
const colors = [
  "#579BFF",
  "#F3E035",
  "#5F9EA0",
  "#F08E70",
  "#33FF33",
  "#D00000",
  "#808000",
];

const lastCellItem = "0px 10px 10px 0px";

const PollResult = (props) => {
  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

  const classes1 = TitleBox();
  const classes = PollResultStyle();
  const [poll_records, setPollRecords] = useState([]);
  const [all_invitees, setAllInvitees] = useState([]);
  const [poll_index, setPollIndex] = useState(0);
  const [poll, setPoll] = useState(0);
  const [poll_result, setPollResult] = useState(0);
  const [invitees, setInvitee] = useState(0);
  const [inviteeOptions, setInviteeOptions] = useState("");
  const [pieData, setPieData] = useState([]);
  const [fetchSuccess, setFetchSuccess] = useState(false);
  const [summerizedResult, setSummerizedResult] = useState([]);
  const [detailedResult, setDetailedResult] = useState([]);
  const [dataset, setDataset] = useState([]);
  const [open, setOpen] = useState(false);
  const [appData, setAppData] = useContext(GlobalContext);
  const [not_casted, setNotCasted] = useState(false);

  const createExcelNotCasted = (result, currentUser) => {
    setNotCasted(true);
    const { poll, questions } = result;
    let blankData = { index: " ", resolution: " ", type: " " };
    if (poll) {
      let summerizedResult = [];
      summerizedResult.push({ index: "Company name : " + currentUser?.name });
      summerizedResult.push({
        index: poll.poll_title,
        resolution:
          "Start Date : " + moment(poll.start_date).format("DD-MM-YYYY"),
        type: "End Date : " + moment(poll.end_date).format("DD-MM-YYYY"),
      });
      summerizedResult.push(blankData);
      summerizedResult.push(blankData);
      summerizedResult.push({
        index: "Summary of Poll Results",
        resolution: " ",
        type: " ",
      });
      summerizedResult.push({
        index: "Sr. No.",
        resolution: "Resolution",
        type: "Type",
        0: "Options",
      });
      for (let i = 0; i < questions.length; i++) {
        let questionData = questions[0];
        let qtemp = questionData.question.replace(/<[^>]+>/g, "");
        if (qtemp && qtemp.length > 150) {
          qtemp = qtemp.substring(0, 150) + "...";
        }
        let tempdata = {
          index: i + 1,
          resolution: qtemp,
          type: questionData?.questionType?.question_type,
        };
        for (let j = 0; j < questionData.options.length; j++) {
          tempdata = {
            ...tempdata,
            [j]: questionData.options[j].value,
          };
        }
        summerizedResult.push(tempdata);
      }

      summerizedResult.push(blankData);
      summerizedResult.push(blankData);

      summerizedResult.push({
        index: " ",
        resolution: "The result can't be generated.",
        type: " ",
      });
      summerizedResult.push({
        index: " ",
        resolution: "*No one has caste vote.",
        type: " ",
      });
      summerizedResult.push(blankData);
      summerizedResult.push(blankData);
      summerizedResult.push(blankData);
      summerizedResult.push(blankData);
      summerizedResult.push(blankData);
      summerizedResult.push(blankData);
      summerizedResult.push({
        index: "Votechain Private Limited",
        resolution:
          "Result Date : " + moment(poll.end_date).format("DD-MM-YYYY"),
        type: " ",
      });

      setSummerizedResult(summerizedResult);
    }
  };

  const detailedReportIBC = (result) => {
    const { poll_records, all_invitees } = result;
    let excelData = [];
    if (poll_records && poll_records.length > 0) {
      let { poll, poll_result } = poll_records[0];
      let { total_voter_score } = poll_result;
      excelData.push({ colA: "Final Detailed Voting Report" });
      excelData.push({
        colA: "Report Generation Date and Time :",
        colB:
          moment(poll?.end_date).format("DD-MM-YYYY") +
          " " +
          moment(poll?.end_time, "hh:mm:ss").format("hh:mm:ss a"),
      });

      excelData.push({ colA: "" });
      excelData.push({ colA: "" });

      excelData.push({
        colA: "Member ID",
        colB: "Member Name",
        colC: "Member Shares",
        colD: "Voting Share %",
        colE: "Resolution Number",
        colF: "No. of Votes for Yes",
        colG: "No. of Votes for No",
        colH: "No. of Votes for Abstain",
        colI: "Email ID",
        colJ: "Date of Voting",
        colK: "Status of Vote",
        colL: "IP Address",
      });
      for (let i = 0; i < poll_records.length; i++) {
        const { poll, invitees, poll_result } = poll_records[i];
        let { options } = poll_result;

        let yesOptionId = null;
        let noOptionId = null;

        let yesTemp = options.filter(
          (opt) => opt?.value?.toLowerCase() == "yes"
        );
        let noTemp = options.filter((opt) => opt?.value?.toLowerCase() == "no");

        if (yesTemp && yesTemp.length > 0) yesOptionId = yesTemp[0]._id;

        if (noTemp && noTemp.length > 0) noOptionId = noTemp[0]._id;

        console.log("all_invitees --", all_invitees);
        for (let l = 0; l < all_invitees.length; l++) {
          let tempInvitee = all_invitees[l];
          let colA = tempInvitee?._id || " ";
          let colB = tempInvitee?.voter_id?.name || " ";
          let colC = tempInvitee?.share_id?.share_value || 1;
          let colD =
            parseFloat(
              (parseFloat(colC) / parseFloat(total_voter_score)) * 100
            ).toFixed(2) + " %";
          let colI = tempInvitee?.voter_id?.email || " ";
          let colF = "",
            colG = "",
            colH = "",
            colJ = "",
            colK = tempInvitee.is_voted ? "VOTED" : "NOT VOTED";
          if (tempInvitee.is_voted) {
            let index = invitees.findIndex(
              (m) => m.voter_id == tempInvitee?.voter_id?._id
            );
            if (index > -1) {
              if (invitees[index]?.selected_option == yesOptionId) {
                colF = "Yes";
              } else if (invitees[index]?.selected_option == noOptionId) {
                colG = "Yes";
              } else {
                colH = "Yes";
              }
              // colJ = moment(invitees[index]?.voted_at).format('DD-MM-YYYY hh:mm:ss a')
              // console.log(
              //   "MOMENT==> ",
              //   moment
              //     .parseZone(invitees[index]?.voted_at)
              //     .local()
              //     .format("L LTS")
              // );
              colJ = moment
                .parseZone(invitees[index]?.voted_at)
                .local()
                .format("L LTS");
            }
          }
          excelData.push({
            colA,
            colB,
            colC,
            colD,
            colE: i + 1,
            colF,
            colG,
            colH,
            colI,
            colJ,
            colK,
            colL: " ",
          });
        }
      }
      setDetailedResult(excelData);
    }
  };

  const oldPollResultFormat = (
    currentUser,
    poll_records,
    all_invitees,
    poll,
    poll_result,
    invitees
  ) => {
    let dataset = [];
    dataset.push({ index: "Company name : " + currentUser?.name });
    dataset.push({
      index: poll.poll_title,
      resolution:
        "Start Date : " + moment(poll.start_date).format("DD-MM-YYYY"),
      type: "End Date : " + moment(poll.end_date).format("DD-MM-YYYY"),
    });
    dataset.push({ index: " ", resolution: " ", type: " " });
    dataset.push({ index: " ", resolution: " ", type: " " });
    let indexData =
      "(A) Summary of Poll Results - CoC Members Voted on Resolution.";
    let resolutionData = "Resolution";
    let optionsData = {};
    for (let i = 0; i < poll_result.options.length; i++) {
      if (i == 0) optionsData = { [i]: "Option - " + (i + 1) };
      else optionsData = { ...optionsData, [i]: "Option - " + (i + 1) };
    }
    dataset.push({
      index: indexData,
      resolution: resolutionData,
      type: "Type",
      ...optionsData,
    });
    if (poll && poll.election_type.election_type_id === 3) {
      for (let i = 0; i < poll_records.length; i++) {
        let { poll_result: current_poll_result } = poll_records[i];
        let index = i + 1;
        let resolution = current_poll_result
          ? current_poll_result.question.replace(/<[^>]+>/g, "")
          : "";
        let yes_count = 0;
        let yes_percentage = 0;
        let no_count = 0;
        let no_percentage = 0;
        let yesOptionIndex = findIndex(
          current_poll_result.options,
          (o) => {
            return o.value.toLowerCase() === "yes";
          },
          0
        );

        let noOptionIndex = findIndex(
          current_poll_result.options,
          (o) => {
            return o.value.toLowerCase() === "no";
          },
          0
        );

        if (yesOptionIndex > -1) {
          yes_count = current_poll_result.options[yesOptionIndex].vote_count;
          yes_percentage =
            current_poll_result.options[yesOptionIndex].percentage;
        }

        if (noOptionIndex > -1) {
          no_count = current_poll_result.options[noOptionIndex].vote_count;
          no_percentage = current_poll_result.options[noOptionIndex].percentage;
        }

        dataset.push({
          index,
          resolution,
          yes_count,
          yes_percentage,
          no_count,
          no_percentage,
          total_count: parseInt(yes_count) + parseInt(no_count),
        });
      }
    } else {
      for (let i = 0; i < poll_records.length; i++) {
        let { poll_result: current_poll_result } = poll_records[i];

        let index = i + 1;
        let resolution = current_poll_result
          ? current_poll_result.question.replace(/<[^>]+>/g, "")
          : "";
        let options = current_poll_result ? current_poll_result.options : [];
        let type =
          current_poll_result && current_poll_result.questionType
            ? current_poll_result.questionType.question_type
            : "";
        let tempData = {};
        let emptyData = {};
        for (let i = 0; i < options.length; i++) {
          let option = options[i];
          let percentage = option.percentage ? option.percentage : 0;
          let overall_percentage = option.overall_percentage
            ? option.overall_percentage
            : 0;
          tempData[i] = percentage.toFixed(2) + "%";
          emptyData[i] = "";
        }
        dataset.push({ index, resolution, type, ...tempData });
      }
    }

    if (
      poll &&
      (poll.election_type.election_type_id === 2 ||
        poll.election_type.election_type_id === 6)
    ) {
      dataset.push({ index: " ", resolution: " ", type: " " });
      dataset.push({
        index:
          "(B) Summary of Poll Result - Total Voting Share of All CoC Members",
        resolution: "Resolution",
        type: "Type",
        voted: " % Voters Not Voted",
      });

      for (let i = 0; i < poll_records.length; i++) {
        let { poll_result: current_poll_result } = poll_records[i];

        let index = i + 1;
        let resolution = current_poll_result
          ? current_poll_result.question.replace(/<[^>]+>/g, "")
          : "";
        let options = current_poll_result ? current_poll_result.options : [];
        let type =
          current_poll_result && current_poll_result.questionType
            ? current_poll_result.questionType.question_type
            : "";
        let tempData = {};
        let emptyData = {};
        let notVoted = 100;
        for (let i = 0; i < options.length; i++) {
          let option = options[i];
          let percentage = option.percentage ? option.percentage : 0;
          let overall_percentage = option.overall_percentage
            ? option.overall_percentage
            : 0;
          tempData[i] = overall_percentage.toFixed(2) + "%";
          notVoted -= overall_percentage.toFixed(2);
          emptyData[i] = "";
        }
        dataset.push({
          index,
          resolution,
          type,
          ...tempData,
          voted: notVoted + "%",
        });
      }

      dataset.push({ index: " ", resolution: " ", type: " " });
      dataset.push({ index: " ", resolution: " ", type: " " });
      dataset.push({
        index: "(C)Members Voting Table",
        resolution: "Resolution",
        type: "Email",
        0: "Option1",
        1: "Option2",
        2: "Option3",
      });
      for (let i = 0; i < poll_records.length; i++) {
        if (i > 0) {
          dataset.push({ index: " ", resolution: " ", type: " " });
          dataset.push({ index: " ", resolution: " ", type: " " });
        }

        let { poll, poll_result, invitees } = poll_records[i];
        if (
          poll &&
          (poll.election_type.election_type_id === 2 ||
            poll.election_type.election_type_id === 6) &&
          poll_result.questionType.question_type_id !== 4
        ) {
          let { options } = poll_result;
          let resolution = poll_result
            ? poll_result.question.replace(/<[^>]+>/g, "")
            : "";
          options = orderBy(options, ["percentage"], ["desc"]);
          poll_result.options = options;
          let data = [];

          for (let i = 0; i < options.length; i++) {
            let temp = {
              title: options[i].value,
              value: options[i].percentage ? options[i].percentage : 0,
              color: colors[i],
            };
            data.push(temp);
          }
          let inviteeOptions = {};
          let distinctInvitees = [];
          for (let i = 0; i < invitees.length; i++) {
            let { voter_id, selected_option } = invitees[i];
            if (inviteeOptions[voter_id]) {
              inviteeOptions[voter_id].push(selected_option);
            } else {
              inviteeOptions[voter_id] = [selected_option];
              distinctInvitees.push(invitees[i]);
            }
          }
          invitees = uniqBy(invitees, (i) => i.voter_id);
          let optTemp = {};
          for (let k = 0; k < options.length; k++) {
            optTemp = {
              ...optTemp,
              [k]: options[k].value,
            };
          }

          dataset.push({
            index: " ",
            resolution: " " + resolution,
            type: " ",
            ...optTemp,
          });

          for (let j = 0; j < invitees.length; j++) {
            let invitee = invitees[j];
            let name = invitee.name;
            let email = invitee.email;
            let selectedOptions = {};
            for (let l = 0; l < options.length; l++) {
              if (inviteeOptions[invitee.voter_id].includes(options[l]._id))
                selectedOptions[l] = "Yes";
              else selectedOptions[l] = " ";
            }
            dataset.push({
              index: j + 1,
              resolution: name,
              type: email,
              ...selectedOptions,
            });
          }
        } else if (poll && poll_result.questionType.question_type_id === 4) {
          let { options } = poll_result;
          let resolution = poll_result
            ? poll_result.question.replace(/<[^>]+>/g, "")
            : "";

          options = orderBy(options, ["percentage"], ["desc"]);
          poll_result.options = options;
          let data = [];

          for (let i = 0; i < options.length; i++) {
            let temp = {
              title: options[i].value,
              value: options[i].percentage ? options[i].percentage : 0,
              color: colors[i],
            };
            data.push(temp);
          }
          let inviteeOptions = {};
          let distinctInvitees = [];
          for (let i = 0; i < invitees.length; i++) {
            let { voter_id, selected_option } = invitees[i];
            if (inviteeOptions[voter_id]) {
              inviteeOptions[voter_id].push(selected_option);
            } else {
              inviteeOptions[voter_id] = [selected_option];
              distinctInvitees.push(invitees[i]);
            }
          }
          let uniqueInvitees = uniqBy(invitees, (i) => i.voter_id);
          let optTemp = {};
          for (let k = 0; k < options.length; k++) {
            optTemp = {
              ...optTemp,
              [k]: options[k].value,
            };
          }

          dataset.push({
            index: " ",
            resolution: resolution,
            type: " ",
            ...optTemp,
          });

          for (let j = 0; j < uniqueInvitees.length; j++) {
            let invitee = uniqueInvitees[j];
            let name = invitee.name;
            let email = invitee.email;
            let selectedOptions = {};
            for (let l = 0; l < options.length; l++) {
              let index = findIndex(
                invitees,
                (i) =>
                  i.selected_option === options[l]._id &&
                  i.voter_id === invitee.voter_id
              );
              if (index > -1) {
                selectedOptions[l] = invitees[index].rank;
              } else {
                selectedOptions[l] = "";
              }
            }
            dataset.push({
              index: j + 1,
              resolution: name,
              type: email,
              ...selectedOptions,
            });
          }
        }
      }
    }

    if (
      poll &&
      (poll.election_type.election_type_id === 2 ||
        poll.election_type.election_type_id === 6)
    ) {
      let total_voter_score = poll_result.total_voter_score
        ? poll_result.total_voter_score
        : 1;
      dataset.push({ index: " ", resolution: " ", type: " " });
      dataset.push({ index: " ", resolution: " ", type: " " });
      dataset.push({
        index:
          "(D) Data of Voting and Non-voting CoC Members and Corresponding Voting Share ",
        resolution: " ",
        type: " ",
      });
      dataset.push({
        index: "",
        resolution: "Name",
        type: "Email",
        0: "Voted/Not Voted",
        1: "Amount",
        2: "Voting %",
      });
      // dataset.push({ index: 'All Members List' })
      let count = 1;
      let total_amount = 0;
      let total_percentage = 0;
      let sortedArray = sortBy(all_invitees, (e) => {
        return e.is_voted;
      });
      for (let i = 0; i < sortedArray.length; i++) {
        let tempInvitee = sortedArray[i];
        let shares =
          tempInvitee.share_id && tempInvitee.share_id.share_value
            ? tempInvitee.share_id.share_value
            : 0;
        // if (!Boolean(tempInvitee.is_voted)) {

        dataset.push({
          index: count,
          resolution: tempInvitee.voter_id.name,
          type: tempInvitee.voter_id.email,
          0: tempInvitee.is_voted ? "Voted" : "Not Voted",
          1: parseFloat(shares),
          2:
            (
              (parseFloat(shares) / parseFloat(total_voter_score)) *
              100
            ).toFixed(2) + "%",
        });
        count++;
        total_amount += parseFloat(shares);
        total_percentage +=
          (parseFloat(shares) / parseFloat(total_voter_score)) * 100;
        // }
      }
      dataset.push({
        index: "",
        resolution: "",
        type: "",
        0: "",
        1: total_amount,
        2: total_percentage.toFixed(2) + "%",
      });
    }
    dataset.push({ index: " ", resolution: " ", type: " " });
    dataset.push({ index: " ", resolution: " ", type: " " });
    dataset.push({ index: " ", resolution: " ", type: " " });

    dataset.push({
      index: "Votechain Private Limited",
      resolution: "Result Date : " + moment(poll.end_date).format("DD-MM-YYYY"),
      type: " ",
    });

    setDataset(dataset);
  };

  const newPollResultFormat = async (
    currentUser,
    poll_records,
    all_invitees,
    poll,
    poll_result,
    invitees
  ) => {
    let summerizedResult = [];
    summerizedResult.push({
      colA: "E-voting platform by Votechain Private Limited",
    });

    summerizedResult.push({ colA: "" });
    summerizedResult.push({
      colA: "Report Generation Date and Time:",
      colB:
        moment(poll?.end_date).format("DD-MM-YYYY") +
        " " +
        moment(poll?.end_time, "hh:mm:ss").format("hh:mm:ss a"),
    });
    summerizedResult.push({
      colA: "Name of The Company : ",
      colB: poll?.company?.company_name,
    });
    summerizedResult.push({
      colA: "Name of The Organizer : ",
      colB: poll?.created_by_id?.name,
    });

    let winnerVote, winnerPercentage;

    for (let i = 0; i < poll_result?.options?.length; i++) {
      if (poll_result?.options[i]?.percentage > 50) {
        winnerVote = poll_result?.options[i]?.value;
        winnerPercentage = poll_result?.options[i]?.percentage;

        break;
      } else {
        winnerVote = null;
        winnerPercentage = null;
        break;
      }
    }

    summerizedResult.push({ colA: "" });
    summerizedResult.push({
      colA: "Winner : ",
      colB: `${winnerVote} (${parseFloat(winnerPercentage).toFixed(2)}%)`,
    });

    summerizedResult.push({ colA: "" });
    summerizedResult.push({
      colA: "EVSN",
      colB: "No. of folios voted",
      colC: "Total no. of CoC members",
    });
    summerizedResult.push({
      colA: poll?.poll_id,
      colB: invitees.length,
      colC: all_invitees.length,
    });

    summerizedResult.push({ colA: "" });
    summerizedResult.push({
      colA: "Voting Start Date and Time:",
      colB:
        moment(poll?.start_date).format("DD-MM-YYYY") +
        " " +
        moment(poll?.start_time, "hh:mm:ss").format("hh:mm:ss a"),
    });
    summerizedResult.push({
      colA: "Voting End Date and Time:",
      colB:
        moment(poll?.end_date).format("DD-MM-YYYY") +
        " " +
        moment(poll?.end_time, "hh:mm:ss").format("hh:mm:ss a"),
    });

    summerizedResult.push({ colA: "" });
    summerizedResult.push({
      colA: "",
      colB: "",
      colC: "<=====",
      colD: "Voted Assent (YES)",
      colE: "=====>",
      colF: "<=====",
      colG: "Voted Dissent (No)",
      colH: "=====>",
      colI: "<=====",
      colJ: "Voted Abstain",
      colK: "=====>",
      colL: "<=====",
      colM: "Not Voted (Absent)",
      colN: "=====>",
      colO: "<=====",
      colP: "Total Voting Shares",
      colQ: "=====>",
    });
    summerizedResult.push({
      colA: "Resolution #",
      colB: "Resolution",
      colC: "No of Voters",
      colD: "Voting Share",
      colE: "% of Assent (Yes)",
      colF: "No of Voters",
      colG: "Voting Share",
      colH: "% of Dissent (No)",
      colI: "No of Voters",
      colJ: "Voting Share",
      colK: "% of Abstain",
      colL: "Count of Not Voted",
      colM: "Voting Share",
      colN: "% of Absent",
      colO: "No of Members",
      colP: "Voting Share",
      colQ: "% of Total Share",
    });

    for (let i = 0; i < poll_records.length; i++) {
      const { poll, poll_result, invitees } = poll_records[i];
      let { options, total_voter_score } = poll_result;
      let notVotedInvitees = JSON.parse(JSON.stringify(all_invitees));
      let yesOptionId = null;
      let noOptionId = null;

      let yesTemp = options.filter((opt) => opt?.value?.toLowerCase() == "yes");
      let noTemp = options.filter((opt) => opt?.value?.toLowerCase() == "no");

      if (yesTemp && yesTemp.length > 0) yesOptionId = yesTemp[0]._id;

      if (noTemp && noTemp.length > 0) noOptionId = noTemp[0]._id;

      let colA = i + 1,
        colB = poll_result?.question?.replace(/<[^>]+>/g, "") || "",
        colC = 0,
        colF = 0,
        colI = 0,
        colL = all_invitees.length - invitees.length,
        colO = all_invitees.length,
        colD = 0,
        colG = 0,
        colJ = 0,
        colM = 0,
        colP = total_voter_score,
        colE = 0,
        colH = 0,
        colK = 0,
        colN = 0,
        colQ = 100;

      for (let j = 0; j < invitees.length; j++) {
        let { selected_option, share, voter_id } = invitees[j];
        if (selected_option == yesOptionId) {
          colC += 1;
          colD += parseFloat(share);
        } else if (selected_option == noOptionId) {
          colF += 1;
          colG += parseFloat(share);
        } else {
          colI += 1;
          colJ += parseFloat(share);
        }

        let index = notVotedInvitees.findIndex(
          (m) => m.voter_id._id == voter_id
        );
        if (index > -1) notVotedInvitees.splice(index, 1);
      }

      for (let k = 0; k < notVotedInvitees.length; k++) {
        const {
          share_id: { share_value },
        } = notVotedInvitees[k];
        colM += share_value ? parseFloat(share_value) : 0;
      }

      //% of Assent (Yes)
      if (colD)
        colE = parseFloat((parseFloat(colD) / parseFloat(colP)) * 100).toFixed(
          2
        );

      //% of Dissent (No)
      if (colG)
        colH = parseFloat((parseFloat(colG) / parseFloat(colP)) * 100).toFixed(
          2
        );

      //% of Abstain
      if (colJ)
        colK = parseFloat((parseFloat(colJ) / parseFloat(colP)) * 100).toFixed(
          2
        );

      //% of Absent
      if (colM)
        colN = parseFloat((parseFloat(colM) / parseFloat(colP)) * 100).toFixed(
          2
        );

      summerizedResult.push({
        colA,
        colB,
        colC,
        colD,
        colE,
        colF,
        colG,
        colH,
        colI,
        colJ,
        colK,
        colL,
        colM,
        colN,
        colO,
        colP,
        colQ,
      });
    }
    setSummerizedResult(summerizedResult);
  };

  // API Call START
  const fetchData = () => {
    const poll_id = localStorage.getItem("selected_poll_id");
    let currentUser = localStorage.getItem("currentUser");
    if (currentUser) {
      currentUser = JSON.parse(currentUser);
    }
    axiosGet("api/votes/get-result/" + poll_id)
      .then(async (result) => {
        if (result && result.code === "vote_not_caste") {
          createExcelNotCasted(result, currentUser);
        } else if (result && result.poll_records !== undefined) {
          const sendData = JSON.parse(JSON.stringify(result));
          const { poll_records, all_invitees } = result;
          const { poll, poll_result, invitees } = poll_records[0];

          let { options } = poll_result;
          options = orderBy(options, ["percentage"], ["desc"]);
          poll_result.options = options;

          //Pie Chart Data Start
          let data = [];
          for (let i = 0; i < options.length; i++) {
            let temp = {
              title: options[i].value,
              value: options[i].percentage ? options[i].percentage : 0,
              color: colors[i],
            };
            data.push(temp);
          }
          //Pie Chart Data Start

          let inviteeOptions = {};
          let distinctInvitees = [];
          for (let i = 0; i < invitees.length; i++) {
            let { voter_id, selected_option } = invitees[i];
            if (inviteeOptions[voter_id]) {
              inviteeOptions[voter_id].push(selected_option);
            } else {
              inviteeOptions[voter_id] = [selected_option];
              distinctInvitees.push(invitees[i]);
            }
          }

          if (
            moment(poll?.end_date).diff(
              moment("24-03-2023", "DD-MM-YYYY"),
              "days"
            ) > -1
          ) {
            newPollResultFormat(
              currentUser,
              poll_records,
              all_invitees,
              poll,
              poll_result,
              invitees
            );
          } else {
            oldPollResultFormat(
              currentUser,
              poll_records,
              all_invitees,
              poll,
              poll_result,
              invitees
            );
          }

          setAllInvitees(all_invitees);
          setAppData({ ...appData, poll });
          setPollRecords(poll_records);
          setInviteeOptions(inviteeOptions);
          setPoll(poll);
          setPollResult(poll_result);
          setPieData(data);
          setInvitee(distinctInvitees);
          detailedReportIBC(sendData);
        } else {
          setFetchSuccess(true);
        }
      })
      .catch((err) => {
        console.log("Error first", err);
        setFetchSuccess(true);
      });
  };

  const handlePollIndexChange = (value) => {
    let tempIndex = poll_index + value;

    if (tempIndex < poll_records.length) {
      let poll_record = poll_records[tempIndex];
      let { poll, poll_result, invitees } = poll_record;
      var { options } = poll_result;
      options = orderBy(options, ["score_value"], ["desc"]);
      poll_result.options = options;
      let data = [];
      for (let i = 0; i < options.length; i++) {
        let temp = {
          title: options[i].value,
          value: options[i].percentage,
          color: colors[i],
        };
        data.push(temp);
      }

      let inviteeOptions = {};
      let distinctInvitees = [];
      for (let i = 0; i < invitees.length; i++) {
        let { voter_id, selected_option } = invitees[i];
        if (inviteeOptions[voter_id]) {
          inviteeOptions[voter_id].push(selected_option);
        } else {
          inviteeOptions[voter_id] = [selected_option];
          distinctInvitees.push(invitees[i]);
        }
      }

      setInviteeOptions(inviteeOptions);
      setPollIndex(tempIndex);
      setPoll(poll);
      setPollResult(poll_result);
      setPieData(data);
      setInvitee(distinctInvitees);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const createExcelSheetColumn = (value) => {
    let htmlData = [];
    let loopValue = 65 + parseInt(value);
    for (let i = 65; i <= loopValue; i++) {
      htmlData.push(
        <ExcelColumn
          key={"col" + i}
          label=""
          value={"col" + String.fromCharCode(i)}
        />
      );
    }
    return [...htmlData];
  };

  // API CALl END
  return (
    <DashboardScreen>
      {not_casted ? (
        <Box>
          <Grid container className={classes1.TitleBand} alignItems="center">
            <Grid item>
              <NavLink
                to={{
                  pathname: "/dashboard/mypoll",
                  // state: { title: 'hidebutton', fromPage: 'add-question' },
                }}
                style={{ color: "#fff", textDecoration: "none" }}
              >
                <ArrowBackRounded style={{ width: "30px", height: "30px" }} />
              </NavLink>
            </Grid>
            <Grid item sm={10}>
              <Typography variant="h5">Poll Results</Typography>
            </Grid>
          </Grid>
          <CardContent id="divToPrint">
            <PollDetails />
            <TableContainer className="poll-question">
              <Typography
                gutterBottom
                variant="h5"
                component="h5"
                style={{
                  marginTop: "20px",
                  fontWeight: "500",
                  marginLeft: "15px",
                }}
              >
                Poll Result
              </Typography>

              <Grid container justify="flex-end" alignItems="center">
                <Grid item xs={12} sm={3} className={classes.ResponsiveButtons}>
                  <ExcelFile
                    element={
                      <DownloadButton variant="outlined" color="primary">
                        Download as Excel Sheet
                      </DownloadButton>
                    }
                  >
                    <ExcelSheet data={summerizedResult} name="Poll Result">
                      <ExcelColumn label="" value="index" />
                      <ExcelColumn label="" value="resolution" />
                      <ExcelColumn label="" value="type" />
                      <ExcelColumn label="" value="0" />
                      <ExcelColumn label="" value="1" />
                      <ExcelColumn label="" value="2" />
                      <ExcelColumn label="" value="3" />
                      <ExcelColumn label="" value="4" />
                    </ExcelSheet>
                  </ExcelFile>
                </Grid>
                <Modal open={open} onClose={handleClose}>
                  <CreatePdf
                    poll_records={poll_records}
                    all_invitees={all_invitees}
                  />
                </Modal>
              </Grid>
            </TableContainer>
            <br />
            <br />
            <br />
            <br />
            <Grid container spacing={2}>
              {/* <Paper className={classes.chartpaper} variant="outlined" square> */}
              <Grid container style={{ justifyContent: "center" }}>
                <Typography variant="h4" style={{ textAlign: "center" }}>
                  The result can't be generated. <br />
                  No one has caste vote.
                </Typography>
              </Grid>
              {/* </Paper> */}
            </Grid>
          </CardContent>
        </Box>
      ) : poll_records.length > 0 ? (
        <Box>
          <Grid container className={classes1.TitleBand} alignItems="center">
            <Grid item>
              <NavLink
                to={{
                  pathname: "/dashboard/mypoll",
                  // state: { title: 'hidebutton', fromPage: 'add-question' },
                }}
                style={{ color: "#fff", textDecoration: "none" }}
              >
                <ArrowBackRounded style={{ width: "30px", height: "30px" }} />
              </NavLink>
            </Grid>
            <Grid item sm={10}>
              <Typography variant="h5">Poll Results</Typography>
            </Grid>
          </Grid>
          <CardContent id="divToPrint">
            <PollDetails />
            <TableContainer className="poll-question">
              <Typography
                gutterBottom
                variant="h5"
                component="h5"
                style={{
                  marginTop: "20px",
                  fontWeight: "500",
                  marginLeft: "15px",
                }}
              >
                Poll Result
              </Typography>

              <Grid container justify="flex-end" alignItems="center">
                <Grid item xs={12} sm={2} className={classes.ResponsiveButtons}>
                  <DownloadButton
                    onClick={() => setOpen(true)}
                    variant="outlined"
                    color="primary"
                  >
                    Download as PDF File
                  </DownloadButton>
                </Grid>
                <Grid item xs={12} sm={3} className={classes.ResponsiveButtons}>
                  {moment(poll?.end_date).diff(
                    moment("24-03-2023", "DD-MM-YYYY"),
                    "days"
                  ) > -1 ? (
                    <ExcelFile
                      element={
                        <DownloadButton variant="outlined" color="primary">
                          Download as Excel Sheet
                        </DownloadButton>
                      }
                    >
                      <ExcelSheet
                        data={summerizedResult}
                        name="Summarized Result"
                      >
                        {createExcelSheetColumn(17)}
                      </ExcelSheet>
                      <ExcelSheet data={detailedResult} name="Detailed Report">
                        {createExcelSheetColumn(12)}
                      </ExcelSheet>
                    </ExcelFile>
                  ) : (
                    <ExcelFile
                      element={
                        <DownloadButton variant="outlined" color="primary">
                          Download as Excel Sheet
                        </DownloadButton>
                      }
                    >
                      <ExcelSheet data={dataset} name="Poll Result">
                        <ExcelColumn label="" value="index" />
                        <ExcelColumn label="" value="resolution" />
                        <ExcelColumn label="" value="type" />
                        {poll_result && poll_result.options.length > 0
                          ? poll_result.options.map((option, index) => (
                              <ExcelColumn label="" value={index} />
                            ))
                          : null}
                        <ExcelColumn label="" value="voted" />
                      </ExcelSheet>
                    </ExcelFile>
                  )}
                </Grid>
                <Modal open={open} onClose={handleClose}>
                  <CreatePdf
                    poll_records={poll_records}
                    all_invitees={all_invitees}
                  />
                </Modal>
              </Grid>
            </TableContainer>

            <Grid container className={classes.CardContainer}>
              <Grid item sm={12} xs={12}>
                <Paper
                  style={{
                    padding: "2rem",
                    background: "#fff",
                    boxShadow: "0px 0px 20px rgb(57 57 57 / 15%)",
                    borderRadius: "10px",
                  }}
                >
                  <p
                    style={{
                      textAlign: "center",
                      fontSize: "1.1rem",
                    }}
                  >
                    <span style={{ float: "left" }}>
                      {poll_index + 1}/{poll_records.length}
                    </span>
                    <span style={{ fontWeight: 600, marginLeft: "5px" }}>
                      {poll_result && poll_result.questionType
                        ? "Question Type : " +
                          poll_result.questionType.question_type
                        : ""}
                    </span>
                  </p>

                  {poll_index > 0 ? (
                    <Box className={classes.ArrowIconL}>
                      <KeyboardArrowLeftRoundedIcon
                        color="primary"
                        fontSize="large"
                        onClick={() => handlePollIndexChange(-1)}
                      />
                    </Box>
                  ) : (
                    <Box className={classes.ArrowIconL1}>
                      <KeyboardArrowLeftRoundedIcon
                        color="primary"
                        fontSize="large"
                      />
                    </Box>
                  )}

                  {poll_records &&
                  poll_records.length - 1 > parseInt(poll_index) ? (
                    <KeyboardArrowRightRoundedIcon
                      className={classes.ArrowIconR}
                      color="primary"
                      fontSize="large"
                      onClick={() => handlePollIndexChange(1)}
                    />
                  ) : (
                    <KeyboardArrowRightRoundedIcon
                      className={classes.ArrowIconR1}
                      color="primary"
                      fontSize="large"
                    />
                  )}

                  <Grid
                    style={{
                      minHeight: "50px",
                      maxHeight: "500px",
                      // overflowY: 'scroll',
                      margin: "20px",
                      border: "1px solid #579bff",
                      background: "#fff",
                      boxShadow: "0px 0px 20px rgb(57 57 57 / 15%)",
                      borderRadius: "10px",
                    }}
                  >
                    <Typography
                      className={classes.Optionhead}
                      gutterBottom
                      variant="h5"
                      component="h2"
                      dangerouslySetInnerHTML={{
                        __html: poll_result && poll_result.question,
                      }}
                    />
                  </Grid>
                </Paper>
              </Grid>
            </Grid>

            <Grid container spacing={2} className={classes.CardContainer}>
              <Grid item xs={12} sm={12} lg={6} xl={6} md={6}>
                <MyCard className={classes.CardOption}>
                  <Grid>
                    {poll_result &&
                      poll_result.options.length > 0 &&
                      poll_result.options.map((option, index) => {
                        return index === 0 ? (
                          <Grid item xs={12}>
                            <Para1>
                              <Bullet
                                bulletColor={colors[index]}
                                height="12px"
                                width="12px"
                              />
                              &nbsp;
                              {option.value}
                              <span className={classes.subtitle}>(Winner)</span>
                              <Percentage>
                                {option.percentage
                                  ? option.percentage.toFixed(2) + "%"
                                  : "0.00%"}
                              </Percentage>
                            </Para1>
                          </Grid>
                        ) : (
                          <Grid item xs={12}>
                            <Para2>
                              <Bullet
                                bulletColor={colors[index]}
                                height="10px"
                                width="10px"
                              />
                              &nbsp;
                              {option.value}
                              <OtherPercentage>
                                {option.percentage
                                  ? option.percentage.toFixed(2) + "%"
                                  : "0.00%"}
                              </OtherPercentage>
                            </Para2>
                          </Grid>
                        );
                      })}

                    <Para3 className={classes.paraOptionstyle}>
                      Total Votes &emsp;&nbsp;{" "}
                      <span className={classes.Winner}>
                        {poll_result.total_votes}
                      </span>
                    </Para3>
                    <Para3 className={classes.paraOptionstyle}>
                      Total invitees&ensp;{" "}
                      <span className={classes.Winner}>
                        {poll_result.invitee}
                      </span>
                    </Para3>
                    <Para3 className={classes.paraOptionstyle}>
                      Skipped &ensp; &ensp; &emsp;{" "}
                      <span className={classes.Winner}>
                        {poll_result.skipped}
                      </span>
                    </Para3>
                  </Grid>
                </MyCard>
              </Grid>

              <Grid item xs={12} sm={6} lg={6} xl={6} md={6}>
                <MyCard className={classes.CardOption}>
                  <PieChart
                    lineWidth={50}
                    paddingAngle={15}
                    data={pieData && pieData.length > 0 ? pieData : []}
                  />
                </MyCard>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Paper className={classes.chartpaper} variant="outlined" square>
                <Grid container>
                  <TableRow className={classes.tablechartrow}>
                    {poll_result &&
                      poll_result.options &&
                      poll_result.options.map((option, index) => {
                        return index === 0 ? (
                          <TableCell
                            className={classes.tablechartCellblue}
                            style={{
                              minWidth:
                                option.percentage && option.percentage > 50
                                  ? option.percentage.toFixed(2) + "%"
                                  : `50%`,
                              borderRadius:
                                poll_result.options.length - 1 === index
                                  ? lastCellItem
                                  : null,
                            }}
                          >
                            {option.value} (Winner){" "}
                            {option.percentage
                              ? option.percentage.toFixed(2) + "%"
                              : null}
                          </TableCell>
                        ) : index === 1 ? (
                          <TableCell
                            className={classes.tablechartCellYellow}
                            style={{
                              minWidth:
                                option.percentage &&
                                poll_result.options.length - 1 !== index
                                  ? option.percentage.toFixed(2) + "%"
                                  : "1%",
                              borderRadius:
                                poll_result.options.length - 1 === index
                                  ? lastCellItem
                                  : null,
                            }}
                          >
                            {option.value}{" "}
                            {option.percentage
                              ? option.percentage.toFixed(2) + "%"
                              : null}
                          </TableCell>
                        ) : index === 2 ? (
                          <TableCell
                            className={classes.tablechartCellOrange}
                            style={{
                              minWidth:
                                option.percentage &&
                                poll_result.options.length - 1 !== index
                                  ? option.percentage.toFixed(2) + "%"
                                  : "1%",
                              borderRadius:
                                poll_result.options.length - 1 === index
                                  ? lastCellItem
                                  : null,
                            }}
                          >
                            {option.value}{" "}
                            {option.percentage
                              ? option.percentage.toFixed(2) + "%"
                              : null}
                          </TableCell>
                        ) : index === 3 ? (
                          <TableCell
                            className={classes.tablechartCellDarkblue}
                            style={{
                              minWidth:
                                option.percentage &&
                                poll_result.options.length - 1 !== index
                                  ? option.percentage.toFixed(2) + "%"
                                  : "1%",
                              borderRadius:
                                poll_result.options.length - 1 === index
                                  ? lastCellItem
                                  : null,
                            }}
                          >
                            {option.value}{" "}
                            {option.percentage
                              ? option.percentage.toFixed(2) + "%"
                              : null}
                          </TableCell>
                        ) : index === 4 ? (
                          <TableCell
                            className={classes.tablechartCellGreen}
                            style={{
                              minWidth:
                                option.percentage &&
                                poll_result.options.length - 1 !== index
                                  ? option.percentage.toFixed(2) + "%"
                                  : "1%",
                              borderRadius:
                                poll_result.options.length - 1 === index
                                  ? lastCellItem
                                  : null,
                            }}
                          >
                            {option.value}{" "}
                            {option.percentage
                              ? option.percentage.toFixed(2) + "%"
                              : null}
                          </TableCell>
                        ) : index === 5 ? (
                          <TableCell
                            className={classes.tablechartCellRed}
                            style={{
                              minWidth:
                                option.percentage &&
                                poll_result.options.length - 1 !== index
                                  ? option.percentage.toFixed(2) + "%"
                                  : "1%",
                              borderRadius:
                                poll_result.options.length - 1 === index
                                  ? lastCellItem
                                  : null,
                            }}
                          >
                            {option.value}{" "}
                            {option.percentage
                              ? option.percentage.toFixed(2) + "%"
                              : null}
                          </TableCell>
                        ) : (
                          <TableCell
                            className={classes.tablechartCellOlive}
                            style={{
                              minWidth:
                                option.percentage &&
                                poll_result.options.length - 1 !== index
                                  ? option.percentage.toFixed(2) + "%"
                                  : "1%",
                              borderRadius:
                                poll_result.options.length - 1 === index
                                  ? lastCellItem
                                  : null,
                            }}
                          >
                            {option.value}{" "}
                            {option.percentage
                              ? option.percentage.toFixed(2) + "%"
                              : null}
                          </TableCell>
                        );
                      })}
                  </TableRow>
                </Grid>
              </Paper>
            </Grid>

            <Para4 style={{ fontSize: "1.2rem", fontWeight: "500" }}>
              Poll Winner
            </Para4>
            <Box className={classes.PollWinner}>
              {poll_result && poll_result.options && (
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <p>1 &nbsp; {poll_result.options[0].value}</p>
                  </Grid>
                  <Grid item xs={4}>
                    <p>Vote Count : {poll_result.options[0].vote_count}</p>
                  </Grid>
                  <Grid item xs={4}>
                    Percentage:{" "}
                    {" " + poll_result.options[0].percentage.toFixed(2) + "%"}
                  </Grid>
                </Grid>
              )}
            </Box>
            {poll_result &&
              poll_result.options &&
              poll_result.options.map(
                (option, index) =>
                  index > 0 && (
                    <Box className={classes[`Other${index + 1}`]}>
                      <Grid container spacing={2}>
                        <Grid item xs={4}>
                          <p>
                            {index + 1} &nbsp; {option.value}
                          </p>
                        </Grid>
                        <Grid item xs={4}>
                          <p>Vote Count : {option.vote_count}</p>
                        </Grid>
                        <Grid item xs={4}>
                          Percentage:{" "}
                          {option.percentage
                            ? option.percentage.toFixed(2) + "%"
                            : "0.00%"}
                        </Grid>
                      </Grid>
                    </Box>
                  )
              )}

            {/* for ibc only */}
            {poll &&
            (poll.election_type.election_type_id === 2 ||
              poll.election_type.election_type_id === 6) ? (
              <>
                <Typography style={{ fontWeight: "bold", fontSize: "18px" }}>
                  Voting Table
                </Typography>
                <TableContainer component={Paper}>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>S.No.</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell align="right">Share</TableCell>
                        {poll_result &&
                          poll_result.options &&
                          poll_result.options.map((option, index) => (
                            <TableCell align="right">{option.value}</TableCell>
                          ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {invitees &&
                        invitees.length > 0 &&
                        invitees.map((invitee, index) => (
                          <TableRow key={index}>
                            <TableCell component="th" scope="row">
                              <Box
                                style={{
                                  color: "#fff",
                                  background: "gray",
                                  fontWeight: "500",
                                  borderRadius: "50%",
                                  textAlign: "center",
                                  border: "1px solid gray",
                                  fontSize: "1rem",
                                  width: "25px",
                                  display: "flex",
                                  alignItems: "center",
                                  height: "25px",
                                  justifyContent: "center",
                                }}
                              >
                                {index + 1}
                              </Box>
                              {/* {index + 1} */}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {invitee.name}
                            </TableCell>
                            <TableCell
                              align="right"
                              style={{ fontWeight: "bold" }}
                            >
                              {invitee.share
                                ? parseFloat(invitee.share).toFixed(2)
                                : "00.00"}
                            </TableCell>
                            {poll_result &&
                              poll_result.options &&
                              poll_result.options.map((option, index) => (
                                <TableCell align="right">
                                  {poll &&
                                  poll.election_type.election_type_id === 3 ? (
                                    <span
                                      style={{
                                        padding: "5px 12px 5px 12px",
                                        backgroundColor: colors[index],
                                        borderRadius: "20%",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {option.score_value}
                                    </span>
                                  ) : inviteeOptions[invitee.voter_id].includes(
                                      option._id
                                    ) ? (
                                    <span
                                      style={{
                                        padding: "5px 12px 5px 12px",
                                        backgroundColor: colors[index],
                                        borderRadius: "20%",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      YES
                                    </span>
                                  ) : (
                                    <span style={{ padding: "5px" }}>NO</span>
                                  )}
                                </TableCell>
                              ))}
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            ) : null}

            {/* for ibc only */}
          </CardContent>
        </Box>
      ) : fetchSuccess ? (
        <Grid container spacing={2} className={classes.NoResult}>
          <Grid item xs={12} sm={12}>
            <Alert severity="error" style={{ fontSize: 20 }}>
              <AlertTitle style={{ fontSize: 25, marginTop: "-10px" }}>
                No Records Found
              </AlertTitle>
              Report cannot be Generated because no vote has been cast.
            </Alert>
          </Grid>
        </Grid>
      ) : null}
    </DashboardScreen>
  );
};

export default PollResult;
