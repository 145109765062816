import { makeStyles } from "@material-ui/core/styles";
import { styled } from "@material-ui/core/styles";
import { TableCell, Typography } from "@material-ui/core";

export const MyPollStyle = makeStyles((theme) => ({
  root: {
    flexGrow: 1,

    paddingTop: "20px",
  },
  card: {
    background: "#fff",
    boxShadow: "0px 0px 20px rgba(57, 57, 57, 0.15)",
    borderRadius: "10px",
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  responsivebtn: {
    width: "300px",
    [theme.breakpoints.down("sm")]: {
      width: "auto",
    },
  },
  NoPollGrid: {
    height: "300px",
    width: "70%",
    border: "5px solid #0B2F64",
    margin: "auto",
    borderRadius: "5px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  createPollBtn: {
    marginBottom: "10px",
    marginTop: "1rem",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      marginRight: "0",
      justifyContent: "center",
    },
  },

  control: {
    padding: theme.spacing(2),
  },

  //side card media
  media: {
    width: "150px",
    height: "150px",
    margin: "50px auto",
  },

  span: {
    fontStyle: "normal",
    fontSize: "18px",
    lineHeight: "22px",
    textAlign: "center",
    color: "#000000",
    fontWeight: 500,
  },

  ".nav &a": {
    "&. MuiTypography-root:hover": {
      textDecoration: "none",
      color: "#fff",
    },
  },

  menuItem: {
    display: "flex",
    alignItems: "center",
    paddingLeft: "30px",
    color: "grey",
    "&:hover": {
      backgroundColor: "#1171FF",
      borderTopLeftRadius: "30px",
      borderBottomRightRadius: "10px",
      color: "#fff",
    },
  },

  // },
  menuItemTitle: {
    marginLeft: 16,
  },

  tablecell: {
    borderBottom: "none",
  },

  para: {
    fontSize: "16px",
    lineHeight: "19px",
    textAlign: "center",
    color: "#000000",
    fontStyle: "normal",
    fontWeight: "normal",
  },

  Button: {
    marginLeft: "auto",
    background: "#007bff",
    padding: "8px 45px",
    borderRadius: "10px",
    fontWeight: "500",
    fontSize: "12px",
    color: "#fff",
  },

  TableRow: {
    fontWeight: "400",
  },

  Arrowicon1: {
    marginBottom: "8%",
    marginTop: "5%",
    fontSize: "none",
    float: "left",
    zIndex: 100,
    backgroundColor: "#e0e0e0",
    borderRadius: "50%",
  },

  Arrowicon2: {
    marginBottom: "8%",
    marginTop: "5%",
    fontSize: "none",
    float: "right",
    zIndex: 100,
    backgroundColor: "#e0e0e0",
    borderRadius: "50%",
  },

  PollStatus: {
    boxShadow: "1px 2px rgb(220,220,220)",
    borderRadius: "10px",
    marginBottom: "10px",
    border: "1px solid rgb(220,220,220)",
    padding: "13px 20px 7px 10px",
    marginTop: "15px",
    fontSize: "15px",

    "&:hover": {
      border: "1px solid #1171FF",
    },
  },
  AddQue: {
    padding: "15px",
    textAlign: "center",
    width: "200px",
    border: "1px solid grey",
    borderRadius: "12px",
    minHeight: "5rem",
  },

  removebtn: {
    backgroundColor: "#007bff",
    borderRadius: "6px",
    color: "white",
    display: "flex",
    fontWeight: "400",
    "&:hover": {
      color: "#3f51b5",
    },
  },
  btn: {
    fontWeight: "400",
    fontSize: "14px",
    marginTop: "10px",
    color: "#02366e",
  },
  btn1: {
    fontWeight: "400",
    fontSize: "14px",
    color: "#02366e",
  },
  icondiv: {
    display: "flex",
  },
  icon: {
    margin: "auto",
  },
  started: {
    fontSize: "14px",
    color: "#007bff",
    fontWeight: "400",
  },
  closed: {
    fontSize: "14px",
    color: "#fc0b03",
    fontWeight: "500",
  },
  scheduled: {
    fontSize: "14px",
    fontWeight: "500",
    color: "gray",
  },
  expired: {
    fontSize: "14px",
    color: "#FFA500",
    fontWeight: "500",
  },
  polltitle: {
    fontWeight: "500",
    "&:hover": {
      whiteSpace: "normal",
    },
  },
  timing: {
    fontWeight: "500",
    fontSize: "13px",
  },
  Datetime: {
    fontWeight: "400",
    fontSize: "18px",
  },
  Datetime1: {
    fontWeight: "400",
  },
  link: {
    textDecoration: "underline",
    color: "#007bff",
  },
  linkBtn: {
    color: "#fff",
    marginLeft: "auto",
    padding: "0px 10px",
    textAlign: "right",
    marginRight: 35,
    textDecoration: "none",
  },

  linkpollresult: {
    width: 200,
  },

  loadingGrid: {
    height: window.innerHeight - 500,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  loadingIcon: {
    marginTop: "-100px",
  },

  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "5px",
  },
  paper: {
    height: "400px",
    width: "865px",
    background: "#FFFFFF",
    // boxShadow: "0px 0px 20px rgba(57, 57, 57, 0.15)",
    borderRadius: "20px",
    padding: "40px",
    border: "2px solid grey",
  },
  dateNtime: {
    border: "1px solid #E5E5E5",
    minWidth: "86%",
    background: "#FFFFFF",
    /* box-shadow: inset 0px 0px 5px 1px rgb(144 144 144 / 15%); */
    boxSizing: "border-box",
    borderRadius: "5px",
  },
}));

export const Para = styled(Typography)({
  fontSize: "16px",
  lineHeight: "19px",
  textAlign: "center",
  color: "#000000",
  fontStyle: "normal",
  fontWeight: "normal",
});

export const MyTableCell = styled(TableCell)({
  borderBottom: "none",
  paddingTop: "0px",
  paddingBottom: "5px",
  paddingRight: "40px,",
});
