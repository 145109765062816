import {
  Card,
  CardContent,
  Grid,
  Modal,
  Typography,
  VotingPageStyles,
  Button2,
  useState,
  Link,
  useEffect,
  useContext,
  GlobalContext,
  axiosGet,
} from '../../collections/Imports';
import useAPIError from '../../hooks/useAPIError';
import {alphabetArray, INSTRUCTIONS} from './Constants';
import ModalContent from './ModalContent';
import VotingServices from './VotingServices';

const MainVoteCard = props => {
  const {shares, voter} = props;
  const classes = VotingPageStyles ();
  const [open, setOpen] = useState (false);
  const [appData] = useContext (GlobalContext);
  const [questions, setQuestions] = useState ([]);
  // const [voter] = useState (JSON.parse (localStorage.getItem ('voter')));
  const [isLoading, setIsLoading] = useState (true);
  const [electionType, setElectionType] = useState ('');

  const handleModalOpen = () => {
    setOpen (true);
  };

  const handleModalClose = () => {
    setOpen (false);
  };
  const {addError} = useAPIError ();
  const poll_id = appData && appData.selected_poll_id
    ? appData.selected_poll_id
    : localStorage.getItem ('selected_poll_id');
  const voter_id = voter._id;

  useEffect (
    () => {
      const params = {
        voter_id,
        poll_id,
      };
      const onSuccess = res => {
        if (res) {
          const tempQuestions = res.questions.map (question => ({
            ...question,
            options: question.options.map ((option, i) => ({
              ...option,
              option: `option ${alphabetArray[i]}`,
              option_id: option._id,
            })),
          }));
          setQuestions (tempQuestions);
          setIsLoading (false);
        }
      };
      VotingServices.getQuestionCastStatus (params, onSuccess, addError);
    },
    [addError, poll_id, voter_id]
  );

  useEffect (
    () => {
      const poll_id = localStorage.getItem ('selected_poll_id');
      const getData = () => {
        axiosGet (`api/polls/id/${poll_id}`)
          .then (res => {
            if (res) setElectionType (res.poll.election_type.election_type_id);
          })
          .catch (err => {
            addError (`${err}`);
          });
      };
      getData ();
    },
    [addError]
  );

  return (
    <Card className={classes.card}>
      <CardContent style={{background: ''}} align="center">
        <Grid
          item
          style={{
            backgroundColor: '#0B2F64',
          }}
        >
          <Typography
            gutterBottom
            variant="h4"
            style={{
              fontWeight: '400',
              color: '#FFFFFF',
              backgroundColor: '#0B2F64',
            }}
          >
            Instructions
          </Typography>
        </Grid>

        {INSTRUCTIONS.map ((item, index) => {
          return (
            <Typography
              variant="subtitle1"
              align="justify"
              display="block"
              gutterBottom
              style={{
                fontWeight: '400',
                // paddingLeft: '5px',
              }}
            >
              {index + 1}. {`${item}`}.
            </Typography>
          );
        })}
        <Typography
          component="h6"
          align="justify"
          display="block"
          style={{paddingBottom: '20px'}}
        >
          10. In case of any help or query write us at
          <Link
            href="mailto: enquiry@eazyvc.com"
            style={{paddingLeft: '5px', fontWeight: '500'}}
          >
             enquiry@eazyvc.com
          </Link>
        </Typography>

        <Grid item xs={12} sm={6}>
          <Button2
            width="264px"
            height="60px"
            fontWeight="500"
            onClick={handleModalOpen}
          >
            Start Voting
          </Button2>

          <Modal
            className={classes.modal}
            open={open}
            // onClose={handleModalClose}
            // BackdropComponent={Backdrop}
            // BackdropProps={{
            //   timeout: 500,
            // }}
            scroll="paper"
          >
            <ModalContent
              totalShares={shares}
              handleModalClose={handleModalClose}
              questions={questions}
              isLoading={isLoading}
              setQuestions={setQuestions}
              voter={voter}
              electionType={electionType}
            />
          </Modal>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default MainVoteCard;
