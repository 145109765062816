import React, { useEffect } from 'react';
import {
  Typography,
  Button,
  Card,
  Grid,
  useState,
  useContext,
  useHistory,
  GlobalContext,
  moment,
  Box,
  axiosGet,
  SpinGif,
  CardContent,
} from '../../collections/Imports';
import useAPIError from './../../hooks/useAPIError';
import Mobile from '../../../../src/img/Mobile.webp';
import WelcomeStyle from './WelcomeStyle';

const Welcome = (props) => {
  const { addError } = useAPIError();
  const classes = WelcomeStyle();
  const [appData, setAppData] = useContext(GlobalContext);
  const [poll_start, setPollStart] = useState(0)
  const [daysDiff, setDaysDiff] = useState(0);
  const [hoursDiff, setHoursDiff] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [remMinutes, setRemMinutes] = useState(0)
  const [remSeconds, setRemSeconds] = useState(0)

  const [voter, setVoter] = useState({
    _id: '',
    name: '',
    email: '',
  });
  const [pollData, setPollData] = useState({});
  const [isStarted, setIsStarted] = useState(false);
  const [locationKeys, setLocationKeys] = useState([]);
  const history = useHistory();

  useEffect(() => {
    var voter = localStorage.getItem('voter');
    if (voter) {
      voter = JSON.parse(voter);
      setVoter(voter);
      setAppData({
        ...appData,
        from_location: '/welcome',
      });
    } else {
      props.history.push('/');
    }
  }, []);

  useEffect(() => {
    let poll_id = localStorage.getItem('selected_poll_id');
    try {
      axiosGet(`api/polls/id/${poll_id}`)
        .then((res) => {
          setPollData(res.poll);
          setIsLoading(false);
        })
        .catch((err) => console.log(err));
    } catch (error) {
      addError(`LOAD_DATA_ERROR: ${error}`);
    }
  }, [addError]);

  useEffect(() => {

    let start = moment(
      moment(pollData.start_date).format('YYYY.MM.DD') +
      ' ' +
      pollData.start_time,
      moment.defaultFormat
    ).format();

    setPollStart(start)

    if (moment().diff(moment(start), 'seconds') > 1) {
      setIsStarted(true)
    } else {
      setIsStarted(false)
    }

    let hoursDiff = moment(start).diff(moment(), 'hours');
    let daysDiff = moment(start).diff(moment(), 'days');
    setDaysDiff(daysDiff);
    setHoursDiff(hoursDiff);
  }, [pollData]);

  useEffect(() => {
    return history.listen((location) => {
      if (history.action === 'PUSH') {
        setLocationKeys([location.key]);
      }

      if (history.action === 'POP') {
        if (locationKeys[1] === location.key) {
          // Handle forward event
          localStorage.clear()
          props.history.push('/login');
        } else {
          // Handle back event
          localStorage.clear()
          props.history.push('/login');
        }
      }
    });
  }, [locationKeys]);

  useEffect(() => {
    if (daysDiff === 0 && hoursDiff === 0) {
      setRemMinutes(moment(poll_start).diff(moment(), 'minutes'))
      setRemSeconds(moment(poll_start).diff(moment(), 'seconds') % 60)
    }
    setTimeout(() => setRemSeconds(moment(poll_start).diff(moment(), 'seconds') % 60), 1000)
  }, [daysDiff, hoursDiff, remSeconds])

  useEffect(() => {
    if (remMinutes === 0 && remSeconds === 0)
      setIsStarted(true)
  }, [remSeconds])

  return (
    <Box className={classes.mainBody} style={{ overflow: 'auto' }}>
      {isLoading ? (
        <Grid
          container
          justify="center"
          alignItems="center"
          direction="column"
          style={{ paddingTop: '100px' }}
        >
          <Grid item>
            <img src={SpinGif} alt="spinner" />
          </Grid>
          <Grid item>
            <Typography variant="h5">Fetching Information...</Typography>
          </Grid>
        </Grid>
      ) : (
        <Grid container>
          <Card className={classes.card}>
            <img src={Mobile} width="70%" alt="Logo" className={classes.mobileImage} />
            <Typography
              variant="h3"
              gutterBottom
              style={{
                textAlign: 'center',
                fontWeight: 500,
                color: '#414448',
              }}
            >
              Hi, <span style={{ color: '#1171FF' }}>{voter.name}</span>
            </Typography>

            {isStarted ? (
              <>
                <Box align="center">
                  <Typography style={{ fontSize: '15px', fontWeight: '500' }} variant="caption" gutterBottom>
                    Welcome to your Voting Session.
                  </Typography>
                </Box>

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  onClick={() => props.history.push('/voting')}
                >
                  START VOTING SESSION
                </Button>
              </>
            ) : (
              <>
                <CardContent>
                  <Typography
                    style={{
                      textAlign: 'center',
                      margin: '0 auto',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                    variant="caption"
                    gutterBottom
                  >
                    Your voting session will start in....
                  </Typography>
                  <Typography
                    style={{
                      marginTop: '10px',
                      textAlign: 'center',
                      fontWeight: 500,
                      color: '#414448',
                    }}
                    variant="h5"
                  >
                    {
                      (parseInt(daysDiff) || parseInt(hoursDiff)) ?

                        (parseInt(daysDiff) + ' Days & ' + parseInt(hoursDiff) + 'Hours') :
                        remMinutes + ':' + remSeconds
                    }

                  </Typography>
                </CardContent>
              </>
            )}
          </Card>
        </Grid>
      )}
    </Box>
  );
};

export default Welcome;
