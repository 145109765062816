import { makeStyles } from "@material-ui/styles";

const DashBoardScreenStyles = makeStyles((theme) => ({
  root: {
    paddingTop: "20px",
  },
  card: {
    background: "#fff",
    boxShadow: "0px 0px 20px rgba(57, 57, 57, 0.15)",
    borderRadius: "10px",
    minHeight: "800px",
    marginLeft: "290px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0px",
    },
  },

  sidecard: {
    minHeight: "775px",
    minWidth: "280px",
    maxWidth: "280px",
    background: "#fffff",
    boxShadow: "0px 0px 20px rgba(57, 57, 57, 0.15)",
    borderRadius: "10px",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },

  dashTitleheading: {
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "30px",
    lineHeight: "43px",
    color: "#1171FF",
  },
  sidecardTitle: {
    textTransform: "capitalize",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "29px",
    textAlign: "center",
    color: "#0b2f64",
  },

  sidecardSubTitle: {
    marginBottom: "20px",
    fontSize: "16px",
    lineHeight: "19px",
    textAlign: "center",
  },

  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },

  control: {
    padding: theme.spacing(2),
  },

  //side card media
  media: {
    height: "138px",
    width: " 138px",
    borderRadius: "100px",
    marginTop: "30px",
    marginBottom: "27px",
  },

  span: {
    fontStyle: "normal",
    fontSize: "18px",
    lineHeight: "22px",
    textAlign: "center",
    color: "#000000",
    fontWeight: 400,
  },

  ".nav &a": {
    "&. MuiTypography-root:hover": {
      textDecoration: "none",
      color: "#fff",
    },
  },

  menuItem: {
    display: "flex",
    alignItems: "center",
    color: "#000000",
    "&:hover": {
      backgroundColor: "#1171FF",
      borderTopLeftRadius: "30px",
      borderBottomRightRadius: "10px",
      color: "#fff",
    },
  },
}));

export default DashBoardScreenStyles;
