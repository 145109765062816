import {
  GlobalContext,
  useContext,
  Grid,
  Typography,
  React,
  useState,
  Button,
  NavLink,
  MyPollStyle,
  EditIcon,
  AccessTimeIcon,
  HiChartBar,
  PersonAddSharpIcon,
  ViewListIcon,
  Box,
  IconButton,
} from '../../collections/Imports';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import ChangeScheduleModal from './ChangeScheduleModal';
import PollSummaryTable from './PollSummaryTable';
import { Tooltip } from '@material-ui/core';
import ConfirmDialog from '../../AlertNotification/ConfirmDialog';
const PollSummary = (props) => {
  const { poll, fetchPolls, deletePollById, electionType } = props;
  const classes = MyPollStyle();
  const [open, setOpen] = useState(false);
  const [appData] = useContext(GlobalContext);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
  });

  const showViewQuestionButton = () => {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (poll.status.toLowerCase() === 'scheduled') {
      if (poll.rta_id && poll.election_type._id === "60844acbc18867305001c508" && poll.rta_id._id !== currentUser._id) {
        return null
      }
      return (
        <NavLink
          to={{
            pathname: '/dashboard/addquestions',
            state: { fromPage: 'mypolls' },
          }}
          style={{ textDecoration: 'none' }}
        >
          <Grid className={classes.AddQue}>
            <Grid className={classes.icondiv}>
              <ViewListIcon className={classes.icon} />
            </Grid>
            <Typography className={classes.btn}>
              VIEW/EDIT QUESTIONS
            </Typography>
          </Grid>
        </NavLink>
      )
    }
    return null
  }

  const showInviteMembersButton = () => {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if ((poll.status.toLowerCase() === 'scheduled') || poll.status.toLowerCase() === 'started') {
      if (poll.rta_id && poll.election_type._id === "60844acbc18867305001c508" && poll.rta_id._id !== currentUser._id) {
        return null
      }
      return (
        <NavLink
          to="/dashboard/invite"
          style={{ textDecoration: 'none' }}
        >
          <Grid className={classes.AddQue}>
            <Grid className={classes.icondiv}>
              <PersonAddSharpIcon className={classes.icon} />
            </Grid>
            <Typography className={classes.btn}>
              INVITE MEMBERS
            </Typography>
          </Grid>
        </NavLink>
      )
    }
    return null
  }

  const showResutlButton = () => {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (poll.status.toLowerCase() === 'closed' ||
      (electionType === `Insolvency-(IBC)` &&
        poll.status.toLowerCase() === 'closed')) {
      if (poll.rta_id && poll.election_type._id === "60844acbc18867305001c508" && poll.rta_id._id !== currentUser._id) {
        return null
      }
      return (
        <NavLink to="/dashboard/pollresult" style={{ textDecoration: 'none' }}>
          <Grid className={classes.AddQue}>
            <Grid className={classes.icondiv}>
              <HiChartBar className={classes.icon} />
            </Grid>
            <Typography className={classes.btn}>POLL RESULTS</Typography>
          </Grid>
        </NavLink>
      )
    }
    return null
  }
  return (
    <>
      <Box margin={1}>
        <Grid container spacing={2} justify="center" alignItems="center">
          <Grid item sm={11}>
            <PollSummaryTable poll={poll} />
          </Grid>
          <Grid item sm={1}>
            {poll.status.toLowerCase() === 'started' ? null : (
              <Tooltip title="Remove Poll">
                <IconButton
                  aria-label="delete"
                  id={poll.id}
                  onClick={() => {
                    setConfirmDialog({
                      isOpen: true,
                      title: 'Are you sure to delete this Poll ?',
                      subTitle: "You can't undo this operation",
                      onConfirm: () => {
                        deletePollById(props);
                      },
                    });
                  }}
                >
                  <DeleteForeverIcon style={{ color: 'red' }} />
                </IconButton>
              </Tooltip>
            )}

            <ConfirmDialog
              confirmDialog={confirmDialog}
              setConfirmDialog={setConfirmDialog}
            />
          </Grid>
        </Grid>

        {/* edit poll */}
        <Grid container spacing={2} justify="center" alignItems="center">
          <Grid item>
            {poll.status.toLowerCase() === 'scheduled' ? (
              <NavLink
                to="/dashboard/editpoll"
                style={{ textDecoration: 'none' }}
              >
                <Grid className={classes.AddQue}>
                  <Grid className={classes.icondiv}>
                    <EditIcon className={classes.icon} />
                  </Grid>
                  <Typography className={classes.btn}>EDIT POLL</Typography>
                </Grid>
              </NavLink>
            ) : null}
          </Grid>
          <Grid item>
            {
              showViewQuestionButton()
            }
          </Grid>
          <Grid item>
            {
              showInviteMembersButton()
            }
          </Grid>
          <Grid item>
            {
              showResutlButton()
            }
            {/* {poll.status.toLowerCase() === 'closed' ||
              (electionType === `Insolvency-(IBC)` &&
                poll.status.toLowerCase() === 'closed') ? (

                ): null} */}
          </Grid>
          <Grid item>
            {poll.status.toLowerCase() === 'started' &&
              poll.election_type.election_type_id !== 3 ? (
              <>
                <Button onClick={() => setOpen(true)}>
                  <Grid className={classes.AddQue}>
                    <Grid className={classes.icondiv}>
                      <AccessTimeIcon className={classes.icon} />
                    </Grid>
                    <Typography className={classes.btn}>
                      Change Scheduled
                    </Typography>
                  </Grid>
                </Button>

                <Modal
                  aria-labelledby="transition-modal-title"
                  aria-describedby="transition-modal-description"
                  className={classes.modal}
                  open={open}
                  onClose={() => setOpen(false)}
                  closeAfterTransition
                  BackdropComponent={Backdrop}
                  BackdropProps={{
                    timeout: 1000,
                  }}
                >
                  <Fade in={open}>
                    <ChangeScheduleModal
                      poll_id={appData.selected_poll_id}
                      poll={appData.selected_poll}
                      handleClose={() => setOpen(false)}
                      fetchPolls={fetchPolls}
                      enableStartDate={false}
                    />
                  </Fade>
                </Modal>
              </>
            ) : null}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default PollSummary;
