import { makeStyles } from "@material-ui/core";

export const InviteTableStyles = makeStyles((theme) => ({
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  chip: {
    fontWeight: "500",
    fontSize: "14px",
    textTransform: "capitalize",
  },
  checkbox: {
    "&.MuiCheckbox-colorSecondary.Mui-checked": {
      color: "#0b2f64",
    },
  },
  SelectedTableRow: {
    "&.MuiTableRow-root.Mui-selected": {
      backgroundColor: "rgb(224 224 224 / 61%)",
    },
  },

  dialog: {
    padding: theme.spacing(2),
    position: "absolute",
    top: theme.spacing(5),
  },
  dialogTitle: {
    textAlign: "center",
  },
  dialogContent: {
    textAlign: "center",
  },
  dialogAction: {
    justifyContent: "center",
  },
  titleIcon: {
    backgroundColor: "rgb(178,34,34)",
    color: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: "rgb(178,34,34)",
      cursor: "default",
    },
    "& .MuiSvgIcon-root": {
      fontSize: "8rem",
      color: "#fff",
    },
  },
}));
