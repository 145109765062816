import {
  React,
  useEffect,
  useState,
  useContext,
  moment,
  axiosGet,
  Grid,
  CardContent,
  TableContainer,
  TableRow,
  Typography,
  Button,
  NavLink,
  MyTableCell,
  MyPollStyle,
  AccessTimeIcon,
  HiChartBar,
  ExpandMoreIcon,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  GlobalContext,
  SpinGif,
  Box,
} from '../../collections/Imports';
import Modal from '@material-ui/core/Modal';
import useAPIError from '../../hooks/useAPIError';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import PeopleIcon from '@material-ui/icons/People';
import ChangeScheduleModal from './ChangeScheduleModal';
import MyPollTable from './MyPollTable';
import { getPollStatus } from '../CreatePoll/helpers/getPollStatus';

const ScrutinizerPoll = (props) => {
  const classes = MyPollStyle();
  const { addError } = useAPIError();
  const [open, setOpen] = useState(false);
  const [polls, setPolls] = useState(null);
  const [appData, setAppData] = useContext(GlobalContext);
  const [enableLoading, setEnableLoading] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [expanded, setExpanded] = useState(false);
  const [totalCount, setTotalCount] = useState({});

  const fetchPolls = () => {
    setEnableLoading(true);
    axiosGet('api/polls/scrutinizer/' + appData.user_id)
      .then(({ Polls }) => {
        let allStatusCounts = {
          all: 0,
          started: 0,
          closed: 0,
          scheduled: 0,
          expired: 0,
        };
        Polls?.map((item) => {
          const { status, statusCount } = getPollStatus(item, allStatusCounts);
          item.status = status;
          allStatusCounts = statusCount;
          return item;
        });

        setTotalCount(allStatusCounts);
        setPolls(Polls);
        setAppData({
          ...appData,
          selected_poll: polls ? polls[currentIndex] : null,
          selected_poll_index: currentIndex,
        });
        setEnableLoading(false);
      })
      .catch((err) => {
        addError(`Failed to fetch polls: ${err}`);
      });
  };

  //execute once
  useEffect(() => {
    fetchPolls();
  }, []);

  const handleChange = (panel, index, poll_id) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    localStorage.setItem('selected_poll_id', poll_id);
    setCurrentIndex(index);
    setAppData({
      ...appData,
      selected_poll_id: poll_id,
      selected_poll: polls[index],
      selected_poll_index: index,
    });
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return enableLoading ? (
    <Grid className={classes.loadingGrid}>
      <img src={SpinGif} alt="Spin" />
    </Grid>
  ) : polls && polls.length > 0 ? (
    <CardContent style={{ background: '' }}>
      <MyPollTable statusCount={totalCount} />

      {polls && polls.length > 0
        ? polls.map((poll, index) => (
            <Accordion
              className={classes.PollStatus}
              expanded={expanded === 'panel' + index}
              onChange={handleChange('panel' + index, index, poll._id)}
              key={poll._id}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: '#007bff' }} />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Grid item xs={4}>
                  <Typography className={classes.polltitle} noWrap>
                    {poll.poll_title} &nbsp;{' '}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    className={classes[poll.status]}
                    // style={{ textAlign: "center" }}
                  >
                    {poll.status.toUpperCase()}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  Created at &ensp;
                  <span className={classes.timing}>
                    {moment(poll.created_at).format('LL') +
                      ',  ' +
                      moment(poll.created_at).format('LT')}
                  </span>
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2} justify="center">
                  <TableContainer className={classes.polltiming}>
                    <TableRow>
                      <MyTableCell>Starting Date</MyTableCell>
                      <MyTableCell className={classes.Datetime1}>
                        {moment(poll.start_date).format('D MMM YYYY')}
                      </MyTableCell>
                      <MyTableCell>Ending Date</MyTableCell>
                      <MyTableCell className={classes.Datetime1}>
                        {moment(poll.end_date).format('D MMM YYYY')}
                      </MyTableCell>
                    </TableRow>
                    <TableRow>
                      <MyTableCell>Starting Time</MyTableCell>
                      <MyTableCell className={classes.Datetime1}>
                        {moment(poll.start_time, 'hh:mm:ss').format('hh:mm a')}
                      </MyTableCell>
                      <MyTableCell>Ending Time</MyTableCell>
                      <MyTableCell className={classes.Datetime1}>
                        {moment(poll.end_time, 'hh:mm:ss').format('hh:mm a')}
                      </MyTableCell>
                    </TableRow>
                  </TableContainer>
                  <Box>
                    <NavLink
                      to="/dashboard/allvoter"
                      style={{ textDecoration: 'none' }}
                    >
                      <Grid className={classes.AddQue} style={{minHeight: '6rem',
    marginRight: '1rem',
    marginTop: '1rem'}}>
                        <Grid className={classes.icondiv}>
                          <PeopleIcon className={classes.icon} />
                        </Grid>
                        <Typography className={classes.btn}>
                          All VOTERS
                        </Typography>
                      </Grid>
                    </NavLink>
                  </Box>
                  {poll.status.toLowerCase() === 'closed' ? (
                    <Box>
                      <NavLink
                        className={classes.linkpollresult}
                        to="/dashboard/pollresult"
                      >
                        <Grid className={classes.AddQue} style={{minHeight: '6rem',
    marginRight: '1rem',
    marginTop: '1rem'}}>
                          <Grid className={classes.icondiv}>
                            <HiChartBar className={classes.icon} />
                          </Grid>
                          <Typography className={classes.btn}>
                            POLL RESULTS
                          </Typography>
                        </Grid>
                      </NavLink>
                    </Box>
                  ) : null}

                  {(poll.status.toLowerCase() === 'started' ||
                    poll.status.toLowerCase() === 'scheduled') &&
                  poll.election_type.election_type_id !== 3 ? (
                    <Box>
                      <Button onClick={handleOpen}>
                        <Grid className={classes.AddQue}>
                          <Grid className={classes.icondiv}>
                            <AccessTimeIcon className={classes.icon} />
                          </Grid>
                          <Typography className={classes.btn}>
                            Change Scheduled
                          </Typography>
                        </Grid>
                      </Button>

                      <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        className={classes.modal}
                        open={open}
                        onClose={handleClose}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                          timeout: 1000,
                        }}
                      >
                        <Fade in={open}>
                          <ChangeScheduleModal
                            poll_id={appData.selected_poll_id}
                            poll={appData.selected_poll}
                            handleClose={handleClose}
                            fetchPolls={fetchPolls}
                            enableStartDate={false}
                          />
                        </Fade>
                      </Modal>
                    </Box>
                  ) : null}
                </Grid>
              </AccordionDetails>
            </Accordion>
          ))
        : null}
    </CardContent>
  ) : (
    <CardContent>
      <Grid
        container
        spacing={0}
        align="center"
        justify="center"
        direction="column"
        style={{
          height: '300px',
          width: '70%',
          border: '5px solid #007bff',
          margin: 'auto',
          borderRadius: '5px',
        }}
      >
        <h1>There are no polls</h1>
      </Grid>
    </CardContent>
  );
};

export default ScrutinizerPoll;
