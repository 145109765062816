import {makeStyles} from '@material-ui/core/styles';

export const footerStyles = makeStyles (theme => ({
  footer: {
    flexGrow: 1,
    padding: '30px 100px',
    // backgroundColor: '#f3f3f3',
    backgroundColor: '#fff',
    // position: 'fixed',
    // bottom: 0,
    width: '100%',
    borderTop: '1px solid grey',
    [theme.breakpoints.down("sm")]: {
      padding: '30px 52px',
    },
  },
  footerLogo: {
    height: '3rem',
  },
  paper: {
    padding: theme.spacing (2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  quicklinks: {
    Typography: {
      lineHeight: 2.0,
    },
  },
  footerHeading: {
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '24px',
    lineHeight: '29px',
    fontFamily:'inherit',
    color: '#121212',
  },
  footerdiscription: {
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '24px',
    lineHeight: '29px',
    color: '#121212',
  },
}));
