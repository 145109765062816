import {
  Toolbar,
  AppBar,
  IconButton,
  useState,
  NavBarStyles,
  Logo,
  NavLink,
  Box,
  GlobalContext,
  useContext,
} from "../../collections/Imports";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import MenuIcon from "@material-ui/icons/Menu";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
export default function ButtonAppBar() {
  const classes = NavBarStyles();
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const [appData] = useContext(GlobalContext);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  let voterLogin = false;

  try {
    const voter = localStorage.getItem("voter");
    if (voter && JSON.parse(voter)._id && JSON.parse(voter)._id) {
      voterLogin = true;
    }
  } catch (err) {
    voterLogin = false;
  }

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >

      <MenuItem
        style={{
          backgroundColor: "transparent",
          fontsize: "10px",
          fontWeight: "500",
          fontFamily: "inherit",
        }}
        component={NavLink}
        to="/"
        onClick={handleMobileMenuClose}
      >
        HOME
      </MenuItem>
      <MenuItem
        style={{
          backgroundColor: "transparent",
          fontsize: "10px",
          fontWeight: "500",
          fontFamily: "inherit",
        }}
        component={NavLink}
        to="/services"
        onClick={handleMobileMenuClose}
      >
        SERVICES
      </MenuItem>
      <MenuItem
        style={{
          backgroundColor: "transparent",
          fontsize: "10px",
          fontWeight: "500",
          fontFamily: "inherit",
        }}
        component={NavLink}
        to="/login"
        onClick={handleMobileMenuClose}
      >
        LOGIN
      </MenuItem>
      <MenuItem
        style={{
          backgroundColor: "transparent",
          fontsize: "10px",
          fontWeight: "500",
          fontFamily: "inherit",
        }}
        onClick={handleMobileMenuClose}
        component={NavLink}
        to="/signup"
      >
        SIGNUP
      </MenuItem>
      <MenuItem
        style={{
          backgroundColor: "transparent",
          fontsize: "10px",
          fontWeight: "500",
          fontFamily: "inherit",
        }}
        onClick={handleMobileMenuClose}
        component={NavLink}
        to="/aboutus"
      >
        ABOUT
      </MenuItem>
      <MenuItem
        style={{
          backgroundColor: "transparent",
          fontsize: "10px",
          fontWeight: "500",
          fontFamily: "inherit",
        }}
        onClick={handleMobileMenuClose}
        component={NavLink}
        to="/pricingplan"
      >
        PRICING
      </MenuItem>
      <MenuItem
        style={{
          backgroundColor: "transparent",
          fontsize: "10px",
          fontWeight: "500",
          fontFamily: "inherit",
        }}
        onClick={handleMobileMenuClose}
        component={NavLink}
        to="/contactus"
      >
        CONTACT
      </MenuItem>
    </Menu>
  );

  return (
    <Box className={classes.root}>
      <AppBar className={classes.root} position="fixed">
        <Toolbar>
          <NavLink to="/" className={classes.title} style={{ textDecoration: 'none' }}>
            <img src={Logo} alt="logo" />
            <p style={{ fontWeight: 600, color: '#000' }}>A Product of Votechain Private Limited</p>
          </NavLink>
          {voterLogin || appData.is_voter ? null : (
            <>
              <Box className={classes.sectionDesktop}>
                <MenuItem
                  component={NavLink}
                  to="/"
                  style={{
                    backgroundColor: "transparent",
                    fontsize: "12px",
                    fontWeight: "500",
                    fontFamily: "inherit",
                  }}
                >
                  HOME
                </MenuItem>
                <MenuItem
                  style={{
                    backgroundColor: "transparent",
                    fontsize: "12px",
                    fontWeight: "500",
                    fontFamily: "inherit",
                  }}
                  component={NavLink}
                  to="/services/"
                >
                  <span>
                    SERVICES{" "}
                    <KeyboardArrowDownIcon
                      aria-expanded={open ? "true" : undefined}
                      onClick={handleClick}
                      onMouseOver={handleClick}
                    />
                  </span>
                </MenuItem>
                <Menu
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  style={{
                    backgroundColor: "transparent",
                    fontsize: "12px",
                    fontWeight: "500",
                    fontFamily: "inherit",
                  }}
                >
                  <MenuItem
                    onClick={handleClose}
                    style={{
                      backgroundColor: "transparent",
                      fontsize: "12px",
                      fontWeight: "500",
                      fontFamily: "inherit",
                    }}
                    component={NavLink}
                    to="/services/evoting-for-irps"
                  >
                    eVoting For IRPs
                  </MenuItem>
                  {/* <MenuItem onClick={handleClose}>Housing Society Voting</MenuItem>
                    <MenuItem onClick={handleClose}>Shareholder Voting</MenuItem> */}
                  {/* <MenuItem onClick={handleClose}>Student Voting</MenuItem>
                    <MenuItem onClick={handleClose}>Others</MenuItem>
                    <MenuItem onClick={handleClose}>Customer Feedback</MenuItem> 
                    <MenuItem onClick={handleClose}>Associate Voting</MenuItem>  */}
                </Menu>
                <MenuItem
                  style={{
                    backgroundColor: "transparent",
                    fontsize: "12px",
                    fontWeight: "500",
                    fontFamily: "inherit",
                  }}
                  component={NavLink}
                  to="/blogs"
                >
                  BLOGS
                </MenuItem>
                <MenuItem
                  style={{
                    backgroundColor: "transparent",
                    fontsize: "12px",
                    fontWeight: "500",
                    fontFamily: "inherit",
                  }}
                  component={NavLink}
                  to="/aboutus"
                >
                  ABOUT
                </MenuItem>
                <MenuItem
                  style={{
                    backgroundColor: "transparent",
                    fontsize: "12px",
                    fontWeight: "500",
                    fontFamily: "inherit",
                  }}
                  component={NavLink}
                  to="/pricingplan"
                >
                  PRICING
                </MenuItem>
                <MenuItem
                  style={{
                    backgroundColor: "transparent",
                    fontsize: "12px",
                    fontWeight: "500",
                    fontFamily: "inherit",
                  }}
                  component={NavLink}
                  to="/contactus"
                >
                  CONTACT
                </MenuItem>
                <MenuItem
                  style={{
                    backgroundColor: "transparent",
                    fontsize: "12px",
                    fontWeight: "500",
                    fontFamily: "inherit",
                  }}
                  component={NavLink}
                  to="/login"
                >
                  LOG IN
                </MenuItem>
                <MenuItem
                  style={{
                    backgroundColor: "transparent",
                    fontsize: "12px",
                    fontWeight: "500",
                    fontFamily: "inherit",
                  }}
                  component={NavLink}
                  to="/signup"
                >
                  SIGN UP
                </MenuItem>
              </Box>
              <Box className={classes.sectionMobile}>
                <IconButton
                  aria-label="show more"
                  aria-controls={mobileMenuId}
                  aria-haspopup="true"
                  onClick={handleMobileMenuOpen}
                  color="inherit"
                >
                  <MenuIcon />
                </IconButton>
              </Box>
            </>
          )}
        </Toolbar>
      </AppBar>
      {voterLogin || appData.is_voter ? null : renderMobileMenu}
    </Box>
  );
}
