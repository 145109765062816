import React, { useState, createContext, useEffect } from 'react';

// Create Context object
export const GlobalContext = createContext();

// Create a provider for components to consume and subscribe to changes
export const GlobalContextProvider = (props) => {
  let currentUser = localStorage.getItem('currentUser');
  if (currentUser) {
    try {
      currentUser = JSON.parse(currentUser)
      var { user_role } = currentUser;
    } catch (err) {
      console.log("currentUser = JSON.parse(currentUser) : ", err)
    }
  }

  const [appData, setAppData] = useState({
    user_id: currentUser?._id,
    user_name: currentUser?.name,
    user_email: currentUser?.email,
    user_phone: currentUser?.phone,
    user_role: user_role ? user_role.user_role : 'Company',
    user_role_id: user_role ? user_role.user_role_id : 1,

    token: localStorage.getItem('token'),

    selected_poll_id: localStorage.getItem('selected_poll_id'),
    selected_question_id: null,
    polls: [],
    questions: [],
    from_location: '/',
    to_location: '/login',
    questionType: 'singleoption',
    flag: false,
    is_voter: false,
    pollCounterFlag: false,
    activePlans: [],
    plansFetch: false,
    selecetdPoll: {},
    selectedPollFetch: false
  });

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <GlobalContext.Provider value={[appData, setAppData]}>
      {props.children}
    </GlobalContext.Provider>
  );
};
