import React from 'react';

import {
  AddQuestionStyles,
  FormControl,
  Grid,
  Typography,
  DashBoardStyles,
  MenuHeading,
  FormControlLabel,
  Switch,
  useState
} from '../../collections/Imports';

const QuestionTitle = (props) => {
  const classes = DashBoardStyles();
  const classes1 = AddQuestionStyles();
  const [isSkippable, setIsSkippable] = useState(false);
  const handleChangeCheck = (event) => {
    setIsSkippable(event.target.checked);
    props.onChangeSkippable(event.target.checked);
  };

  return (
    <Grid item xs={12} sm={4}>
      <Typography className={classes1.questionlabel}>Question Type</Typography>
      <FormControl variant="outlined" className={classes.multisalect}>
        <MenuHeading onChangeMenu={props.onChangeMenu} question_type_id={props.question_type_id}/>
      </FormControl>
      <Grid container style={{ marginTop: '1.5rem' }}>
        <Grid item sm={8}>
          <Typography
            style={{ paddingTop: 5 }}
            className={classes1.questionlabelbold}
          >
            Skippable Question
          </Typography>
        </Grid>
        <Grid item sm={2}>
          <FormControlLabel
            control={
              <Switch
                value={isSkippable}
                onChange={(event) => handleChangeCheck(event)}
                checked={isSkippable}
                name="isSkippable"
                color="primary"
              />
            }
            label=""
          />
         
        </Grid>
      </Grid>
    </Grid>

  );
};

export default QuestionTitle;
