import { axiosPost, axiosGet } from '../../collections/Imports';

const getQuestionCastStatus = (params, onSuccess, addError) => {
  axiosPost('api/votes/get-question-cast-status', params)
    .then(onSuccess)
    .catch((err) => {
      addError(`${err}`);
    });
};

const addVote = (voter_id, questions) => {
  return axiosPost('api/votes/add-vote', { voter_id, questions });
};

const getShares = (params, setShares, setSharePercentage, addError) => {
  axiosPost('api/polls/voter/shares', params)
    .then((res) => {
      if (res) {
        setShares(Number(res.voter_share));
        setSharePercentage(res.voter_share_percentage)
      }
    })
    .catch((err) => {
      addError(`${err}`);
    });
};

const getElectionType = (poll_id, setPoll, addError) => {
  axiosGet('api/polls/election-type/' + poll_id)
    .then((res) => {
      if (res) {
        setPoll(res.poll)
      }
    })
    .catch((err) => {
      addError(`${err}`);
    });
}

const VotingServices = {
  getQuestionCastStatus,
  addVote,
  getShares,
  getElectionType
};

export default VotingServices;
