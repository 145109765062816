import {makeStyles} from '@material-ui/core/styles';
const WelcomeStyle = makeStyles (theme => ({
  card: {
    background: '#fff',
    boxShadow: '0px 0px 20px rgba(57, 57, 57, 0.15)',
    borderRadius: '10px',
    flexDirection: 'column',
    alignItems: 'left',
    padding: '105px 40px 24px',
    minHeight: '300px',
    width: '400px',
    margin: 'auto',
    marginTop: '14rem',

    [theme.breakpoints.up (400 + theme.spacing (3) * 2)]: {
      width: 400,
    },
    [theme.breakpoints.down ('sm')]: {
      // marginTop: '17rem',
      // margin: 'auto',
      width: 300,
      // position: 'relative',
      top: '17rem',
    },
  },

  mainBody: {
    background: '#1171FF',
    height: '100vh',
    width: '100%',
    backgroundSize: 'cover',
    position: 'fixed',
    [theme.breakpoints.down ('sm')]: {
      height: '158vh',
      backgroundSize: 'cover',
      position: 'relative',
    },
  },
  mobileImage: {
    width: 'auto',
    height: '361px',
    position: 'absolute',
    margin: '-300px -16px auto',
    [theme.breakpoints.down ('md')]: {
      margin: '-300px -78px auto',
    },
  },

  form: {
    width: '100%', // Fix IE 11 issue.
  },

  submit: {
    marginTop: theme.spacing (3),
    background: '#1171FF',
    boxShadow: '0px 4px 20px rgb(11 47 100 / 40%)',
    borderRadius: '12px',
    padding: '12px',
    marginBottom: '2rem',
    fontWeight: '600',
    fontSize: '12px',
  },

  inputfield: {
    background: '#FFFFFF',
    border: '1px solid #E5E5E5',
    boxSizing: 'border-box',
    // boxShadow: "inset 0px 0px 5px 1px rgba(0, 0, 0, 0.15)",
    borderRadius: '10px',
    '& .MuiOutlinedInput-input': {
      padding: '10.5px 14px',
    },
  },

  LoginHeading: {
    marginTop: '0px',
    textAlign: 'center',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '22px',
    lineHeight: '43px',
    color: '#414448',
  },
  InputLabel: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '15px',
    lineHeight: '21px',
    color: '#414448',
    textAlign: 'center',
  },
}));

export default WelcomeStyle;
