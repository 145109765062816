import * as yup from 'yup';

const phoneRegex = RegExp(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/);

const validationSchema = yup.object({
  name: yup.string('Enter your name').required('Name is required'),
  email: yup
    .string('Enter your email')
    .email('Enter a valid email')
    .required('Email is required'),
  phone: yup.string().matches(phoneRegex, 'Inavalid Phone').nullable(),
});

const validationSchemaShareholder = yup.object({
  name: yup.string('Enter your name').required('Name is required'),
  email: yup
    .string('Enter your email')
    .email('Enter a valid email')
    .required('Email is required'),
  share: yup.number('Enter shares').required('shares is required'),
  phone: yup.string().matches(phoneRegex, 'Inavalid Phone').nullable(),
});
export { validationSchema, validationSchemaShareholder };
