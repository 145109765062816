import {
  React,
  useEffect,
  Box,
  useState,
  Avatar,
} from '../../collections/Imports';

const GetOptionImage = (props) => {
  const { currentQuestion } = props;
  const [imgSrc, setImgSrc] = useState('');

  useEffect(() => {
    if (currentQuestion) {
      const { option_images } = currentQuestion;
      let index = props.index ? parseInt(props.index) : 0;
      let binary = '';
      if (option_images.length > 0 && option_images[index]?.img.data.data) {
        let bytes = [].slice.call(
          new Uint8Array(option_images[index].img.data.data)
        );
        bytes.forEach((b) => (binary += String.fromCharCode(b)));
        let imageData = window.btoa(binary);
        let contentType = option_images[index].img.contentType;
        let imgSrc = 'data:image/' + contentType + ';base64, ' + imageData;
        setImgSrc(imgSrc);
      } else {
        setImgSrc(null);
      }
    }
  }, [currentQuestion]);
  return currentQuestion.option_images[props.index]?.img.data.data ? (
    <Box>
      <Avatar
        src={imgSrc}
        alt="option-img"
        style={{ width: '50px', height: '50px' }}
      />
    </Box>
  ) : null;
};

export default GetOptionImage;
