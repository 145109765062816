import {makeStyles} from '@material-ui/core/styles';
import {styled} from '@material-ui/core/styles';
import {TableCell, Card, Typography, Button, Grid} from '@material-ui/core';

export const PollResultStyle = makeStyles (theme => ({
  root: {
    flexGrow: 1,
    paddingTop: '20px',
  },

  ResponsiveButtons: {
    [theme.breakpoints.down ('sm')]: {
      display: 'flex',
      justifyContent: 'center',
    },
  },
  downloadText: {
    float: 'right',
    marginRight: '5rem',
    marginTop: '2rem',
    [theme.breakpoints.down ('sm')]: {
      marginRight: '0px',
      float: 'none',
      textAlign: 'center',
    },
  },
  // ResponsiveButtons:{
  //   [theme.breakpoints.down('sm')]: {
  //     paddingLeft:'5rem',
  //     overflowX: 'hidden',
  //   },
  // },

  side: {},
  title: {
    display: 'none',
    [theme.breakpoints.up ('sm')]: {
      // display: 'block',
      marginLeft: '0',
    },
  },

  control: {
    padding: theme.spacing (2),
  },

  //side card media
  media: {
    // background: "#e5e5e5",
    width: '150px',
    height: '150px',
    margin: '50px auto',
  },

  span: {
    fontStyle: 'normal',
    fontSize: '18px',
    lineHeight: '22px',
    textAlign: 'center',
    color: '#000000',
    fontWeight: 500,
  },

  '.nav &a': {
    ':hover': {
      textDecoration: 'none',
    },
  },
  menuItem: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '30px',
    '&:hover': {
      backgroundColor: 'blue',
      borderTopLeftRadius: '30px',
      borderBottomRightRadius: '10px',
    },
  },

  // Start My Code
  menuItemTitle: {
    marginLeft: 16,
  },

  subtitle: {
    color: '#007bff',
    fontSize: '20px',
    fontWeight: '400',
    marginLeft: '10px',
  },

  PollWinner: {
    boxShadow: '1px 10px rgba(50, 106, 227)',
    borderRadius: '10px',
    marginBottom: '30px',
    border: '1px solid rgba(50, 106, 227, 0.4)',
    padding: '20px 10px 0px 10px',
  },

  Other2: {
    boxShadow: '1px 10px rgba(232, 229, 67)',
    borderRadius: '10px',
    marginBottom: '30px',
    border: '1px solid rgba(232, 229, 67, 0.4)',
    padding: '20px 10px 0px 10px',
  },

  Other3: {
    boxShadow: '1px 10px rgba(232, 92, 67)',
    borderRadius: '10px',
    marginBottom: '30px',
    border: '1px solid rgba(232, 92, 67, 0.4)',
    padding: '20px 10px 0px 10px',
  },

  Other4: {
    boxShadow: '1px 10px rgba(95,158,160)',
    borderRadius: '10px',
    marginBottom: '30px',
    border: '1px solid rgba(95,158,160, 0.4)',
    padding: '20px 10px 0px 10px',
  },
  Other5: {
    boxShadow: '1px 10px rgba(51 ,255 ,51)',
    borderRadius: '10px',
    marginBottom: '30px',
    border: '1px solid rgba(51 ,255 ,51, 0.4)',
    padding: '20px 10px 0px 10px',
  },
  Other6: {
    boxShadow: '1px 10px rgb(192, 0, 0)',
    borderRadius: '10px',
    marginBottom: '30px',
    border: '1px solid rgb(192, 0, 0, 0.4)',
    padding: '20px 10px 0px 10px',
  },

  Other7: {
    boxShadow: '1px 10px rgb(128, 128, 0)',
    borderRadius: '10px',
    marginBottom: '30px',
    border: '1px solid rgb(128, 128, 0,0.4)',
    padding: '20px 10px 0px 10px',
  },
  Winner: {
    fontWeight: '400',
  },
  Datetime: {
    fontWeight: '400',
    [theme.breakpoints.up ('sm')]: {
      width: 'auto',
    },
  },

  OptionCard: {
    boxShadow: '0px 2px 5px 1px rgba(0, 0, 0, 0.1)',
    padding: '2rem',
    width: '85%',
    [theme.breakpoints.down ('sm')]: {
      width: 'auto',
    },
  },

  PiechartCard: {
    padding: '4.5rem 2rem',
    float: 'left',
  },

  CardContainer: {
    padding: 'auto',
    paddingTop: '2rem',
    margin: 'auto',
  },

  chartpaper: {
    width: '100%',
    background: '#FFFFFF',
    border: '1px solid #E5E5E5',
    boxSizing: 'border-box',
    boxShadow: '0px 2px 5px 1px rgba(0, 0, 0, 0.1)',
    borderRadius: '10px',
    marginTop: '20px',
    [theme.breakpoints.down ('sm')]: {
      overflow: 'scroll',
    },
  },

  tablechartrow: {
    width: '100%',
    padding: '15px',
  },

  tablechartCellblue: {
    background: 'linear-gradient(\n180deg\n, #1171FF 0%, #579BFF 100%)',
    borderRadius: '10px 0px 0px 10px',
    textAlign: 'center',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '21px',
    color: '#000',
  },

  tablechartCellYellow: {
    background: 'linear-gradient(\n180deg\n, #F3E035 0%, #FCEE6E 100%)',
    textAlign: 'center',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '21px',
    color: '#000',
  },

  tablechartCellOrange: {
    background: 'linear-gradient(\n180deg\n, #F08E70 0%, #F48F70 100%)',
    textAlign: 'center',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '21px',
    color: '#000',
  },

  tablechartCellDarkblue: {
    background: 'linear-gradient(\n180deg\n, #5F9EA0 0%, #5F9EA0 100%)',
    textAlign: 'center',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '21px',
    color: '#000',
  },

  tablechartCellGreen: {
    background: 'linear-gradient(\n180deg\n, #33FF00 0%, #33FF33 100%)',
    textAlign: 'center',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '21px',
    color: '#000',
  },

  tablechartCellRed: {
    background: 'linear-gradient(\n180deg\n, #C00000 0%, #D00000  100%)',
    textAlign: 'center',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '21px',
    color: '#000',
  },

  tablechartCellOlive: {
    background: 'linear-gradient(\n180deg\n, #808000 0%, #808000  100%)',
    textAlign: 'center',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '21px',
    color: '#000',
  },

  ArrowIconL: {
    position: 'relative',
    top: '30px',
    fontSize: 'none',
    float: 'left',
    zIndex: 100,
    backgroundColor: '#f4f4f4',
    borderRadius: '50%',
    marginLeft: '-30px',
    cursor: 'pointer',
  },

  ArrowIconL1: {
    position: 'relative',
    top: '30px',
    float: 'left',
    zIndex: 100,
    backgroundColor: '#f4f4f4',
    borderRadius: '50%',
    marginLeft: '-30px',
    cursor: 'pointer',
  },

  ArrowIconR: {
    float: 'right',
    cursor: 'pointer',
    zIndex: '100',
    position: 'relative',
    top: '30px',
    right: '-20px',
    borderRadius: '50%',
    backgroundColor: '#f4f4f4',
  },

  ArrowIconR1: {
    float: 'right',
    cursor: 'pointer',
    zIndex: '100',
    position: 'relative',
    top: '30px',
    right: '-20px',
    borderRadius: '50%',
    backgroundColor: '#f4f4f4',
  },

  CardOption: {
    background: '#FFFFFF',
    // border: "1px solid #E5E5E5",
    boxSizing: 'border-box',
    // boxShadow: "0px 2px 5px 1px rgba(0, 0, 0, 0.1)",
    borderRadius: '10px',
    padding: '2rem',
    minHeight: '28rem',
  },

  Mainheading: {
    color: '#007bff',
    fontSize: '28px',
    marginTop: '-28px',
  },
  paraOptionstyle: {
    fontWeight: '500',
    fontSize: '1.2rem',
  },

  Optionhead: {
    margin: '15px 0px',
    textAlign: 'left',
    fontSize: '22px',
    padding: '10px',
  },
  NoResult: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    minHeight: '450px',
  },
}));

export const Para = styled (Typography) ({
  fontSize: '16px',
  lineHeight: '19px',
  textAlign: 'center',
  color: '#000000',
  fontStyle: 'normal',
  fontWeight: 'normal',
});

export const MyTableCell = styled (TableCell) ({
  borderBottom: 'none',
});

export const MyCard = styled (Card) ({
  background: '#fff',
  boxShadow: '0px 0px 20px rgba(57, 57, 57, 0.15)',
  borderRadius: '10px',
});

export const DownloadButton = styled (Button) ({
  fontSize: '12px',
  padding: '15px',
  textTransform: 'capitalize',
  fontWeight: '400',
  borderRadius: '10px',
  marginTop: '10px',
  // [theme.breakpoints.up('sm')]: {
  //   display: 'block',
  // },
});

export const PollViewerGridWinner = styled (Grid) ({
  width: '41.2%',
  backgroundColor: 'skyblue',
  height: '100px',
});

export const PollViewerGridSecond = styled (Grid) ({
  width: '41.2%',
  backgroundColor: 'skyblue',
  height: '100px',
});

export const Para1 = styled (Typography) ({
  fontWeight: '400',
  fontSize: '20px',
  color: 'black',
});

export const Para2 = styled (Typography) ({
  fontWeight: '400',
  marginTop: '20px',
  fontSize: '17px',
  color: 'black',
  margin: 'auto',
});
export const Percentage = styled (Typography) ({
  fontWeight: '400',
  fontSize: '20px',
  color: 'black',
  float: 'right',
});
export const OtherPercentage = styled (Typography) ({
  fontWeight: '400',
  fontSize: '17px',
  color: 'black',
  float: 'right',
});
export const Para3 = styled (Typography) ({
  marginTop: '20PX',
  textAlign: 'right',
});
export const Para4 = styled (Typography) ({
  fontWeight: '400',
  color: 'rgba(50, 106, 227)',
  marginTop: '30px',
});
export const Para5 = styled (Typography) ({
  fontWeight: '400',
  color: '#000000',
  marginTop: '40px',
});
export const FullReport = styled (Typography) ({
  fontWeight: '400',
  color: '#3f51b5',
  margin: 'auto',
});

export const TableCellOption = styled (TableCell) ({
  width: '100%',
});

export const Bullet = styled (Grid) ({
  height: props => props.height,
  width: props => props.width,
  borderRadius: '999px',
  backgroundColor: props => props.bulletColor,
  display: 'inline-block',
});
