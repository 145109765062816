import { makeStyles } from '@material-ui/core/styles';

const LoginStyles = makeStyles((theme) => ({
  main: {
    width: 'auto',
    display: 'block',
    [theme.breakpoints.up(800 + theme.spacing(3) * 2)]: {
      width: 470,
    },
  },
  imgapp1:{
    width:'80%',
    [theme.breakpoints.down ('sm')]: {
      width:'100%',
    },
  },
  card: {
    background: '#fff',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    marginBottom: '2rem',
    padding: `${theme.spacing(5)}px ${theme.spacing(5)}px ${theme.spacing(
      3
    )}px`,
  },

  paper: {},
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    marginTop: theme.spacing(3),
    background: '#1171FF',
    color:"#fff",
    boxShadow: '0px 4px 20px rgb(11 47 100 / 40%)',
    borderRadius: '15px',
    padding: '15px',
    marginBottom: '2rem',
    '& :hover':{
      color:"#000", 
      fontWeight:'600',     
    },
  },

  disabledSubmit: {
    marginTop: theme.spacing(3),
    background: '#FFF',
    boxShadow: '0px 4px 20px rgb(11 47 100 / 40%)',
    borderRadius: '15px',
    padding: '15px',
    marginBottom: '2rem',
  },
  submitText: {
    fontSize: 13,
    color: '#000000',
  },
  submitText2: {
    fontSize: 13,
    fontWeight: '700',
  },
  gridContainer: {
    padding: '50px',
    paddingTop: '5rem',
    marginBottom: '-50px',
    [theme.breakpoints.down ('sm')]: {
      padding: '30px',
    },
  },
  inputfield: {
    borderRadius: '10px',
    // '& .MuiOutlinedInput-input': {
    //   padding: '12.5px 14px',
    // },
  },
  Forgotlink: {
    justifyContent: 'center',
    margin: 'auto',
    textAlign: 'center',
    display: 'flex',
    fontSize: '16px',
    paddingBottom: '10px',
  },
  bg: {
    position: 'fixed',
    overflowX: 'hidden',
    width: '100%',
    background: '#1171FF',
    marginTop: '5px',
    height: '100vh',
    paddingTop: '64px',
    backgroundImage: `url(${'/static/media/bgimage-vt.af4debb1.png'})`,
    backgroundPosition: 'left top',
  },
  imgnText: {
    padding: '50px',
    zIndex: '100',
  },
  otpInputs: {
    width: '1.8em',
    textAlign: 'center',
  },
  LoginHeading: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '25px',
    lineHeight: '43px',
    color: '#414448',
    textAlign: 'center',
  },
  loginText: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '35px',
    lineHeight: '58px',
    color: '#0B2F64',
    marginLeft: '30px',
    [theme.breakpoints.down('md')]: {
      marginRight: '30',
    },
  },

  digitText: {
    lineHeight: '29px',
    marginTop: '4rem',
  },

  otpInputbox: {
    marginTop: '1rem',
    fontSize: '29px',
    lineHeight: '29px',
    background: '#FFFFFF',
    boxShadow: 'inset 0px 0px 5px 1px rgba(0, 0, 0, 0.15)',
    borderRadius: '10px',
    marginRight: '0.5rem',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      fontSize: '15px',
      justifyContent: 'center',
      alignItems: 'center',
    },
    // [theme.breakpoints.down('xs')]: {
    //   fontSize: '12px',
    // },
  },
  messageText: {
    marginTop: '2rem',
    textAlign: 'center',
    FontFamily:'inherit',
    fontWeight:'500'
  },
}));

export default LoginStyles;
